import AccordionItem from "components/AccordionItem";
import Button from "components/Button";
import { GlobalContext } from "context";
import { useApiCall } from "hooks";
import React, {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { FiX } from "react-icons/fi";
import { getCustomStrategyData } from "services";
import CreateCustomStrategyForm from "components/CreateCustomStrategyForm";
import { Box, Modal } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";
import {
  customStrategyReducer,
  ICreateStrategyModal,
  modalStyles,
} from "./utils";
import Fileicon from "assets/images/file-icon.svg";

const CreateStrategyModal: React.FC<ICreateStrategyModal> = ({
  isOpen,
  setIsOpen,
  data,
  onAdd,
}) => {
  const [fetchData, loading] = useApiCall(getCustomStrategyData);
  const { state } = useContext(GlobalContext);
  const { machiningSetups, setupNumber, projectInfo } = state;
  const [templateData, setTemplateData] = useState<any>({});
  const [customStrategy, customStrategyDispatch] = useReducer(
    customStrategyReducer,
    {
      isSelected: false,
      passes: [],
      custom: true,
      recommended: false,
    }
  );

  useEffect(() => {
    (async () => {
      try {
        if (!isOpen) return;
        const setupIdx = machiningSetups?.findIndex(
          (setup: any) => setup?.machiningSetupNumber === setupNumber
        );
        const featureIdx = machiningSetups?.[setupIdx]?.features?.findIndex(
          (feature: any) => feature?.feature_id === data?.feature_id
        );
        const featureInformation = {
          ...machiningSetups?.[setupIdx]?.features?.[featureIdx]
            ?.featureInformation,
        };
        const res = await fetchData({
          featureName: featureInformation?.feature_data?.feature_name,
          camSoftware: projectInfo.cam_software ?? "siemens",
        });
        if (!res) {
          throw new Error("Unable to fetch data! Please try again later");
        }
        setTemplateData(res.custom_strategy_options);
        customStrategyDispatch({
          type: "SET-PASSES",
          payload: Object.keys(
            res.custom_strategy_options.recommended_cutting_passes
          ),
        });
      } catch (error: any) {
        setIsOpen(false);
        toast.error(error.message);
      }
    })();
  }, [
    isOpen,
    data,
    fetchData,
    machiningSetups,
    projectInfo.cam_software,
    setupNumber,
    setIsOpen,
  ]);

  const createStrategy = useCallback(() => {
    // Sample
    // {
    //   "isSelected": false,
    //   "passes": [
    //     {
    //       "pass": "Roughing",
    //       "operations": [
    //         {
    //           "location": "Pocket - Pass 1 for Island 1 top, Island 2 top, Floor",
    //           "tool_paths": [
    //             {
    //               "tool_path_style": "Adaptive Milling",
    //               "tool_types": [
    //                 "Bull nose end mill"
    //               ],
    //               "tool_path_type": "mill_Planar",
    //               "tool_path": "Planer Mill"
    //             }
    //           ],
    //           "operation": "Pocket milling"
    //         }
    //       ]
    //     }
    //   ],
    //   "custom": true,
    //   "recommended": false
    // }
    const strategy: any = {
      passes: [],
      isSelected: false,
      custom: true,
      recommeded: false,
      isValid: true,
      feedback: "",
    };
    let totalOperationsCount = 0;
    customStrategy.passes?.forEach((pass: any) => {
      strategy.passes.push({
        pass: pass.pass,
        operations: pass.operations.map((operation: any) => {
          totalOperationsCount++;
          return {
            operation: operation.operation,
            location: operation.location,
            tool_paths: [
              {
                tool_types: [operation.toolType],
                tool_path_type: operation.toolPathType,
                ...(operation.toolPathStyle && {
                  tool_path_style: operation.toolPathStyle,
                }),
                ...(operation.toolPathName && {
                  tool_path: operation.toolPathName,
                }),
              },
            ],
            isValid: true,
            feedback: "",
          };
        }),
      });
    });
    strategy.passes = strategy.passes.filter(
      (pass: any) => pass.operations.length
    );
    if (totalOperationsCount) {
      onAdd(strategy);
      setIsOpen(false);
    } else {
      toast.error("No information is present");
    }
  }, [customStrategy, onAdd, setIsOpen]);

  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)}>
      <Box sx={modalStyles}>
        <div className="flex flex-row items-center justify-between p-[16px] border-b border-gray-300">
          <h3 className="text-lg font-medium leading-6 text-black-222">
            Create Strategy
          </h3>
          <FiX
            className="w-6 h-6 cursor-pointer text-black-222"
            onClick={() => setIsOpen(false)}
          />
        </div>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              overflow: "hidden",
              justifyContent: "center",
              marginY: "16px",
            }}
          >
            <CircularProgress size={80} />
          </Box>
        ) : (
          <Box
            sx={{
              overflowY: "auto",
              maxHeight: "60vh",
            }}
          >
            <div className="p-[16px] inline-flex flex-col cgap-16 w-full">
              {customStrategy.passes?.map((passObj: any, passIndex: number) => (
                <AccordionItem
                  key={passObj.pass}
                  tabLabelChild={
                    <>
                      <img src={Fileicon} alt="file icon" />
                      {passObj.pass}
                    </>
                  }
                  labelChildClass={
                    "!text-[14px] font-semibold text-gray-600 p-[8px] gap-[8px] text-[14px]"
                  }
                  className="!border-gray-200 rounded-[4px] overflow-hidden p-0"
                  tabContentClassName="!p-0"
                  dragIcon={false}
                >
                  <div className="flex flex-col gap-[8px] !p-[8px] !pl-0 border-t border-gray-200">
                    {/* {passObj.operations?.length > 0 && !passObj.openForm}  */}
                    {/* Change this condition to show/hide form single check */}
                    {passObj.operations?.length > 0 && !passObj.openForm && (
                      <div>
                        {passObj.operations.map(
                          (operationObj: any, operationIndex: number) => (
                            <div key={operationIndex} className="flex relative">
                              <div className="flex w-[16px] flex-col items-center gap-[4px]">
                                <div className="w-[8px] h-[8px] border border-green-400 mt-[5px] bg-green-200 rounded-[8px]"></div>
                                {operationIndex !==
                                  passObj.operations?.length - 1 && (
                                  <div className="bg-green-500 h-[calc(100%_-_2px)] w-[2px] rounded-[4px] absolute top-[12px]"></div>
                                )}
                              </div>
                              <div className="flex-col flex gap-[8px] w-full">
                                <div className="flex items-center gap-[4px]">
                                  <div className="text-[12px] w-full text-gray-500">
                                    Operation:{" "}
                                    <span className="font-semibold">
                                      {operationObj.operation}
                                    </span>
                                  </div>

                                  <button
                                    onClick={() => {
                                      customStrategyDispatch({
                                        type: "SET-SELECTED-DATA",
                                        payload: { passIndex, operationIndex },
                                      });
                                      customStrategyDispatch({
                                        type: "SET-FORM-VIEW-STATUS",
                                        payload: {
                                          passIndex,
                                          value: true,
                                        },
                                      });
                                    }}
                                  >
                                    <svg
                                      className="w-[16px] h-[16px] cursor-pointer"
                                      width="14"
                                      height="14"
                                      viewBox="0 0 14 14"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M2.45682 11.9702C2.40068 11.9708 2.34498 11.9603 2.29292 11.9392C2.24087 11.9182 2.19348 11.8871 2.15349 11.8477C2.10764 11.8024 2.07236 11.7476 2.05018 11.6871C2.02799 11.6266 2.01945 11.5619 2.02516 11.4977L2.20599 9.2577C2.21374 9.15368 2.25941 9.05612 2.33432 8.98354L8.78599 2.53187C9.14547 2.19524 9.62374 2.01484 10.116 2.0302C10.605 2.034 11.0743 2.22379 11.4285 2.56104C11.7682 2.90987 11.9636 3.37418 11.9755 3.86095C11.9874 4.34772 11.8149 4.82102 11.4927 5.18604L5.03516 11.6669C4.96288 11.7389 4.86825 11.7841 4.76682 11.7952L2.49766 11.9994L2.45682 11.9702ZM3.04016 9.4852L2.91766 11.0835L4.51599 10.9377L10.8743 4.5677C10.9983 4.42098 11.0775 4.24176 11.1026 4.05132C11.1278 3.86089 11.0977 3.66725 11.016 3.49341C10.9343 3.31957 10.8044 3.17283 10.6418 3.07063C10.4791 2.96843 10.2906 2.91506 10.0985 2.91687C9.97225 2.90885 9.84569 2.92642 9.7264 2.96852C9.60712 3.01063 9.49757 3.07639 9.40432 3.16187L3.04016 9.4852Z"
                                        fill="#475467"
                                      />
                                    </svg>
                                  </button>

                                  <button
                                    onClick={() => {
                                      customStrategyDispatch({
                                        type: "DELETE-OPERATION",
                                        payload: { passIndex, operationIndex },
                                      });
                                    }}
                                  >
                                    <svg
                                      className="w-[16px] h-[16px] cursor-pointer"
                                      width="14"
                                      height="14"
                                      viewBox="0 0 14 14"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M7.83333 6.16667V9.66667M5.5 6.16667V9.66667M3.16667 3.83333V10.8333C3.16667 11.1428 3.28958 11.4395 3.50838 11.6583C3.72717 11.8771 4.02391 12 4.33333 12H9C9.30942 12 9.60616 11.8771 9.82496 11.6583C10.0437 11.4395 10.1667 11.1428 10.1667 10.8333V3.83333M2 3.83333H11.3333M3.75 3.83333L4.91667 1.5H8.41667L9.58333 3.83333"
                                        stroke="#D92D20"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </button>
                                </div>
                              </div>
                            </div>
                          )
                        )}
                        <div className="cursor-pointer inline-flex text-blue-600 text-[12px] font-medium ml-[16px]">
                          <button
                            onClick={() => {
                              customStrategyDispatch({
                                type: "ADD-NEW-OPERATION",
                                payload: { passIndex },
                              });
                            }}
                          >
                            + Add new operation...
                          </button>
                        </div>
                      </div>
                    )}
                    {(!passObj.operations?.length || passObj.openForm) && (
                      <div className="flex relative w-full pl-[4px]">
                        <CreateCustomStrategyForm
                          pass={passObj.pass}
                          editRowData={passObj.selectedData}
                          templateData={templateData}
                          showCancelButton={!!passObj.operations?.length}
                          onCancel={() => {
                            customStrategyDispatch({
                              type: "SET-SELECTED-DATA",
                              payload: { passIndex, reset: true },
                            });
                            customStrategyDispatch({
                              type: "SET-FORM-VIEW-STATUS",
                              payload: {
                                passIndex,
                                value: false,
                              },
                            });
                          }}
                          onSave={(data: any) => {
                            customStrategyDispatch({
                              type: "SET-OPERATION",
                              payload: {
                                passIndex,
                                operation: { ...data },
                              },
                            });
                          }}
                        />
                      </div>
                    )}
                  </div>
                </AccordionItem>
              ))}
            </div>
          </Box>
        )}
        <div className="flex justify-center w-full p-[16px] border-t border-gray-300">
          <Button size="md" className="modal-button" onClick={createStrategy}>
            Add
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default CreateStrategyModal;
