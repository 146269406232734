/* Objects for assignedMachiningSetups and mappingForMachiningSetups */

// assignedMachiningSetups : {
//     setupId: [{
//         featureId: string,
//         operationIndex: number,
//         passIndex: number,
//         strategyIndex: number,
//     },{},{}]
// }

// mappingForMachiningSetups : {
// setupId : {
// index: number,
// name: number
//   }
// }

export function assignSetup(
  operationIndex: number,
  passIndex: number,
  strategyIndex: number,
  featureId: any,
  assignedMachiningSetups: any,
  machiningSetups: any,
  toAssignId: string,
  mappingForMachiningSetups: any
) {
  //setup index for to be assigned setup
  const toAssignIndex = mappingForMachiningSetups?.[toAssignId]?.index;
  let errorMessage = "";
  let lowerBoundOperationInd,
    lowerBoundSetupInd,
    upperBoundOperationInd,
    upperBoundSetupInd;

  lowerBoundSetupInd = -1;
  upperBoundSetupInd = 100000;

  const featureIndex = machiningSetups?.[0]?.features?.findIndex(
    (feature: any) => feature?.feature_id === featureId
  );
  const feature_information = {
    ...machiningSetups?.[0]?.features?.[featureIndex]?.featureInformation,
  };

  const selected_strategy =
    feature_information?.strategies?.[0]?.machining_strategy?.[strategyIndex];
  let found = false;
  let passIterator = passIndex,
    operationIterator = operationIndex - 1;
  while (passIterator >= 0) {
    while (operationIterator >= 0) {
      if (
        selected_strategy?.passes?.[passIterator]?.operations?.[
          operationIterator
        ]?.assignedSetupId
      ) {
        lowerBoundSetupInd =
          mappingForMachiningSetups?.[
            selected_strategy?.passes?.[passIterator]?.operations?.[
              operationIterator
            ]?.["assignedSetupId"]
          ]?.index;
        lowerBoundOperationInd =
          selected_strategy?.passes?.[passIterator]?.operations?.[
            operationIterator
          ]?.["pass_number"];
        found = true;
        break;
      }
      operationIterator -= 1;
    }
    if (passIterator > 0)
      operationIterator =
        selected_strategy?.passes?.[passIterator - 1]?.operations?.length - 1;
    if (found) break;
    passIterator -= 1;
  }
  // Finding operation after the current one that has an assigned setup
  passIterator = passIndex;
  operationIterator = operationIndex + 1;
  found = false;
  const totalPasses = selected_strategy?.passes?.length ?? 0;
  while (passIterator < totalPasses) {
    while (
      operationIterator <
      selected_strategy?.passes?.[passIterator]?.operations?.length
    ) {
      if (
        selected_strategy?.passes?.[passIterator]?.operations?.[
          operationIterator
        ]?.assignedSetupId
      ) {
        upperBoundSetupInd =
          mappingForMachiningSetups?.[
            selected_strategy?.passes?.[passIterator]?.operations?.[
              operationIterator
            ]?.["assignedSetupId"]
          ]?.index;
        upperBoundOperationInd =
          selected_strategy?.passes?.[passIterator]?.operations?.[
            operationIterator
          ]?.["pass_number"];
        found = true;
        break;
      }
      operationIterator += 1;
    }
    operationIterator = 0;
    if (found) break;
    passIterator += 1;
  }
  if (
    toAssignIndex < lowerBoundSetupInd ||
    toAssignIndex > upperBoundSetupInd
  ) {
    //Error message when cannot assign setup
    if (toAssignIndex < lowerBoundSetupInd) {
      errorMessage = `Moving this operation to a lower setup <b>${mappingForMachiningSetups?.[toAssignId]?.name}</b> is not allowed due to its sequence position in Feature-Operations.`;
    } else
      errorMessage = `Moving this operation to a higher setup <b>${mappingForMachiningSetups?.[toAssignId]?.name}</b> is not allowed due to its sequence position in Feature-Operations.`;
    return {
      changedMachiningSetups: [],
      changedAssignedMachiningSetups: {},
      errorMessage,
    };
  }
  let assignedOperationIndex = -1;
  switch (true) {
    case toAssignIndex === lowerBoundSetupInd:
      assignedOperationIndex = lowerBoundOperationInd + 1;
      break;
    case toAssignIndex === upperBoundSetupInd:
      assignedOperationIndex = Math.max(upperBoundOperationInd - 1, 0);
      break;
    case toAssignIndex > lowerBoundSetupInd &&
      toAssignIndex < upperBoundSetupInd:
      assignedOperationIndex =
        assignedMachiningSetups?.[toAssignId]?.length ?? 0;
      break;
    default:
      break;
  }
  // "Adding the given operation to assignedMachiningSetups at the assignedOperationIndex."
  assignedMachiningSetups?.[toAssignId]?.splice(assignedOperationIndex, 0, {
    featureId,
    operationIndex,
    passIndex,
    strategyIndex,
  });

  //Change machiningSetups for the following operation.
  selected_strategy.passes[passIndex].operations[
    operationIndex
  ].assignedSetupId = toAssignId;
  selected_strategy.passes[passIndex].operations[operationIndex].pass_number =
    assignedOperationIndex;

  assignedMachiningSetups?.[toAssignId]?.forEach(
    (operation: any, index: number) => {
      const featureIndex = machiningSetups?.[0]?.features?.findIndex(
        (feature: any) => feature?.feature_id === operation?.featureId
      );
      machiningSetups[0].features[
        featureIndex
      ].featureInformation.strategies[0].machining_strategy[
        operation.strategyIndex
      ].passes[operation?.passIndex].operations[operation?.operationIndex][
        "pass_number"
      ] = index;
    }
  );

  return {
    changedMachiningSetups: machiningSetups,
    changedAssignedMachiningSetups: assignedMachiningSetups,
    errorMessage: "",
  };
}

export function deleteSetups(
  deletedSetups: string[],
  machiningSetups: any,
  mappingForMachiningSetups: any,
  assignedMachiningSetups: any
) {
  deletedSetups?.forEach((setupId: string) => {
    // delete key of assignedSetup from every operation for the current setup
    assignedMachiningSetups?.[setupId]?.forEach((operation: any) => {
      const featureIndex = machiningSetups?.[0]?.features?.findIndex(
        (feature: any) => feature?.feature_id === operation?.featureId
      );
      const currentOperation =
        machiningSetups?.[0]?.features?.[featureIndex]?.featureInformation
          ?.strategies?.[0]?.machining_strategy?.[operation.strategyIndex]
          ?.passes?.[operation?.passIndex]?.operations?.[
          operation?.operationIndex
        ];

      delete currentOperation.assignedSetupId;
      delete currentOperation.pass_number;
    });
    // delete setup from AssignedMachiningSetups
    delete assignedMachiningSetups[setupId];
    delete mappingForMachiningSetups[setupId];
  });

  // change mapping indices for all the setups
  Object.keys(mappingForMachiningSetups).forEach(
    (setupId: string, index: number) => {
      mappingForMachiningSetups[setupId].index = index;
    }
  );
  return {
    changedMachiningSetups: machiningSetups,
    changedAssignedMachiningSetups: assignedMachiningSetups,
    changedMappingForMachiningSetups: mappingForMachiningSetups,
  };
}

export function unAssignSetup(
  pass_number: number,
  setupId: string,
  machiningSetups: any,
  assignedMachiningSetups: any
) {
  let operationList = assignedMachiningSetups[setupId];
  for (let index = pass_number; index < operationList.length; index++) {
    const featureIndex = machiningSetups?.[0]?.features?.findIndex(
      (feature: any) => feature?.feature_id === operationList[index]?.featureId
    );
    const feature_information =
      machiningSetups?.[0]?.features?.[featureIndex]?.featureInformation;

    const selected_strategy =
      feature_information?.strategies?.[0]?.machining_strategy?.[
        operationList[index]?.strategyIndex
      ];
    if (index === pass_number) {
      delete selected_strategy.passes[operationList[index]?.passIndex]
        .operations[operationList[index]?.operationIndex].pass_number;
      delete selected_strategy.passes[operationList[index]?.passIndex]
        .operations[operationList[index]?.operationIndex].assignedSetupId;
    } else
      selected_strategy.passes[operationList[index]?.passIndex].operations[
        operationList[index]?.operationIndex
      ].pass_number = index - 1;
  }
  assignedMachiningSetups[setupId].splice(pass_number, 1);

  assignedMachiningSetups?.[setupId]?.forEach(
    (operation: any, index: number) => {
      const featureIndex = machiningSetups?.[0]?.features?.findIndex(
        (feature: any) => feature?.feature_id === operation?.featureId
      );
      machiningSetups[0].features[
        featureIndex
      ].featureInformation.strategies[0].machining_strategy[
        operation.strategyIndex
      ].passes[operation?.passIndex].operations[operation?.operationIndex][
        "pass_number"
      ] = index;
    }
  );
  return {
    changedMachiningSetups: machiningSetups,
    changedAssignedSetups: assignedMachiningSetups,
  };
}

export const compareSetups = (assigned: any[], origin: any[]) => {
  if (assigned.length !== origin.length) {
    return true;
  }

  for (let i = 0; i < assigned.length; i++) {
    const op1 = assigned[i];
    const op2 = origin[i];
    if (
      op1.featureId !== op2.featureId ||
      op1.operationIndex !== op2.operationIndex ||
      op1.passIndex !== op2.passIndex ||
      op1.strategyIndex !== op2.strategyIndex
    ) {
      return true;
    }
  }
  return false;
};
