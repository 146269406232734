import { useState, useContext, useEffect, useCallback } from "react";
import AccordionItem from "components/AccordionItem";
import { Checkbox } from "components/Checkbox";
import Step from "components/Step";
import StepSlider from "components/StepSlider";
import SummaryBox from "components/SummaryBox";
import Toolbox from "components/Toolbox";
import {
  keyList,
  SET_STREAM_STEP,
  SET_LOADING_BAR,
  SET_MACHINE_SETUP,
  SET_ORIGIN_MACHINE_SETUP,
  SET_PROJECT_INFO,
} from "constant";
import { GlobalContext } from "context/GlobalContext";
import { useApiCall } from "hooks";
import {
  getCuttingToolAPI,
  getParametersAPI,
  postCreateUserSelectionAPI,
  putProjectAPI,
  putUserSelectionAPI,
} from "services";
import Button from "components/Button";
import { useLocation, useNavigate } from "react-router-dom";
import {
  extractSelectedDataFromMachiningSetups,
  getLowestValue,
  isObj,
  resetScroll,
} from "utils";
import ChangeStrategy from "./ChangeStrategy";
import clsx from "clsx";
import { FiArrowRight } from "react-icons/fi";
import NoFoundImg from "assets/images/NoFound.svg";
import NoParamsToolbox from "components/NoParamsToolbox";
import AddToolModal from "components/AddToolModal";

const CuttingTool = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const {
    showTooltip,
    hideTooltip,
    projectInfo,
    setupNumber,
    machiningSetups,
    visiblefeature_ids,
    originMachiningSetups,
    mappingForMachiningSetups,
    assignedMachiningSetups,
    userInfo,
    featureMapping,
  } = state;
  const [stepData, setStepData] = useState<any>([]);
  const [selectedStep, setSelectedStep] = useState(stepData[0]);
  const navigate = useNavigate();

  const [putProject] = useApiCall(putProjectAPI);
  const [postCreateUserSelection]: any = useApiCall(postCreateUserSelectionAPI);
  const [putUserSelection]: any = useApiCall(putUserSelectionAPI);

  const [selectedCard, setSelectedCard] = useState(0);
  const [selectedItem, setSelectedItem] = useState<any>({
    type: null,
    index: null,
    key: null,
  });
  const [selectedTitle, setSelectedTitle] = useState<any>(null);
  const [boxData, setBoxData] = useState<any>(null);
  const [getCuttingTool] = useApiCall(getCuttingToolAPI);
  const [toolData, setToolData] = useState<any>(null);
  const [getParameters] = useApiCall(getParametersAPI);
  const [parameterData, setParameterData] = useState<any>(null);
  const [mrrValue, setMrrValue] = useState<any>(0);

  const [machiningStrategySummaryData, setMachiningStrategySummaryData] =
    useState([]);
  const [showChangeCategory, setShowChangeCategory] = useState(false);

  const [availableList, setAvailableList] = useState([]);
  const [possibleList, setPossibleList] = useState([]);

  const [selectToolID, setSelectToolID] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isFirst, setIsFirst] = useState(true);
  const [openAddToolModal, setOpenAddToolModal] = useState(false);
  const [preparingBoxData, setPreparingBoxData] = useState(true);

  const countStepsWithSelectedTool = useCallback((strategy: any) => {
    let visitedCount = 0;
    strategy?.passes?.forEach((pass: any) => {
      pass?.operations?.forEach((operation: any) => {
        visitedCount += operation?.selectedToolId ? 1 : 0;
      });
    });
    return visitedCount;
  }, []);

  useEffect(() => {
    const setupIdx = machiningSetups?.findIndex(
      (setup: any) => setup?.machiningSetupNumber === setupNumber
    );

    if (setupIdx < 0 || !visiblefeature_ids?.[setupNumber]?.length) return;

    let arr: any = [];
    keyList?.forEach(({ title, type }) => {
      const existing_features = machiningSetups?.[setupIdx]?.features?.filter(
        (feature: any) =>
          feature?.featureInformation?.feature_data?.feature_type === type
      );
      if (!existing_features?.length) return;
      let detailData: any[] = [];
      existing_features?.forEach((feature: any, idx1: number) => {
        const strategy_index =
          feature.featureInformation?.strategies?.[0]?.machining_strategy?.findIndex(
            (item: any) => item?.isSelected
          );
        const selected_strategy =
          feature.featureInformation?.strategies?.[0]?.machining_strategy?.[
            strategy_index
          ];
        if (
          !visiblefeature_ids?.[setupNumber]?.includes(feature?.feature_id) ||
          !selected_strategy
        )
          return;
        let stepCount = 0;
        selected_strategy.passes?.forEach((pass: any) => {
          pass?.operations?.forEach((operation: any) => {
            stepCount += 1;
          });
        });
        detailData.push({
          title: `${title} ${idx1 + 1}`,
          index: idx1,
          feature_id: feature?.feature_id,
          featureInformation: feature?.featureInformation,
          stepCount,
          strategy_index,
        });
      });
      if (!detailData.length) return;
      arr.push({
        title,
        type,
        data: detailData,
      });
    });

    if (isFirst && !!arr?.length) {
      setSelectedItem({
        type: arr?.[0]?.type,
        feature_id: arr?.[0]?.data?.[0]?.feature_id,
      });
      setSelectedTitle(arr?.[0]?.data?.[0]?.title);
      setIsFirst(false);
    }

    setAvailableList(arr);

    let list =
      arr?.find((category: any) => {
        return !!category?.data?.find(({ featureInformation }: any) => {
          return !!featureInformation?.strategies?.find((strategy: any) => {
            return !!strategy?.machining_strategy?.find(
              (mach: any) =>
                !!mach?.isSelected &&
                !!mach?.passes?.find((pass: any) =>
                  pass?.operations?.find(
                    (operation: any) => !!operation?.["selectedToolId"]
                  )
                )
            );
          });
        });
      }) || [];
    setPossibleList(list?.data || []);
  }, [machiningSetups, visiblefeature_ids]);

  const getBoxData = (parameterData: any) => {
    const lowestCost = getLowestValue(
      parameterData || [],
      "Objective function value"
    );
    let newParameterData = [...parameterData];
    newParameterData?.forEach((item, idx) => {
      if (lowestCost === item["Objective function value"])
        newParameterData[idx] = { ...item, label: "Lowest cost" };
      else {
        const percent =
          ((item["Objective function value"] - lowestCost) / lowestCost) * 100;
        newParameterData[idx] = {
          ...item,
          label: `+${percent.toFixed(4)}% cost`,
        };
      }
    });
    return newParameterData;
  };

  useEffect(() => {
    if (!selectedItem?.feature_id) {
      setPreparingBoxData(false);
      return;
    }
    setPreparingBoxData(true);
    resetScroll("feature-container", "scrollTop");
    resetScroll("tools-container", "scrollLeft");
    let feature_information;

    if (!selectedItem?.featureInformation) {
      const setupIdx = machiningSetups?.findIndex(
        (setup: any) => setup?.machiningSetupNumber === setupNumber
      );

      const feature_idx = machiningSetups?.[setupIdx]?.features?.findIndex(
        (feature: any) => feature?.feature_id === selectedItem?.feature_id
      );

      feature_information = {
        ...machiningSetups?.[setupIdx]?.features?.[feature_idx]
          ?.featureInformation,
      };
    } else {
      feature_information = selectedItem?.feature_information;
    }

    const selected_strategy =
      feature_information?.strategies?.[0]?.machining_strategy?.find(
        (item: any) => item?.isSelected
      );

    const new_passes = [...selected_strategy?.passes];
    selected_strategy?.passes?.forEach((pass: any, idx1: number) => {
      pass?.operations?.forEach((operation: any, idx2: number) => {
        const selectedToolPath =
          operation?.tool_paths?.[operation?.selectedToolPathInd ?? 0];
        new_passes[idx1].operations[idx2] = {
          ...operation,
          tool_path: selectedToolPath?.tool_path,
          tool_path_type: selectedToolPath?.tool_path_type,
          tool_type:
            selectedToolPath?.tool_types?.[operation?.selectedToolTypeInd ?? 0],
        };
      });
    });
    let payload: any = [
      {
        ...feature_information?.feature_data,
        measurement_unit: projectInfo?.unit,
        cam_software: projectInfo?.cam_software ?? "siemens",
      },
    ];
    if (
      !!new_passes?.find(
        (pass: any) =>
          !!pass?.operations?.find((operation: any) => !!operation?.tools)
      )
    ) {
      setToolData({
        data: { passes: new_passes },
        feature_data: payload,
      });
      return;
    }
    payload[0]["machining_strategy"] = {
      passes: new_passes,
    };
    setStepData([]);
    setIsLoading(true);
    getCuttingTool(payload)
      .then((res: any) => {
        if (!res) return;
        // ---------------------------------------------------------------------
        // Saving the tools for all the operations.
        let updated_setups = [...machiningSetups];

        let updated_feature_information: any;

        const setupIdx = updated_setups?.findIndex(
          (setup: any) => setup?.machiningSetupNumber === setupNumber
        );

        const feature_idx = updated_setups?.[setupIdx]?.features?.findIndex(
          (feature: any) => feature?.feature_id === selectedItem?.feature_id
        );

        updated_feature_information = {
          ...updated_setups?.[setupIdx]?.features?.[feature_idx]
            ?.featureInformation,
        };

        const selected_strategy_idx =
          updated_feature_information?.strategies?.[0]?.machining_strategy?.findIndex(
            (item: any) => item?.isSelected
          );

        res?.cutting_tools?.[0]?.tool_recommendation?.passes?.forEach(
          (pass: any, passIdx: number) => {
            pass?.operations?.forEach(
              (operation: any, operationIdx: number) => {
                operation?.tools?.forEach((tool: any) => {
                  tool.isValid = true;
                  tool.feedback = "";
                });
                updated_feature_information.strategies[0].machining_strategy[
                  selected_strategy_idx
                ].passes[passIdx].operations[operationIdx].tools =
                  operation?.tools ?? [];
              }
            );
          }
        );
        // ---------------------------------------------------------------------
        setToolData({
          data: res?.cutting_tools?.[0]?.tool_recommendation,
          feature_data: payload,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [selectedItem]);

  useEffect(() => {
    setBoxData([]);
    if (!toolData) {
      setPreparingBoxData(false);
      return;
    }
    setPreparingBoxData(true);
    let steps: any = [];
    toolData?.data?.passes?.forEach((pass: any, idx: number) => {
      steps.push({
        index: idx + 1,
        label: pass?.pass,
        key: pass?.pass,
        isNull: false,
        operations: pass?.operations,
        subIndex: 0,
      });
    });

    setStepData(steps);
    if (!!steps?.length) {
      setSelectedStep(steps[0]);
    }
  }, [toolData]);

  useEffect(() => {
    setSelectToolID(null);
    setBoxData([]);
    if (!selectedStep) {
      setPreparingBoxData(false);
      return;
    }
    setPreparingBoxData(true);
    setSelectToolID(
      selectedStep.operations?.[selectedStep?.subIndex]?.["selectedToolId"]
    );
    if (
      selectedStep.operations?.[selectedStep?.subIndex]?.parameters &&
      selectedStep.operations?.[selectedStep?.subIndex]?.mrr_value >= 0
      // duplicateFlag &&
      // false // Explicitly marking the check as false as it is unstable
    ) {
      // FIXME: Need to re-develop the logic behind caching of tool parameters.
      let feature_information;
      let updated_setups = [...machiningSetups];
      const feature_idx = updated_setups?.[0]?.features?.findIndex(
        (feature: any) => feature?.feature_id === selectedItem?.feature_id
      );
      feature_information = {
        ...updated_setups?.[0]?.features?.[feature_idx]?.featureInformation,
      };
      const selected_strategy_idx =
        feature_information?.strategies?.[0]?.machining_strategy?.findIndex(
          (item: any) => item?.isSelected
        );
      setParameterData(
        feature_information.strategies[0].machining_strategy[
          selected_strategy_idx
        ].passes[selectedStep?.index - 1].operations[selectedStep?.subIndex][
          "parameters"
        ]
      );
      return;
    }
    if (!selectedStep.operations?.[selectedStep?.subIndex]?.tools?.length) {
      setParameterData(null);
      setPreparingBoxData(false);
      return;
    }

    const currentMrrValue =
      selectedStep.operations?.[selectedStep?.subIndex]?.mrr_value ?? 100;

    setIsLoading(true);
    let payloadTools = JSON.parse(
      JSON.stringify(selectedStep.operations?.[selectedStep?.subIndex].tools)
    );
    payloadTools?.forEach((tool: any) => {
      delete tool.isValid;
      delete tool.feedback;
    });
    const selectedToolPath =
      selectedStep.operations?.[selectedStep?.subIndex]?.tool_paths[
        selectedStep.operations?.[selectedStep?.subIndex]
          ?.selectedToolPathInd ?? 0
      ];
    const paramsRequestPayload = [
      {
        organization_id: userInfo?.["custom:organizationId"],
        feature_type: toolData?.feature_data?.[0]?.feature_type,
        feature_name: toolData?.feature_data?.[0]?.feature_name,
        feature_id: toolData?.feature_data?.[0]?.feature_id,
        material_info: toolData?.feature_data?.[0]?.material_info,
        machine_info: toolData?.feature_data?.[0]?.machine_info,
        feature_info: toolData?.feature_data?.[0]?.feature_info,
        machining_strategy: {
          cutting_pass: selectedStep.key, // FIXME: Need to rebuild payload for params
          operation:
            selectedStep.operations?.[selectedStep?.subIndex]?.operation, // machining_strategy.passes
          tools: payloadTools,
          weightage: currentMrrValue / 25 + 1,
          tool_path: selectedToolPath?.tool_path,
          tool_path_type: selectedToolPath?.tool_path_type,
          // stock_to_remove:
          //   selectedStep.operations?.[selectedStep?.subIndex]?.stock_to_remove,
        },
        measurement_unit: projectInfo?.unit,
        cam_software: projectInfo?.cam_software ?? "siemens",
      },
    ];
    getParameters(paramsRequestPayload)
      .then((res: any) => {
        setParameterData(
          res?.cutting_tools_parameters?.[0]?.cutting_tools_parameters
        );
        if (!res) return;

        const toolIdToOrderMap: { [key: string]: number } = {};
        res?.cutting_tools_parameters?.[0]?.cutting_tools_parameters?.forEach(
          (toolParamObj: any, index: number) => {
            toolParamObj.valid_data = {};
            toolIdToOrderMap[toolParamObj["Tool ID"]] = 5 - index;
          }
        );
        const orderedTools: any = JSON.parse(
          JSON.stringify(
            toolData?.data?.passes?.[selectedStep?.index - 1].operations[
              selectedStep?.subIndex
            ]["tools"]
          )
        );
        orderedTools.sort((valA: any, valB: any) => {
          if (valA.isCustom && !valB.isCustom) return -1;
          if (!valA.isCustom && valB.isCustom) return 1;
          return (
            (toolIdToOrderMap[valB["Tool ID"]] ?? 0) -
            (toolIdToOrderMap[valA["Tool ID"]] ?? 0)
          );
        });

        let updated_setups = [...machiningSetups];

        let feature_information;

        const setupIdx = updated_setups?.findIndex(
          (setup: any) => setup?.machiningSetupNumber === setupNumber
        );

        const feature_idx = updated_setups?.[setupIdx]?.features?.findIndex(
          (feature: any) => feature?.feature_id === selectedItem?.feature_id
        );

        feature_information = {
          ...updated_setups?.[setupIdx]?.features?.[feature_idx]
            ?.featureInformation,
        };

        const selected_strategy_idx =
          feature_information?.strategies?.[0]?.machining_strategy?.findIndex(
            (item: any) => item?.isSelected
          );
        const strategy =
          feature_information.strategies[0].machining_strategy[
            selected_strategy_idx
          ];
        strategy.passes[selectedStep?.index - 1].operations[
          selectedStep?.subIndex
        ]["parameters"] = JSON.parse(
          JSON.stringify(
            res?.cutting_tools_parameters?.[0]?.cutting_tools_parameters || []
          )
        );
        strategy.passes[selectedStep?.index - 1].operations[
          selectedStep?.subIndex
        ]["tools"] = orderedTools || [];

        strategy.passes[selectedStep?.index - 1].operations[
          selectedStep?.subIndex
        ]["mrr_value"] = currentMrrValue;

        strategy.passes[selectedStep?.index - 1].operations[
          selectedStep?.subIndex
        ]["parameters"]?.forEach((paramData: any) => {
          delete paramData.non_optimal_points;
        });

        strategy.passes[selectedStep?.index - 1].operations[
          selectedStep?.subIndex
        ]["tool_specification"] =
          toolData?.data?.passes?.[selectedStep?.index - 1].operations[
            selectedStep?.subIndex
          ]["tool_specification"];

        selectedStep.operations[selectedStep.subIndex].tools = orderedTools;

        const currentPass = strategy?.passes?.[selectedStep?.index - 1];
        const currentOperation =
          currentPass?.operations?.[selectedStep?.subIndex];
        const selectedOperation =
          selectedStep?.operations?.[selectedStep?.subIndex];

        if (selectedStep?.requestPath !== "newToolAdded") {
          delete currentOperation?.selectedTool;
          delete currentOperation?.selectedToolId;
          delete selectedOperation?.selectedTool;
          delete selectedOperation?.selectedToolId;
        } else {
          const tools =
            toolData?.data?.passes?.[selectedStep.index - 1]?.operations?.[
              selectedStep?.subIndex
            ]?.tools;
          const selectedToolId = selectedOperation?.selectedToolId;

          const toolIndex =
            tools?.findIndex(
              (tool: any) => tool?.["Tool ID"] === selectedToolId
            ) ?? -1;

          if (toolIndex >= 0) {
            if (selectedOperation) selectedOperation.selectedTool = toolIndex;
            if (currentOperation) currentOperation.selectedTool = toolIndex;
          }
        }
        setSelectToolID(null);

        updated_setups[setupIdx].features[feature_idx].featureInformation =
          feature_information;

        selectedStep.operations[selectedStep.subIndex].parameters =
          res?.cutting_tools_parameters?.[0]?.cutting_tools_parameters || [];
        selectedStep.operations[selectedStep.subIndex].mrr_value =
          currentMrrValue;
        setSelectedStep(selectedStep);
        dispatch({
          type: SET_MACHINE_SETUP,
          payload: updated_setups,
        });
      })
      .catch((err: any) => {
        setParameterData(null);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [selectedStep]);

  useEffect(() => {
    if (!selectedStep?.operations?.[selectedStep?.subIndex]?.tools?.length) {
      setPreparingBoxData(false);
      return;
    }
    setPreparingBoxData(true);
    let newParameter: any = [];
    selectedStep.operations?.[selectedStep?.subIndex].tools?.forEach(
      (tool: any, idx: number) => {
        const parameter = parameterData?.find(
          (param: any) => param?.["Tool ID"] === tool?.["Tool ID"]
        );
        let payloadTool = JSON.parse(JSON.stringify(tool));
        delete payloadTool.isValid;
        delete payloadTool.feedback;
        const currentMrrValue =
          selectedStep.operations?.[selectedStep?.subIndex]?.mrr_value ?? 100;
        const addnInfo = {
          feature_type: toolData?.feature_data?.[0]?.feature_type,
          feature_name: toolData?.feature_data?.[0]?.feature_name,
          feature_id: toolData?.feature_data?.[0]?.feature_id,
          material_info: toolData?.feature_data?.[0]?.material_info,
          machine_info: toolData?.feature_data?.[0]?.machine_info,
          feature_info: toolData?.feature_data?.[0]?.feature_info,
          machining_strategy: {
            cutting_pass: selectedStep.key, // FIXME: Need to rebuild payload for params
            operation:
              selectedStep.operations?.[selectedStep?.subIndex]?.operation, // machining_strategy.passes
            tools: [payloadTool],
            weightage: currentMrrValue / 25 + 1,
            tool_path:
              selectedStep.operations?.[selectedStep?.subIndex].tool_path,
            tool_path_type:
              selectedStep.operations?.[selectedStep?.subIndex].tool_path_type,
          },
          measurement_unit: projectInfo?.unit,
          cam_software: projectInfo?.cam_software ?? "siemens",
        };
        newParameter.push({
          ...tool,
          ...(parameter || { paramsNotPresent: true }),
          "Tool material": tool?.["Body material code"],
          additional_info: addnInfo,
        });
      }
    );
    const boxData = getBoxData(newParameter);
    setBoxData(JSON.parse(JSON.stringify(boxData)));
  }, [parameterData]);

  useEffect(() => {
    if (isLoading) {
      dispatch({
        type: SET_LOADING_BAR,
        payload: true,
      });
    } else {
      dispatch({
        type: SET_LOADING_BAR,
        payload: false,
      });
    }
  }, [isLoading]);

  useEffect(() => {
    if (!boxData?.length) return;
    setPreparingBoxData(false);
    if (selectedStep?.operations[selectedStep?.subIndex]?.selectedTool > -1) {
      handleSelectedTool(
        selectedStep?.operations[selectedStep?.subIndex]?.selectedTool,
        true
      );
    } else if (selectedStep?.requestPath !== "newToolAdded") {
      selectedStep?.operations[selectedStep?.subIndex]?.tools?.[0]?.isValid !==
        false && handleSelectedTool(0, true);
    }
    delete selectedStep?.requestPath;
  }, [boxData]);

  const confirmInValidData = useCallback(
    (tool: any) => {
      let feature_information;
      let updated_setups = [...machiningSetups];

      const setupIdx = updated_setups?.findIndex(
        (setup: any) => setup?.machiningSetupNumber === setupNumber
      );

      const feature_idx = updated_setups?.[setupIdx]?.features?.findIndex(
        (feature: any) => feature?.feature_id === selectedItem?.feature_id
      );

      feature_information = {
        ...updated_setups?.[setupIdx]?.features?.[feature_idx]
          ?.featureInformation,
      };
      const selected_strategy_idx =
        feature_information?.strategies?.[0]?.machining_strategy?.findIndex(
          (item: any) => item?.isSelected
        );
      feature_information.strategies[0].machining_strategy[
        selected_strategy_idx
      ].passes[selectedStep?.index - 1].operations[selectedStep?.subIndex][
        "parameters"
      ]?.forEach((param: any) => {
        if (param["Tool ID"] === tool["Tool ID"]) {
          param.valid_data = { ...tool.valid_data };
        }
      });
      feature_information.strategies[0].machining_strategy[
        selected_strategy_idx
      ].passes[selectedStep?.index - 1].operations[selectedStep?.subIndex][
        "tools"
      ]?.forEach((machiningSetUpsTool: any) => {
        if (machiningSetUpsTool["Tool ID"] === tool["Tool ID"]) {
          machiningSetUpsTool.isValid = tool.isValid ?? true;
          machiningSetUpsTool.feedback = tool.feedback ?? "";
        }
      });
      if (selectToolID === tool["Tool ID"]) {
        if (
          tool.isValid === false ||
          tool.valid_data?.["Spindle speed"]?.isInValid ||
          tool.valid_data?.["Feed rate"]?.isInValid ||
          tool.valid_data?.["Stepover [Ae]"]?.isInValid ||
          tool.valid_data?.["Stepdown [Ap]"]?.isInValid
        ) {
          feature_information.strategies[0].machining_strategy[
            selected_strategy_idx
          ].passes[selectedStep?.index - 1].operations[selectedStep?.subIndex][
            "selectedToolId"
          ] = "";
          feature_information.strategies[0].machining_strategy[
            selected_strategy_idx
          ].passes[selectedStep?.index - 1].operations[selectedStep?.subIndex][
            "selectedTool"
          ] = -1;
          setSelectToolID(null);
        }
      }
      updated_setups[setupIdx].features[feature_idx].featureInformation =
        feature_information;
      dispatch({
        type: SET_MACHINE_SETUP,
        payload: updated_setups,
      });
      setBoxData((boxData: any) => {
        boxData?.forEach((box: any) => {
          if (box["Tool ID"] === tool["Tool ID"]) {
            box.valid_data = tool.valid_data;
            box.feedback = tool.feedback ?? "";
            box.isValid = tool.isValid ?? true;
          }
        });
        return boxData;
      });
    },
    [machiningSetups, setupNumber, selectedItem, selectedStep, selectToolID]
  );

  const handleRedirect = (tab: string) => {
    try {
      if (tab !== "summary") {
        navigate(`/project?tab=${tab}`, {
          ...(tab === "strategy-summary" && {
            state: { referrer: "tool" },
          }),
        });
        return;
      }
      dispatch({
        type: SET_LOADING_BAR,
        payload: true,
      });
      let payload: any = [];
      const newMachiningSetups =
        extractSelectedDataFromMachiningSetups(machiningSetups);
      payload = {
        organizationId: projectInfo?.organizationId,
        streamStep: "params-complete",
        selectionId: projectInfo?.userSelectionId,
        ncProjectId: projectInfo?.ncProjectId,
        userRecordId: projectInfo?.userRecordId,
        machineId: "",
        machineType: "",
        workpieceCADfile: "",
        workpieceId: "",
        workpiecePathToCADfile: "",
        workpieceMaterial: projectInfo?.material,
        workpieceSubMaterial: projectInfo?.subMaterial,
        // workpieceSubMaterial: "high alloy steel",

        workpieceRawDimensions: {
          length: "",
          width: "",
          height: "",
        },
        machiningSetups: newMachiningSetups,
        //saving setupData
        assignedMachiningSetups,
        mappingForMachiningSetups,
        visiblefeature_ids,
        featureMapping,
      };

      if (projectInfo?.userSelectionId) {
        if (
          JSON.stringify(machiningSetups) ===
          JSON.stringify(originMachiningSetups)
        ) {
          setTimeout(() => {
            dispatch({
              type: SET_LOADING_BAR,
              payload: false,
            });
            navigate(`/project?tab=${tab}`, {
              state: {
                referrer: "tool-and-params",
              },
            });
          }, 100);
        } else {
          putUserSelection({
            ...payload,
            selectionId: projectInfo?.userSelectionId,
          })
            .then(() => {
              putProject({ ...projectInfo });
              dispatch({
                type: SET_ORIGIN_MACHINE_SETUP,
                payload: machiningSetups,
              });
            })
            .finally(() => {
              setTimeout(() => {
                dispatch({
                  type: SET_LOADING_BAR,
                  payload: false,
                });
                navigate(`/project?tab=${tab}`, {
                  state: {
                    referrer: "tool-and-params",
                  },
                });
              }, 100);
            });
        }
      } else
        postCreateUserSelection(payload)
          .then(async (res: any) => {
            let payload1 = {
              organizationId: projectInfo?.organizationId,
              ncProjectId: projectInfo?.ncProjectId,
              userRecordId: projectInfo?.userRecordId,
              userSelectionId: res?.selectionId,
              numberOfSetups: Object.keys(mappingForMachiningSetups)?.length,
            };
            await putProject(payload1);
            dispatch({
              type: SET_PROJECT_INFO,
              payload: {
                ...projectInfo,
                userSelectionId: res?.selectionId,
              },
            });
            dispatch({
              type: SET_STREAM_STEP,
              payload: "intial-trigger",
            });
            dispatch({
              type: SET_ORIGIN_MACHINE_SETUP,
              payload: machiningSetups,
            });
          })
          .finally(() => {
            setTimeout(() => {
              dispatch({
                type: SET_LOADING_BAR,
                payload: false,
              });
              navigate(`/project?tab=${tab}`, {
                state: {
                  referrer: "tool-and-params",
                },
              });
            }, 100);
          });
    } catch (err) {}
  };

  const handleSelectedTool = useCallback(
    (index: number, flag: boolean) => {
      if (flag) {
        if (!selectedItem?.feature_id) return;

        let feature_information;
        let updated_setups = [...machiningSetups];

        const setupIdx = updated_setups?.findIndex(
          (setup: any) => setup?.machiningSetupNumber === setupNumber
        );

        const feature_idx = updated_setups?.[setupIdx]?.features?.findIndex(
          (feature: any) => feature?.feature_id === selectedItem?.feature_id
        );

        if (!selectedItem?.featureInformation) {
          feature_information = {
            ...updated_setups?.[setupIdx]?.features?.[feature_idx]
              ?.featureInformation,
          };
        } else {
          feature_information = selectedItem?.feature_information;
        }

        const selected_strategy_idx =
          feature_information?.strategies?.[0]?.machining_strategy?.findIndex(
            (item: any) => item?.isSelected
          );
        if (selected_strategy_idx > -1) {
          feature_information.strategies[0].machining_strategy[
            selected_strategy_idx
          ].passes[selectedStep?.index - 1].operations[selectedStep?.subIndex][
            "selectedTool"
          ] = index;
          feature_information.strategies[0].machining_strategy[
            selected_strategy_idx
          ].passes[selectedStep?.index - 1].operations[selectedStep?.subIndex][
            "selectedToolId"
          ] =
            selectedStep?.operations[selectedStep?.subIndex]?.tools?.[index]?.[
              "Tool ID"
            ];
        }

        setSelectToolID(
          selectedStep?.operations[selectedStep?.subIndex]?.tools?.[index]?.[
            "Tool ID"
          ]
        );

        setSelectedStep((selectedStep: any) => {
          selectedStep.operations[selectedStep.subIndex].selectedTool = index;
          selectedStep.operations[selectedStep.subIndex].selectedToolId =
            selectedStep?.operations[selectedStep?.subIndex]?.tools?.[index]?.[
              "Tool ID"
            ];
          return selectedStep;
        });

        updated_setups[setupIdx].features[feature_idx].featureInformation =
          feature_information;

        dispatch({
          type: SET_MACHINE_SETUP,
          payload: updated_setups,
        });
      }
    },
    [machiningSetups, setupNumber, selectedItem, selectedStep]
  );

  const handleStrategy = (selected_strategy: any) => {
    let toolList: any = [];
    const strategy = selected_strategy;
    if (isObj(strategy)) {
      Object.keys(strategy).forEach((key, idx) => {
        if (strategy[key]["Tool type"])
          toolList.push({
            key: strategy[key]?.["Operation"],
            value: strategy[key]["Tool type"],
          });
      });

      if (state[selectedItem?.key][selectedItem?.index]?.tool) {
        let steps: any = [];
        Object.keys(strategy).forEach((key, idx) => {
          if (
            !state[selectedItem?.key][selectedItem?.index]?.selected_strategy?.[
              key
            ]?.["Operation"]
          )
            return;
          const toolKey = `${state[selectedItem?.key][
            selectedItem?.index
          ]?.selected_strategy?.[key]?.["Operation"]
            ?.toString()
            .toLowerCase()}_tools`;
          if (
            !state[selectedItem?.key][selectedItem?.index]?.tool?.[toolKey] ||
            !state[selectedItem?.key][selectedItem?.index]?.tool?.[toolKey]
              ?.length ||
            !state[selectedItem?.key][selectedItem?.index]?.tool?.[toolKey]?.[0]
              ?.tools?.length
          ) {
            steps.push({
              index: idx + 1,
              label:
                state[selectedItem?.key][selectedItem?.index]
                  ?.selected_strategy?.[key]?.["Operation"],
              key: toolKey,
              isNull: true,
            });
            return;
          }
          steps.push({
            index: idx + 1,
            label:
              state[selectedItem?.key][selectedItem?.index]
                ?.selected_strategy?.[key]?.["Operation"],
            key: toolKey,
            isNull: false,
          });
        });
        setStepData(steps);
        if (!!steps?.length) setSelectedStep(steps[0]);
      }
    }
    setMachiningStrategySummaryData(toolList);
  };

  if (!availableList?.length) {
    navigate("/projects");
  }

  const selectedToolPath =
    selectedStep?.["operations"]?.[selectedStep?.subIndex]?.tool_paths?.[
      selectedStep?.["operations"]?.[selectedStep?.subIndex]
        ?.selectedToolPathInd ?? 0
    ];

  return (
    <div className="relative h-[calc(100vh-166px)] flex px-4">
      <div className="w-[25%] h-full">
        {/* <ProjectItemList /> */}
        <div className="w-full overflow-y-auto h-full flex flex-col justify-between bg-gray-f9f p-1 rounded-md border border-gray-eac">
          <div className="w-full">
            <p className="font-medium text-gray-475 text-[10px]">Features</p>
            <div className="pr-1">
              {availableList?.map(({ title, data, type }: any, idx: number) => {
                return (
                  <AccordionItem
                    key={idx}
                    tabLabelChild={`${idx + 1}. ${title}`}
                    className="border-2 border-surface-soft py-2 px-4 my-1"
                    labelChildClass="text-black-222"
                    tabContentClassName="!pb-0"
                    checked={selectedCard === idx}
                    dragIcon={false}
                    onChange={(e) => {
                      if (selectedCard !== idx) {
                        setSelectedCard(idx);
                        setSelectedItem({
                          type,
                          feature_id: data?.[0]?.feature_id,
                        });
                        setSelectedTitle(data?.[0]?.title);
                      }
                    }}
                    count={
                      data?.filter(
                        (item: any) =>
                          !!item?.featureInformation?.strategies?.find(
                            (strategy: any) =>
                              !!strategy?.machining_strategy?.find(
                                (item3: any) => item3?.isSelected
                              )
                          )
                      )?.length || 0
                    }
                    isBackground
                  >
                    <div>
                      {data?.map((item: any, idx1: number) => {
                        return (
                          <div
                            className="mb-[10px] flex flex-row items-center cursor-pointer justify-between w-full"
                            onClick={() => {
                              if (
                                selectedItem?.feature_id !== item?.feature_id
                              ) {
                                setSelectedItem({
                                  type,
                                  feature_id: item?.feature_id,
                                  featureInfomation: item?.featureInformation,
                                });
                                setSelectedTitle(`${item?.title}`);
                              }
                            }}
                          >
                            <div className="flex flex-row items-center">
                              {selectedItem?.type === type &&
                                selectedItem?.index === idx1 && (
                                  <FiArrowRight className="text-surface-default mr-2" />
                                )}
                              <span
                                className={clsx("text-sm", {
                                  "text-surface-default font-semibold":
                                    selectedItem?.feature_id ===
                                    item?.feature_id,
                                })}
                              >{`${item?.title}`}</span>
                            </div>
                            <div className="text-[12px] font-medium text-gray-600">
                              {countStepsWithSelectedTool(
                                item?.featureInformation?.strategies?.[0]
                                  ?.machining_strategy?.[item.strategy_index]
                              )}
                              /{item?.stepCount}
                            </div>
                            {/* Hiding this checkbox for now. */}
                            {/* {!!item?.featureInformation?.strategies?.find(
                              (strategy: any) =>
                                !!strategy?.machining_strategy?.find(
                                  (item3: any) => item3?.isSelected
                                )
                            ) && (
                              <Checkbox checked={true} onChange={() => {}} />
                            )} */}
                          </div>
                        );
                      })}
                    </div>
                  </AccordionItem>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {selectedItem?.feature_id && (
        <div className="w-[75%] flex flex-col relative">
          <div
            className="w-full px-4 h-[calc(100%-75px)] overflow-y-auto"
            id="feature-container"
            onScroll={() => hideTooltip()}
          >
            <div className="w-full mb-4">
              <SummaryBox
                label={`Feature Summary : `}
                selectedItem={selectedItem}
                className="mb-2"
                title={selectedTitle}
              />
            </div>
            <div className="mb-4 border border-gray-eac rounded-lg">
              <div className="w-full flex flex-row items-center justify-between p-2 border-b border-gray-eac bg-gray-f9f p-6">
                <span className="font-semibold text-black">
                  Machining Strategies
                </span>
                <Step
                  data={[...stepData]}
                  current={{
                    index: selectedStep?.index,
                    subIndex: selectedStep?.subIndex,
                  }}
                  setSelectedStep={setSelectedStep}
                  className="mb-2 max-w-[70%]"
                />
              </div>

              <div className="flex flex-col overflow-x-auto p-2">
                <div className="flex flex-row gap-[8px] items-center">
                  <div>
                    <p className="text-gray-475 text-sm font-bold">
                      {selectedStep?.label || ""}
                    </p>
                    <p className="text-gray-475 text-xs font-bold">
                      <span className="inline-block mr-3">
                        Tool Path:
                        <span className="font-normal ml-1">
                          {selectedToolPath?.tool_path}
                          {selectedToolPath?.tool_path_style
                            ? ` (${selectedToolPath?.tool_path_style})`
                            : ""}
                        </span>
                      </span>
                      <span className="inline-block mr-3">
                        Operation:
                        <span className="font-normal ml-1">
                          {
                            selectedStep?.["operations"]?.[
                              selectedStep?.subIndex
                            ]?.operation
                          }
                        </span>
                      </span>
                      <span className="inline-block mr-3">
                        Location:
                        <span className="font-normal ml-1">
                          {
                            selectedStep?.["operations"]?.[
                              selectedStep?.subIndex
                            ]?.location
                          }
                        </span>
                      </span>
                      <span className="inline-block mr-3">
                        Tool type:
                        <span className="font-normal ml-1">
                          {
                            selectedStep?.["operations"]?.[
                              selectedStep?.subIndex
                            ]?.tool_type
                          }
                        </span>
                      </span>
                    </p>
                  </div>

                  <div className="flex justify-center w-fit ml-auto">
                    <div className="flex flex-col justify-end mr-2">
                      <span className="text-gray-807 font-medium text-[10px]">
                        Tool Life
                      </span>
                      <span className="text-black text-xs font-medium text-end">
                        {100 -
                          (selectedStep?.operations?.[selectedStep?.subIndex]?.[
                            "mrr_value"
                          ] ?? 100)}
                        %
                      </span>
                    </div>
                    <StepSlider
                      min={0}
                      max={100}
                      step={25}
                      value={
                        selectedStep?.operations?.[selectedStep?.subIndex]?.[
                          "mrr_value"
                        ] ?? 100
                      }
                      onChange={(value: number) => {
                        let mrr_value = value;
                        if (value < 25) {
                          mrr_value = 0;
                        }
                        if (value > 75) {
                          mrr_value = 100;
                        }
                        selectedStep.operations[selectedStep?.subIndex][
                          "mrr_value"
                        ] = mrr_value;
                      }}
                      onChangeComplete={() => {
                        let selectedstep = JSON.parse(
                          JSON.stringify(selectedStep)
                        );
                        delete selectedstep.operations[selectedStep?.subIndex][
                          "parameters"
                        ];
                        setSelectedStep(selectedstep);
                      }}
                      className="w-[150px]"
                    />
                    <div className="flex flex-col ml-2">
                      <span className="text-gray-807 font-medium text-[10px]">
                        MRR
                      </span>
                      <span className="text-black text-xs font-medium">
                        {selectedStep?.operations?.[selectedStep?.subIndex]?.[
                          "mrr_value"
                        ] ?? 100}
                        %
                      </span>
                    </div>
                  </div>
                  <Button
                    type="button"
                    aria-label="button"
                    className="ml-[8px] inline-flex items-center gap-[8px] px-[16px] py-[8px] rounded-[4px] bg-blue-100 hover:bg-blue-50 text-blue-600 text-[12px] font-semibold"
                    variant="genric"
                    onClick={() => {
                      setOpenAddToolModal(true);
                    }}
                  >
                    Add Tool
                  </Button>
                </div>
                {!preparingBoxData &&
                  (!!boxData?.length ? (
                    <div
                      className="flex flex-row overflow-x-auto w-full py-1 px-px mt-4"
                      id="tools-container"
                    >
                      {boxData?.map((tool: any, idx: number) => {
                        if (tool.paramsNotPresent) {
                          return (
                            <NoParamsToolbox
                              key={idx}
                              tool={tool}
                              index={idx}
                              setSelectedTool={handleSelectedTool}
                              selectedTool={selectToolID}
                              confirmInvalidData={confirmInValidData}
                              selectedStep={selectedStep}
                              boxData={boxData}
                              setBoxData={setBoxData}
                            />
                          );
                        } else {
                          return (
                            <Toolbox
                              key={idx}
                              tool={tool}
                              className="mr-2"
                              index={idx}
                              setSelectedTool={handleSelectedTool}
                              selectedTool={selectToolID}
                              confirmInValidData={confirmInValidData}
                              selectedStep={selectedStep}
                              boxData={boxData}
                              setBoxData={setBoxData}
                            />
                          );
                        }
                      })}
                    </div>
                  ) : (
                    <div className="w-full flex flex-col justify-center items-center py-20 space-y-2">
                      <img src={NoFoundImg} alt="not-found" />
                      <p className="text-sm font-semibold text-gray-344">
                        No tools found
                      </p>
                      <p className="text-xs font-medium text-gray-475">
                        Please consider changing the machining strategy from
                        above.
                      </p>
                    </div>
                  ))}
              </div>
            </div>
          </div>

          <div className="w-full absolute bottom-[4px] left-0 right-0 bg-white flex justify-end items-center py-2 border-t border-gray-e7e">
            <div className="flex flex-row font-semibold gap-4">
              <Button
                variant="gray"
                onClick={() => {
                  handleRedirect("manufacturing-summary");
                }}
              >
                Back
              </Button>
              <div
                className={clsx({
                  // "cursor-not-allowed": !possibleList?.length,
                })}
                id="tool-summary"
                // onMouseOver={() =>
                //   !possibleList?.length && showTooltip("tool-summary")
                // }
                // onMouseLeave={() => hideTooltip()}
                // data-tooltip-content="You must select at least one tool to Continue"
              >
                <Button
                  className={clsx({
                    // "pointer-events-none": !possibleList?.length,
                  })}
                  onClick={() => handleRedirect("summary")}
                  // disabled={!possibleList?.length}
                >
                  Next: Summary
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showChangeCategory && (
        <ChangeStrategy
          setShowChangeCategory={setShowChangeCategory}
          selectedItem={selectedItem}
          handleStrategy={handleStrategy}
        />
      )}
      <AddToolModal
        isOpen={openAddToolModal}
        setIsOpen={setOpenAddToolModal}
        toolData={toolData}
        setToolData={setToolData}
        selectedStep={selectedStep}
        setSelectedStep={setSelectedStep}
      />
    </div>
  );
};

export default CuttingTool;
