import RadioComponent from "components/RadioComponent";
import {
  EDGE_OPTIONS,
  POCKET_OPTIONS,
  SET_MACHINE_SETUP,
  SET_POCKET_DATA,
} from "constant";
import { GlobalContext } from "context/GlobalContext";
import { useState, useContext, useEffect } from "react";
import ThreeDForm from "./ThreeDForm";
import TwoDForm from "./TwoDForm";
import SlotForm from "./SlotForm";

const PocketForm = ({ feature_id }: any) => {
  // const { state, dispatch } = useContext(GlobalContext);
  // const { pocketList } = state;

  // return (
  //   <div>
  //     {/* <RadioComponent
  //       options={POCKET_OPTIONS}
  //       setValue={handlePocketOption}
  //       value={pocketList[index]?.type}
  //       labelClassName="!mr-4"
  //     /> */}
  //     <TwoDForm feature_id={feature_id} />
  //     {/* {pocketList[index]?.type === "pocket_2d" && <TwoDForm index={index} />}
  //     {pocketList[index]?.type === "pocket_3d" && <ThreeDForm index={index} /> } */}
  //   </div>
  // );
  const { state, dispatch } = useContext(GlobalContext);
  const { machiningSetups, setupNumber } = state;
  const [selectedFeature, setSelectedFeature] = useState<any>(null);

  useEffect(() => {
    if (setupNumber < 0 || !feature_id) return;
    const setupIdx = machiningSetups?.findIndex(
      (setup: any) => setup?.machiningSetupNumber === setupNumber
    );
    const feature_idx = machiningSetups?.[setupIdx]?.features?.findIndex(
      (feature: any) => feature?.feature_id === feature_id
    );
    const feature_data = {
      ...machiningSetups?.[setupIdx]?.features?.[feature_idx]
        ?.featureInformation?.feature_data,
    };
    setSelectedFeature(feature_data);
  }, [setupNumber, feature_id]);

  const handlePocketOption = (val: string) => {
    // dispatch({
    //   type: SET_CONTOUR_DATA,
    //   payload: {
    //     index,
    //     key: 'type',
    //     value: val
    //   }
    // })
    let updated_setups = [...machiningSetups];

    const setupIdx = updated_setups?.findIndex(
      (setup: any) => setup?.machiningSetupNumber === setupNumber
    );

    const feature_idx = updated_setups?.[setupIdx]?.features?.findIndex(
      (feature: any) => feature?.feature_id === feature_id
    );

    let updated_data = {
      ...updated_setups?.[setupIdx]?.features?.[feature_idx]?.featureInformation
        ?.feature_data,
    };
    updated_data = {
      ...updated_data,
      feature_name: val,
      // Setting default value for quantity.
      // Additionally reseting the island_info.
      feature_info: {
        quantity: 1,
      },
    };
    updated_setups[setupIdx].features[
      feature_idx
    ].featureInformation.feature_data = updated_data;

    setSelectedFeature(updated_data);

    dispatch({
      type: SET_MACHINE_SETUP,
      payload: updated_setups,
    });
  };

  return (
    <div>
      <RadioComponent
        options={POCKET_OPTIONS}
        setValue={handlePocketOption}
        value={selectedFeature?.feature_name}
        labelClassName="!mr-4"
      />
      {selectedFeature?.feature_name === "pocket_2d" && (
        <TwoDForm
          feature_info={selectedFeature?.feature_info}
          feature_id={selectedFeature?.feature_id}
        />
      )}
      {selectedFeature?.feature_name === "slot" && (
        <SlotForm
          feature_info={selectedFeature?.feature_info}
          feature_id={selectedFeature?.feature_id}
        />
      )}
    </div>
  );
};

export default PocketForm;
