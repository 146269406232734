export const CUTTING_TOOL_TABLE_COLUMNS = [
  { label: "Libraries", id: "libraries" },
  { label: "Type", id: "type" },
  { label: "Corner Radius", id: "cornorRadius" },
  { label: "Diameter", id: "diameter" },
  { label: "Flute Length", id: "fluteLength" },
];

const baseToolSpecs = [
  "Tool ID",
  "Tool type",
  "Tool class",
  "Body material code",
  "Description",
  "Body length",
  "Cutting diameter",
  "Shaft diameter",
  "Flute count",
  "Flute length",
  "Shank length",
];

export const POSSIBLE_TOOL_SPECS = [
  ...baseToolSpecs,
  "Corner radius",
  "Point angle",
  "Taper angle",
  "Thread pitch",
  "Cut width",
];

export const TOOL_TYPE_TO_TOOL_SPECS_MAPPING: any = {
  "Flat end mill": new Set(baseToolSpecs),
  "Bull nose end mill": new Set([...baseToolSpecs, "Corner radius"]),
  "Ball end mill": new Set(baseToolSpecs),
  "Spot drill": new Set([...baseToolSpecs, "Point angle", "Taper angle"]),
  "Center drill": new Set([...baseToolSpecs, "Point angle", "Taper angle"]),
  Drill: new Set([...baseToolSpecs, "Point angle", "Taper angle"]),
  "Flat bottom drill": new Set(baseToolSpecs),
  "U drill": new Set(baseToolSpecs),
  Shaper: new Set([...baseToolSpecs, "Point angle", "Taper angle"]),
  "Boring bar": new Set([...baseToolSpecs, "Corner radius"]),
  Reamer: new Set(baseToolSpecs),
  "Thread mill": new Set([...baseToolSpecs, "Thread pitch"]),
  "Cutting tap": new Set([...baseToolSpecs, "Thread pitch"]),
  "Form mill": new Set([...baseToolSpecs, "Corner radius"]),
  "Face mill": new Set([...baseToolSpecs, "Corner radius"]),
  "Chamfer mill": new Set([...baseToolSpecs, "Taper angle"]),
  "T-slot mill": new Set([...baseToolSpecs, "Corner radius", "Cut width"]),
};

export const tool_indexability_options = [
  {
    label: "Indexable",
    value: "indexable",
    type: 0,
  },
  {
    label: "Non Indexable",
    value: "non indexable",
    type: 0,
  },
];

export const tool_body_material_code_options = [
  {
    label: "Carbide",
    value: "carbide",
    type: 0,
  },
  {
    label: "Hard",
    value: "hard",
    type: 0,
  },
  {
    label: "Cobalt",
    value: "Cobalt",
    type: 0,
  },
  {
    label: "HSCO",
    value: "HSCO",
    type: 0,
  },
  {
    label: "HSS",
    value: "hss",
    type: 0,
  },
];

export const OPERATIONS_NOT_SUPPORTING_STEPOVER = new Set([
  "Pre drilling",
  "Centering",
  "Plunge milling",
  "Drilling",
  "Reaming",
  "Thread milling",
  "Boring",
  "Bore finishing",
]);

export const OPERATIONS_NOT_SUPPORTING_STEPDOWN = new Set([
  "Pre drilling",
  "Centering",
  "Plunge milling",
  "Drilling",
  "Reaming",
  "Thread milling",
  "Boring",
  "Bore finishing",
  "Scallop finishing",
]);
