import { useState, useContext, useEffect, useCallback } from "react";
import clsx from "clsx";
import { Checkbox } from "components/Checkbox";
import { FiEdit2 } from "react-icons/fi";
import { GlobalContext } from "context/GlobalContext";
import StrategyMenu from "./StrategyMenu";
import Button from "components/Button/Button";
import { SET_ASSIGNED_SETUPS, SET_MACHINE_SETUP } from "constant";
import "./style.css";
import InvalidOperationsPopUp from "./InvalidOperationsPopUp";
import InValidCross from "assets/images/icons/uiw_stop-o.svg";
import InValidCrossRed from "assets/images/icons/uiw_stop-o-red.svg";
import { renderToString } from "react-dom/server";
import ToolPathMenu from "./ToolpathMenu";
import EditStrategyErrorModal from "components/EditStrategyErrorModal";
import { createDeepCopy } from "utils";

interface IMachiningStrategySummaryBox {
  isAdd?: boolean;
  type?: "recommend" | "alternate" | "custom";
  setSelectedSummary?: any;
  kind?: string;
  checked?: boolean;
  selectedItem?: any;
  index: number;
  setStrategyData?: any;
  handleSelectedStrategyData?: any;
  strategy?: any;
  setInValid?: Function;
}

const MachiningStrategySummaryBox: React.FC<IMachiningStrategySummaryBox> = ({
  strategy,
  type,
  setSelectedSummary,
  selectedItem,
  index,
  setInValid,
}) => {
  const { state, dispatch } = useContext(GlobalContext);
  const {
    machiningSetups,
    setupNumber,
    showTooltip,
    hideTooltip,
    assignedMachiningSetups,
  } = state;
  const [isEdit, setIsEdit] = useState(false);
  const [tempData, setTempData] = useState<any>(null);
  const [showPopUp, setShowPopUp] = useState(false);
  const [showDeletionErrorPopup, setShowDeletionErrorPopup] = useState(false);

  useEffect(() => {
    setTempData(null);
    setIsEdit(false);
  }, [strategy]);

  const handleCheck = (checked: boolean) => {
    if (!checked) return;
    let updated_setups = [...machiningSetups];

    const setupIdx = updated_setups?.findIndex(
      (setup: any) => setup?.machiningSetupNumber === setupNumber
    );

    const feature_idx = updated_setups?.[setupIdx]?.features?.findIndex(
      (feature: any) => feature?.feature_id === selectedItem?.feature_id
    );

    const feature_information = {
      ...updated_setups?.[setupIdx]?.features?.[feature_idx]
        ?.featureInformation,
    };

    let selected = null;
    let data = [...(feature_information?.strategies ?? [])];
    const new_machining_strategy = data?.[0]?.machining_strategy?.map(
      (item: any, idx: number) => {
        if (idx === index) {
          return { ...item, isSelected: true };
        }
        return { ...item, isSelected: false };
      }
    );
    // deleting assigned setup data when changing selected strategy
    new_machining_strategy?.[index]?.passes?.forEach((pass: any) => {
      pass.operations?.forEach((operation: any) => {
        delete operation.pass_number;
        delete operation.assignedSetupId;
      });
    });

    selected = new_machining_strategy?.[index];
    data[0].machining_strategy = new_machining_strategy;

    updated_setups[setupIdx].features[
      feature_idx
    ].featureInformation.strategies = data;

    dispatch({
      type: SET_MACHINE_SETUP,
      payload: updated_setups,
    });

    setSelectedSummary(selected);
  };

  const handleOperationDeletion = useCallback(
    (passIdx: number, operationIdx: number) => {
      const newData: any = createDeepCopy(tempData);
      const existingOperationsCount = newData.passes.reduce(
        (operationCount: number, pass: any) => {
          return operationCount + pass.operations.length;
        },
        0
      );
      if (existingOperationsCount <= 1) {
        setShowDeletionErrorPopup(true);
      } else {
        newData.passes[passIdx].operations.splice(operationIdx, 1);
        if (!newData.passes[passIdx].operations.length) {
          newData.passes.splice(passIdx, 1);
        }
      }
      setTempData(newData);
    },
    [tempData]
  );

  const handleToolType = useCallback(
    (passIdx: number, operationIdx: number, value: number) => {
      const new_data: any = JSON.parse(JSON.stringify(tempData));
      if (
        new_data?.passes?.[passIdx]?.operations?.[operationIdx]?.[
          "selectedToolPathInd"
        ] === undefined
      ) {
        new_data.passes[passIdx].operations[operationIdx][
          "selectedToolPathInd"
        ] = 0;
      }
      new_data.passes[passIdx].operations[operationIdx]["selectedToolTypeInd"] =
        value;
      setTempData(new_data);
    },
    [tempData]
  );

  const handleToolPathChange = useCallback(
    (passIdx: number, operationIdx: number, value: number) => {
      const new_data: any = JSON.parse(JSON.stringify(tempData));
      if (
        value !==
        new_data?.passes?.[passIdx]?.operations?.[operationIdx]?.[
          "selectedToolPathInd"
        ]
      ) {
        new_data.passes[passIdx].operations[operationIdx][
          "selectedToolTypeInd"
        ] = 0;
      }
      new_data.passes[passIdx].operations[operationIdx]["selectedToolPathInd"] =
        value;
      setTempData(new_data);
    },
    [tempData]
  );

  const handleSave = () => {
    let updated_setups = [...machiningSetups];

    const setupIdx = updated_setups?.findIndex(
      (setup: any) => setup?.machiningSetupNumber === setupNumber
    );

    const feature_idx = updated_setups?.[setupIdx]?.features?.findIndex(
      (feature: any) => feature?.feature_id === selectedItem?.feature_id
    );

    const feature_information = {
      ...updated_setups?.[setupIdx]?.features?.[feature_idx]
        ?.featureInformation,
    };

    /** -----------------------------------------------------------------------
     * When an operation is deleted from the user-recommended strategy,
     * its corresponding mapping must also be removed from the
     * assignedMachiningSetups object.
     */
    const originalOperationsCount = strategy.passes.reduce(
      (operationCount: number, pass: any) => {
        return operationCount + pass.operations.length;
      },
      0
    );
    const currentOperationsCount = tempData.passes.reduce(
      (operationCount: number, pass: any) => {
        return operationCount + pass.operations.length;
      },
      0
    );
    const assignedMachiningSetupsCopy = createDeepCopy(assignedMachiningSetups);
    if (originalOperationsCount !== currentOperationsCount) {
      Object.keys(assignedMachiningSetupsCopy).forEach((setupId: string) => {
        assignedMachiningSetupsCopy[setupId] = assignedMachiningSetupsCopy[
          setupId
        ].filter(
          (operation: any) => operation.featureId !== selectedItem?.feature_id
        );
      });
    }
    // ------------------------------------------------------------------------

    tempData?.passes?.forEach((pass: any, ind1: number) => {
      pass?.operations?.forEach((operation: any, ind2: number) => {
        if (
          operation?.selectedToolPathInd !==
            strategy?.passes?.[ind1]?.operations?.[ind2]?.selectedToolPathInd ||
          operation?.selectedToolTypeInd !==
            strategy?.passes?.[ind1]?.operations?.[ind2]?.selectedToolTypeInd ||
          originalOperationsCount !== currentOperationsCount
        ) {
          delete operation.parameters;
          delete operation.tools;
          // deleting assigned setups data when strategy is edited
          delete operation.assignedSetupId;
          delete operation.pass_number; // assignedOperationIndex
        }
      });
    });

    let data = [...(feature_information?.strategies || [])];
    data[0].machining_strategy[index] = tempData;
    updated_setups[setupIdx].features[
      feature_idx
    ].featureInformation.strategies = data;
    dispatch({
      type: SET_MACHINE_SETUP,
      payload: updated_setups,
    });
    dispatch({
      type: SET_ASSIGNED_SETUPS,
      payload: assignedMachiningSetupsCopy,
    });
    setIsEdit(false);
  };

  return (
    <>
      <div
        className={clsx(
          "shadow-box-1 bg-white rounded-lg min-w-[322px] min-h-[212px] relative mr-2 border border-black border-opacity-10",
          {
            "bg-[#FEF3F2] border-[1px] border-solid border-[#F04438] !border-opacity-100":
              !strategy?.isValid,
          }
        )}
      >
        {/* Title */}
        <div className="py-2 mx-2 border-b border-b-gray-eac flex flex-row items-center justify-between">
          <div className="flex flex-row items-center">
            <Checkbox
              checked={(isEdit ? tempData : strategy)?.isSelected}
              onChange={(val: boolean) => {
                handleCheck(val);
              }}
              disabled={!strategy?.isValid}
              type="yellow"
            />
            <span
              className={`font-bold text-sm ${
                strategy?.isValid ? "text-gray-475" : "text-gray-400"
              } ml-3`}
            >
              Strategy {Number(index) + 1}
            </span>
          </div>
          <div className="flex flex-row space-x-[10px] items-center">
            {!isEdit ? (
              <>
                {type === "recommend" && (
                  <span className="bg-grean-039 rounded py-1 px-1.5 text-white text-[10px]">
                    Recommended
                  </span>
                )}
                {type === "custom" && (
                  <span className="bg-cyan-500 rounded py-1 px-1.5 text-white text-[10px]">
                    Custom
                  </span>
                )}
                <button
                  onClick={() => {
                    setShowPopUp(true);
                  }}
                  id={`strategy${index}-invalidButton`}
                  onMouseOver={() => {
                    if (strategy?.isValid) {
                      showTooltip(`strategy${index}-invalidButton`);
                    }
                  }}
                  onFocus={() => {}}
                  onKeyDown={(e: any) => {
                    e.preventDefault();
                  }}
                  onMouseLeave={() => hideTooltip()}
                  data-tooltip-html={renderToString(
                    <p className="font-medium text-base leading-[16.94px]">
                      Mark as Invalid
                    </p>
                  )}
                >
                  <img
                    src={strategy?.isValid ? InValidCross : InValidCrossRed}
                    alt="InValid"
                  />
                </button>
                <InvalidOperationsPopUp
                  strategyInd={index}
                  showPopUp={showPopUp}
                  closePopUp={() => setShowPopUp(false)}
                  strategy={strategy}
                  setInValid={setInValid}
                />
                {strategy?.isValid && (
                  <FiEdit2
                    className="text-[14px] text-gray-475 cursor-pointer"
                    onClick={() => {
                      setTempData(strategy);
                      setIsEdit(true);
                    }}
                  />
                )}
              </>
            ) : (
              <div className="flex flex-row items-center space-x-2">
                <Button
                  size="sm"
                  variant="gray"
                  className="rounded"
                  onClick={() => {
                    setIsEdit(false);
                  }}
                >
                  Cancel
                </Button>
                <Button size="sm" className="rounded" onClick={handleSave}>
                  Save
                </Button>
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-col pl-2 pr-2 overflow-y-auto">
          {(isEdit ? tempData : strategy)?.passes?.length &&
            (isEdit ? tempData : strategy)?.passes?.map(
              (item: any, idx: number) => (
                <div
                  className={clsx("py-1.5 w-full", {
                    "border-t border-t-gray-eac": idx > 0,
                  })}
                  key={idx}
                >
                  <p
                    className={`text-xs font-semibold ${
                      strategy?.isValid ? "text-gray-344" : "text-gray-400"
                    } mb-1`}
                  >
                    {item?.pass}
                  </p>
                  {!!item?.operations?.length &&
                    item?.operations?.map((operation: any, idx1: number) => {
                      return (
                        <div
                          className="flex flex-row items-center justify-between w-full py-1"
                          key={idx1}
                        >
                          <div className="flex flex-row space-x-1 w-full">
                            <div className="flex flex-col text-center overflow-hidden">
                              <div className="w-2 h-2 round-label rounded-full mt-1 bg-gray-b9b"></div>
                              <div className="w-[2px] h-[100%] bg-gray-200 mt-0 m-auto"></div>
                            </div>
                            <div className="w-full flex flex-col">
                              <div className="w-full flex items-center">
                                <p className="text-[10px] text-gray-400 font-medium w-full mr-[8px]">
                                  <span
                                    className={
                                      strategy?.isValid
                                        ? "text-gray-600"
                                        : "text-gray-400"
                                    }
                                  >
                                    Operation:
                                  </span>{" "}
                                  {operation?.operation ?? "N/A"} (
                                  {operation?.location ?? "N/A"})
                                </p>
                                {isEdit && (
                                  <Button
                                    type="button"
                                    className="!p-0 !bg-transparent"
                                    onClick={() => {
                                      handleOperationDeletion(idx, idx1);
                                    }}
                                  >
                                    {/* For some unknown reason we can't move the savg into another file, it messes up the styling */}
                                    <svg
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <g clipPath="url(#clip0_11974_156727)">
                                        <path
                                          d="M1 3.66667H14.3333M6.33333 7V10.3333M9 7V10.3333M2.33333 3.66667H13L11.9467 13.1467C11.9106 13.4729 11.7554 13.7744 11.5108 13.9933C11.2663 14.2123 10.9496 14.3334 10.6213 14.3333H4.712C4.38376 14.3334 4.06704 14.2123 3.8225 13.9933C3.57796 13.7744 3.42277 13.4729 3.38667 13.1467L2.33333 3.66667ZM4.56333 1.76467C4.67117 1.53598 4.8418 1.34266 5.05534 1.20727C5.26887 1.07188 5.5165 0.999997 5.76933 1H9.564C9.81695 0.999871 10.0647 1.0717 10.2784 1.2071C10.492 1.34249 10.6628 1.53588 10.7707 1.76467L11.6667 3.66667H3.66667L4.56333 1.76467V1.76467Z"
                                          stroke="#F04438"
                                          strokeWidth="1.6"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_11974_156727">
                                          <rect
                                            width="16"
                                            height="16"
                                            fill="white"
                                          />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  </Button>
                                )}
                              </div>
                              <ToolPathMenu
                                value={{
                                  label:
                                    operation?.tool_paths?.[
                                      operation?.selectedToolPathInd ?? 0
                                    ],
                                  value: operation?.selectedToolPathInd ?? 0,
                                }}
                                options={operation?.tool_paths?.map(
                                  (tool_path: any, idx2: number) => ({
                                    label: tool_path,
                                    value: idx2,
                                  })
                                )}
                                onChange={(option: any) => {
                                  handleToolPathChange(
                                    idx,
                                    idx1,
                                    option?.value
                                  );
                                }}
                                isEdit={
                                  isEdit && operation?.tool_paths?.length > 1
                                }
                                isValid={strategy?.isValid ?? true}
                              />
                              <div className="flex flex-row mt-2">
                                <p className="text-[10px] text-gray-400 font-medium flex flex-row items-center">
                                  <span
                                    className={`${
                                      strategy?.isValid
                                        ? "text-gray-600"
                                        : "text-gray-400"
                                    } mr-[4px]`}
                                  >
                                    Tool Type:{" "}
                                  </span>
                                  <StrategyMenu
                                    value={{
                                      label:
                                        operation?.tool_paths?.[
                                          operation?.selectedToolPathInd ?? 0
                                        ]?.tool_types?.[
                                          operation?.selectedToolTypeInd ?? 0
                                        ],
                                      value:
                                        operation?.selectedToolTypeInd ?? 0,
                                    }}
                                    options={operation?.tool_paths?.[
                                      operation?.selectedToolPathInd ?? 0
                                    ]?.tool_types?.map(
                                      (type: string, idx2: number) => ({
                                        label: type,
                                        value: idx2,
                                      })
                                    )}
                                    onChange={(option: any) => {
                                      handleToolType(idx, idx1, option?.value);
                                    }}
                                    isEdit={
                                      isEdit &&
                                      operation?.tool_paths?.[
                                        operation?.selectedToolPathInd ?? 0
                                      ]?.tool_types?.length > 1
                                    }
                                    isValid={strategy?.isValid ?? true}
                                  />
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              )
            )}
        </div>
      </div>
      <EditStrategyErrorModal
        isOpen={showDeletionErrorPopup}
        setIsOpen={setShowDeletionErrorPopup}
        errorMessage={
          "Deleting the last operation is not allowed. At least one operation must remain."
        }
      />
    </>
  );
};

export default MachiningStrategySummaryBox;
