import React, { forwardRef, useRef } from "react";
import { v4 as uuid } from "uuid";

const SummaryExport = forwardRef<HTMLTableElement, any>(
  ({ data, projectInfo, setupId }, ref) => {
    const measurement_unit: string = projectInfo?.unit;
    return (
      <div>
        <table ref={ref}>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td
              data-f-bold={true}
              data-fill-color="FFF3F3F3"
              data-b-t-s="thick"
              data-b-l-s="thick"
            >
              Project
            </td>
            <td data-b-t-s="thick" data-b-r-s="thick">
              {projectInfo?.projectName || ""}
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td
              data-f-bold={true}
              data-fill-color="FFF3F3F3"
              data-b-l-s="thick"
            >
              CAD File
            </td>
            <td data-b-r-s="thick">{projectInfo?.cadPartId || ""}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td
              data-f-bold={true}
              data-fill-color="FFF3F3F3"
              data-b-l-s="thick"
            >
              Workpiece Material
            </td>
            <td data-b-r-s="thick">{projectInfo?.material || ""}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td
              data-f-bold={true}
              data-fill-color="FFF3F3F3"
              data-b-l-s="thick"
              data-b-b-s="thick"
            >
              Machine
            </td>
            <td data-b-r-s="thick" data-b-b-s="thick">
              {projectInfo?.machineName || ""}
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td
              colSpan={3}
              data-a-h="center"
              data-f-sz={13}
              data-f-bold={true}
              data-fill-color="FFD9D9D9"
            >
              Machining Feature
            </td>
            <td
              colSpan={3}
              data-a-h="center"
              data-f-sz={13}
              data-f-bold={true}
              data-fill-color="FFF9CB9C"
            >
              Machining Strategy
            </td>
            <td
              colSpan={10}
              data-a-h="center"
              data-f-sz={13}
              data-f-bold={true}
              data-fill-color="FFB6D7A8"
            >
              Cutting Tool Recommendations
            </td>
            <td
              colSpan={7}
              data-a-h="center"
              data-f-sz={13}
              data-f-bold={true}
              data-fill-color="FFFFE599"
            >
              Optimal Machining Parameters Recommendations
            </td>
          </tr>
          <tr>
            <td></td>
            <td data-a-h="center" data-f-bold={true} data-fill-color="FFD9D9D9">
              Type
            </td>
            <td data-a-h="center" data-f-bold={true} data-fill-color="FFD9D9D9">
              Name
            </td>
            <td data-a-h="center" data-f-bold={true} data-fill-color="FFD9D9D9">
              Number
            </td>
            <td data-a-h="center" data-f-bold={true} data-fill-color="FFF9CB9C">
              Step
            </td>
            <td data-a-h="center" data-f-bold={true} data-fill-color="FFF9CB9C">
              Pass Number
            </td>
            <td data-a-h="center" data-f-bold={true} data-fill-color="FFF9CB9C">
              Operation
            </td>
            <td data-a-h="center" data-f-bold={true} data-fill-color="FFB6D7A8">
              ID
            </td>
            <td data-a-h="center" data-f-bold={true} data-fill-color="FFB6D7A8">
              Description
            </td>
            <td data-a-h="center" data-f-bold={true} data-fill-color="FFB6D7A8">
              Type
            </td>
            <td data-a-h="center" data-f-bold={true} data-fill-color="FFB6D7A8">
              Diameter {`(${measurement_unit})`}
            </td>
            <td data-a-h="center" data-f-bold={true} data-fill-color="FFB6D7A8">
              Corner radius {`(${measurement_unit})`}
            </td>
            <td data-a-h="center" data-f-bold={true} data-fill-color="FFB6D7A8">
              Flute Length {`(${measurement_unit})`}
            </td>
            <td data-a-h="center" data-f-bold={true} data-fill-color="FFB6D7A8">
              Total Length {`(${measurement_unit})`}
            </td>
            <td data-a-h="center" data-f-bold={true} data-fill-color="FFB6D7A8">
              Shaft Diameter {`(${measurement_unit})`}
            </td>
            <td data-a-h="center" data-f-bold={true} data-fill-color="FFB6D7A8">
              Flute Count
            </td>
            <td data-a-h="center" data-f-bold={true} data-fill-color="FFB6D7A8">
              Taper Angle
            </td>
            <td data-a-h="center" data-f-bold={true} data-fill-color="FFFFE599">
              Stepdown [Ap] {`(${measurement_unit})`}
            </td>
            <td data-a-h="center" data-f-bold={true} data-fill-color="FFFFE599">
              Stepover [Ae] {`(${measurement_unit})`}
            </td>
            <td data-a-h="center" data-f-bold={true} data-fill-color="FFFFE599">
              Spindle Speed (rpm)
            </td>
            <td data-a-h="center" data-f-bold={true} data-fill-color="FFFFE599">
              Feed rate {`(${measurement_unit}/min)`}
            </td>
            <td data-a-h="center" data-f-bold={true} data-fill-color="FFFFE599">
              Material Removal Rate {`(cu ${measurement_unit})`}
            </td>
            <td data-a-h="center" data-f-bold={true} data-fill-color="FFFFE599">
              Tool Life (min)
            </td>
            <td data-a-h="center" data-f-bold={true} data-fill-color="FFFFE599">
              Torque (Nm)
            </td>
          </tr>
          {data?.map((item: any, idx1: number) => {
            return (
              <tr key={`${idx1}_${uuid()}`}>
                <td></td>
                <td data-fill-color="FFF3F3F3" data-a-h="center">
                  {item?.label}
                </td>
                <td data-fill-color="FFF3F3F3" data-a-h="center">
                  {item?.sub_label}
                </td>
                <td data-fill-color="FFF3F3F3" data-t="n" data-a-h="center">
                  {item?.number_label}
                </td>
                <td data-fill-color="FFFCE5CD">{`Step ${item?.operation_step}`}</td>
                <td
                  data-fill-color="FFFCE5CD"
                  data-t="n"
                  data-a-h="center"
                >{`${item?.pass_number}`}</td>
                <td data-fill-color="FFFCE5CD">{item?.operation_label}</td>
                {item?.data?.map((col: any, idx2: number) => (
                  <td
                    key={`${idx1}_${idx2}_${uuid()}`}
                    data-fill-color={col?.bgColor}
                    data-t={col?.type}
                  >
                    {col?.value}
                  </td>
                ))}
              </tr>
            );
          })}
        </table>
      </div>
    );
  }
);

export default SummaryExport;
