import { Dispatch, SetStateAction, useState } from "react";
import { IconButton } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import CustomOrderIcon from "../../assets/images/icons/custom-order-icon.svg";

import {
  BsThreeDotsVertical,
  BsArrowsAngleExpand,
  BsChevronDoubleDown,
  BsChevronDoubleUp,
  BsChevronDown,
  BsChevronUp,
} from "react-icons/bs";

interface IOrderDropDownMenu {
  item: any;
  index: number;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setModalData: Dispatch<SetStateAction<any>>;
  handleReordering: (
    selectedId: string,
    type: number,
    position?: number
  ) => void;
  lastIndex: number;
}

const OrderDropDownMenu: React.FC<IOrderDropDownMenu> = ({
  item,
  index,
  setOpen,
  setModalData,
  handleReordering,
  lastIndex,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  let menuItems = [
    { icon: <BsChevronDoubleUp />, label: "Move to Top", type: 0 },
    { icon: <BsChevronUp />, label: "Move to Up", type: 1 },
    { icon: <BsChevronDown />, label: "Move to Down", type: 2 },
    { icon: <BsChevronDoubleDown />, label: "Move to Bottom", type: 3 },
  ];

  if (index === 0) {
    menuItems.splice(0, 2);
  } else if (index === lastIndex) {
    menuItems.splice(2, 2);
  }

  return (
    <div className="inline-flex relative w-6 h-6 items-center justify-center">
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <BsThreeDotsVertical className="!h-5" />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {menuItems.map((menuItem: any) => {
          return (
            <MenuItem
              className="hover:bg-surface-secondary hover:text-surface-default !my-0"
              onClick={() => {
                handleReordering(item.id, menuItem.type);
                handleClose();
              }}
            >
              <p className="flex cgap-4 py-2 px-3 items-center cursor-pointer font-semibold text-xs text-gray-700">
                {menuItem.icon} {menuItem.label}
              </p>
            </MenuItem>
          );
        })}
        <MenuItem
          className="hover:bg-surface-secondary hover:text-surface-default !my-0"
          onClick={() => {
            setOpen(true);
            handleClose();
            setModalData({ ...item });
          }}
        >
          <p className="flex cgap-4 py-2 px-3 items-center cursor-pointer font-semibold text-xs text-gray-700">
            <img src={CustomOrderIcon} alt="" /> Custom
          </p>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default OrderDropDownMenu;
