const ContourIcon = ({ active = false }) => {
  const fillClass = active ? "#1570EF" : "black";
  const strokeClass = active ? "#1570EF" : "black";
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3 1L12.1 2.01053V5.2H10.6V7H13.6H16V5.2H14.5V1H13.3Z"
        fill={fillClass}
      />
      <path d="M4 14.2H7L12.7 8.8H13.6V7L7.6 11.8H4V14.2Z" fill="#D9D9D9" />
      <path d="M7.6 5.8V9.4L10.6 7V5.2H12.1V2.01053L7.6 5.8Z" fill="#D9D9D9" />
      <path d="M1 5.8H7.6L12.1 2.01053V1H7.6L1 5.8Z" fill="white" />
      <path d="M7.6 5.8H1V16H7V14.2H4V11.8V9.4H7.6V5.8Z" fill="white" />
      <path
        d="M1 5.8H7.6M1 5.8V16H7M1 5.8L7.6 1M7.6 5.8V9.4M7.6 5.8L12.1 2.01053M7 16V14.2M7 16L13.6 10V8.8M7.6 9.4H4M7.6 9.4L10.6 7M7 14.2H4M7 14.2L12.7 8.8H13.6M4 14.2V9.4M4 14.2V11.8M4 9.4V11.8M7.6 1H13.3M7.6 1H12.1M13.3 1L12.1 2.01053M13.3 1H14.5M13.6 8.8V7M16 7V5.2H14.5V1M16 7H10.6M16 7H13.6M10.6 7V5.2H12.1V2.01053M10.6 7H13.6M12.1 1H14.5M12.1 1V2.01053M4 11.8H7.6L13.6 7"
        stroke={strokeClass}
        strokeWidth="0.5"
      />
    </svg>
  );
};

export default ContourIcon;
