import { Button, Menu, MenuItem } from "@mui/material";
import clsx from "clsx";
import { useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import { v4 as uuid } from "uuid";

const people = [
  { id: 1, name: "Durward Reynolds", unavailable: false },
  { id: 2, name: "Kenton Towne", unavailable: false },
  { id: 3, name: "Therese Wunsch", unavailable: false },
  { id: 4, name: "Benedict Kessler", unavailable: true },
  { id: 5, name: "Katelyn Rohan", unavailable: false },
];

interface IStrategyMenu {
  isEdit?: boolean;
  value: any;
  options: any;
  onChange?: any;
  isValid: boolean;
}

const StrategyMenu: React.FC<IStrategyMenu> = ({
  isEdit,
  value,
  options,
  onChange,
  isValid,
}) => {
  const id = uuid();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="z-20">
      <Button
        color="inherit"
        sx={{
          textTransform: "none",
          padding: "0 0",
          mx: 0,
        }}
        disableRipple={true}
        id={`basic-button-${id}`}
        aria-controls={open ? `basic-menu-${id}` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <div className="bg-gray-100 rounded-sm px-1.5 py-px flex flex-row items-center space-x-2">
          <span
            className={`text-xs font-medium ${
              isValid ? "text-gray-600" : "text-gray-400"
            }`}
          >
            {value?.label}
          </span>
          {isEdit && (
            <FiChevronDown className="h-[14px] w-[14px] text-gray-475" />
          )}
        </div>
      </Button>
      {isEdit && (
        <Menu
          id={`basic-menu-${id}`}
          anchorEl={anchorEl}
          MenuListProps={{
            "aria-labelledby": "basic-button",
            sx: {
              minWidth: anchorEl && anchorEl.offsetWidth,
              py: 0,
            },
          }}
          open={open}
          onClose={handleClose}
        >
          {options.map((option: any) => (
            <MenuItem
              key={option.value}
              value={option}
              sx={{
                height: "auto",
              }}
              onClick={() => {
                onChange(option);
                handleClose();
              }}
              className={clsx(
                "rounded-sm text-xs font-medium hover:opacity-80 text-gray-475 px-1.5 py-1 cursor-pointer",
                { "bg-gray-100": value.value === option.value }
              )}
            >
              {option?.label}
            </MenuItem>
          ))}
        </Menu>
      )}
    </div>
  );
};

export default StrategyMenu;
