import { useState, useEffect } from "react";
import Modal from "react-modal";
import ClossInValidPopUp from "assets/images/icons/charm_cross.svg";
import ConfirmPopUp from "./ConfirmPopUp";
import InvalidSwitch from "components/InvalidSwitch";
import {
  customStylesInvalidPopUp,
  IInvalidOperationsPopUp,
} from "../../constant/invalidPopupUtils";
import Button from "components/Button";
const InvalidOperationsPopUp: React.FC<IInvalidOperationsPopUp> = ({
  strategyInd,
  closePopUp,
  showPopUp,
  setInValid,
  strategy,
}) => {
  const [showConfirmPopUp, setShowConfirmPopUp] = useState(false);
  const [localStrategy, setLocalStrategy] = useState(
    JSON.parse(JSON.stringify(strategy))
  );

  const openConfirmPopUp = () => {
    let hasInvalidOperation = false;
    localStrategy?.passes?.forEach((pass: any, idx: number) => {
      pass?.operations?.forEach((operation: any, idx1: number) => {
        if (
          strategy?.passes?.[idx]?.operations?.[idx1]?.isValid &&
          operation?.isValid === false
        ) {
          hasInvalidOperation = true;
        }
      });
    });

    return hasInvalidOperation;
  };

  const confirmInvalidStatus = () => {
    let localStrategyCopy = { ...localStrategy };
    let check = true;
    localStrategyCopy?.passes?.forEach((pass: any) => {
      pass?.operations?.forEach((operation: any) => {
        if (!operation?.isValid) {
          check = false;
        }
      });
    });
    localStrategyCopy.isValid = check;
    setInValid({ ...localStrategyCopy }, strategyInd);
    closePopUp();
  };

  useEffect(() => {
    setLocalStrategy(JSON.parse(JSON.stringify(strategy)));
  }, [showPopUp]);
  return (
    <Modal
      isOpen={showPopUp}
      style={customStylesInvalidPopUp}
      onRequestClose={closePopUp}
    >
      <div>
        <div className="flex flex-row justify-between border-b border-gray-300 p-[16px] sticky top-0 bg-white">
          <div className="font-[500] text-[20px] leading-[24px]">Invalid</div>
          <button onClick={closePopUp}>
            <img src={ClossInValidPopUp} alt="cross" />
          </button>
        </div>
        <div className="p-[16px] max-h-[70vh] overflow-y-auto">
          <div className="rounded-[12px] border border-gray-200 w-full overflow-hidden">
            <table>
              <thead>
                <tr className="text-[12px] leading-[18px] text-gray-600 border-b">
                  <th
                    colSpan={2}
                    className="px-[24px] py-[12px] text-left font-medium"
                  >
                    Operations
                  </th>
                  <th className="font-medium">Validity</th>
                  <th className="font-medium" colSpan={2}>
                    Reason
                  </th>
                </tr>
              </thead>
              <tbody>
                {localStrategy?.passes?.map((element: any, idx: number) =>
                  element?.operations?.map((operation: any, idx1: number) => {
                    const selectedToolPath =
                      operation?.tool_paths?.[
                        operation?.selectedToolPathInd ?? 0
                      ];

                    return (
                      <tr
                        className="border-b border-gray-200 bg-gray-50"
                        key={`${idx}-${idx1}`}
                      >
                        <td colSpan={2}>
                          <div className="flex flex-col py-[12px] px-[24px]">
                            <div className="font-[600] text-[14px] leading-lg">
                              {`${
                                selectedToolPath?.tool_path_type ||
                                selectedToolPath?.type ||
                                ""
                              } - ${
                                selectedToolPath?.tool_path ||
                                selectedToolPath?.name ||
                                ""
                              }`}{" "}
                              {selectedToolPath?.tool_path_style
                                ? ` (${selectedToolPath?.tool_path_style})`
                                : ""}
                            </div>
                            <div className="font-[400] text-[12px] leading-lg">
                              {operation?.operation} ({operation?.location})
                            </div>
                          </div>
                        </td>
                        <td className="text-center">
                          <InvalidSwitch
                            initialChecked={
                              !localStrategy?.passes[idx]?.operations[idx1]
                                ?.isValid
                            }
                            handleChange={(e: any) => {
                              let lStrat = { ...localStrategy };
                              if (e.target.checked) {
                                lStrat.passes[idx].operations[idx1].isValid =
                                  false;
                                lStrat.isValid = false;
                                setLocalStrategy({ ...lStrat });
                              } else {
                                lStrat.passes[idx].operations[idx1].isValid =
                                  true;
                                lStrat.passes[idx].operations[idx1].feedback =
                                  "";
                                setLocalStrategy({ ...lStrat });
                              }
                            }}
                          />
                        </td>
                        <td className="p-[16px]">
                          <textarea
                            className="bg-white rounded-[8px] h-[100%] w-[240px] text-[12px] resize-none overflow-hidden p-[8px] focus:outline-none border border-gray-200"
                            rows={3}
                            maxLength={150}
                            disabled={
                              localStrategy?.passes[idx]?.operations[idx1]
                                ?.isValid
                            }
                            value={
                              localStrategy?.passes[idx]?.operations[idx1]
                                ?.feedback
                            }
                            placeholder="Prefer boring operation for deeper holes"
                            onChange={(e) => {
                              let lStrat = { ...localStrategy };
                              lStrat.passes[idx].operations[idx1].feedback =
                                e.target.value;
                              setLocalStrategy({ ...lStrat });
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex border-t border-gray-200 p-[16px] justify-center">
          <Button
            className="modal-button"
            onClick={() => {
              if (openConfirmPopUp()) {
                setShowConfirmPopUp(true);
              } else {
                confirmInvalidStatus();
              }
            }}
          >
            Save
          </Button>
        </div>
        <ConfirmPopUp
          showConfirmPopUp={showConfirmPopUp}
          closeConfirmPopUp={() => setShowConfirmPopUp(false)}
          confirmFunction={confirmInvalidStatus}
        />
      </div>
    </Modal>
  );
};

export default InvalidOperationsPopUp;
