import { useState, useEffect } from "react";
import Modal from "react-modal";
import ClossInValidPopUp from "assets/images/icons/charm_cross.svg";
import InvalidSwitch from "components/InvalidSwitch";
import ConfirmPopUp from "components/MachiningStrategySummaryBox/ConfirmPopUp";
import {
  OPERATIONS_NOT_SUPPORTING_STEPDOWN,
  OPERATIONS_NOT_SUPPORTING_STEPOVER,
} from "constant";
import {
  customStylesInvalidToolPopUp,
  IInValidToolPopUp,
} from "constant/invalidPopupUtils";
import Button from "components/Button";

const InValidToolPopUp: React.FC<IInValidToolPopUp> = ({
  showPopUp,
  closePopUp,
  tool,
  confirmInValidData,
}) => {
  const [localTool, setLocalTool] = useState(JSON.parse(JSON.stringify(tool)));
  const [showConfirmPopUp, setShowConfirmPopUp] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");

  const invalidParams = [
    {
      key: "Spindle speed",
      label: "Spindle Speed",
      placeholder: "Radial depth of cut should be reduced",
    },
    {
      key: "Feed rate",
      label: "Feed Rate",
      placeholder: "Radial depth of cut should be reduced",
    },
  ];

  if (
    !OPERATIONS_NOT_SUPPORTING_STEPOVER.has(
      tool?.additional_info?.machining_strategy?.operation
    )
  ) {
    invalidParams.push({
      key: "Stepover [Ae]",
      label: "Stepover [Ae]",
      placeholder: "Radial depth of cut should be reduced",
    });
  }

  if (
    !OPERATIONS_NOT_SUPPORTING_STEPDOWN.has(
      tool?.additional_info?.machining_strategy?.operation
    )
  ) {
    invalidParams.push({
      key: "Stepdown [Ap]",
      label: "Stepdown [Ap]",
      placeholder: "Radial depth of cut should be reduced",
    });
  }

  useEffect(() => {
    setLocalTool(JSON.parse(JSON.stringify(tool)));
  }, [showPopUp]);
  const disableParams = localTool?.isValid === false;

  const openConfirmPopUp = () => {
    if (tool?.isValid && localTool?.isValid === false) {
      return 1;
    }
    for (const param in localTool?.valid_data) {
      if (
        localTool.valid_data[param].isInValid &&
        !tool.valid_data[param]?.isInValid
      ) {
        return 2;
      }
    }
    return 0;
  };

  return (
    <Modal
      isOpen={showPopUp}
      style={customStylesInvalidToolPopUp}
      onRequestClose={closePopUp}
    >
      <div className="modalinvalidandvalid">
        <div className="flex flex-row justify-between border-b border-gray-300 p-[16px] sticky top-0 bg-white">
          <div className="font-[500] text-[20px] leading-[24px]">Invalid</div>
          <button onClick={closePopUp}>
            <img src={ClossInValidPopUp} alt="" />
          </button>
        </div>
        <div className="p-[16px] max-h-[70vh] overflow-y-auto">
          <div className="rounded-[12px] border border-gray-200 w-full mb-4 overflow-hidden">
            <table className="w-full">
              <thead className="rounded-[12px] border-b">
                <tr className="text-[12px] leading-[18px] font-semibold text-gray-600">
                  <th className="px-[24px] py-[12px] text-left w-[100%] font-medium">
                    Tool ID
                  </th>
                  <th className="w-[92px] font-medium  py-[12px] px-[16px]">
                    Validity
                  </th>
                  <th className="w-[272px] font-medium py-[12px] px-[16px]">
                    Reason
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="bg-gray-50">
                  <td className=" px-[24px] py-[16px]">
                    <div className="font-[600] text-[14px] leading-lg">
                      {tool["Tool ID"]}
                    </div>
                  </td>
                  <td className="text-center w-[92px] p-[16px]">
                    <InvalidSwitch
                      initialChecked={localTool.isValid === false}
                      handleChange={(e: any) => {
                        let lTool = { ...localTool };
                        if (e.target.checked) {
                          lTool.isValid = false;
                          setLocalTool({ ...lTool });
                        } else {
                          lTool.isValid = true;
                          lTool.feedback = "";
                          setLocalTool({ ...lTool });
                        }
                      }}
                    />
                  </td>
                  <td className="w-[272px] p-[16px]">
                    <textarea
                      className="bg-white rounded-[8px] h-[100%] w-[240px] text-[12px] resize-none overflow-hidden focus:outline-none p-[8px] border border-gray-200"
                      rows={3}
                      maxLength={150}
                      disabled={localTool.isValid !== false}
                      value={localTool.feedback}
                      placeholder="Tool diameter is insufficient for roughing large surfaces"
                      onChange={(e) => {
                        let lTool = { ...localTool };
                        lTool.feedback = e.target.value;
                        setLocalTool({ ...lTool });
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {!localTool?.paramsNotPresent && (
            <div className="rounded-[12px] border border-gray-200 w-full overflow-hidden">
              <table className="w-full">
                <thead className="rounded-[12px] border-b">
                  <tr className="text-[12px] leading-[18px] font-semibold text-gray-600">
                    <th className="px-[24px] py-[12px] font-medium text-left">
                      Parameters
                    </th>
                    <th className="font-medium w-[92px] px-[16px] py-[12px]">
                      Validity
                    </th>
                    <th className="font-medium w-[272px] px-[16px] py-[12px]">
                      Reason
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {invalidParams.map(({ key, label, placeholder }) => (
                    <tr
                      className="border-b border-gray-200 bg-gray-50"
                      key={`${tool["Tool ID"]}-${key}`}
                    >
                      <td className="px-[24px] py-[16px]">
                        <div className="flex flex-col gap-1">
                          <div className="font-[600] text-[14px]">{label}</div>
                          <div className="font-[400]  text-[12px]">
                            {localTool[key]?.value?.toFixed(2) || "--"}{" "}
                            {localTool[key]?.unit}
                          </div>
                        </div>
                      </td>
                      <td
                        className={`text-center w-[92px] p-[16px] ${
                          disableParams ? "opacity-60" : ""
                        }`}
                      >
                        <InvalidSwitch
                          initialChecked={
                            localTool.valid_data?.[key]?.isInValid
                          }
                          handleChange={(e: any) => {
                            let lTool = { ...localTool };
                            if (
                              lTool.valid_data[key]?.isInValid === undefined
                            ) {
                              lTool.valid_data[key] = {
                                isInValid: false,
                                feedback: "",
                              };
                            }
                            if (e.target.checked) {
                              lTool.valid_data[key].isInValid = true;
                            } else {
                              lTool.valid_data[key].isInValid = false;
                              lTool.valid_data[key].feedback = "";
                            }
                            setLocalTool({ ...lTool });
                          }}
                          disabled={disableParams}
                        />
                      </td>
                      <td
                        className={`text-center w-[272px] p-[16px] ${
                          disableParams ? "opacity-60" : ""
                        }`}
                      >
                        <textarea
                          className="bg-white rounded-[8px] h-[100%] w-[240px] text-[12px] resize-none overflow-hidden focus:outline-none p-[8px] border border-gray-200"
                          rows={3}
                          maxLength={150}
                          disabled={
                            !localTool.valid_data?.[key]?.isInValid ||
                            disableParams
                          }
                          value={localTool.valid_data?.[key]?.feedback}
                          onChange={(e) => {
                            let lTool = { ...localTool };
                            lTool.valid_data[key].feedback = e.target.value;
                            setLocalTool({ ...lTool });
                          }}
                          placeholder={placeholder}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
        <div className="flex border-t border-gray-200 p-[16px] justify-center gap-4">
          <Button
            className="modal-button"
            onClick={() => {
              const openConfirm = openConfirmPopUp();
              if (!openConfirm) {
                confirmInValidData({ ...localTool });
                closePopUp();
              } else {
                setConfirmMessage(
                  openConfirm === 1
                    ? "Are you sure you want to mark this tool as Invalid?"
                    : "Are you sure you want to mark the parameters as Invalid?"
                );
                setShowConfirmPopUp(true);
              }
            }}
          >
            Save
          </Button>
        </div>
        <ConfirmPopUp
          showConfirmPopUp={showConfirmPopUp}
          closeConfirmPopUp={() => setShowConfirmPopUp(false)}
          confirmFunction={() => {
            confirmInValidData({ ...localTool });
            closePopUp();
          }}
          confirmMessage={confirmMessage}
        />
      </div>
    </Modal>
  );
};

export default InValidToolPopUp;
