import SearchIcon from "assets/images/icons/search.svg";
import { useState, useEffect } from "react";

interface ISearchInput {
  value: string;
  onChange: any;
  placeholder?: string;
  className?: string;
}
const SearchInput: React.FC<ISearchInput> = ({
  value,
  onChange,
  placeholder,
  className,
}) => {
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleChange = (e: any) => {
    setInputValue(e?.target?.value);
    if (onChange) onChange(e);
  };

  return (
    <div className={`relative ${className}`}>
      <div className="absolute top-0 bottom-0 left-0 w-[37px] flex justify-center items-center">
        <img src={SearchIcon} alt="search icon" />
      </div>
      <input
        data-testid="search-input-field"
        value={inputValue}
        onChange={handleChange}
        placeholder={placeholder}
        className="rounded-[4px] shadow-input w-full py-[7px] pl-[37px] pr-2 text-sm text-black font-light"
      />
    </div>
  );
};

export default SearchInput;
