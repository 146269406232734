import { useContext, useState, useEffect, useCallback } from "react";
import AccordionItem from "components/AccordionItem";
import { Checkbox } from "components/Checkbox";
import MachiningStrategySummaryBox from "components/MachiningStrategySummaryBox";
import SummaryBox from "components/SummaryBox";
import { GlobalContext } from "context/GlobalContext";
import {
  ACTION_BY_KEY,
  keyList,
  SET_ASSIGNED_SETUPS,
  SET_FEATURE_MAPPING,
  SET_LOADING_BAR,
  SET_MACHINE_SETUP,
  SET_ORIGIN_MACHINE_SETUP,
  SET_PROJECT_INFO,
  SET_STREAM_STEP,
} from "constant";
import { useApiCall } from "hooks";
import {
  getMachiningStrategy,
  postCreateUserSelectionAPI,
  putProjectAPI,
  putUserSelectionAPI,
} from "services";
import Button from "components/Button";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { FiArrowRight, FiPlus } from "react-icons/fi";
import NoFoundImg from "assets/images/NoFound.svg";
import { constructTitle } from "../MachiningStrategySummary/utils";
import CreateStrategyModal from "components/CreateStrategyModal";
import { toast } from "react-toastify";
import { createDeepCopy, resetScroll } from "utils";

const MachineStrategy = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const {
    showTooltip,
    hideTooltip,
    projectInfo,
    setupNumber,
    machiningSetups,
    visiblefeature_ids,
    originMachiningSetups,
    assignedMachiningSetups,
  } = state;
  const navigate = useNavigate();
  const [putProject] = useApiCall(putProjectAPI);
  const [postCreateUserSelection]: any = useApiCall(postCreateUserSelectionAPI);
  const [putUserSelection]: any = useApiCall(putUserSelectionAPI);

  const [selectedItem, setSelectedItem] = useState<any>({
    type: null,
    feature_id: null,
  });
  const [selectedTitle, setSelectedTitle] = useState<any>(null);
  const [selectedCard, setSelectedCard] = useState(0);
  const [selectedSummary, setSelectedSummary] = useState<any>(null);
  const [fetchData, loading]: any = useApiCall(getMachiningStrategy);
  const [data, setData] = useState<any>(null);
  const [availableList, setAvailableList] = useState([]);
  const [possibleList, setPossibleList] = useState([]);
  const [isFirst, setIsFirst] = useState(true);
  const [showCreateStrategyModal, setShowCreateStrategyModal] = useState(false);

  useEffect(() => {
    const setupIdx = machiningSetups?.findIndex(
      (setup: any) => setup?.machiningSetupNumber === setupNumber
    );

    if (setupIdx < 0 || !visiblefeature_ids?.[setupNumber]?.length) return;

    let arr: any = [];
    keyList?.forEach(({ title, type }, idx: number) => {
      const existing_features = machiningSetups?.[setupIdx]?.features?.filter(
        (feature: any) =>
          feature?.featureInformation?.feature_data?.feature_type === type
      );
      if (!existing_features?.length) return;
      let detailData: any[] = [];
      existing_features?.forEach((feature: any, idx1: number) => {
        if (!visiblefeature_ids?.[setupNumber]?.includes(feature?.feature_id)) {
          return;
        }
        detailData.push({
          title: `${title} ${idx1 + 1}`,
          index: idx1,
          feature_id: feature?.feature_id,
          featureInformation: feature?.featureInformation,
        });
      });

      if (detailData?.length) {
        arr.push({
          title,
          type,
          data: detailData,
        });
      }
    });
    if (isFirst && !!arr?.length) {
      setSelectedItem({
        type: arr?.[0]?.type,
        feature_id: arr?.[0]?.data?.[0]?.feature_id,
      });
      setSelectedTitle(arr?.[0]?.data?.[0]?.title);
      setIsFirst(false);
    }

    setAvailableList(arr);

    let list =
      arr?.find((category: any) => {
        return !!category?.data?.find(({ featureInformation }: any) => {
          return !!featureInformation?.strategies?.find((strategy: any) => {
            return !!strategy?.machining_strategy?.find(
              (mach: any) => !!mach?.isSelected
            );
          });
        });
      }) || [];
    setPossibleList(list?.data || []);
  }, [machiningSetups, visiblefeature_ids]);

  useEffect(() => {
    if (!selectedItem?.feature_id) return;
    let updated_setups = [...machiningSetups];
    const setupIdx = updated_setups?.findIndex(
      (setup: any) => setup?.machiningSetupNumber === setupNumber
    );

    const feature_idx = updated_setups?.[setupIdx]?.features?.findIndex(
      (feature: any) => feature?.feature_id === selectedItem?.feature_id
    );

    let feature_information = {
      ...updated_setups?.[setupIdx]?.features?.[feature_idx]
        ?.featureInformation,
    };

    const originaSetupIdx = originMachiningSetups?.findIndex(
      (setup: any) => setup?.machiningSetupNumber === setupNumber
    );

    const originfeature_idx = originMachiningSetups?.[
      originaSetupIdx
    ]?.features?.findIndex(
      (feature: any) => feature?.feature_id === selectedItem?.feature_id
    );

    const origin_feature_infomation = {
      ...originMachiningSetups?.[originaSetupIdx]?.features?.[originfeature_idx]
        ?.featureInformation,
    };

    let flag = false;
    if (
      !feature_information?.strategies?.length ||
      feature_information?.feature_data?.feature_name !==
        origin_feature_infomation?.feature_data?.feature_name ||
      feature_information?.feature_data?.feature_type !==
        origin_feature_infomation?.feature_data?.feature_type ||
      JSON.stringify(origin_feature_infomation?.feature_data?.feature_info) !==
        JSON.stringify(feature_information?.feature_data?.feature_info)
    ) {
      flag = true;
    }
    feature_information?.strategies?.[0]?.machining_strategy?.forEach(
      (strategy: any) => {
        if (strategy?.["isValid"] === undefined) flag = true;
      }
    );

    if (flag) {
      fetchData([
        {
          ...feature_information?.feature_data,
          measurement_unit: projectInfo?.unit,
          cam_software: projectInfo?.cam_software ?? "siemens",
        },
      ]).then((res: any) => {
        if (!res) return;

        let new_strategy = [
          ...(res?.strategies?.[0]?.machining_strategy ?? []),
        ];

        new_strategy?.forEach((strategy: any) => {
          strategy?.passes?.forEach((pass: any) => {
            pass?.operations?.forEach((operation: any) => {
              operation["isValid"] = true;
              operation["feedback"] = "";
            });
          });
        });

        new_strategy = new_strategy?.map((item: any, idx: number) => {
          if (idx === 0) {
            return { ...item, isSelected: true, isValid: true, feedback: "" };
          }
          return { ...item, isSelected: false, isValid: true, feedback: "" };
        });
        res.strategies[0].machining_strategy = new_strategy;
        feature_information["strategies"] = res.strategies;
        updated_setups[setupIdx].features[feature_idx].featureInformation =
          feature_information;

        dispatch({
          type: SET_MACHINE_SETUP,
          payload: updated_setups,
        });
        setData(res);
      });
    } else {
      setData({ strategies: feature_information?.strategies });
    }
  }, [selectedItem]);

  const addCustomStrategy = useCallback(
    (strategy: any) => {
      const updated_setups = [...machiningSetups];
      const setupIdx = updated_setups?.findIndex(
        (setup: any) => setup?.machiningSetupNumber === setupNumber
      );
      const feature_idx = updated_setups?.[setupIdx]?.features?.findIndex(
        (feature: any) => feature?.feature_id === selectedItem?.feature_id
      );
      const feature_information = {
        ...updated_setups?.[setupIdx]?.features?.[feature_idx]
          ?.featureInformation,
      };

      let customStrategyCount = 0;
      feature_information?.strategies?.[0]?.machining_strategy?.forEach(
        (strategy: any) => {
          if (strategy.custom) {
            customStrategyCount++;
          }
        }
      );

      if (customStrategyCount >= 5) {
        toast.error("You can not add more than 5 custom strategies");
        return;
      }

      feature_information?.strategies?.[0]?.machining_strategy?.unshift(
        strategy
      );

      /**
       * Once a new strategy is added, the index of the existing strategy will
       * be incremented by one. If there is already a selected strategy,
       * we will update the assignedMachiningSetups mapping accordingly.
       */
      const selectedStrategy =
        feature_information?.strategies?.[0]?.machining_strategy?.find(
          (strategy: any) => strategy?.isSelected
        );
      if (selectedStrategy) {
        const updatedAssignedSetups = createDeepCopy(assignedMachiningSetups);
        selectedStrategy.passes?.forEach((pass: any) => {
          pass.operations?.forEach((operation: any) => {
            if (operation.assignedSetupId) {
              updatedAssignedSetups[operation.assignedSetupId][
                operation.pass_number
              ].strategyIndex += 1;
            }
          });
        });
        dispatch({
          type: SET_ASSIGNED_SETUPS,
          payload: updatedAssignedSetups,
        });
      }
      dispatch({
        type: SET_MACHINE_SETUP,
        payload: updated_setups,
      });
      setData({ strategies: feature_information?.strategies });
    },
    [
      dispatch,
      machiningSetups,
      selectedItem?.feature_id,
      setupNumber,
      assignedMachiningSetups,
    ]
  );

  useEffect(() => {
    dispatch({
      type: SET_LOADING_BAR,
      payload: loading,
    });
  }, [dispatch, loading]);

  useEffect(() => {
    if (!data) return;
    let selected = data?.strategies?.[0]?.machining_strategy?.find(
      (strategy: any) => strategy?.isSelected
    );
    setSelectedSummary(selected);
    resetScroll("strategies-container", "scrollLeft");
    resetScroll("feature-container", "scrollTop");
  }, [data]);

  const confirmValidData = (strategy: any, index: number) => {
    let updated_setups = [...machiningSetups];
    const setupIdx = updated_setups?.findIndex(
      (setup: any) => setup?.machiningSetupNumber === setupNumber
    );

    const feature_idx = updated_setups?.[setupIdx]?.features?.findIndex(
      (feature: any) => feature?.feature_id === selectedItem?.feature_id
    );

    let feature_information = {
      ...updated_setups?.[setupIdx]?.features?.[feature_idx]
        ?.featureInformation,
    };

    feature_information.strategies[0].machining_strategy[index].isValid =
      strategy.isValid;
    feature_information.strategies[0].machining_strategy[index].feedback =
      strategy.feedback;

    feature_information.strategies[0].machining_strategy[index].passes?.forEach(
      (pass: any, ind: number) => {
        pass?.operations?.forEach((operation: any, ind2: number) => {
          operation["isValid"] =
            strategy?.passes?.[ind]?.operations?.[ind2]?.isValid;
          operation["feedback"] =
            strategy?.passes?.[ind]?.operations?.[ind2]?.feedback;
        });
      }
    );
    updated_setups[setupIdx].features[feature_idx].featureInformation =
      feature_information;
    if (strategy?.isSelected && !strategy?.isValid) {
      feature_information.strategies[0].machining_strategy[index].isSelected =
        false;
      setSelectedSummary({});
    }

    dispatch({
      type: SET_MACHINE_SETUP,
      payload: updated_setups,
    });
  };

  const handleRedirect = (tab: string) => {
    navigate(`/project?tab=${tab}`, {
      ...(tab === "strategy-summary" && {
        state: { referrer: "machine" },
      }),
    });
  };

  const makeFeatureMapping = () => {
    const featureTypeToCountMap: any = {};
    const featureTypeToTitleMap: any = {};
    keyList.forEach((element: any) => {
      featureTypeToTitleMap[element.type] = element.title;
    });
    const featureMapping: any = {};
    machiningSetups?.[0]?.features?.forEach((feature: any, index: number) => {
      const type = feature?.featureInformation?.feature_data?.feature_type;
      if (type in featureTypeToCountMap) {
        featureTypeToCountMap[type]++;
      } else {
        featureTypeToCountMap[type] = 1;
      }
      const title = constructTitle(
        type,
        featureTypeToTitleMap,
        featureTypeToCountMap
      );
      featureMapping[feature?.feature_id] = {
        index,
        title,
        serialNumber: featureTypeToCountMap[type],
      };
    });
    dispatch({
      type: SET_FEATURE_MAPPING,
      payload: featureMapping,
    });
  };

  const handleContinue = async () => {
    makeFeatureMapping();
    handleRedirect("manufacturing-process");
    // try {
    //   const payload: any = {
    //     organizationId: projectInfo?.organizationId,
    //     streamStep: "initial-trigger",
    //     selectionId: projectInfo?.userSelectionId,
    //     ncProjectId: projectInfo?.ncProjectId,
    //     userRecordId: projectInfo?.userRecordId,
    //     machineId: "",
    //     machineType: "",
    //     workpieceCADfile: "",
    //     workpieceId: "",
    //     workpiecePathToCADfile: "",
    //     workpieceMaterial: projectInfo?.material,
    //     workpieceSubMaterial: projectInfo?.subMaterial,
    //     workpieceRawDimensions: {
    //       length: "",
    //       width: "",
    //       height: "",
    //     },
    //     machiningSetups,
    //   };
    //   if (projectInfo?.userSelectionId) {
    //     if (
    //       JSON.stringify(machiningSetups) ===
    //       JSON.stringify(originMachiningSetups)
    //     ) {
    //       dispatch({
    //         type: SET_ORIGIN_MACHINE_SETUP,
    //         payload: [...machiningSetups],
    //       });
    //       handleRedirect("strategy-summary");
    //     } else {
    //       dispatch({
    //         type: SET_LOADING_BAR,
    //         payload: true,
    //       });
    //       putUserSelection(payload).finally(() => {
    //         dispatch({
    //           type: SET_LOADING_BAR,
    //           payload: false,
    //         });
    //         dispatch({
    //           type: SET_ORIGIN_MACHINE_SETUP,
    //           payload: [...machiningSetups],
    //         });
    //         handleRedirect("strategy-summary");
    //       });
    //     }
    //   } else {
    //     dispatch({
    //       type: SET_LOADING_BAR,
    //       payload: true,
    //     });
    //     postCreateUserSelection(payload)
    //       .then(async (res: any) => {
    //         const putProjectPayload = {
    //           organizationId: projectInfo?.organizationId,
    //           ncProjectId: projectInfo?.ncProjectId,
    //           userRecordId: projectInfo?.userRecordId,
    //           userSelectionId: res?.selectionId,
    //         };
    //         await putProject(putProjectPayload);
    //         dispatch({
    //           type: SET_PROJECT_INFO,
    //           payload: {
    //             ...projectInfo,
    //             userSelectionId: res?.selectionId,
    //           },
    //         });
    //         dispatch({
    //           type: SET_STREAM_STEP,
    //           payload: "intial-trigger",
    //         });
    //         dispatch({
    //           type: SET_ORIGIN_MACHINE_SETUP,
    //           payload: [...machiningSetups],
    //         });
    //       })
    //       .finally(() => {
    //         dispatch({
    //           type: SET_LOADING_BAR,
    //           payload: false,
    //         });
    //         handleRedirect("strategy-summary");
    //       });
    //   }
    // } catch (err) {
    //   dispatch({
    //     type: SET_LOADING_BAR,
    //     payload: false,
    //   });
    // }
  };

  if (!availableList?.length) {
    navigate("/projects");
  }

  return (
    <div className="relative h-[calc(100vh-166px)] flex px-4">
      <div className="w-[25%] h-full">
        <div className="w-full overflow-y-auto h-full flex flex-col justify-between bg-gray-f9f p-1 rounded-md border border-gray-eac">
          <div className="w-full">
            <p className="font-medium text-gray-475 text-[10px]">Features</p>
            <div className="pr-1">
              {availableList?.map(({ title, data, type }: any, idx: number) => {
                return (
                  <AccordionItem
                    key={idx}
                    tabLabelChild={`${idx + 1}. ${title}`}
                    className="border-2 border-surface-soft py-2 px-4 my-1"
                    labelChildClass="text-black-222"
                    tabContentClassName="!pb-0"
                    checked={selectedCard === idx}
                    dragIcon={false}
                    onChange={(e) => {
                      if (selectedCard !== idx) {
                        setSelectedCard(idx);
                        setSelectedItem({
                          type,
                          feature_id: data?.[0]?.feature_id,
                        });
                        setSelectedTitle(data?.[0]?.title);
                      }
                    }}
                    count={
                      data?.filter(
                        (item: any) =>
                          !!item?.featureInformation?.strategies?.find(
                            (strategy: any) =>
                              !!strategy?.machining_strategy?.find(
                                (item3: any) => item3?.isSelected
                              )
                          )
                      )?.length || 0
                    }
                    isBackground
                  >
                    <div>
                      {data?.map((item: any, idx1: number) => {
                        return (
                          <div
                            className="mb-[10px] flex flex-row items-center cursor-pointer justify-between w-full"
                            onClick={() => {
                              if (
                                selectedItem?.feature_id !== item?.feature_id
                              ) {
                                setSelectedItem({
                                  type,
                                  feature_id: item?.feature_id,
                                });
                                setSelectedTitle(`${item?.title}`);
                              }
                            }}
                          >
                            <div className="flex flex-row items-center">
                              {selectedItem?.type === type &&
                                selectedItem?.index === idx1 && (
                                  <FiArrowRight className="text-surface-default mr-2" />
                                )}
                              <span
                                className={clsx("text-sm", {
                                  "text-surface-default font-semibold":
                                    selectedItem?.feature_id ===
                                    item?.feature_id,
                                })}
                              >{`${item?.title}`}</span>
                            </div>
                            {!!item?.featureInformation?.strategies?.find(
                              (strategy: any) =>
                                !!strategy?.machining_strategy?.find(
                                  (item3: any) => item3?.isSelected
                                )
                            ) && (
                              <Checkbox checked={true} onChange={() => {}} />
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </AccordionItem>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      {selectedItem?.feature_id && (
        <div className="w-[75%] flex flex-col relative">
          <div
            className="w-full px-4 h-[calc(100%-75px)] overflow-y-auto"
            id="feature-container"
            onScroll={() => hideTooltip()}
          >
            <div className="w-full mb-4">
              <SummaryBox
                label={`Feature Summary : `}
                selectedItem={selectedItem}
                title={selectedTitle}
              />
            </div>
            <div className="mb-4 border border-gray-eac rounded-lg">
              <div className="w-full flex flex-row items-center justify-between p-2 border-b border-gray-eac bg-gray-f9f">
                <span className="font-semibold text-black">
                  Machining Strategies
                </span>
                <button
                  className="bg-blue-d1e flex flex-row items-center text-surface-default space-x-2 text-xs font-semibold px-3 py-1.5 rounded"
                  onClick={() => {
                    setShowCreateStrategyModal(true);
                  }}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.33333 13.6667V8.33333M8.33333 8.33333V3M8.33333 8.33333H13.6667M8.33333 8.33333H3"
                      stroke="#1570EF"
                      strokeWidth="1.6"
                      strokeLinecap="round"
                    />
                  </svg>
                  <span>Add strategy</span>
                </button>
              </div>
              {!loading && (
                <div
                  className="flex flex-row overflow-x-auto p-2"
                  id="strategies-container"
                >
                  {data?.strategies?.[0]?.machining_strategy &&
                  !!data?.strategies?.[0]?.machining_strategy?.length ? (
                    data?.strategies?.[0]?.machining_strategy?.map(
                      (item: any, idx: number) => (
                        <MachiningStrategySummaryBox
                          strategy={item}
                          checked={selectedSummary?.index === idx}
                          key={idx}
                          type={
                            item?.custom
                              ? "custom"
                              : item?.recommended && "recommend"
                          }
                          selectedItem={selectedItem}
                          setSelectedSummary={setSelectedSummary}
                          index={idx}
                          setInValid={confirmValidData}
                        />
                      )
                    )
                  ) : (
                    <div
                      className="w-full flex flex-col justify-center items-center
                   py-20 space-y-2"
                    >
                      <img src={NoFoundImg} alt="not-found" />
                      <p className="text-sm font-semibold text-gray-344">
                        Oops! No machining strategy is available.
                      </p>
                    </div>
                  )}
                </div>
              )}
            </div>

            {/* Hiding strategy rationale */}
            {/* <div className="p-2 bg-gray-f9f rounded-lg border border-gray-eac">
              <p className="text-black font-semibold mb-[10px]">Rationale</p>
              <div>
                {selectedSummary?.rationale &&
                  selectedSummary?.rationale?.map(
                    (item: string, idx: number) => {
                      return (
                        <div className="flex flex-row items-start" key={idx}>
                          <img
                            src={DoubleCheckIcon}
                            alt="double check"
                            className="mr-[6px]"
                          />
                          <p className="text-gray-737 text-sm font-medium">
                            {item}
                          </p>
                        </div>
                      );
                    }
                  )}
              </div>
            </div> */}
          </div>

          <div className="w-full absolute bottom-[4px] left-0 right-0 bg-white flex justify-end items-center py-2 border-t border-gray-e7e pl-4">
            <div className="flex flex-row font-semibold gap-4">
              <Button variant="gray" onClick={() => handleRedirect("feature")}>
                Back
              </Button>
              <div
                className={clsx({
                  "cursor-not-allowed": !possibleList?.length,
                })}
                id="machine-btn"
                onMouseOver={() =>
                  !possibleList?.length && showTooltip("machine-btn")
                }
                onMouseLeave={() => hideTooltip()}
                data-tooltip-content="You must select at least one strategy to Continue"
              >
                <Button
                  className={clsx({
                    "pointer-events-none": !possibleList?.length,
                  })}
                  onClick={() => handleContinue()}
                  disabled={!possibleList?.length}
                >
                  Next : Manufacturing Process
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      <CreateStrategyModal
        isOpen={showCreateStrategyModal}
        setIsOpen={setShowCreateStrategyModal}
        data={selectedItem}
        onAdd={addCustomStrategy}
      />
    </div>
  );
};

export default MachineStrategy;
