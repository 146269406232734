import Modal from "react-modal";

export interface IInvalidOperationsPopUp {
  strategyInd?: any;
  showPopUp?: any;
  closePopUp?: any;
  setInValid?: any;
  strategy?: any;
}
export interface IConfirmPopUp {
  showConfirmPopUp: boolean;
  closeConfirmPopUp: any;
  confirmFunction: any;
  confirmMessage?: string;
}
export interface IInValidToolPopUp {
  showPopUp?: any;
  closePopUp?: any;
  tool?: any;
  confirmInValidData?: any;
}
export const customStylesInvalidPopUp: Modal.Styles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    padding: 0,
    marginRight: "-50%",
    borderRadius: "16px",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 999,
  },
};

export const customStylesConfirmPopUp: Modal.Styles = {
  ...customStylesInvalidPopUp,
  content: { ...customStylesInvalidPopUp.content, width: "348px" },
};
export const customStylesInvalidToolPopUp: Modal.Styles = {
  ...customStylesInvalidPopUp,
  content: { ...customStylesInvalidPopUp.content, width: "600px" },
};
