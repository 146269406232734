// simple Hole Icons
import simpleHoleDiameter from "assets/images/icons/featureInputs/simpleHole/holeDiameter.svg";
import simpleHoleDepth from "assets/images/icons/featureInputs/simpleHole/depth.svg";
import simpleHoleDeburring from "assets/images/icons/featureInputs/simpleHole/deburring.svg";
import simpleHoleBlindBottomType from "assets/images/icons/featureInputs/simpleHole/bottomType/blind.svg";
import simpleHoleThroughBottomType from "assets/images/icons/featureInputs/simpleHole/bottomType/through.svg";
import simpleHoleTaperedSurfaceType from "assets/images/icons/featureInputs/simpleHole/surfaceType/tapered.svg";
import simpleHoleFlatSurfaceType from "assets/images/icons/featureInputs/simpleHole/surfaceType/flat.svg";
import simpleHoleFlatBottom from "assets/images/icons/featureInputs/simpleHole/bottom/flat.svg";
import simpleHoleVShapeBottom from "assets/images/icons/featureInputs/simpleHole/bottom/vShape.svg";
import simpleHoleBottomAngle from "assets/images/icons/featureInputs/simpleHole/bottomAngle.svg";
import simpleHoleBottomRadius from "assets/images/icons/featureInputs/simpleHole/bottomRadius.svg";
import simpleHoleSequantial from "assets/images/icons/featureInputs/simpleHole/sequential.svg";
import simpleHoleHorizontalClearance from "assets/images/icons/featureInputs/simpleHole/horizontalClearance.svg";
import simpleHoleVerticalClearance from "assets/images/icons/featureInputs/simpleHole/verticalClearance.svg";

// Thread Hole Icons
import ThreadHoleThreadDepth from "assets/images/icons/featureInputs/threadHole/threadDepth.svg";
import ThreadHoleThreadPitch from "assets/images/icons/featureInputs/threadHole/threadPitch.svg";
import ThreadHoleThreadAngle from "assets/images/icons/featureInputs/threadHole/threadAngle.svg";
import ThreadHoleMinorDiameter from "assets/images/icons/featureInputs/threadHole/minorDiameter.svg";
import ThreadHoleThreadSize from "assets/images/icons/featureInputs/threadHole/threadSize.svg";

// Top Face Icons
import topFaceLength from "assets/images/icons/featureInputs/face/topFace/length.svg";
import topFaceWidth from "assets/images/icons/featureInputs/face/topFace/width.svg";
import topFaceStockToRemove from "assets/images/icons/featureInputs/face/topFace/stockToRemove.svg";
import topFaceCompleteFinishingSurface from "assets/images/icons/featureInputs/face/topFace/completeFinishingSurface.svg";
import topFaceWallTopFinishingSurface from "assets/images/icons/featureInputs/face/topFace/wallTopFinishingSurface.svg";
import topFaceHorizontalClearance from "assets/images/icons/featureInputs/face/topFace/horizontalClearance.svg";
import topFaceVerticalClearance from "assets/images/icons/featureInputs/face/topFace/verticalClearance.svg";

// Bottom Face Icons
import bottomFaceLength from "assets/images/icons/featureInputs/face/bottomFace/length.svg";
import bottomFaceHeight from "assets/images/icons/featureInputs/face/bottomFace/wallHeight.svg";
import bottomFaceWidth from "assets/images/icons/featureInputs/face/bottomFace/width.svg";
import bottomFaceWallAngle from "assets/images/icons/featureInputs/face/bottomFace/wallAngle.svg";
import bottomFaceFlatWallType from "assets/images/icons/featureInputs/face/bottomFace/wallType/flat.svg";
import bottomFaceCurvedWallType from "assets/images/icons/featureInputs/face/bottomFace/wallType/curved.svg";
import bottomFaceTaperedWallType from "assets/images/icons/featureInputs/face/bottomFace/wallType/tapered.svg";
import bottomFaceFloorRadius from "assets/images/icons/featureInputs/face/bottomFace/floorRadius.svg";
import bottomFaceHorizontalClearance from "assets/images/icons/featureInputs/face/bottomFace/horizontalClearance.svg";
import bottomFaceVerticalClearance from "assets/images/icons/featureInputs/face/bottomFace/verticalClearance.svg";

// Side Face Icons
import sideFaceSolidBottomType from "assets/images/icons/featureInputs/face/sideFace/bottomType/solid.svg";
import sideFaceThroughBottomType from "assets/images/icons/featureInputs/face/sideFace/bottomType/through.svg";
import sideFaceFlatSurfaceType from "assets/images/icons/featureInputs/face/sideFace/surfaceType/flat.svg";
import sideFaceCurvedSurfaceType from "assets/images/icons/featureInputs/face/sideFace/surfaceType/curved.svg";
import sideFaceTaperedSurfaceType from "assets/images/icons/featureInputs/face/sideFace/surfaceType/tapered.svg";
import sideFaceBottomRadius from "assets/images/icons/featureInputs/face/sideFace/bottomRadius.svg";
import sideFaceBottomWidth from "assets/images/icons/featureInputs/face/sideFace/bottomWidth.svg";
import sideFaceDepth from "assets/images/icons/featureInputs/face/sideFace/depth.svg";
import sideFaceHorizontalClearance from "assets/images/icons/featureInputs/face/sideFace/horizontalClearance.svg";
import sideFaceVerticalClearance from "assets/images/icons/featureInputs/face/sideFace/verticalClearance.svg";
import sideFaceLength from "assets/images/icons/featureInputs/face/sideFace/length.svg";
import sideFaceStockToRemove from "assets/images/icons/featureInputs/face/sideFace/stockToRemove.svg";
import sideFaceTaperAngle from "assets/images/icons/featureInputs/face/sideFace/taperAngle.svg";
import sideFaceTaperLength from "assets/images/icons/featureInputs/face/sideFace/taperLength.svg";

// Chamfer Icons
import chamferLength from "assets/images/icons/featureInputs/edge/chamfer/length.svg";
import chamferAngle from "assets/images/icons/featureInputs/edge/chamfer/angle.svg";
import chamferBlindHoleType from "assets/images/icons/featureInputs/edge/chamfer/blindHoleType.svg";
import chamferEdgeChamferType from "assets/images/icons/featureInputs/edge/chamfer/edgeChamferType.svg";
import chamferEdgeLength from "assets/images/icons/featureInputs/edge/chamfer/edgeLength.svg";
import chamferHoleChamferType from "assets/images/icons/featureInputs/edge/chamfer/holeChamferType.svg";
import chamferHoleDepth from "assets/images/icons/featureInputs/edge/chamfer/holeDepth.svg";
import chamferHoleDiameter from "assets/images/icons/featureInputs/edge/chamfer/holeDiameter.svg";
import chamferHorizontalClearance from "assets/images/icons/featureInputs/edge/chamfer/horizontalClearance.svg";
import chamferVerticalClearance from "assets/images/icons/featureInputs/edge/chamfer/verticalClearance.svg";
import chamferThroughHoleType from "assets/images/icons/featureInputs/edge/chamfer/throughHoleType.svg";

// Groove Icons
import grooveOuterDiameter from "assets/images/icons/featureInputs/groove/outerDiameter.svg";
import grooveWidth from "assets/images/icons/featureInputs/groove/width.svg";
import grooveDepth from "assets/images/icons/featureInputs/groove/depth.svg";
import grooveBottomRadius from "assets/images/icons/featureInputs/groove/bottomRadius.svg";
import grooveHorizontalClearance from "assets/images/icons/featureInputs/groove/horizontalClearance.svg";
import grooveVerticalClearance from "assets/images/icons/featureInputs/groove/verticalClearance.svg";

// Slot Icons
import slotLength from "assets/images/icons/featureInputs/pocket/slot/length.svg";
import slotWidth from "assets/images/icons/featureInputs/pocket/slot/width.svg";
import slotDepth from "assets/images/icons/featureInputs/pocket/slot/depth.svg";
import slotCornerRadius from "assets/images/icons/featureInputs/pocket/slot/cornerRadius.svg";
import slotSolidBottomType from "assets/images/icons/featureInputs/pocket/slot/bottomType/solid.svg";
import slotThroughBottomType from "assets/images/icons/featureInputs/pocket/slot/bottomType/through.svg";
import slotBottomRadius from "assets/images/icons/featureInputs/pocket/slot/bottomRadius.svg";
import slotSimpleSlotType from "assets/images/icons/featureInputs/pocket/slot/slotType/simple.svg";
import slotDovetailSlotType from "assets/images/icons/featureInputs/pocket/slot/slotType/dovetail.svg";
import slotTSlotSlotType from "assets/images/icons/featureInputs/pocket/slot/slotType/tSlot.svg";
import slotOpenType from "assets/images/icons/featureInputs/pocket/slot/type/open.svg";
import slotClosedType from "assets/images/icons/featureInputs/pocket/slot/type/closed.svg";
import slotOneOpenSides from "assets/images/icons/featureInputs/pocket/slot/openSides/one.svg";
import slotBothOpenSides from "assets/images/icons/featureInputs/pocket/slot/openSides/both.svg";
import slotSectionWidth from "assets/images/icons/featureInputs/pocket/slot/sectionWidth.svg";
import slotSectionDepth from "assets/images/icons/featureInputs/pocket/slot/sectionDepth.svg";
import slotHorizontalLocation from "assets/images/icons/featureInputs/pocket/slot/location/horizontal.svg";
import slotVerticalLocation from "assets/images/icons/featureInputs/pocket/slot/location/vertical.svg";
import slotHorizontalClearance from "assets/images/icons/featureInputs/pocket/slot/horizontalClearance.svg";
import slotVerticalClearance from "assets/images/icons/featureInputs/pocket/slot/verticalClearance.svg";

// Pocket Icons
import pocketLength from "assets/images/icons/featureInputs/pocket/2d_pocket/length.svg";
import pocketWidth from "assets/images/icons/featureInputs/pocket/2d_pocket/width.svg";
import pocketDepth from "assets/images/icons/featureInputs/pocket/2d_pocket/depth.svg";
import pocketOpenPocketType from "assets/images/icons/featureInputs/pocket/2d_pocket/pocketType/open.svg";
import pocketClosedPocketType from "assets/images/icons/featureInputs/pocket/2d_pocket/pocketType/closed.svg";
import pocketMinDistance from "assets/images/icons/featureInputs/pocket/2d_pocket/minDistance.svg";
import pocketCurvedWallType from "assets/images/icons/featureInputs/pocket/2d_pocket/wallType/curved.svg";
import pocketFlatWallType from "assets/images/icons/featureInputs/pocket/2d_pocket/wallType/flat.svg";
import pocketTaperedWallType from "assets/images/icons/featureInputs/pocket/2d_pocket/wallType/tapered.svg";
import pocketNumberOfWalls from "assets/images/icons/featureInputs/pocket/2d_pocket/numberOfWalls.svg";
import pocketTaperAngle from "assets/images/icons/featureInputs/pocket/2d_pocket/taperAngle.svg";
import pocketSolidFloorType from "assets/images/icons/featureInputs/pocket/2d_pocket/floorType/solid.svg";
import pocketThroughFloorType from "assets/images/icons/featureInputs/pocket/2d_pocket/floorType/through.svg";
import pocketFloorRadius from "assets/images/icons/featureInputs/pocket/2d_pocket/floorRadius.svg";
import pocketMinDistanceBetweenIslands from "assets/images/icons/featureInputs/pocket/2d_pocket/minDistanceBetweenIslands.svg";
import pocketIslandLength from "assets/images/icons/featureInputs/pocket/2d_pocket/islandData/length.svg";
import pocketIslandWidth from "assets/images/icons/featureInputs/pocket/2d_pocket/islandData/width.svg";
import pocketIslandHeight from "assets/images/icons/featureInputs/pocket/2d_pocket/islandData/height.svg";
import pocketIslandFloorRadius from "assets/images/icons/featureInputs/pocket/2d_pocket/islandData/floorRadius.svg";
import pocketMinDistanceX from "assets/images/icons/featureInputs/pocket/2d_pocket/islandData/minDistanceX.svg";
import pocketMinDistanceY from "assets/images/icons/featureInputs/pocket/2d_pocket/islandData/minDistanceY.svg";
import pocketHorizontalClearance from "assets/images/icons/featureInputs/pocket/2d_pocket/horizontalClearance.svg";
import pocketVerticalClearance from "assets/images/icons/featureInputs/pocket/2d_pocket/verticalClearance.svg";

//Filet Icons
import filletSolidBottomType from "assets/images/icons/featureInputs/edge/fillet/bottomType/solid.svg";
import filletThroughBottomType from "assets/images/icons/featureInputs/edge/fillet/bottomType/through.svg";
import filletEdgeLocation from "assets/images/icons/featureInputs/edge/fillet/location/edge.svg";
import filletHoleTopLocation from "assets/images/icons/featureInputs/edge/fillet/location/holeTop.svg";
import filletHoleHorizontalLocation from "assets/images/icons/featureInputs/edge/fillet/orientation/horizontal.svg";
import filletHoleVerticalLocation from "assets/images/icons/featureInputs/edge/fillet/orientation/vertical.svg";
import filletConcaveShape from "assets/images/icons/featureInputs/edge/fillet/shape/concave.svg";
import filletConvexShape from "assets/images/icons/featureInputs/edge/fillet/shape/convex.svg";
import filletHoleDiameter from "assets/images/icons/featureInputs/edge/fillet/holeDiameter.svg";
import filletLength from "assets/images/icons/featureInputs/edge/fillet/length.svg";
import filletRadius from "assets/images/icons/featureInputs/edge/fillet/radius.svg";
import filletHorizontalClearance from "assets/images/icons/featureInputs/edge/fillet/horizontalClearance.svg";
import filletVerticalClearance from "assets/images/icons/featureInputs/edge/fillet/verticalClearance.svg";

export const INPUT_DESCRIPTION: any = {
  // Hole
  simple_hole: {
    diameter: [
      {
        heading: "",
        description: "Diameter of the hole.",
        image: simpleHoleDiameter,
      },
    ],
    depth: [
      {
        heading: "",
        description: "Depth of the hole.",
        image: simpleHoleDepth,
      },
    ],
    bottomType: [
      {
        description: "Specifies the type of the hole's bottom.",
      },
      {
        heading: "Blind",
        description: "Hole that stops at a specified depth.",
        image: simpleHoleBlindBottomType,
      },
      {
        heading: "Through",
        description: "A hole that passes completely through the material.",
        image: simpleHoleThroughBottomType,
      },
    ],
    bottom: [
      {
        description: "Specifies the shape of the hole's bottom.",
      },
      {
        heading: "Flat",
        description: "Hole with a flat, level bottom.",
        image: simpleHoleFlatBottom,
      },
      {
        heading: "V Shape",
        description: "Hole with a tapered, conical bottom.",
        image: simpleHoleVShapeBottom,
      },
    ],
    bottomRadius: [
      {
        heading: "",
        description: "Radius of curved bottom.",
        image: simpleHoleBottomRadius,
      },
    ],
    bottomAngle: [
      {
        heading: "",
        description: "Angle at the bottom of the hole if Tapered.",
        image: simpleHoleBottomAngle,
      },
    ],
    sequential: [
      {
        heading: "",
        description:
          "Hole passing through two surfaces with a gap in between the two surfaces.",
        image: simpleHoleSequantial,
      },
    ],
    surfaceType: [
      {
        description: "Characteristic of the hole’s surface.",
      },
      {
        heading: "Flat",
        description: "Surface on which the hole is created is flat and level.",
        image: simpleHoleFlatSurfaceType,
      },
      {
        heading: "Tapered",
        description: "Surface on which the hole is created is angled.",
        image: simpleHoleTaperedSurfaceType,
      },
    ],
    deburring: [
      {
        heading: "",
        description:
          "Indicates whether the edges of the hole need to be smoothed/unsharpen on the top.",
        image: simpleHoleDeburring,
      },
    ],
    quantity: [
      {
        heading: "",
        description: "Number of identical required holes.",
        image: "",
      },
    ],
    holeType: [
      {
        description: "Classification of holes based on the material type.",
      },
      {
        heading: "Solid",
        description: "Hole on solid material.",
        image: "",
      },
      {
        heading: "Hollow",
        description: "Hole on forged material, machined partially already.",
        image: "",
      },
    ],
    stockToRemove: [
      {
        heading: "",
        description: "Amount of material needs to machined from a workpiece.",
        image: "",
      },
    ],
    finishType: [
      {
        heading: "",
        description: "Surface finish required.",
        image: "",
      },
    ],
    upperTolerance: [
      {
        heading: "",
        description: "Maximum allowable size of a hole for machining.",
        image: "",
      },
    ],
    lowerTolerance: [
      {
        heading: "",
        description: "Minimum allowable size of a hole for machining.",
        image: "",
      },
    ],
    horizontalClearance: [
      {
        heading: "",
        description:
          "Maximum horizontal space around the feature for tool movement.",
        image: simpleHoleHorizontalClearance,
      },
    ],
    verticalClearance: [
      {
        heading: "",
        description:
          "Minimum vertical space required above the feature for tool movement.",
        image: simpleHoleVerticalClearance,
      },
    ],
  },
  thread_hole: {
    threadSize: [
      {
        heading: "",
        description: "Largest diameter of the thread.",
        image: ThreadHoleThreadSize,
      },
    ],
    threadDepth: [
      {
        heading: "",
        description: "Depth of the threaded section of the hole.",
        image: ThreadHoleThreadDepth,
      },
    ],
    minorDiameter: [
      {
        heading: "",
        description: "Smallest diameter of the thread.",
        image: ThreadHoleMinorDiameter,
      },
    ],
    threadPitch: [
      {
        heading: "",
        description: "Distance between threads.",
        image: ThreadHoleThreadPitch,
      },
    ],
    threadAngle: [
      {
        heading: "",
        description: "Angle formed by the thread flanks.",
        image: ThreadHoleThreadAngle,
      },
    ],
    holeDepth: [
      {
        heading: "",
        description: "Total depth of the hole.",
        image: simpleHoleDepth,
      },
    ],
    bottomType: [
      {
        description: "Specifies the type of the hole's bottom.",
      },
      {
        heading: "Blind",
        description: "Hole that stops at a specified depth.",
        image: simpleHoleBlindBottomType,
      },
      {
        heading: "Through",
        description: "A hole that passes completely through the material.",
        image: simpleHoleThroughBottomType,
      },
    ],
    bottom: [
      {
        description: "Specifies the shape of the hole's bottom.",
      },
      {
        heading: "V Shape",
        description: "Hole with a tapered, conical bottom.",
        image: simpleHoleVShapeBottom,
      },
      {
        heading: "Flat",
        description: "Hole with a flat, level bottom.",
        image: simpleHoleFlatBottom,
      },
    ],
    bottomRadius: [
      {
        heading: "",
        description: "Radius of curved bottom.",
        image: simpleHoleBottomRadius,
      },
    ],
    bottomAngle: [
      {
        heading: "",
        description: "Angle at the bottom of the hole if Tapered.",
        image: simpleHoleBottomAngle,
      },
    ],
    deburring: [
      {
        heading: "",
        description:
          "Indicates whether the edges of the hole needs to be smoothed/unsharpen on the top.",
        image: simpleHoleDeburring,
      },
    ],
    quantity: [
      {
        heading: "",
        description: "Number of identical required holes.",
        image: "",
      },
    ],
    finishType: [
      {
        heading: "",
        description: "Surface finish required.",
        image: "",
      },
    ],
    upperTolerance: [
      {
        heading: "",
        description: "Maximum allowable size of a hole for machining.",
        image: "",
      },
    ],
    lowerTolerance: [
      {
        heading: "",
        description: "Minimum allowable size of a hole for machining.",
        image: "",
      },
    ],
    holeType: [
      {
        description: "Classification of holes based on the material type.",
      },
      {
        heading: "Solid",
        description: "Hole on solid material.",
        image: "",
      },
      {
        heading: "Hollow",
        description: "Hole on forged material, machined partially already.",
        image: "",
      },
    ],
    stockToRemove: [
      {
        heading: "",
        description: "Amount of material needs to machined from a workpiece",
        image: "",
      },
    ],
    surfaceType: [
      {
        description: "Characteristic of the hole’s surface.",
      },
      {
        heading: "Flat",
        description: "Surface on which the hole is created is flat and level.",
        image: simpleHoleFlatSurfaceType,
      },
      {
        heading: "Tapered",
        description: "Surface on which the hole is created is angled.",
        image: simpleHoleTaperedSurfaceType,
      },
    ],
    sequential: [
      {
        heading: "",
        description:
          "Hole passing through two surfaces with a gap in between the two surfaces.",
        image: "",
      },
    ],
    horizontalClearance: [
      {
        heading: "",
        description:
          "Maximum horizontal space around the feature for tool movement.",
        image: simpleHoleHorizontalClearance,
      },
    ],
    verticalClearance: [
      {
        heading: "",
        description:
          "Minimum vertical space required above the feature for tool movement.",
        image: simpleHoleVerticalClearance,
      },
    ],
  },

  // Face
  top_face: {
    length: [
      {
        heading: "",
        description: "Longest dimension of the face.",
        image: topFaceLength,
      },
    ],
    width: [
      {
        heading: "",
        description: "Shortest dimension of the face.",
        image: topFaceWidth,
      },
    ],
    stockToRemove: [
      {
        heading: "",
        description: "Amount of material to be removed from the face.",
        image: topFaceStockToRemove,
      },
    ],
    finishingSurface: [
      {
        description: "Finishing area on the top face.",
      },
      {
        heading: "Complete",
        description: "Finishing applied to the entire top surface.",
        image: topFaceCompleteFinishingSurface,
      },
      {
        heading: "Wall Top",
        description: "Finishing applied only to the wall tops.",
        image: topFaceWallTopFinishingSurface,
      },
    ],
    quantity: [
      {
        heading: "",
        description: "Number of identical faces.",
        image: "",
      },
    ],
    horizontalClearance: [
      {
        heading: "",
        description:
          "Maximum space available around the face to allow for tool movement.",
        image: topFaceHorizontalClearance,
      },
    ],
    verticalClearance: [
      {
        heading: "",
        description:
          "Minimum space required above the face to allow for tool reachability.",
        image: topFaceVerticalClearance,
      },
    ],
  },
  bottom_face: {
    length: [
      {
        heading: "",
        description: "Longest dimension of the face.",
        image: bottomFaceLength,
      },
    ],
    width: [
      {
        heading: "",
        description: "Shortest dimension of the face.",
        image: bottomFaceWidth,
      },
    ],
    wallHeight: [
      {
        heading: "",
        description: "Height of the adjacent wall(s).",
        image: bottomFaceHeight,
      },
    ],
    wallType: [
      {
        description: "Classification of the wall.",
      },
      {
        heading: "Flat",
        description: "Walls with 90 degree angle.",
        image: bottomFaceFlatWallType,
      },
      {
        heading: "Tapered",
        description: "Angled walls.",
        image: bottomFaceTaperedWallType,
      },
      {
        heading: "Curved",
        description: "Walls with varied curved angle.",
        image: bottomFaceCurvedWallType,
      },
    ],
    wallAngle: [
      {
        heading: "",
        description: "Angle between the adjacent walls and the face.",
        image: bottomFaceWallAngle,
      },
    ],
    floorRadius: [
      {
        heading: "",
        description: "Radius between the floor and wall.",
        image: bottomFaceFloorRadius,
      },
    ],
    quantity: [
      {
        heading: "",
        description: "Number of identical faces.",
        image: "",
      },
    ],
    horizontalClearance: [
      {
        heading: "",
        description:
          "Maximum space available around the face or adjacent walls to allow for tool movement.",
        image: bottomFaceHorizontalClearance,
      },
    ],
    verticalClearance: [
      {
        heading: "",
        description:
          "Minimum space required above the adjacent wall top to allow for tool reachability.",
        image: bottomFaceVerticalClearance,
      },
    ],
  },
  side_face: {
    length: [
      {
        heading: "",
        description:
          "Length of the side face measured from one end to the other along its longest edge.",
        image: sideFaceLength,
      },
    ],
    depth: [
      {
        heading: "",
        description:
          "Height or vertical depth from top of the side face to the bottom.",
        image: sideFaceDepth,
      },
    ],
    stockToRemove: [
      {
        heading: "",
        description: "Amount of material to be removed from the face.",
        image: sideFaceStockToRemove,
      },
    ],
    bottomType: [
      {
        description: "Classification of bottom.",
      },
      {
        heading: "Solid",
        description: "A completely enclosed bottom with no openings.",
        image: sideFaceSolidBottomType,
      },
      {
        heading: "Through",
        description:
          "The bottom is open, creating a side face that extends completely through the material.",
        image: sideFaceThroughBottomType,
      },
    ],
    bottomRadius: [
      {
        heading: "",
        description: "Radius between the bottom and face.",
        image: sideFaceBottomRadius,
      },
    ],
    bottomWidth: [
      {
        heading: "",
        description: "Width of the bottom.",
        image: sideFaceBottomWidth,
      },
    ],
    quantity: [
      {
        heading: "",
        description: "Number of identical faces.",
        image: "",
      },
    ],
    surfaceType: [
      {
        description: "Surface classification.",
      },
      {
        heading: "Flat",
        description:
          "Face with a uniform, straight surface perpendicular to the bottom.",
        image: sideFaceFlatSurfaceType,
      },
      {
        heading: "Curved",
        description:
          "Face with a rounded or curved profile along their height.",
        image: sideFaceCurvedSurfaceType,
      },
      {
        heading: "Tapered",
        description:
          "Face that incline inward or outward from the base at a specific angle.",
        image: sideFaceTaperedSurfaceType,
      },
    ],
    taperAngle: [
      {
        heading: "",
        description: "Face inclination angle with the bottom.",
        image: sideFaceTaperAngle,
      },
    ],
    taperLength: [
      {
        heading: "",
        description: "Height or vertical depth of the side face.",
        image: sideFaceTaperLength,
      },
    ],
    horizontalClearance: [
      {
        heading: "",
        description:
          "Maximum space available around the side face or adjacent bottom to allow for tool movement.",
        image: sideFaceHorizontalClearance,
      },
    ],
    verticalClearance: [
      {
        heading: "",
        description:
          "Minimum vertical space required above the feature for tool movement.",
        image: sideFaceVerticalClearance,
      },
    ],
  },

  //Edge
  fillet: {
    length: [
      {
        heading: "",
        description: "Length of the Fillet along its curved edge.",
        image: filletLength,
      },
    ],
    radius: [
      {
        heading: "",
        description: "Radius of the curved edge.",
        image: filletRadius,
      },
    ],
    holeDiameter: [
      {
        heading: "",
        description: "Diameter of the hole on which Fillet is present.",
        image: filletHoleDiameter,
      },
    ],
    location: [
      {
        description: "Position of the fillet.",
      },
      {
        heading: "Hole top",
        description: "Edge of the hole tip.",
        image: filletHoleTopLocation,
      },
      {
        heading: "Edge",
        description: "Fillet on any other edge.",
        image: filletEdgeLocation,
      },
    ],
    bottomType: [
      {
        description: "Surface adjacent to the fillet.",
      },
      {
        heading: "Through",
        description: "No bottom surface adjacent to the fillet edge.",
        image: filletThroughBottomType,
      },
      {
        heading: "Solid",
        description: "Bottom surface available adjacent to the fillet edge.",
        image: filletSolidBottomType,
      },
    ],
    orientation: [
      {
        description: "Alignment of the fillet.",
      },
      {
        heading: "Horizontal",
        description: "Fillet parallel to X-axis.",
        image: filletHoleHorizontalLocation,
      },
      {
        heading: "Vertical",
        description: "Fillet parallel to Y-axis.",
        image: filletHoleVerticalLocation,
      },
    ],
    shape: [
      {
        description: "Curve type of the fillet.",
      },
      {
        heading: "Concave",
        description: "Inward curve between two surfaces.",
        image: filletConcaveShape,
      },
      {
        heading: "Convex",
        description: "Outward curve between two surfaces.",
        image: filletConvexShape,
      },
    ],
    quantity: [
      {
        heading: "",
        description: "Number of identical fillets.",
        image: "",
      },
    ],
    horizontalClearance: [
      {
        heading: "",
        description:
          "Maximum space available around the fillet to allow for tool movement.",
        image: filletHorizontalClearance,
      },
    ],
    verticalClearance: [
      {
        heading: "",
        description:
          "Minimum vertical space required above the feature for tool movement.",
        image: filletVerticalClearance,
      },
    ],
  },
  chamfer: {
    length: [
      {
        heading: "",
        description: "Total length of the chamfer.",
        image: chamferLength,
      },
    ],
    angle: [
      {
        heading: "",
        description:
          "Angle between the chamfered edge and the adjacent surface.",
        image: chamferAngle,
      },
    ],
    type: [
      {
        description: "Defines the type of chamfer availability.",
      },
      {
        heading: "Edge Chamfer",
        description: "Available on edges for all features except Holes.",
        image: chamferEdgeChamferType,
      },
      {
        heading: "Hole Chamfer",
        description: "Available on Holes.",
        image: chamferHoleChamferType,
      },
    ],
    quantity: [
      {
        heading: "",
        description: "Number of identical chamfers.",
        image: "",
      },
    ],
    edgeLength: [
      {
        heading: "",
        description: "Length of the edge to be chamfered.",
        image: chamferEdgeLength,
      },
    ],
    holeType: [
      {
        description: "Classification of the hole.",
      },
      {
        heading: "Blind",
        description: "Hole that stops at a specified depth.",
        image: chamferBlindHoleType,
      },
      {
        heading: "Through",
        description: "Hole that passes completely through the material.",
        image: chamferThroughHoleType,
      },
    ],
    holeDepth: [
      {
        heading: "",
        description: "Depth of hole where chamfer is located.",
        image: chamferHoleDepth,
      },
    ],
    holeDiameter: [
      {
        heading: "",
        description: "Diameter of hole where chamfer is located.",
        image: chamferHoleDiameter,
      },
    ],
    horizontalClearance: [
      {
        heading: "",
        description:
          "Maximum horizontal space around the feature for tool movement.",
        image: chamferHorizontalClearance,
      },
    ],
    verticalClearance: [
      {
        heading: "",
        description:
          "Minimum space required above the chamfer top to allow for tool reachability.",
        image: chamferVerticalClearance,
      },
    ],
  },

  // Groove
  groove: {
    width: [
      {
        heading: "",
        description:
          "Width of the groove (Distance between two opposite walls).",
        image: grooveWidth,
      },
    ],
    depth: [
      {
        heading: "",
        description: "Depth of the groove (From Groove top to bottom).",
        image: grooveDepth,
      },
    ],
    outerDiameter: [
      {
        heading: "",
        description: "Diameter of the groove measured from the outermost edge.",
        image: grooveOuterDiameter,
      },
    ],
    bottomRadius: [
      {
        heading: "",
        description: "Radius between the bottom and walls.",
        image: grooveBottomRadius,
      },
    ],
    quantity: [
      {
        heading: "",
        description: "Number of identical grooves.",
        image: "",
      },
    ],
    horizontalClearance: [
      {
        heading: "",
        description:
          "Maximum space available above the top of the groove to accommodate tool movement; if located inside a hole, its diameter can be used here.",
        image: grooveHorizontalClearance,
      },
    ],
    verticalClearance: [
      {
        heading: "",
        description:
          "Minimum space required above the Groove (Perpendicular to walls) to allow for tool reachability.",
        image: grooveVerticalClearance,
      },
    ],
  },

  // Pocket
  pocket: {
    length: [
      {
        heading: "",
        description: "Longest dimension of the pocket.",
        image: pocketLength,
      },
    ],
    width: [
      {
        heading: "",
        description: "Shortest dimension of the pocket.",
        image: pocketWidth,
      },
    ],
    depth: [
      {
        heading: "",
        description: "Height of Pocket walls.",
        image: pocketDepth,
      },
    ],
    quantity: [
      {
        heading: "",
        description: "Number of identical Pockets.",
        image: "",
      },
    ],
    pocketType: [
      {
        description: "Classification of the pocket.",
      },
      {
        heading: "Open",
        description: "A pocket with at least one side open.",
        image: pocketOpenPocketType,
      },
      {
        heading: "Closed",
        description:
          "A fully enclosed pocket where all sides are bounded by walls.",
        image: pocketClosedPocketType,
      },
    ],
    minDistance: [
      {
        heading: "",
        description:
          "Minimum internal dimension, if not available Minimum of Length or Width can be considered.",
        image: pocketMinDistance,
      },
    ],
    wallType: [
      {
        description: "Pocket walls classification",
      },
      {
        heading: "Flat",
        description:
          "Walls with a uniform, straight surface perpendicular to the pocket floor.",
        image: pocketFlatWallType,
      },
      {
        heading: "Curved",
        description:
          "Walls with a rounded or curved profile along their height.",
        image: pocketCurvedWallType,
      },
      {
        heading: "Tapered",
        description:
          "Walls that incline inward or outward from the base at a specific angle.",
        image: pocketTaperedWallType,
      },
    ],
    numberOfWalls: [
      {
        heading: "",
        description: "Number of Pocket walls (2 or 3).",
        image: pocketNumberOfWalls,
      },
    ],
    taperAngle: [
      {
        heading: "",
        description: "Angle of wall inclination.",
        image: pocketTaperAngle,
      },
    ],
    cornerRadius: [
      {
        heading: "",
        description: "Radius at the intersection of two walls.",
        // image: pocketCornerRadius,
      },
    ],
    floorType: [
      {
        description: "Pocket floor classification.",
      },
      {
        heading: "Solid",
        description: "A completely enclosed floor with no openings.",
        image: pocketSolidFloorType,
      },
      {
        heading: "Through",
        description:
          "The floor is open, creating a pocket that extends completely through the material.",
        image: pocketThroughFloorType,
      },
    ],
    floorRadius: [
      {
        heading: "",
        description: "Radius between the floor and walls.",
        image: pocketFloorRadius,
      },
    ],
    noOfIslands: [
      {
        heading: "",
        description: "Number of islands/boss inside the pocket.",
        image: "",
      },
    ],
    minDistanceBetweenIslands: [
      {
        heading: "",
        description: "Smallest gap between adjacent islands.",
        image: pocketMinDistanceBetweenIslands,
      },
    ],
    islandLength: [
      {
        heading: "",
        description: "Longest dimension of an island.",
        image: pocketIslandLength,
      },
    ],
    islandWidth: [
      {
        heading: "",
        description: "Shortest dimension of an island.",
        image: pocketIslandWidth,
      },
    ],
    islandHeight: [
      {
        heading: "",
        description: "Height of the island.",
        image: pocketIslandHeight,
      },
    ],
    minXDistanceTofaceEdges: [
      {
        heading: "",
        description:
          "Smallest horizontal gap from island walls in the X-direction to the Pocket walls.",
        image: pocketMinDistanceX,
      },
    ],
    minYDistanceTofaceEdges: [
      {
        heading: "",
        description:
          "Smallest horizontal gap from island walls in the Y-direction to the Pocket walls.",
        image: pocketMinDistanceY,
      },
    ],
    floorRadiusWithIsland: [
      {
        heading: "",
        description:
          "Radius at the intersection of the Pocket floor and the island.",
        image: pocketIslandFloorRadius,
      },
    ],
    horizontalClearance: [
      {
        heading: "",
        description:
          "Maximum space available around the Pocket walls to allow for tool movement.",
        image: pocketHorizontalClearance,
      },
    ],
    verticalClearance: [
      {
        heading: "",
        description:
          "Minimum space required above the Pocket top to allow for tool reachability.",
        image: pocketVerticalClearance,
      },
    ],
  },
  slot: {
    length: [
      {
        heading: "",
        description: "Length of the Slot.",
        image: slotLength,
      },
    ],
    width: [
      {
        heading: "",
        description: "Width of the Slot.",
        image: slotWidth,
      },
    ],
    depth: [
      {
        heading: "",
        description: "Depth of the Slot.",
        image: slotDepth,
      },
    ],
    quantity: [
      {
        heading: "",
        description: "Number of identical slots.",
        image: "",
      },
    ],
    cornerRadius: [
      {
        heading: "",
        description: "Radius at the intersection of two walls.",
        image: slotCornerRadius,
      },
    ],
    bottomType: [
      {
        description: "Classification of slot bottom.",
      },
      {
        heading: "Solid",
        description: "A completely enclosed bottom with no openings.",
        image: slotSolidBottomType,
      },
      {
        heading: "Through",
        description:
          "The bottom is open, creating a slot that extends completely through the material.",
        image: slotThroughBottomType,
      },
    ],
    bottomRadius: [
      {
        heading: "",
        description: "Radius between the bottom and walls.",
        image: slotBottomRadius,
      },
    ],
    slotType: [
      {
        description: "Classification of slot.",
      },
      {
        heading: "Simple",
        description:
          "A rectangular slot with straight walls and a flat or rounded bottom.",
        image: slotSimpleSlotType,
      },
      {
        heading: "T-Slot",
        description: `A slot with an undercut section forming a "T" shape.`,
        image: slotTSlotSlotType,
      },
      {
        heading: "Dovetail",
        description: "A slot with an undercut that forms a trapezoidal shape.",
        image: slotDovetailSlotType,
      },
    ],
    type: [
      {
        description: "Slot opening.",
      },
      {
        heading: "Open",
        description: "A slot with at least one side open.",
        image: slotOpenType,
      },
      {
        heading: "Closed",
        description: "A slot fully enclosed by material.",
        image: slotClosedType,
      },
    ],
    openSides: [
      {
        description: "Number of open sides.",
      },
      {
        heading: "One",
        description:
          "The slot has one side open, allowing access from one edge of the workpiece.",
        image: slotOneOpenSides,
      },
      {
        heading: "Both",
        description:
          "The slot has two opposite sides open, making it accessible from both edges of the workpiece.",
        image: slotBothOpenSides,
      },
    ],
    sectionDepth: [
      {
        heading: "",
        description:
          "Depth of the T-slot or dovetail section below the simple slot.",
        image: slotSectionDepth,
      },
    ],
    sectionWidth: [
      {
        heading: "",
        description: "Width of the T-slot or dovetail section.",
        image: slotSectionWidth,
      },
    ],
    location: [
      {
        description: "Slot orientation.",
      },
      {
        heading: "Horizontal",
        description:
          "The slot is oriented along the horizontal plane of the workpiece.",
        image: slotHorizontalLocation,
      },
      {
        heading: "Vertical",
        description:
          "The slot is oriented along the vertical plane of the workpiece.",
        image: slotVerticalLocation,
      },
    ],
    horizontalClearance: [
      {
        heading: "",
        description:
          "Maximum space available around the Slot walls to allow for tool movement.",
        image: slotHorizontalClearance,
      },
    ],
    verticalClearance: [
      {
        heading: "",
        description:
          "Minimum space required above the Slot top to allow for tool reachability",
        image: slotVerticalClearance,
      },
    ],
  },
};
