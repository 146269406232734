import { createHashRouter } from "react-router-dom";
import SplashPage from "pages/splash";
import ProjectList from "pages/projectList";
import Project from "pages/project";
import {
  Login,
  Register,
  ForgotPassword,
  VerifyPassword,
  Register2,
  RegisterSuccess,
  SetPassword,
  VerifyCode,
} from "pages/auth";
import { RequiredAuth, UnRequiredAuth } from "./RequiredAuth";
import Setting from "pages/setting";
import { NotFoundPage } from "pages/error";

const RootRoutes = createHashRouter([
  {
    path: "/",
    element: <SplashPage />,
  },
  {
    path: "/",
    element: <RequiredAuth />,
    children: [
      {
        path: "projects",
        element: <ProjectList />,
      },
      {
        path: "project",
        element: <Project />,
      },
      {
        path: "setting",
        element: <Setting />,
      },
    ],
  },
  {
    path: "/",
    element: <UnRequiredAuth />,
    children: [
      {
        path: "/login",
        element: <Login />,
      },
      // {
      //   path: "/forgot-password",
      //   element: <ForgotPassword />,
      // },
      // {
      //   path: "/verify-password",
      //   element: <VerifyPassword />,
      // },
      // {
      //   path: "/signup",
      //   element: <Register />,
      // },
      // {
      //   path: "/signup-2",
      //   element: <Register2 />,
      // },
      // {
      //   path: "/set-password",
      //   element: <SetPassword />,
      // },
      // {
      //   path: "/signup-success",
      //   element: <RegisterSuccess />,
      // },
      // {
      //   path: "/verify-code",
      //   element: <VerifyCode />,
      // },
    ],
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
]);

export default RootRoutes;
