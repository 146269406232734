import clsx from "clsx";
import { useState, useEffect } from "react";
import "./style.css";
import uniqid from "uniqid";

interface ICheckbox {
  checked: boolean;
  onChange: any;
  label?: string;
  type?: 'black' | 'yellow';
  className?: string;
  disabled?: boolean;
}

const Checkbox: React.FC<ICheckbox> = ({ disabled = false, checked = false, onChange, label, type = 'black', className }) => {
  const [isCheck, setIsCheck] = useState(checked);

  const handleChange = (e: any) => {
    // setIsCheck(e.target.checked);
    onChange(e.target.checked);
  }

  useEffect(() => {
    setIsCheck(checked);
  }, [checked]);

  const name = `check-${uniqid()}`;

  return (
    <div className="flex flex-row items-center">
      <div className="round">
        <input type="checkbox" disabled={disabled} id={name} checked={isCheck} onChange={handleChange}/>
        <label htmlFor={name} className={clsx('h-4 w-4',{
          'border-2 border-black': type === 'black',
          'border-2 border-gray-400': disabled,
          'border-2 border-surface-default': type === 'yellow'
        }, className)}>
          <span className={clsx('absolute left-[20px] text-xs font-medium', {
            'text-surface-default': isCheck,
            'text-black': !isCheck
            })}>{label}</span>
        </label>
      </div>
    </div>
  );
};

export default Checkbox;
