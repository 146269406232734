import { ReactNode } from "react";
import { Tooltip } from "react-tooltip";

export interface ICustomTooltipProps {
  children: ReactNode;
  anchorSelect: string;
  place: any;
  positionStrategy?: any;
  style?: any;
}

const CustomTooltip: React.FC<ICustomTooltipProps> = ({
  children,
  anchorSelect,
  place,
  positionStrategy = "fixed",
  style,
}) => {
  return (
    <Tooltip
      anchorSelect={anchorSelect}
      style={{
        fontSize: "14px",
        borderRadius: "4px",
        padding: "4px 10px",
        ...(style && { ...style }),
      }}
      place={place}
      positionStrategy={positionStrategy}
    >
      {children}
    </Tooltip>
  );
};

export default CustomTooltip;
