import { Auth } from "aws-amplify";
import Button from "components/Button";
import Input from "components/Input";
import Modal from "components/Modal";
import { passwordValidation, getConfirmPasswordValidation } from "constant";
import { useFormik } from "formik";
import { useEffect } from "react";
import { FiX } from "react-icons/fi";
import { toast } from "react-toastify";
import * as Yup from "yup";

interface IChangePasswordModal {
  isOpen: boolean;
  setIsOpen?: any;
}

const validationSchema = Yup.object().shape({
  oldPassword: passwordValidation,
  newPassword: passwordValidation,
  confirmPassword: getConfirmPasswordValidation("newPassword"),
});

const ChangePasswordModal: React.FC<IChangePasswordModal> = ({
  isOpen,
  setIsOpen,
}) => {
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    values,
    errors,
    touched,
    isValid,
    dirty,
    setValues,
  } = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema,
    onSubmit: async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const data = await Auth.changePassword(
          user,
          values?.oldPassword,
          values?.newPassword
        );
        if (data) setIsOpen(false);
      } catch (err: any) {
        if (err.code === "NotAuthorizedException") {
          toast("Old password is Invalid");
        } else if (err.message) toast(err?.message);
      }
    },
  });

  useEffect(() => {
    if (!isOpen) return;
    setValues({
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} size="default">
      <Modal.Header>
        <div className="flex flex-row items-center justify-between pb-4">
          <h3 className="text-lg font-medium leading-6 text-black-222">
            Change Password
          </h3>
          <FiX
            className="w-6 h-6 cursor-pointer text-black-222"
            onClick={() => setIsOpen(false)}
          />
        </div>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <div className="mb-4">
            <Input
              handleFocus={handleBlur}
              className="w-full"
              placeholder="Old Password"
              type="password"
              name="oldPassword"
              value={values?.oldPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={touched.oldPassword && errors.oldPassword}
              helperText={touched.oldPassword && errors.oldPassword}
            />
          </div>
          <div className="mb-4">
            <Input
              handleFocus={handleBlur}
              className="w-full"
              placeholder="New Password"
              type="password"
              name="newPassword"
              value={values?.newPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={touched.newPassword && errors.newPassword}
              helperText={touched.newPassword && errors.newPassword}
            />
          </div>
          <div>
            <Input
              handleFocus={handleBlur}
              className="w-full"
              placeholder="Confirm Password"
              type="password"
              name="confirmPassword"
              value={values?.confirmPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={touched.confirmPassword && errors.confirmPassword}
              helperText={touched.confirmPassword && errors.confirmPassword}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex flex-row justify-center space-x-4 w-full">
            <Button
              size="md"
              className="!rounded-full py-[6px] w-[150px] bg-gray-f2f text-gray-344 font-bold"
              onClick={() => setIsOpen(false)}
            >
              Cancel
            </Button>
            <Button
              size="md"
              className="!rounded-full py-[6px] w-[150px] whitespace-nowrap font-bold"
              type="submit"
              disabled={!isValid || !dirty}
            >
              Confirm
            </Button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default ChangePasswordModal;
