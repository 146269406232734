import DefaultLayout from "Layouts/DefaultLayout";
import queryString from "query-string";
import { TAB_LIST } from "constant";
import { useLocation } from "react-router-dom";
import CreateProject from "./CreateProject";
import FeatureRecognition from "./FeatureRecognition";
import MachineStrategy from "./MachineStrategy";
import CuttingTool from "./CuttingTool";
import Summary from "./Summary";
import clsx from "clsx";
import FeatureSummary from "./FeatureSummary";
import DefaultRecommendations from "./DefaultRecommendations";
import MachiningStrategySummary from "./MachiningStrategySummary";
import ManufacturingProcess from "./ManufacturingProcess";
import ManufacturingProcessSummary from "./ManufacturingProcessSummary";

const Project = () => {
  const location = useLocation();
  const params = queryString.parse(location.search);
  const selectedTab = params?.tab || "project";
  const selectedTabIndex = TAB_LIST?.findIndex(
    (item: any) => item.type === selectedTab
  );
  let View = undefined;
  let noLayout = false;
  if (selectedTab === "project") {
    View = CreateProject;
  } else if (selectedTab === "feature") {
    View = FeatureRecognition;
  } else if (selectedTab === "default-recommendations") {
    View = DefaultRecommendations;
  } else if (selectedTab === "machine") {
    View = MachineStrategy;
  } else if (selectedTab === "tool") {
    View = CuttingTool;
  } else if (selectedTab === "summary") {
    View = Summary;
    noLayout = true;
  } else if (selectedTab === "feature-summary") {
    View = FeatureSummary;
    noLayout = true;
  } else if (selectedTab === "strategy-summary") {
    View = MachiningStrategySummary;
    noLayout = true;
  } else if (selectedTab === "manufacturing-process") {
    View = ManufacturingProcess;
    noLayout = true;
  } else if (selectedTab === "manufacturing-summary") {
    View = ManufacturingProcessSummary;
    noLayout = true;
  }

  return (
    <DefaultLayout noLayout={noLayout}>
      {/* Tab  */}
      {!noLayout && (
        <div className="flex flex-row p-[16px]">
          {TAB_LIST?.map((item, idx) => {
            const Icon = item?.icon;
            let iconColor: string, textClass: string, iconClass: string;
            switch (true) {
              case idx < selectedTabIndex:
                iconColor = "#1570EF";
                textClass = "text-gray-700";
                iconClass = "border-surface-default bg-white";
                break;
              case idx > selectedTabIndex:
                iconColor = "#98A2B3";
                textClass = "text-gray-400";
                iconClass = "bg-white border-gray-200";
                break;
              default:
                iconColor = "white";
                textClass = "text-surface-default";
                iconClass = "bg-surface-default border-surface-default";
                break;
            }
            return (
              <button
                className="w-full flex flex-col justify-center items-center gap-[10px] relative"
                key={idx}
                // onClick={() => navigate(`/project?tab=${item?.type}`)}
              >
                {idx !== 0 && (
                  <div
                    className={clsx(
                      "z-[-1] fillprogress absolute top-[18px] h-[4px] w-full right-[50%]",
                      {
                        "bg-surface-default": idx <= selectedTabIndex,
                        "bg-gray-400": idx > selectedTabIndex,
                      }
                    )}
                  ></div>
                )}
                <div
                  className={clsx(
                    "w-[40px] h-[40px] rounded-[30px] border-[2px] flex items-center justify-center relative",
                    iconClass
                  )}
                >
                  <Icon color={iconColor} />
                </div>
                <span className={clsx("font-semibold text-sm ml-1", textClass)}>
                  {item?.label}
                </span>
              </button>
            );
          })}
        </div>
      )}

      <div
        className={clsx("relative ", {
          "mt-0 flex flex-col h-full overflow-hidden": !noLayout,
          "h-full": noLayout,
        })}
      >
        {!!selectedTab && View && <View />}
      </div>
    </DefaultLayout>
  );
};

export default Project;
