import AddSetupModal from "components/AddSetupModal";
import Button from "components/Button";
import EditSetupModal from "components/EditSetupsModal/EditSetupsModal";
import ProjectItem from "components/ProjectItem";
import { SET_ASSIGNED_SETUPS, SET_SETUP_MAPPING } from "constant";
import { GlobalContext } from "context";
import { useContext, useEffect, useState } from "react";
import { FiPlus } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
interface ProjectItemListProps {
  selectedSetupId: any;
  setSelectedSetupId: any;
  setupChangeHandler: any;
}

const ProjectItemList: React.FC<ProjectItemListProps> = ({
  selectedSetupId,
  setSelectedSetupId,
  setupChangeHandler,
}) => {
  const { state, dispatch } = useContext(GlobalContext);
  const {
    projectInfo,
    assignedMachiningSetups,
    mappingForMachiningSetups,
    showTooltip,
    hideTooltip,
  } = state;
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const navigate = useNavigate();
  const [list, setList] = useState<any>({});

  useEffect(() => {
    if (!projectInfo || !Object.keys(projectInfo)?.length) {
      navigate("/projects");
      return;
    }
    setList(mappingForMachiningSetups);
  }, [assignedMachiningSetups, mappingForMachiningSetups]);

  const handleSetup = (setupId: any = "") => {
    if (selectedSetupId) setupChangeHandler(selectedSetupId);
    setSelectedSetupId(setupId);
  };

  const onAddSetup = (name: string, programName: string) => {
    const setupId = "setupId" + uuid();
    dispatch({
      type: SET_ASSIGNED_SETUPS,
      payload: {
        ...assignedMachiningSetups,
        [setupId]: [],
      },
    });
    dispatch({
      type: SET_SETUP_MAPPING,
      payload: {
        ...list,
        [setupId]: {
          index: Object.keys(list)?.length,
          name,
          programName,
        },
      },
    });
    //   if (setup?.machiningSetupNumber >= latestNum)
    //     latestNum = setup?.machiningSetupNumber;
    // });
    // const newSetup = {
    //   machiningSetupNumber: latestNum + 1,
    //   toolLibraryId: "",
    //   toolLibraryName: "",
    //   features: []
    // }
    // dispatch({
    //   type: SET_MACHINE_SETUP,
    //   payload: [
    //     ...machiningSetups,
    //     newSetup,
    //   ],
    // });
    // dispatch({
    //   type: SET_SETUP_NUMBER,
    //   payload: latestNum + 1,
    // });
    // let data = !!localStorage.getItem("data")?.length
    //   ? JSON.parse(localStorage.getItem("data") || "")
    //   : null;
    // const holeList = DEFAULT_HOLE_LIST?.map((obj) => ({
    //   ...obj,
    //   id: `${obj?.type}_${uuid()}`,
    // }));
    // dispatch({
    //   type: SET_HOLE_LIST,
    //   payload: [...holeList],
    // });
    // const threadHoleList = DEFAULT_THREAD_HOLE_LIST?.map((obj) => ({
    //   ...obj,
    //   id: `thread_hole_${uuid()}`,
    // }));
    // dispatch({
    //   type: SET_THREAD_HOLE_LIST,
    //   payload: [...threadHoleList],
    // });
    // const faceList = DEFAULT_FACE_LIST?.map((obj) => ({
    //   ...obj,
    //   id: `${obj?.type?.toLowerCase()}_${uuid()}`,
    // }));
    // dispatch({
    //   type: SET_FACE_LIST,
    //   payload: [...faceList],
    // });
    // const pocketList = DEFAULT_POCKET_LIST?.map((obj) => ({
    //   ...obj,
    //   id: `${obj?.type}_${uuid()}`,
    // }));
    // dispatch({
    //   type: SET_POCKET_LIST,
    //   payload: [...pocketList],
    // });
    // const contourList = DEFAULT_CONTOUR_LIST?.map((obj) => ({
    //   ...obj,
    //   id: `${obj?.type}_${uuid()}`,
    // }));
    // dispatch({
    //   type: SET_CONTOUR_LIST,
    //   payload: [...contourList],
    // });
    // const list_data = data[projectInfo?.ncProjectId]?.data || [];
    // list_data.push({
    //   holeList: holeList,
    //   threadHoleList: threadHoleList,
    //   faceList: faceList,
    //   pocketList: pocketList,
    //   contourList: contourList,
    // });
    // data[projectInfo?.ncProjectId]["data"] = list_data;
    // localStorage.setItem("data", JSON.stringify(data));
    // setList(list_data);
    // dispatch({
    //   type: SET_LOAD_DATA,
    //   payload: list_data[list_data?.length],
    // });
    // dispatch({
    //   type: SET_SETUP_NUMBER,
    //   payload: list_data?.length-1,
    // });
  };

  return (
    <div className="w-[48px] border border-gray-200 rounded-[6px] h-full bg-gray-50 flex flex-col overflow-hidden items-center gap-[4px] py-[4px]">
      <label className="text-[10px] text-gray-600 font-semibold">Setups</label>
      <div className="w-full flex flex-col gap-[4px] h-full overflow-auto items-center">
        <ProjectItem
          value={""}
          onSelect={handleSetup}
          selected={selectedSetupId === ""}
        >
          All
        </ProjectItem>
        {list &&
          Object.keys(list)?.map((setupId: string, index: number) => {
            return (
              <ProjectItem
                value={setupId}
                onSelect={() => handleSetup(setupId)}
                selected={setupId === selectedSetupId}
                id={setupId}
                onMouseOver={() => {
                  if (list[setupId]?.name) {
                    showTooltip(setupId);
                  }
                }}
                onMouseLeave={() => hideTooltip()}
                name={list[setupId]?.name}
              >{`S${list[setupId]?.index + 1}`}</ProjectItem>
            );
          })}
      </div>
      <div className="w-full flex flex-col gap-[8px] items-center">
        <Button
          variant="black"
          className="w-[34px] h-[34px] !rounded-full !p-0"
          onClick={() => {
            setIsEditModalOpen(true);
          }}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.46875 13.5625L15.3438 1.6875L18.3125 4.65625L6.4375 16.5312M3.46875 13.5625L2.28125 17.7188L6.4375 16.5312M3.46875 13.5625L6.4375 16.5312M12.9688 4.0625L15.9375 7.03125"
              stroke="white"
              stroke-width="0.875"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </Button>
        <Button
          variant="ghost"
          disabled={Object.keys(list)?.length === 10}
          className="w-[34px] h-[34px] bg-blue-600 !rounded-full !p-0"
          onClick={() => {
            setIsAddModalOpen(true);
          }}
        >
          <FiPlus className="text-white w-6 h-6 font-semibold" />
        </Button>
      </div>
      <AddSetupModal
        isOpen={isAddModalOpen}
        setIsOpen={setIsAddModalOpen}
        onAddSetup={onAddSetup}
      />
      <EditSetupModal isOpen={isEditModalOpen} setIsOpen={setIsEditModalOpen} />
    </div>
  );
};

export default ProjectItemList;
