import { useContext, useEffect, useMemo } from "react";
import Input from "components/Input";
import RadioComponent from "components/RadioComponent";
import {
  FILLET_BOTTOM_TYPE_OPTIONS,
  FILLET_LOCATION_OPTIONS,
  FILLET_ORIENTATION_OPTIONS,
  FILLET_SHAPE_OPTIONS,
  FILLET_VALIDATION,
  POSITIVE_VALIDATION,
  QUANTITY_VALIDATION,
  SET_MACHINE_SETUP,
  decimalRegExp,
  onlyNumberRegexExp,
} from "constant";
import { useFormik } from "formik";
import * as Yup from "yup";
import { GlobalContext } from "context/GlobalContext";
import clsx from "clsx";
import TooltipContent from "../../FeatureToolTipContent";
import FeatureInfoIcon from "assets/images/icons/feature-info.svg";
import TooltipV2 from "components/ToolTipV2";

interface IFilletForm {
  feature_info?: any;
  feature_id?: string;
}

const init_fillet_edge = {
  location: "",
  orientation: "",
  shape: "",
  bottom_type: "",
  length: "",
  hole_diameter: "",
  radius: "",
  quantity: "",
  horizontal_clearance: "",
  vertical_clearance: "",
};

const FilletForm: React.FC<IFilletForm> = ({ feature_info, feature_id }) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { machiningSetups, projectInfo, setupNumber } = state;

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        length: Yup.number().required("This field is required"),
        // .min(
        //   FILLET_VALIDATION.length.min[projectInfo?.unit || "mm"],
        //   `Value must be at least ${
        //     FILLET_VALIDATION.length.min[projectInfo?.unit || "mm"]
        //   }`
        // ),
        hole_diameter: Yup.number().required("This field is required"),
        // .min(
        //   FILLET_VALIDATION.hole_diameter.min[projectInfo?.unit || "mm"],
        //   `Value must be at least ${
        //     FILLET_VALIDATION.hole_diameter.min[projectInfo?.unit || "mm"]
        //   }`
        // ),
        radius: Yup.number().required("This field is required"),
        // .min(
        //   FILLET_VALIDATION.radius.min[projectInfo?.unit || "mm"],
        //   `Value must be at least ${
        //     FILLET_VALIDATION.radius.min[projectInfo?.unit || "mm"]
        //   }`
        // ),
        quantity: QUANTITY_VALIDATION,
        horizontal_clearance: POSITIVE_VALIDATION,
        vertical_clearance: POSITIVE_VALIDATION,
      }),
    []
  );

  useEffect(() => {
    if (!feature_info) return;
    let formValues: any = {};
    Object.keys(init_fillet_edge).forEach((key: string) => {
      formValues = {
        ...formValues,
        [key]: feature_info?.[key] ?? "",
      };
    });
    setValues(formValues);
  }, [feature_info]);

  const {
    handleChange,
    handleBlur,
    setFieldValue,
    setValues,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: init_fillet_edge,
    validationSchema,
    onSubmit: () => {},
  });

  // useEffect(() => {
  //   if (!contourList?.[index]) return;
  //   let formValues: any = {};
  //   Object.keys(init_fillet_contour).forEach((key: string) => {
  //     formValues = {
  //       ...formValues,
  //       [key]: contourList[index][key],
  //     };
  //   });
  //   setValues(formValues);
  // }, [contourList, index]);

  const handleChangeFunc = (key: string, value: any, flag: boolean = false) => {
    if (flag) setFieldValue(key, value);

    let updated_setups = [...machiningSetups];

    const setupIdx = updated_setups?.findIndex(
      (setup: any) => setup?.machiningSetupNumber === setupNumber
    );

    const feature_idx = updated_setups?.[setupIdx]?.features?.findIndex(
      (feature: any) => feature?.feature_id === feature_id
    );

    let updated_feature = {
      ...updated_setups?.[setupIdx]?.features?.[feature_idx]?.featureInformation
        ?.feature_data?.feature_info,
    };
    switch (key) {
      case "location":
        if (value === "hole top") {
          delete updated_feature.length;
          delete updated_feature.bottom_type;
          setFieldValue("length", "");
          setFieldValue("bottom_type", "");

          //set orientation to horizontal and shape to convex
          setFieldValue("orientation", "horizontal");
          updated_feature.orientation = "horizontal";
          setFieldValue("shape", "convex");
          updated_feature.shape = "convex";
        } else if (value === "edge") {
          delete updated_feature.hole_diameter;
          setFieldValue("hole_diameter", "");
        }
        updated_feature = {
          ...updated_feature,
          [key]: value,
        };
        break;
      case "orientation":
        if (value === "vertical") {
          delete updated_feature.bottom_type;
          setFieldValue("bottom_type", "");
        }
        updated_feature = {
          ...updated_feature,
          [key]: value,
        };
        break;
      case "shape":
        if (value === "concave") {
          delete updated_feature.bottom_type;
          setFieldValue("bottom_type", "");
        }
        updated_feature = {
          ...updated_feature,
          [key]: value,
        };
        break;
      case "bottom_type":
        updated_feature = {
          ...updated_feature,
          [key]: value,
        };
        break;
      default:
        if (value !== "") {
          updated_feature = {
            ...updated_feature,
            [key]: Number(value),
          };
        } else delete updated_feature[key];
    }

    updated_setups[setupIdx].features[
      feature_idx
    ].featureInformation.feature_data.feature_info = updated_feature;

    dispatch({
      type: SET_MACHINE_SETUP,
      payload: updated_setups,
    });
    // setFieldValue(key, value);
    // dispatch({
    //   type: SET_CONTOUR_DATA,
    //   payload: {
    //     index,
    //     key,
    //     value,
    //   },
    // });
    // dispatch({
    //   type: SET_CONTOUR_DATA,
    //   payload: {
    //     index,
    //     key: "parameter",
    //     value: null,
    //   },
    // });
    // dispatch({
    //   type: SET_CONTOUR_DATA,
    //   payload: {
    //     index,
    //     key: "selected_strategy",
    //     value: null,
    //   },
    // });
  };
  const showBottomType =
    values?.location === "edge" &&
    values?.orientation === "horizontal" &&
    values?.shape === "convex";
  return (
    <div className="relative">
      <div className="mt-4">
        <p className="font-semibold text-base text-gray-202">
          Feature Dimensions
        </p>
        <div className="flex flex-row mt-4">
          <div className="flex justify-between items-center w-6/12 py-[14px]">
            <div className="flex align-baseline">
              <label className="font-semibold text-gray-475 truncate mr-2">
                Location
              </label>
              <TooltipV2
                openOnHover={false}
                title={TooltipContent("fillet", "location")}
              >
                <div className="my-auto">
                  <img
                    className="inline-block"
                    src={FeatureInfoIcon}
                    alt=""
                    height={16}
                    width={16}
                  />
                </div>
              </TooltipV2>
            </div>
            <RadioComponent
              options={FILLET_LOCATION_OPTIONS}
              setValue={(val: any) => handleChangeFunc("location", val, true)}
              value={values?.location}
            />
          </div>
          <div className="w-6/12">
            <Input
              handleFocus={handleBlur}
              placeholder="Radius"
              className="ml-2"
              type="text"
              name="radius"
              unit={projectInfo?.unit || "mm"}
              value={values?.radius}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc("radius", values?.radius);
              }}
              infoTooltipTitle={TooltipContent("fillet", "radius")}
              invalid={touched.radius && errors.radius}
              helperText={touched.radius && errors.radius}
              regex={decimalRegExp}
            />
          </div>
        </div>
      </div>
      {values?.location === "edge" && (
        <div className="mt-4">
          <div className="w-6/12">
            <Input
              handleFocus={handleBlur}
              placeholder="Length"
              className="mr-2"
              type="text"
              unit={projectInfo?.unit || "mm"}
              name="length"
              value={values?.length}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc("length", values?.length);
              }}
              infoTooltipTitle={TooltipContent("fillet", "length")}
              invalid={touched.length && errors.length}
              helperText={touched.length && errors.length}
              regex={decimalRegExp}
            />
          </div>
        </div>
      )}
      {values?.location === "hole top" && (
        <div className="mt-4">
          <div className="w-6/12">
            <Input
              handleFocus={handleBlur}
              placeholder="Hole diameter"
              className="mr-2"
              type="text"
              unit={projectInfo?.unit || "mm"}
              name="hole_diameter"
              value={values?.hole_diameter}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc("hole_diameter", values?.hole_diameter);
              }}
              infoTooltipTitle={TooltipContent("fillet", "holeDiameter")}
              invalid={touched.hole_diameter && errors.hole_diameter}
              helperText={touched.hole_diameter && errors.hole_diameter}
              regex={decimalRegExp}
            />
          </div>
        </div>
      )}

      <div className="mt-4">
        <p className="font-semibold text-base text-gray-202">Other Inputs</p>
        <div className="flex flex-row mt-4">
          <div className="flex justify-between items-center w-6/12 py-[14px]">
            <div className="flex align-baseline">
              <label className="font-semibold text-gray-475 truncate mr-2">
                Orientation
              </label>
              <TooltipV2
                openOnHover={false}
                title={TooltipContent("fillet", "orientation")}
              >
                <div className="my-auto">
                  <img
                    className="inline-block"
                    src={FeatureInfoIcon}
                    alt=""
                    height={16}
                    width={16}
                  />
                </div>
              </TooltipV2>
            </div>
            <RadioComponent
              options={FILLET_ORIENTATION_OPTIONS}
              setValue={(val: any) =>
                handleChangeFunc("orientation", val, true)
              }
              value={values?.orientation}
              disabled={values?.location === "hole top"}
            />
          </div>
          <div className="flex justify-between items-center w-6/12 py-[14px]">
            <div className="flex align-baseline ml-2">
              <label className="font-semibold text-gray-475 truncate mr-2">
                Shape
              </label>
              <TooltipV2
                openOnHover={false}
                title={TooltipContent("fillet", "shape")}
              >
                <div className="my-auto">
                  <img
                    className="inline-block"
                    src={FeatureInfoIcon}
                    alt=""
                    height={16}
                    width={16}
                  />
                </div>
              </TooltipV2>
            </div>
            <RadioComponent
              options={FILLET_SHAPE_OPTIONS}
              setValue={(val: any) => handleChangeFunc("shape", val, true)}
              value={values?.shape}
              disabled={values?.location === "hole top"}
            />
          </div>
        </div>
        <div className="flex flex-row mt-4">
          {showBottomType && (
            <div className="flex justify-between items-center w-6/12 py-[14px]">
              <div className="flex align-baseline">
                <label className="font-semibold text-gray-475 truncate mr-2">
                  Bottom Type
                </label>
                <TooltipV2
                  openOnHover={false}
                  title={TooltipContent("fillet", "bottomType")}
                >
                  <div className="my-auto">
                    <img
                      className="inline-block"
                      src={FeatureInfoIcon}
                      alt=""
                      height={16}
                      width={16}
                    />
                  </div>
                </TooltipV2>
              </div>
              <RadioComponent
                options={FILLET_BOTTOM_TYPE_OPTIONS}
                setValue={(val: any) =>
                  handleChangeFunc("bottom_type", val, true)
                }
                value={values?.bottom_type}
              />
            </div>
          )}
          <div className="w-6/12">
            <Input
              handleFocus={handleBlur}
              placeholder="Quantity"
              className={clsx("mr-2", {
                "ml-2 mr-0": showBottomType,
              })}
              type="text"
              name="quantity"
              value={values?.quantity}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc("quantity", values?.quantity);
              }}
              infoTooltipTitle={TooltipContent("fillet", "quantity")}
              invalid={touched.quantity && errors.quantity}
              helperText={touched.quantity && errors.quantity}
              regex={onlyNumberRegexExp}
            />
          </div>
        </div>
      </div>

      <div className="mt-4">
        <p className="font-semibold text-base text-gray-202">
          Clearance Inputs
        </p>
        <div className="flex flex-row mt-4">
          <div className="flex w-6/12">
            <Input
              handleFocus={handleBlur}
              placeholder="Vertical Clearance"
              className="w-full mr-2"
              type="text"
              unit={projectInfo?.unit || "mm"}
              name="vertical_clearance"
              value={values?.vertical_clearance}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc(
                  "vertical_clearance",
                  values?.vertical_clearance
                );
              }}
              infoTooltipTitle={TooltipContent("fillet", "verticalClearance")}
              invalid={touched.vertical_clearance && errors.vertical_clearance}
              helperText={
                touched.vertical_clearance && errors.vertical_clearance
              }
              regex={decimalRegExp}
            />
          </div>
          <div className="flex w-6/12">
            <Input
              handleFocus={handleBlur}
              placeholder="Horizontal Clearance"
              className="w-full ml-2"
              type="text"
              unit={projectInfo?.unit || "mm"}
              name="horizontal_clearance"
              value={values?.horizontal_clearance}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc(
                  "horizontal_clearance",
                  values?.horizontal_clearance
                );
              }}
              infoTooltipTitle={TooltipContent("fillet", "horizontalClearance")}
              invalid={
                touched.horizontal_clearance && errors.horizontal_clearance
              }
              helperText={
                touched.horizontal_clearance && errors.horizontal_clearance
              }
              regex={decimalRegExp}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilletForm;
