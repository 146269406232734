const VerifyIcon = ({ verified = false }) => {
  const fillColor = verified ? "#00D549" : "#1570EF";
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.38006 14.72H7.67006L6.00006 13H3.53006L3.00006 12.5V10.08L1.31006 8.36004V7.65004L3.00006 5.93004V3.50004L3.53006 3.00004H6.00006L7.67006 1.29004H8.38006L10.1001 3.00004H12.5301L13.0301 3.49004V5.93004L14.7401 7.65004V8.36004L13.0001 10.08V12.5L12.5001 13H10.1001L8.38006 14.72ZM6.73006 10.48H7.44006L11.2101 6.71004L10.5001 6.00004L7.09006 9.42004L5.71006 8.04004L5.00006 8.75004L6.73006 10.48Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default VerifyIcon;
