const HoleIcon = ({ active = false }) => {
  const fillClass = active ? "#FFF2C4" : "#E2E2E2";
  const strokeClass = active ? "#1570EF" : "black";
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2.5 0.5H15V12L12.5 13.5L2 1.5L2.5 0.5Z" fill={fillClass} />
      <path
        d="M0.5 2.48062C0.5 2.32873 0.569045 2.18508 0.687652 2.09019L2.53843 0.609566C2.62709 0.53864 2.73725 0.5 2.85078 0.5H14.5L13.8 1.43333L14.2 1.73333L13.8 1.43333C13.6053 1.69298 13.5 2.00878 13.5 2.33333V14C13.5 14.2761 13.2761 14.5 13 14.5H1C0.723858 14.5 0.5 14.2761 0.5 14V2.48062Z"
        fill={fillClass}
        stroke={strokeClass}
      />
      <path
        d="M1 2L13.5001 1.99995M13.5001 13.4999L14.7072 12.2928C14.8947 12.1053 15.0001 11.851 15.0001 11.5857V0"
        stroke={strokeClass}
      />
      <circle cx="7" cy="8" r="3.5" fill="white" stroke={strokeClass} />
    </svg>
  );
};

export default HoleIcon;
