import clsx from "clsx";
import { ISLAND_LABELS } from "constant";

interface ITooltipIslandInfo {
  island: any;
  index: any;
  unit?: string;
  type?: string;
}

const TooltipIslandInfo: React.FC<ITooltipIslandInfo> = ({
  island,
  index,
  unit,
  type = "white",
}) => {
  return (
    <div>
      <div
        className={clsx("p-[8px] rounded-md text-start bg-blue-d1e", {
          "border border-gray-400": type === "white",
        })}
      >
        <h4
          className={clsx("font-semibold text-start", {
            "text-gray-700 text-[12px]": type === "white",
            "text-white text-[14px]": type === "black",
          })}
        >
          Island {index + 1}
        </h4>
        {Object.entries(ISLAND_LABELS).map(
          ([key, value]: [string, any], idx: number) =>
            island[key] !== undefined ? (
              <p
                key={idx}
                className={clsx("font-semibold", {
                  "text-[10px] text-gray-600": type === "white",
                  "text-[12px] text-white": type === "black",
                })}
              >
                {`${value}: ${island[key]}${unit}`}
              </p>
            ) : (
              <></>
            )
        )}
      </div>
    </div>
  );
};

export default TooltipIslandInfo;
