import clsx from "clsx";
import { renderToString } from "react-dom/server";

interface IProjectItem {
  value?: any;
  selected?: boolean;
  onSelect?: any;
  children: any;
  id?: string;
  onMouseOver?: any;
  onMouseLeave?: any;
  name?: string;
}

const ProjectItem: React.FC<IProjectItem> = ({
  value,
  selected,
  onSelect,
  children,
  id,
  onMouseOver,
  onMouseLeave,
  name,
}) => {
  const classes = clsx({
    "w-[40px] h-[40px] flex items-center justify-center rounded-[6px] cursor-pointer":
      true,
    "bg-blue-600 border-blue-600": selected,
    "border border-gray-400": !selected,
  });

  const textClass = clsx({
    "font-semibold text-[14px]": true,
    "text-gray-600": !selected,
    "text-white": selected,
  });
  return (
    <div
      className={classes}
      onClick={() => onSelect(value)}
      id={id}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      data-tooltip-html={renderToString(
        <p className="font-medium text-base leading-[16.94px]">{name}</p>
      )}
    >
      <span className={textClass}>{children}</span>
    </div>
  );
};

export default ProjectItem;
