export const numberRegExp = /(?=.*?[0-9])/;
export const upperRegExp = /(?=.*?[A-Z])/;
export const lowerRegExp = /(?=.*?[a-z])/;
export const specialRegExp =
  /(?=.*?[!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|\]+$])/;
export const decimalRegExp = /^([0-9]{1,5})(\.\d{0,4})?$/;
export const onlyNumberRegexExp = /^([0-9]{1,5})$/;
export const negativeDecimalRegExp = /^[-+]?([0-9]{0,5})(\.\d{0,4})?$/;
export const isSpecialCharacterRegExp =
  /[!@#$%^&*(),.?":{}|<>[\]\\'`~;\/+=_-]/g;
