import LogoImg from "assets/images/logo.svg";
import Button from "components/Button";
import Input from "components/Input";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import cn from "classnames";
import zxcvbn from "zxcvbn";
import { GlobalContext } from "context/GlobalContext";
import {
  SET_LOADING_BAR,
  SET_REGISTER_FORM,
  passwordValidation,
  getConfirmPasswordValidation,
} from "constant";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useApiCall } from "hooks";
import {
  postCheckCorporateAPI,
  postCorporateAPI,
  postSignatureAPI,
  postUserAPI,
} from "services";
import { v4 as uuid } from "uuid";

const validationSchema = Yup.object().shape({
  password: passwordValidation,
  confirmPassword: getConfirmPasswordValidation(),
});

const SetPassword = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const { registerForm } = state;
  const navigate = useNavigate();
  const [score, setScore] = useState(0);
  const [postCorporate]: any = useApiCall(postCorporateAPI);
  const [postSignature]: any = useApiCall(postSignatureAPI);
  const [postCheckCorporate]: any = useApiCall(postCheckCorporateAPI);
  const [postUser]: any = useApiCall(postUserAPI);

  useEffect(() => {
    if (!registerForm || !Object.keys(registerForm)?.length)
      navigate("/signup");
  }, [registerForm]);

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    values,
    errors,
    touched,
    isValid,
    dirty,
  } = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        dispatch({
          type: SET_LOADING_BAR,
          payload: true,
        });
        const primaryAccountId = uuid();
        // const organizationId = uuid();

        const checkData = await postCheckCorporate({
          adminEmail: registerForm?.adminEmail,
        });

        let payload: any = {
          username: registerForm?.adminEmail.toLowerCase(),
          password: values?.password,
          attributes: {
            "custom:userRole": checkData?.userRole,
            // "custom:primaryAccountId": primaryAccountId,
            "custom:organizationId": checkData?.organizationId,
          },
        };

        const authUser = await Auth.signUp(payload);
        const security = await postSignature(authUser?.userSub);
        // const industry = registerForm?.industry?.map((item: any) => item?.value) || [];

        // payload = {
        //   primaryAccountId: primaryAccountId,
        //   userRecordId: authUser?.userSub,
        //   organizationId: checkData?.organizationId,
        //   orgFullName: registerForm?.orgFullName,
        //   streetAddress: registerForm?.streetAddress,
        //   addressLine2: registerForm?.addressLine2,
        //   city: registerForm?.city,
        //   stateOf: registerForm?.stateOf,
        //   zipCode: registerForm?.zipCode,
        //   country: registerForm?.country,
        //   orgAdminContact: "empty_flag",
        //   adminPhone: registerForm?.adminPhone,
        //   adminEmail: registerForm?.adminEmail,
        //   industry
        // };
        // const orgResult = await postCorporate({
        //   body: payload,
        //   signature: security,
        // });

        payload = {
          primaryAccountId,
          userRecordId: authUser?.userSub,
          organizationId: checkData?.organizationId,
          orgFullName: registerForm?.orgFullName,
          firstName: registerForm?.firstName,
          lastName: registerForm?.lastName,
          jobTitle: "empty_flag",
          userRole: checkData?.userRole,
          email: registerForm?.adminEmail,
          cellPhone: registerForm?.adminPhone,
        };
        const userResult = await postUser({
          body: payload,
          signature: security,
        });
        dispatch({
          type: SET_REGISTER_FORM,
          payload: { ...registerForm, password: values?.password },
        });
        dispatch({
          type: SET_LOADING_BAR,
          payload: false,
        });
        if (userResult) navigate("/verify-code");
      } catch (err) {
        dispatch({
          type: SET_LOADING_BAR,
          payload: false,
        });
      }
    },
  });

  useEffect(() => {
    setScore(zxcvbn(values.password).score);
  }, [values.password]);

  return (
    <div className="flex items-center justify-center w-full h-full relative">
      <div className="fixed top-0 right-0 left-0 bottom-0 bg-auth-background w-full h-full bg-cover "></div>
      <div className="fixed top-0 right-0 left-0 bottom-0 splash-background"></div>
      <div className="w-6/12 h-full flex flex-col justify-between px-5 py-10 z-[1]">
        <img src={LogoImg} alt="logo" className="max-w-[180px] h-fit" />
        <div className="flex flex-col">
          <p className="text-surface-default font-semibold text-lg">
            Welcome to
          </p>
          <p className="text-black font-bold text-[40px] mb-6">
            Generative Programming
          </p>
          <p className="text-gray-494 font-medium">
            A tool that brings the power of artificial intelligence to CNC
            programmers by recommending machining strategies, optimal cutting
            tools & machining parameters, and more based on the specific
            features in your CAD files.
          </p>
        </div>
        <div className="flex flex-row justify-between items-center">
          <span className="text-gray-424 text-xs font-semibold">
            2024 © Lambda Function, Inc.
          </span>
          <span className="text-gray-424 text-xs font-semibold">v1.0.0</span>
        </div>
      </div>
      <div className="w-6/12 h-full flex justify-center relative pt-10 pb-16">
        <div className="w-full h-full overflow-auto px-5 flex justify-center items-center">
          <div className="flex flex-col max-w-[50vw] p-px my-auto h-fit w-8/12 min-w-[350px]">
            <div className="mb-[30px]">
              <p className="font-semibold text-[27px] text-black mb-1">
                Set new password
              </p>
              <div className="rounded bg-surface-default w-[48px] h-1"></div>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="mb-2">
                <Input
                  handleFocus={handleBlur}
                  label="Enter new password"
                  type="password"
                  name="password"
                  className="min-w-[350px] mb-2"
                  placeholderClassName="text-black-222"
                  subClassName="!shadow-form-input !bg-white !rounded-xl"
                  inputClassName="bg-transparent"
                  value={values?.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={touched.password && errors.password}
                  helperText={touched.password && errors.password}
                />
                <div className="flex flex-row items-center space-x-2">
                  <div
                    className={cn("rounded w-full bg-gray-d9d h-1", {
                      "!bg-red-fb2": score === 1,
                      "!bg-yellow-ffa": score === 2,
                      "!bg-green-1db": score === 3 || score === 4,
                    })}
                  ></div>
                  <div
                    className={cn("rounded w-full bg-gray-d9d h-1", {
                      "!bg-red-fb2": score === 1,
                      "!bg-yellow-ffa": score === 2,
                      "!bg-green-1db": score === 3 || score === 4,
                    })}
                  ></div>
                  <div
                    className={cn("rounded w-full bg-gray-d9d h-1", {
                      "!bg-yellow-ffa": score === 2,
                      "!bg-green-1db": score === 3 || score === 4,
                    })}
                  ></div>
                  <div
                    className={cn("rounded w-full bg-gray-d9d h-1", {
                      "!bg-green-1db": score === 4,
                    })}
                  ></div>
                </div>
              </div>
              <Input
                handleFocus={handleBlur}
                label="Repeat new password"
                type="password"
                name="confirmPassword"
                className="min-w-[350px] mb-4"
                placeholderClassName="text-black-222"
                subClassName="!shadow-form-input !bg-white !rounded-xl"
                inputClassName="bg-transparent"
                value={values?.confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={touched.confirmPassword && errors.confirmPassword}
                helperText={touched.confirmPassword && errors.confirmPassword}
              />
              <Button
                className="w-full rounded-xl text-lg py-4 font-semibold mb-[10px]"
                type="submit"
                disabled={!isValid || score <= 1}
              >
                Confirm
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetPassword;
