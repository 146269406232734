import clsx from 'clsx'
import { useState, useEffect } from 'react'
import { RadioGroup, Radio } from 'react-radio-input'
import uniqid from 'uniqid'
import './style.css'

export interface IRadioOption {
  label: string
  value: string | number | boolean
}

interface IRadioComponent {
  options: IRadioOption[];
  value?: string | number | boolean;
  setValue?: any;
  labelClassName?: string;
  disabled?: boolean;
}

const normalizeBoolean = (value: any) => {
  if (value === 'true') {
    return true
  }
  if (value === 'false') {
    return false
  }
  return value
}

const convertBoolean = (value: any) => {
  if (value === true) {
    return 'true'
  }
  if (value === false) {
    return 'false'
  }
  return value
}

const RadioComponent: React.FC<IRadioComponent> = ({
  value,
  setValue,
  options,
  labelClassName,
  disabled = false,
}) => {
  const [selectedOption, setSelectedOption] = useState(convertBoolean(value))

  const onChange = (e: any) => {
    setSelectedOption(e)
    const val = normalizeBoolean(e)
    setValue(val)
  }

  useEffect(() => {
    setSelectedOption(value)
  }, [value]);

  const name = uniqid()

  return (
    <div className="flex mr-1">
      <RadioGroup
        name={name}
        onChange={onChange}
        selectedValue={selectedOption}
      >
        {options?.map((option: IRadioOption, idx) => (
          <label
            htmlFor={`${name}-${option?.value}-id`}
            key={idx}
            className={clsx(
              `mr-2 flex items-center ${
                disabled && selectedOption !== normalizeBoolean(option?.value)
                  ? "disabled-radio"
                  : "label-radio"
              }`,
              labelClassName
            )}
          >
            <Radio
              id={`${name}-${option?.value}-id`}
              value={`${convertBoolean(option?.value)}`}
              // @ts-ignore
              checked={selectedOption === normalizeBoolean(option?.value)}
              disabled={disabled}
            />
            <span
              className={`font-medium text-sm ml-[10px] ${
                disabled && selectedOption !== normalizeBoolean(option?.value)
                  ? "text-gray-400"
                  : ""
              }`}
            >
              {option?.label}
            </span>
          </label>
        ))}
      </RadioGroup>
    </div>
  )
}

export default RadioComponent
