import YellowLogo from "assets/images/logo/yellow-logo.svg";
import Button from "components/Button";
import { useNavigate } from "react-router-dom";

const SplashPage = () => {
  const navigate = useNavigate();

  return (
    <div className="flex items-center justify-center w-full h-full relative">
      <div className="bg-splash-background bg-surface-default bg-blend-overlay w-full h-full bg-opacity-40 bg-cover bg-no-repeat opacity-40"></div>
      <div className="flex flex-col justify-center items-center max-w-[50vw] absolute inset-auto">
        <div className="w-[60px] h-[60px] bg-white rounded-full flex justify-center items-center">
          <img src={YellowLogo} alt="logo" className="w-[42px] h-[42px]" />
        </div>
        <p className="font-semibold text-2xl mt-[30px] text-center">
          Welcome to Generative Programming
        </p>
        <p className="font-medium text-black-222 text-center mt-[18px]">
          A tool that brings the power of artificial intelligence to CNC
          programmers by recommending machining strategies, optimal cutting
          tools & machining parameters and more based on the specific features
          in your CAD files.
        </p>
        <Button
          size="lg"
          className="mt-[30px] px-[120px]"
          onClick={() => navigate("/projects", { replace: true })}
        >
          Get started
        </Button>
      </div>
    </div>
  );
};

export default SplashPage;
