import { INPUT_DESCRIPTION } from "constant/featureInputsDescription";

const TooltipContent = (feature: string, input: string) => {
  return (
    <>
      {INPUT_DESCRIPTION[feature]?.[input]?.map((item: any, index: number) => {
        return (
          <div key={`${feature}-${input}-${index}`} className="my-[0]">
            <div className="flex gap-2 items-center">
              {item.image && (
                <div className="h-[45px] w-[45px] inline-flex items-center justify-center flex-[0_0_45px]">
                  <img
                    src={item.image}
                    alt="inputImage"
                    width="45px"
                    className="max-h-[100%]"
                  />{" "}
                </div>
              )}
              <div>
                <div className="font-[600] text-[12px] !text-wrap whitespace-normal">
                  {item.heading}
                </div>
                <div className="text-[10px] mb-[4px] !text-wrap whitespace-normal !min-w-[100px]">
                  {item.description}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default TooltipContent;
