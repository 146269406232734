import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "components/Button";
import { decimalRegExp, SET_MACHINE_SETUP } from "constant";
import { GlobalContext } from "context";
import { useFormik } from "formik";
import React, { useContext, useEffect, useMemo } from "react";
import {
  formInitValues,
  getValidationSchema,
  getFormFieldsTemplate,
  IEditCuttingParametersModal,
  modalStyle,
  IformValuesInterface,
} from "./utils";
import { useApiCall } from "hooks/useApiCall";
import { getParametersAPI } from "services";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";

const EditCuttingParametersModal: React.FC<IEditCuttingParametersModal> = ({
  isOpen,
  setIsOpen,
  data,
  toolId,
  selectedStep,
  boxData,
  setBoxData,
  index,
  isAddModal,
}) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { machiningSetups, projectInfo } = state;
  const operation = data?.machining_strategy?.operation || "";

  const formFieldTemplate = useMemo(
    () => getFormFieldsTemplate(operation, projectInfo?.unit ?? "mm"),
    [operation, projectInfo?.unit]
  );
  const [getParameters, loading] = useApiCall(getParametersAPI);

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    resetForm,
    setValues,
    isValid,
    dirty,
    setFieldTouched,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: { ...formInitValues },
    validationSchema: getValidationSchema(operation),
    onSubmit: (values: any) => {
      const formValues = {
        spindle_speed: +values.spindle_speed,
        feed_rate: +values.feed_rate,
        stepover_ae: +values.stepover_ae,
        stepdown_ap: +values.stepdown_ap,
      };
      let paramsFoundFlag = false;
      let parameters: any;
      const paramsRequestPayload = {
        ...data,
        custom_cutting_parameters: [{}],
      };
      formFieldTemplate.forEach((item: any) => {
        paramsRequestPayload.custom_cutting_parameters[0][item.request_key] = {
          value: formValues[item.name as keyof IformValuesInterface],
          units: item.units,
        };
      });
      getParameters([paramsRequestPayload])
        .then((res: any) => {
          if (!res) return;
          parameters =
            res?.cutting_tools_parameters?.[0]?.cutting_tools_parameters?.[0];
        })
        .finally(() => {
          let feature_information;
          let updated_setups = [...machiningSetups];
          const feature_idx = updated_setups?.[0]?.features?.findIndex(
            (feature: any) => feature?.feature_id === data?.feature_id
          );
          feature_information = {
            ...updated_setups?.[0]?.features?.[feature_idx]?.featureInformation,
          };
          const selected_strategy_idx =
            feature_information?.strategies?.[0]?.machining_strategy?.findIndex(
              (item: any) => item?.isSelected
            );
          const paramsArray =
            feature_information.strategies?.[0].machining_strategy[
              selected_strategy_idx
            ].passes[selectedStep?.index - 1].operations[
              selectedStep?.subIndex
            ]["parameters"];
          paramsArray?.forEach((param: any, idx: number) => {
            if (param["Tool ID"] === toolId) {
              paramsFoundFlag = true;
              if (parameters) {
                let original_optimal_point = param.original_optimal_point ?? {
                  "Material removal rate": param["Material removal rate"],
                  "Tool life": param["Tool life"],
                };
                delete parameters?.non_optimal_points;
                paramsArray[idx] = {
                  ...parameters,
                  paramsFlag: "user-edited",
                  originalParams: {
                    "Spindle speed": { ...param["Spindle speed"] },
                    "Feed rate": { ...param["Feed rate"] },
                    ...(param["Stepover [Ae]"] && {
                      "Stepover [Ae]": { ...param["Stepover [Ae]"] },
                    }),
                    ...(param["Stepdown [Ap]"] && {
                      "Stepdown [Ap]": { ...param["Stepdown [Ap]"] },
                    }),
                  },
                  original_optimal_point,
                };
                boxData[index] = {
                  ...boxData[index],
                  ...parameters,
                  original_optimal_point,
                  paramsFlag: "user-edited",
                };
              } else {
                param.paramsFlag = "user-edited";
                param.originalParams = {};
                formFieldTemplate.forEach((item: any) => {
                  param.originalParams[item.label] = { ...param[item.label] };
                  param[item.label].value =
                    formValues[item.name as keyof IformValuesInterface];
                  boxData[index][item.label].value =
                    formValues[item.name as keyof IformValuesInterface];
                });
                boxData[index].paramsFlag = "user-edited";
              }
            }
          });
          if (!paramsFoundFlag) {
            let dataObj: any = {};
            if (parameters) {
              dataObj = {
                ...parameters,
                paramsFlag: "user-added",
              };
            } else {
              dataObj = {
                "Tool ID": toolId,
                non_optimal_points: [[0, 0]],
                paramsFlag: "user-added",
                valid_data: {},
              };
              formFieldTemplate.forEach((item: any) => {
                dataObj[item.label] = {
                  value: formValues[item.name as keyof IformValuesInterface],
                  units: item.units,
                };
              });
            }
            paramsArray?.push(dataObj);
            boxData[index] = { ...boxData[index], ...dataObj };
            delete boxData[index]?.paramsNotPresent;
          }
          updated_setups[0].features[feature_idx].featureInformation =
            feature_information;
          dispatch({
            type: SET_MACHINE_SETUP,
            payload: updated_setups,
          });
          setBoxData([...boxData]);
          setIsOpen(false);
        });
    },
  });

  useEffect(() => {
    if (!isOpen) return;
    resetForm({
      values: {
        ...formInitValues,
        ...(boxData[index]?.["Spindle speed"] && {
          spindle_speed: boxData[index]?.["Spindle speed"]?.value ?? "",
        }),
        ...(boxData[index]?.["Feed rate"] && {
          feed_rate: boxData[index]?.["Feed rate"]?.value ?? "",
        }),
        ...(boxData[index]?.["Stepdown [Ap]"] && {
          stepdown_ap: boxData[index]?.["Stepdown [Ap]"]?.value ?? "",
        }),
        ...(boxData[index]?.["Stepover [Ae]"] && {
          stepover_ae: boxData[index]?.["Stepover [Ae]"]?.value ?? "",
        }),
      },
    });
  }, [isOpen, setValues, boxData, index, resetForm]);

  return (
    <div>
      <Modal open={isOpen} onClose={() => setIsOpen(false)}>
        {loading ? (
          <Box sx={modalStyle}>
            <Box
              sx={{
                display: "flex",
                overflow: "hidden",
                justifyContent: "center",
                marginY: "16px",
              }}
            >
              <CircularProgress size={80} />
            </Box>
          </Box>
        ) : (
          <Box sx={modalStyle}>
            <div className="max-w-[400px] rounded-[16px] bg-white">
              <div className="flex items-center justify-between p-[16px] border-b border-gray-300">
                <h2 className="text-[20px] text-gray-700 font-medium">
                  {isAddModal
                    ? "Add cutting parameters"
                    : "Edit cutting parameters"}
                </h2>
                <Button
                  className="!bg-transparent !p-0 !rounded-0 !border-0 !shadow-none"
                  onClick={() => setIsOpen(false)}
                >
                  <svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.63281 14.8692L10.002 10.5L14.3711 14.8692M14.3711 6.13086L10.0011 10.5L5.63281 6.13086"
                      stroke="#475467"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Button>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="flex flex-col gap-[16px] p-[16px]">
                  {formFieldTemplate.map((item: any) => (
                    <div key={item.key} className="flex flex-col gap-[4px]">
                      <label
                        htmlFor={item.key}
                        className="font-semibold text-gray-500 text-[12px]"
                      >
                        {item.label} <sup className="text-red-600">*</sup>
                      </label>
                      <div className="relative p-0 overflow-hidden rounded-[8px] input-field h-[36px] shadow-input-2 flex">
                        <input
                          id={item.key}
                          className=" w-full outline-none leading-[20px] px-[12px] py-[8px] font-normal text-[16px]"
                          type="input"
                          name={item.name}
                          value={
                            values?.[item.name as keyof typeof formInitValues]
                          }
                          onFocus={() => setFieldTouched(item.name, true)}
                          onChange={(e) => {
                            if (
                              e?.target?.value &&
                              !decimalRegExp.test(e.target.value)
                            )
                              return;
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                        />
                        <div className="text-[14px] text-gray-700 bg-gray-200 py-[8px] px-[10px] ">
                          {item.units}
                        </div>
                      </div>
                      {touched?.[item.name as keyof typeof formInitValues] &&
                        errors?.[item.name as keyof typeof formInitValues] && (
                          <div className="text-[12px] text-red-ff0">
                            This field is required
                          </div>
                        )}
                    </div>
                  ))}
                </div>
                <div className="flex items-center justify-center p-[16px] border-t border-gray-300">
                  <Button
                    className="min-w-[140px]"
                    type="submit"
                    disabled={!isValid || !dirty}
                  >
                    {isAddModal ? "Add" : "Update"}
                  </Button>
                </div>
              </form>
            </div>
          </Box>
        )}
      </Modal>
    </div>
  );
};

export default EditCuttingParametersModal;
