import { FC, useEffect, useState } from "react";
import { FiXCircle } from "react-icons/fi";
import clsx from "clsx";

interface ICheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
  label?: string;
  error?: boolean;
  className?: string;
  labelClassName?: string;
  defaultChecked?: boolean;
  onChange?: any;
}

const FormCheckbox: FC<ICheckboxProps> = ({
  label = "",
  error,
  className,
  defaultChecked = false,
  onChange,
  labelClassName = "",
  ...props
}) => {
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setIsChecked(defaultChecked);
  }, [defaultChecked]);

  const handleChange = (e: any) => {
    setIsChecked(e.target.checked);
    onChange(e.target.checked);
  };

  return (
    <div className={clsx("flex space-x-2 items-center", className)}>
      <div className="flex h-5 items-center">
        <input
          type="checkbox"
          className={clsx(
            "h-5 w-5 rounded text-white focus:ring-white cursor-pointer",
            {
              "border-surface-default": !!error,
            }
          )}
          checked={isChecked}
          onChange={handleChange}
          {...props}
        />
      </div>
      {label && (
        <label
          htmlFor={props.id}
          className={clsx(
            "cursor-pointer text-surface-default font-semibold text-sm flex items-center no-underline",
            labelClassName,
            {
              "text-surface-default": !!error,
            }
          )}
        >
          {!!labelClassName?.length && <FiXCircle className="mr-1 w-4 h-4"/>}
          {label}
        </label>
      )}
    </div>
  );
};

export default FormCheckbox;
