import clsx from "clsx";

interface TdProps {
  children: any;
  className?: string;
  divClassName?: string;
}
const Td: React.FC<TdProps> = ({ children, className, divClassName }) => {
  return (
    <td scope="col" className={clsx("border-hidden py-1 h-full", className)}>
      <div className={clsx("w-full text-sm text-black text-left px-4 bg-surface-secondary cell h-10 flex items-center", divClassName)}>
        {children}
      </div>
    </td>
  );
};

export default Td;
