import "./style.css";

interface InvalidSwitch {
  initialChecked?: boolean;
  handleChange?: any;
  disabled?: boolean;
}

const InvalidSwitch: React.FC<InvalidSwitch> = ({
  initialChecked,
  handleChange,
  disabled=false
}) => {
  return (
    <label className="invalidSwitch">
      <input
        type="checkbox"
        checked={initialChecked}
        onChange={(e) => handleChange(e)}
        disabled={disabled}
      />
      <span className="invalidSlider"></span>
    </label>
  );
};

export default InvalidSwitch;
