import Input from "components/Input";
import { SelectV2 } from "components/Select";
import CloseSvg from "assets/images/icons/charm_cross.svg";
import { useFormik } from "formik";
import { useEffect, useMemo, useReducer } from "react";
import * as Yup from "yup";
import { Tooltip } from "react-tooltip";
import { v4 as uuid } from "uuid";
import Button from "components/Button";
import {
  formStateReducer,
  formulateToolPathNameOptions,
  formulateToolPathStyleOptions,
  formulateToolPathTypeOptions,
  ICreateCustomStrategyForm,
  initFormValues,
} from "./utils";

const CreateCustomStrategyForm: React.FC<ICreateCustomStrategyForm> = ({
  templateData,
  editRowData,
  pass,
  showCancelButton,
  onCancel,
  onSave,
}) => {
  const UUID = uuid();
  const [formState, formStateDispatch] = useReducer(formStateReducer, {
    inputFieldDisplayStatus: {
      operation: false,
      location: false,
      toolType: false,
    },
  });

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      operation: Yup.string().required("This field is required"),
      location: Yup.string().required("This field is required"),
      toolType: Yup.string().required("This field is required"),
      toolPathType: Yup.string().required("This field is required"),
      ...(formState.toolPathNameOptions?.length && {
        toolPathName: Yup.string().required("This field is required"),
      }),
      ...(formState.toolPathStyleOptions?.length && {
        toolPathStyle: Yup.string().required("This field is required"),
      }),
    });
  }, [
    formState.toolPathNameOptions?.length,
    formState.toolPathStyleOptions?.length,
  ]);

  const {
    handleBlur,
    handleSubmit,
    setFieldValue,
    setFieldTouched, // TODO: We will use in future once flow is corrected.
    setValues,
    values,
    errors,
    touched,
    dirty,
    isValid,
  } = useFormik({
    initialValues: { ...initFormValues },
    validationSchema,
    onSubmit: (values: any) => {
      onSave(values);
    },
  });

  useEffect(() => {
    const operationOptions = Object.keys(
      templateData.recommended_cutting_passes[pass]
    );
    formStateDispatch({
      type: "SET-OPERATION-OPTIONS",
      payload: operationOptions.map((key) => ({ label: key, value: key })),
    });

    if (!editRowData) return;

    const preFilledData: any = { ...initFormValues };
    Object.keys(editRowData).forEach((key: string) => {
      preFilledData[key] = editRowData[key];
    });
    setValues(preFilledData);
    formStateDispatch({
      type: "RESTORE-FORM",
      payload: { preFilledData, templateData, pass, operationOptions },
    });
  }, [editRowData, pass, setValues, templateData]);

  const handleChangeFunc = (key: string, value: any) => {
    switch (key) {
      case "operation":
        if (value === "other") {
          value = "";
          formStateDispatch({
            type: "SET-INPUT-FIELD-DISPLAY-STATUS",
            payload: { key, value: true },
          });
          formStateDispatch({
            type: "SET-LOCATION-OPTIONS",
            payload: templateData.custom_cutting_passes[pass].location.map(
              (item: string) => ({ label: item, value: item })
            ),
          });
          formStateDispatch({
            type: "SET-TOOL-TYPE-OPTIONS",
            payload: templateData.custom_cutting_passes[pass].tool_types.map(
              (item: string) => ({
                label: item,
                value: item,
              })
            ),
          });
          formStateDispatch({
            type: "SET-TOOLPATH-TYPE-OPTIONS",
            payload: formulateToolPathTypeOptions(templateData, pass, ""),
          });
        } else {
          formStateDispatch({
            type: "SET-LOCATION-OPTIONS",
            payload: templateData.recommended_cutting_passes[pass][
              value
            ].location.map((item: string) => ({ label: item, value: item })),
          });
          formStateDispatch({
            type: "SET-TOOL-TYPE-OPTIONS",
            payload: templateData.recommended_cutting_passes[pass][
              value
            ].tool_types.map((item: string) => ({
              label: item,
              value: item,
            })),
          });
          formStateDispatch({
            type: "SET-TOOLPATH-TYPE-OPTIONS",
            payload: formulateToolPathTypeOptions(templateData, pass, value),
          });
        }
        formStateDispatch({
          type: "SET-TOOLPATH-NAME-OPTIONS",
          payload: [],
        });
        formStateDispatch({
          type: "SET-TOOLPATH-STYLE-OPTIONS",
          payload: [],
        });
        // reset the form if operation value is changed
        setValues({
          ...initFormValues,
          [key]: value,
        });
        formStateDispatch({
          type: "SET-INPUT-FIELD-DISPLAY-STATUS",
          payload: { key: "location", value: false },
        });
        formStateDispatch({
          type: "SET-INPUT-FIELD-DISPLAY-STATUS",
          payload: { key: "toolType", value: false },
        });
        break;
      case "toolPathType":
        formStateDispatch({
          type: "SET-TOOLPATH-NAME-OPTIONS",
          payload: formulateToolPathNameOptions(
            templateData,
            pass,
            values?.operation,
            value
          ),
        });
        formStateDispatch({
          type: "SET-TOOLPATH-STYLE-OPTIONS",
          payload: [],
        });
        setValues({
          ...values,
          [key]: value,
          toolPathName: "",
          toolPathStyle: "",
        });
        break;
      case "toolPathName":
        formStateDispatch({
          type: "SET-TOOLPATH-STYLE-OPTIONS",
          payload: formulateToolPathStyleOptions(
            templateData,
            pass,
            values?.operation,
            values?.toolPathType,
            value
          ),
        });
        setValues({
          ...values,
          [key]: value,
          toolPathStyle: "",
        });
        break;
      default:
        if (value === "other") {
          value = "";
          formStateDispatch({
            type: "SET-INPUT-FIELD-DISPLAY-STATUS",
            payload: { key, value: true },
          });
        }
        setFieldValue(key, value);
    }
  };

  const hideInputField = (key: string) => {
    if (key === "operation") {
      setValues({ ...initFormValues });
      formStateDispatch({
        type: "SET-TOOLPATH-NAME-OPTIONS",
        payload: [],
      });
      formStateDispatch({
        type: "SET-TOOLPATH-STYLE-OPTIONS",
        payload: [],
      });
      formStateDispatch({
        type: "SET-LOCATION-OPTIONS",
        payload: [],
      });
      formStateDispatch({
        type: "SET-TOOL-TYPE-OPTIONS",
        payload: [],
      });
      formStateDispatch({
        type: "SET-TOOLPATH-TYPE-OPTIONS",
        payload: [],
      });
      formStateDispatch({
        type: "SET-INPUT-FIELD-DISPLAY-STATUS",
        payload: { key: "location", value: false },
      });
      formStateDispatch({
        type: "SET-INPUT-FIELD-DISPLAY-STATUS",
        payload: { key: "toolType", value: false },
      });
    }
    formStateDispatch({
      type: "SET-INPUT-FIELD-DISPLAY-STATUS",
      payload: { key, value: false },
    });
    setFieldValue(key, "");
  };

  const disableInputs = !(
    values?.operation || formState.inputFieldDisplayStatus.operation
  );
  return (
    <form onSubmit={handleSubmit} className="w-full">
      <div className="flex-col flex gap-[8px] w-full">
        <div className="flex items-center gap-[4px]">
          <div className="w-[100px] min-w-[100px] max-w-[100px] text-[12px] font-semibold text-gray-500">
            Operation <sup className="text-red-600">*</sup>
          </div>
          {!formState.inputFieldDisplayStatus.operation ? (
            <SelectV2
              name="operation"
              options={formState.operationOptions}
              size="small"
              value={values?.operation}
              onChange={(val: any) => handleChangeFunc("operation", val)}
              onBlur={handleBlur}
              handleClose={(value: string) => {
                if (!value) setFieldTouched("operation", true);
              }}
              helperText={touched.operation && errors.operation}
            />
          ) : (
            <>
              <Input
                handleFocus={handleBlur}
                className="w-full"
                name="operation"
                value={values?.operation}
                size="small"
                placeholder="Enter Operation"
                onChange={(event: any) => {
                  setFieldValue("operation", event.target.value);
                }}
                onBlur={handleBlur}
                invalid={touched.operation && errors.operation}
                helperText={touched.operation && errors.operation}
              />
              <button
                type="button"
                className="p-0 border-none bg-transparent flex items-center justify-center w-[32px] h-auto"
                onClick={() => {
                  hideInputField("operation");
                }}
              >
                <img src={CloseSvg} alt="close" />
              </button>
            </>
          )}
        </div>
        <div className="flex items-center gap-[4px]">
          <div className="w-[100px] min-w-[100px] max-w-[100px] text-[12px] font-semibold text-gray-500 flex items-center">
            <span>
              Location <sup className="text-red-600">*</sup>
            </span>
            <svg
              className="ml-[4px]"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              id={`toolTipCustomStrategy-${UUID}`}
            >
              <path
                d="M8.0026 11.333C8.19149 11.333 8.34994 11.269 8.47794 11.141C8.60549 11.0135 8.66927 10.8552 8.66927 10.6663V7.98301C8.66927 7.79412 8.60549 7.63856 8.47794 7.51634C8.34994 7.39412 8.19149 7.33301 8.0026 7.33301C7.81371 7.33301 7.65549 7.39679 7.52794 7.52434C7.39994 7.65234 7.33594 7.81079 7.33594 7.99967V10.683C7.33594 10.8719 7.39994 11.0275 7.52794 11.1497C7.65549 11.2719 7.81371 11.333 8.0026 11.333ZM8.0026 5.99967C8.19149 5.99967 8.34994 5.93567 8.47794 5.80767C8.60549 5.68012 8.66927 5.5219 8.66927 5.33301C8.66927 5.14412 8.60549 4.98567 8.47794 4.85767C8.34994 4.73012 8.19149 4.66634 8.0026 4.66634C7.81371 4.66634 7.65549 4.73012 7.52794 4.85767C7.39994 4.98567 7.33594 5.14412 7.33594 5.33301C7.33594 5.5219 7.39994 5.68012 7.52794 5.80767C7.65549 5.93567 7.81371 5.99967 8.0026 5.99967ZM8.0026 14.6663C7.08038 14.6663 6.21372 14.4912 5.4026 14.141C4.59149 13.7912 3.88594 13.3163 3.28594 12.7163C2.68594 12.1163 2.21105 11.4108 1.86127 10.5997C1.51105 9.78856 1.33594 8.9219 1.33594 7.99967C1.33594 7.07745 1.51105 6.21079 1.86127 5.39967C2.21105 4.58856 2.68594 3.88301 3.28594 3.28301C3.88594 2.68301 4.59149 2.2079 5.4026 1.85767C6.21372 1.5079 7.08038 1.33301 8.0026 1.33301C8.92483 1.33301 9.79149 1.5079 10.6026 1.85767C11.4137 2.2079 12.1193 2.68301 12.7193 3.28301C13.3193 3.88301 13.7942 4.58856 14.1439 5.39967C14.4942 6.21079 14.6693 7.07745 14.6693 7.99967C14.6693 8.9219 14.4942 9.78856 14.1439 10.5997C13.7942 11.4108 13.3193 12.1163 12.7193 12.7163C12.1193 13.3163 11.4137 13.7912 10.6026 14.141C9.79149 14.4912 8.92483 14.6663 8.0026 14.6663ZM8.0026 13.333C9.48038 13.333 10.7388 12.8137 11.7779 11.775C12.8166 10.7359 13.3359 9.47745 13.3359 7.99967C13.3359 6.5219 12.8166 5.26345 11.7779 4.22434C10.7388 3.18567 9.48038 2.66634 8.0026 2.66634C6.52483 2.66634 5.2666 3.18567 4.22794 4.22434C3.18883 5.26345 2.66927 6.5219 2.66927 7.99967C2.66927 9.47745 3.18883 10.7359 4.22794 11.775C5.2666 12.8137 6.52483 13.333 8.0026 13.333Z"
                fill="#475467"
              />
            </svg>
          </div>
          <Tooltip
            anchorSelect={`#toolTipCustomStrategy-${UUID}`}
            style={{
              backgroundColor: "#D1E9FF",
              color: "#344054",
              boxShadow:
                "1px 0px 4px rgba(0, 0, 0, .15), 0px 1px 4px rgba(0, 0, 0, .25)",
              width: "160px",
              padding: "8px",
              borderRadius: "4px",
              fontWeight: "500",
            }}
            place={"top"}
            positionStrategy="fixed"
          >
            <div>
              This location belongs<br></br>to above Operation<br></br>of
              Feature
            </div>
          </Tooltip>
          {!formState.inputFieldDisplayStatus.location ? (
            <SelectV2
              name="location"
              options={formState.locationOptions}
              disabled={disableInputs}
              size="small"
              value={values?.location}
              onChange={(val: any) => handleChangeFunc("location", val)}
              handleClose={(value: string) => {
                if (!value) setFieldTouched("location", true);
              }}
              onBlur={handleBlur}
              helperText={touched.location && errors.location}
            />
          ) : (
            <>
              <Input
                handleFocus={handleBlur}
                className="w-full"
                name="location"
                value={values?.location}
                size="small"
                onChange={(event: any) =>
                  setFieldValue("location", event.target.value)
                }
                placeholder="Enter Location"
                onBlur={handleBlur}
                invalid={touched.location && errors.location}
                helperText={touched.location && errors.location}
              />
              <button
                type="button"
                className="p-0 border-none bg-transparent flex items-center justify-center w-[32px] h-auto"
                onClick={() => {
                  hideInputField("location");
                }}
              >
                <img src={CloseSvg} alt="close" />
              </button>
            </>
          )}
        </div>
        <div className="flex items-center gap-[4px]">
          <div className="w-[100px] min-w-[100px] max-w-[100px] text-[12px] font-semibold text-gray-500">
            Tool type <sup className="text-red-600">*</sup>
          </div>
          {!formState.inputFieldDisplayStatus.toolType ? (
            <SelectV2
              name="toolType"
              disabled={disableInputs}
              options={formState.toolTypeOptions}
              size="small"
              value={values?.toolType}
              onChange={(val: any) => handleChangeFunc("toolType", val)}
              handleClose={(value: string) => {
                if (!value) setFieldTouched("toolType", true);
              }}
              onBlur={handleBlur}
              helperText={touched.toolType && errors.toolType}
            />
          ) : (
            <>
              <Input
                handleFocus={handleBlur}
                className="w-full"
                name="toolType"
                value={values?.toolType}
                size="small"
                onChange={(event: any) =>
                  handleChangeFunc("toolType", event.target.value)
                }
                placeholder="Enter Tool type"
                onBlur={handleBlur}
                invalid={touched.toolType && errors.toolType}
                helperText={touched.toolType && errors.toolType}
              />
              <button
                type="button"
                className="p-0 border-none bg-transparent flex items-center justify-center w-[32px] h-auto"
                onClick={() => {
                  hideInputField("toolType");
                }}
              >
                <img src={CloseSvg} alt="close" />
              </button>
            </>
          )}
        </div>
        <div className="flex items-center gap-[4px]">
          <div className="w-[100px] min-w-[100px] max-w-[100px] text-[12px] font-semibold text-gray-500">
            Toolpath type <sup className="text-red-600">*</sup>
          </div>
          <SelectV2
            name="toolPathType"
            options={formState.toolPathTypeOptions}
            disabled={disableInputs}
            size="small"
            value={values?.toolPathType}
            onChange={(val: any) => handleChangeFunc("toolPathType", val)}
            handleClose={(value: string) => {
              if (!value) setFieldTouched("toolPathType", true);
            }}
            onBlur={handleBlur}
            helperText={touched.toolPathType && errors.toolPathType}
          />
        </div>
        {!!formState.toolPathNameOptions?.length && (
          <div className="flex items-center gap-[4px]">
            <div className="w-[100px] min-w-[100px] max-w-[100px] text-[12px] font-semibold text-gray-500">
              Toolpath name <sup className="text-red-600">*</sup>
            </div>
            <SelectV2
              name="toolPathName"
              options={formState.toolPathNameOptions}
              size="small"
              value={values?.toolPathName}
              onChange={(val: any) => handleChangeFunc("toolPathName", val)}
              handleClose={(value: string) => {
                if (!value) setFieldTouched("toolPathName", true);
              }}
              onBlur={handleBlur}
              helperText={touched.toolPathName && errors.toolPathName}
            />
          </div>
        )}
        {!!formState.toolPathStyleOptions?.length && (
          <div className="flex items-center gap-[4px]">
            <div className="w-[100px] min-w-[100px] max-w-[100px] text-[12px] font-semibold text-gray-500">
              Toolpath style <sup className="text-red-600">*</sup>
            </div>
            <SelectV2
              name="toolPathStyle"
              options={formState.toolPathStyleOptions}
              size="small"
              value={values?.toolPathStyle}
              onChange={(val: any) => handleChangeFunc("toolPathStyle", val)}
              handleClose={(value: string) => {
                if (!value) setFieldTouched("toolPathStyle", true);
              }}
              onBlur={handleBlur}
              helperText={touched.toolPathStyle && errors.toolPathStyle}
            />
          </div>
        )}
      </div>
      <div className="flex gap-[8px] justify-end mt-[8px]">
        {showCancelButton && (
          <Button
            size="sm"
            type="button"
            variant="gray"
            className="text-[12px] font-semibold font-inter px-[8px] py-[4px] rounded-[4px] bg-gray-200 text-gray-600"
            onClick={onCancel}
          >
            Cancel
          </Button>
        )}
        <Button
          size="sm"
          type="submit"
          className="text-[12px] font-semibold font-inter px-[8px] py-[4px] rounded-[4px] bg-blue-600 text-white"
          // disabled={!dirty || !isValid}
        >
          {editRowData ? "Update" : "Save"}
        </Button>
      </div>
    </form>
  );
};

export default CreateCustomStrategyForm;
