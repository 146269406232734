import clsx from "clsx";
import { IOption } from "./Select";
import ArrowDown from "assets/images/icons/arrow-down.svg";
import ArrowUp from "assets/images/icons/arrow-up.svg";
import { MATERIAL_LIST } from "constant";
import FeatureInfoIcon from "assets/images/icons/feature-info.svg";
import ToolTipV2 from "components/ToolTipV2";

interface IDefaultToggler {
  selected: any;
  toggleSelect: any;
  isOpen: boolean;
  className?: any;
  infoTooltipTitle?: React.ReactNode;
  placeholder?: string;
  disabled?: boolean;
  options: IOption[];
  isTag?: boolean;
  size?: any;
}

export const DefaultToggler: React.FC<IDefaultToggler> = ({
  selected,
  toggleSelect,
  isOpen,
  className,
  infoTooltipTitle,
  placeholder,
  disabled,
  options,
  isTag,
  size = "default",
}) => {
  const togglerClsx = clsx({
    "select-toggler": true,
    "!pr-[55px]": infoTooltipTitle,
    "justify-between": selected,
    "justify-end": !selected,
    [className]: className,
  });
  const selectedOption = options?.find((option) => option?.value === selected);
  return (
    <>
      <button
        type="button"
        className={togglerClsx}
        onClick={toggleSelect}
        disabled={disabled}
      >
        <label
          className={`left-[20px] font-medium absolute pointer-events-none z-10 transition-all ${
            selected || isOpen
              ? "text-[10px] top-[2px]"
              : "top-[16px] text-gray-b9b text-sm"
          }`}
          htmlFor="name"
        >
          {placeholder}
        </label>
        <div
          className={clsx("flex flex-row items-center valuelabel", {
            "mt-1": isTag,
            "!mt-0": size === "small",
          })}
        >
          {isTag && (
            <>
              {(() => {
                const option = MATERIAL_LIST.find(
                  (material) => material.value === selected
                );
                if (!option) return;
                return (
                  <div
                    className="w-6 h-6 rounded-sm font-medium flex justify-center items-center mr-2"
                    style={{
                      color: option?.textColor,
                      background: option?.bgColor,
                    }}
                  >
                    {option?.tag}
                  </div>
                );
              })()}
            </>
          )}
          {selectedOption?.image && (
            <img src={selectedOption.image} alt="selected" className="mr-2" />
          )}
          {selectedOption?.label}
        </div>
        <div className="flex justify-between gap-2">
          <img src={isOpen ? ArrowUp : ArrowDown} alt="arrow" />
        </div>
      </button>
      {infoTooltipTitle && (
        <div className="absolute right-0 top-0 bottom-0 bg-gray-100 flex align-center py-4 rounded-r-sm">
          <ToolTipV2 title={infoTooltipTitle} openOnHover={false}>
            <div className="px-[14px]">
              <img src={FeatureInfoIcon} alt="" height={16} width={16} />
            </div>
          </ToolTipV2>
        </div>
      )}
    </>
  );
};
