import { convertKeyToLabel } from "utils";
export const SUMMARY_VIEW: any = {
  hole: {
    open_hole: [
      { key: "diameter", label: "Diameter" },
      { key: "depth", label: "Depth" },
      { key: "quantity", label: "Quantity" },
      { key: "bottom_type", label: "Bottom Type" },
      { key: "bottom", label: "Bottom" },
      { key: "bottom_radius", label: "Bottom Radius" },
      { key: "bottom_angle", label: "Bottom Angle" },
      { key: "deburring", label: "Deburring" },
      { key: "additional_length", label: "Top Distance" },
    ],
    finish_hole: [
      { key: "diameter", label: "Diameter" },
      { key: "depth", label: "Depth" },
      { key: "quantity", label: "Quantity" },
      { key: "bottom_type", label: "Bottom Type" },
      { key: "bottom", label: "Bottom" },
      { key: "bottom_radius", label: "Bottom Radius" },
      { key: "bottom_angle", label: "Bottom Angle" },
      { key: "deburring", label: "Deburring" },
      { key: "additional_length", label: "Top Distance" },
      { key: "finish_type", label: "Finish Type" },
    ],
  },

  thread_hole: {
    thread_hole: [
      { key: "thread_type", label: "Thread type" },
      { key: "thread_size", label: "Thread size" },
      { key: "thread_pitch", label: "Thread pitch" },
      { key: "thread_depth", label: "Thread depth" },
      { key: "depth", label: "Depth" },
      { key: "bottom_type", label: "Bottom Type" },
      { key: "bottom", label: "Bottom" },
      { key: "bottom_radius", label: "Bottom Radius" },
      { key: "bottom_angle", label: "Bottom Angle" },
      { key: "deburring", label: "Deburring" },
      { key: "additional_length", label: "Additional Lenght" },
    ],
  },

  face: {
    top_face: [
      { key: "length", label: "Length" },
      { key: "breadth", label: "Breadth" },
      { key: "stock_on_face", label: "Stock on Face" },
    ],
    bottom_face: [
      { key: "length", label: "Length" },
      { key: "breadth", label: "Breadth" },
      { key: "stock_on_face", label: "Stock on Face" },
      {
        key: "min_distance_between_islands",
        label: "Min Distance between Islands",
      },
    ],
    side_face: [
      { key: "length", label: "Length" },
      { key: "breadth", label: "Breadth" },
      { key: "stock_on_face", label: "Stock on Face" },
      { key: "depth", label: "Depth" },
      { key: "floor_type", label: "Floor Type" },
      { key: "floor_radius", label: "Floor Radius" },
    ],
  },

  pocket: {
    pocket_2d: [
      { key: "length", label: "Length" },
      { key: "breadth", label: "Breadth" },
      { key: "depth", label: "Depth" },
      { key: "pocket_type", label: "Pocket Type" },
      { key: "walls", label: "Walls" },
      { key: "corner_radius", label: "Corner Radius" },
      { key: "min_distance", label: "Min Distance" },
      { key: "floor_type", label: "Floor Type" },
      { key: "floor_radius", label: "Floor Radius" },
      {
        key: "min_distance_between_islands",
        label: "Min Distance between Islands",
      },
    ],
    pocket_3d: [
      // { key: "pocketLength", label: "Length" },
      // { key: "pocketBreadth", label: "Breadth" },
      // { key: "wallHeight", label: "Height" },
      // { key: "wallThickness", label: "Wall Thickness" },
      // { key: "wallAngleType", label: "Wall Angle Type" },
      // { key: "bottomType", label: "Bottom Type" },
      // { key: "groundRadius", label: "Ground Radius" },
      // { key: "sideRadius", label: "Side Radius" },
      // { key: "reliefHoleDiameter", label: "Relief Hole Diameter" },
      // { key: "reliefHoleDepth", label: "Relief Hole Depth" },
      // { key: "isLandLength", label: "Land Length" },
      // { key: "isLandBreadth", label: "Land Breadth" },
      // { key: "isLandHeight", label: "Land Height" },
      // { key: "minXDistanceToIsLand", label: "Min X Distance To IsLand" },
      // { key: "minYDistanceToIsLand", label: "Min Y Distance To IsLand" },
      // { key: "groundRadiusWithIsLand", label: "Ground Radius With IsLand" },
      // { key: "corneringOperation", label: "Cornering Operation" },
      // { key: "curlingOperation", label: "Curling Operation" },
    ],
  },

  edge: {
    Fillet: [
      { key: "length", label: "Length" },
      { key: "depth", label: "Depth" },
      { key: "radius", label: "Radius" },
      { key: "location", label: "Location" },
      { key: "fillet_type", label: "Type" },
      { key: "floor_type", label: "Floor Type" },
      { key: "floor_radius", label: "Floor Radius" },
    ],
    Slot: [
      { key: "length", label: "Length" },
      { key: "breadth", label: "Breadth" },
      { key: "depth", label: "Depth" },
      { key: "floor_radius", label: "Floor Radius" },
      { key: "open_sides", label: "Open Sides" },
      { key: "corner_radius", label: "Corner Radius" },
    ],
    Chamfer: [
      { key: "length", label: "Length" },
      { key: "breadth", label: "Breadth" },
      { key: "angle", label: "Angle" },
      { key: "chamfer_type", label: "Type" },
      { key: "location", label: "Location" },
      { key: "corner_radius", label: "Corner Radius" },
      { key: "vertical_edges", label: "Vertical Edges" },
      { key: "distance", label: "Distance" },
    ],
  },

  core_cavity: {
    core_cavity: [{ key: "count", label: "Count" }],
    data: [
      { key: "bottomLength", label: "Bottom Length" },
      { key: "bottomBreadth", label: "Bottom Breadth" },
      { key: "bottomDepth", label: "Bottom Depth" },
      { key: "bottomType", label: "Bottom Type" },
      { key: "groundRadius", label: "Ground Radius" },
      { key: "sideRadius", label: "Side Radius" },
    ],
  },

  pencil: {
    pencil: [
      { key: "pencilDiameter", label: "Diameter" },
      { key: "cornerRadius", label: "Corner Radius" },
      { key: "stockLeft", label: "Stock Left" },
      { key: "groundRadius", label: "Ground Radius" },
      { key: "sideRadius", label: "Side Radius" },
      { key: "distance", label: "Distance" },
      { key: "wallTangent", label: "Wall Tangent" },
      { key: "wallAngleType", label: "wall Angle Type" },
    ],
  },
  groove: {
    groove: [],
  },
};

const HOLE_LABELS = [
  { key: "diameter", label: "Diameter" },
  { key: "depth", label: "Depth" },
  { key: "quantity", label: "Quantity" },
  { key: "bottom_type", label: "Bottom Type" },
  { key: "deburring", label: "Deburring" },
  { key: "additional_length", label: "Top Distance" },
];

const THREADHOLE_LABELS = [
  { key: "thread_type", label: "Thread type" },
  { key: "thread_size", label: "Thread size" },
  { key: "thread_pitch", label: "Thread pitch" },
  { key: "thread_depth", label: "Thread depth" },
  { key: "depth", label: "Depth" },
  { key: "additional_length", label: "Top Distance" },
  { key: "deburring", label: "Deburring" },
  { key: "bottom_type", label: "Bottom Type" },
];

const FACE_LABELS = [
  { key: "length", label: "Length" },
  { key: "breadth", label: "Breadth" },
  { key: "stock_on_face", label: "Stock on Face" },
];

const POCKET_LABELS = [
  { key: "length", label: "Length" },
  { key: "breadth", label: "Breadth" },
  { key: "depth", label: "Depth" },
  { key: "pocket_type", label: "Pocket Type" },
  { key: "walls", label: "Walls" },
  { key: "floor_type", label: "Floor Type" },
];

const EDGE_LABELS = {
  fillet: [
    { key: "length", label: "Length" },
    { key: "depth", label: "Depth" },
    { key: "radius", label: "Radius" },
    { key: "location", label: "Location" },
    { key: "fillet_type", label: "Type" },
  ],
  slot: [
    { key: "length", label: "Length" },
    { key: "breadth", label: "Breadth" },
    { key: "depth", label: "Depth" },
    { key: "floor_radius", label: "Floor Radius" },
    { key: "open_sides", label: "Open Sides" },
    { key: "corner_radius", label: "Corner Radius" },
  ],
  chamfer: [
    { key: "length", label: "Length" },
    { key: "breadth", label: "Breadth" },
    { key: "angle", label: "Angle" },
    { key: "chamfer_type", label: "Type" },
    { key: "vertical_edges", label: "Vertical Edges" },
    { key: "location", label: "Location" },
  ],
};

export const toolsKeysWithNoUnits: string[] = [
  "Tool ID",
  "Body material code",
  "Flute count",
  "Taper angle",
  "Point angle",
  "Tool type",
  "Description",
  "tool_class",
  "feedback",
  "isValid",
  "Tool class",
  "custom",
];

export const ISLAND_LABELS: any = {
  length: "Length",
  width: "Width",
  floor_radius: "Floor Radius",
  height: "Height",
  min_x_distance_to_face_edges: "Min x distance to face edges",
  min_y_distance_to_face_edges: "Min y distance to face edges",
};

const displayInputsSequence: any = {
  simple_hole: {
    diameter: 0,
    depth: 1,
    bottom_type: 2,
    bottom: 3,
    bottom_radius: 4,
    bottom_angle: 5,
    sequential: 6,
    surface_type: 7,
    deburring: 8,
    quantity: 9,
    hole_type: 10,
    stock_to_remove: 11,
    finish_type: 12,
    upper_tolerance: 13,
    lower_tolerance: 14,
    vertical_clearance: 15,
    horizontal_clearance: 16,
  },
  thread_hole: {
    thread_size: 0,
    minor_diameter: 1,
    thread_pitch: 2,
    thread_depth: 3,
    depth: 4,
    thread_angle: 5,
    bottom_type: 6,
    bottom: 7,
    bottom_radius: 8,
    bottom_angle: 9,
    deburring: 10,
    quantity: 11,
    surface_type: 12,
    sequential: 13,
    hole_type: 14,
    stock_to_remove: 15,
    finish_type: 16,
    upper_tolerance: 17,
    lower_tolerance: 18,
    vertical_clearance: 19,
    horizontal_clearance: 20,
  },
  top_face: {
    length: 0,
    width: 1,
    stock_to_remove: 2,
    quantity: 3,
    finishing_surface: 4,
    vertical_clearance: 5,
    horizontal_clearance: 6,
  },
  side_face: {
    length: 0,
    depth: 1,
    stock_to_remove: 2,
    bottom_type: 3,
    bottom_radius: 4,
    bottom_width: 5,
    quantity: 6,
    surface_type: 7,
    taper_angle: 8,
    taper_length: 9,
    vertical_clearance: 10,
    horizontal_clearance: 11,
  },
  bottom_face: {
    length: 0,
    width: 1,
    wall_height: 2,
    quantity: 3,
    wall_type: 4,
    floor_radius: 5,
    wall_angle: 6,
    vertical_clearance: 7,
    horizontal_clearance: 8,
  },
  pocket_2d: {
    length: 0,
    width: 1,
    depth: 2,
    quantity: 3,
    pocket_type: 4,
    min_distance: 5,
    corner_radius: 6,
    no_of_walls: 7,
    wall_type: 8,
    taper_angle: 9,
    floor_type: 10,
    floor_radius: 11,
    vertical_clearance: 12,
    horizontal_clearance: 13,
    island_info: 14,
  },
  slot: {
    length: 0,
    width: 1,
    depth: 2,
    slot_type: 3,
    type: 4,
    section_depth: 5,
    section_width: 6,
    open_sides: 7,
    corner_radius: 8,
    location: 9,
    bottom_type: 10,
    quantity: 11,
    bottom_radius: 12,
    vertical_clearance: 13,
    horizontal_clearance: 14,
  },
  fillet: {
    location: 0,
    radius: 1,
    length: 2,
    hole_diameter: 3,
    orientation: 4,
    shape: 5,
    bottom_type: 6,
    quantity: 7,
    vertical_clearance: 8,
    horizontal_clearance: 9,
  },
  chamfer: {
    length: 0,
    angle: 1,
    quantity: 2,
    type: 3,
    edge_length: 4,
    hole_diameter: 5,
    hole_type: 6,
    hole_depth: 7,
    vertical_clearance: 8,
    horizontal_clearance: 9,
  },
  groove: {
    outer_diameter: 0,
    width: 1,
    depth: 2,
    bottom_radius: 3,
    quantity: 4,
    vertical_clearance: 5,
    horizontal_clearance: 6,
  },
};

export function makeLabels(feature_name: string, featureObject: any) {
  const keysArray = Object.keys(featureObject).sort(
    (a, b) =>
      displayInputsSequence[feature_name]?.[a] -
      displayInputsSequence[feature_name]?.[b]
  );
  const labels: { key: string; label: string }[] = [];
  keysArray.forEach((key: string) => {
    if (key === "island_info")
      labels.push({ key: "island_info", label: "Islands" });
    else labels.push({ key, label: convertKeyToLabel(key) });
  });
  return labels;
}

export const featureInputsWithNoUnits: Set<string> = new Set([
  "bottom_type",
  "bottom",
  "bottom_angle",
  "deburring",
  "quantity",
  "hole_type",
  "surface_type",
  "sequential",
  "finish_type",
  "finishing_surface",
  "wall_type",
  "wall_angle",
  "taper_angle",
  "pocket_type",
  "floor_type",
  "slot_type",
  "type",
  "open_sides",
  "angle",
  "no_of_walls",
  "location",
  "orientation",
  "shape",
  "thread_angle",
]);

export function makeCuttingParamsToDisplay(parameters: any) {
  let CuttingparamKeys: any = [];
  const ignoredParams: string[] = [
    "non_optimal_points",
    "tool_path_parameters",
    "valid_data",
    "originalParams",
    "paramsFlag",
  ];
  Object.keys(parameters)?.forEach((paramKey: string) => {
    if (
      ignoredParams.includes(paramKey) ||
      parameters[paramKey] === null ||
      typeof parameters[paramKey] !== "object"
    )
      return;
    CuttingparamKeys.push(paramKey);
  });
  return CuttingparamKeys;
}
