import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { GlobalContextProvider } from "context/GlobalContext";
import Modal from "react-modal";
import App from "App";
import { Amplify } from "aws-amplify";
import { StyledEngineProvider } from "@mui/material/styles";

Modal.setAppElement("#root");
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: process.env.REACT_APP_COGNITOR_REGION,
    userPoolId: process.env.REACT_APP_COGNITOR_USER_POOL_ID,
    identityPoolId: process.env.REACT_APP_COGNITOR_IDENTITY_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITOR_APP_CLIENT_ID,
  },
  Storage: {
    AWSS3: {
      region: process.env.REACT_APP_S3_REGION,
      bucket: process.env.REACT_APP_S3_BUCKET,
      identityPoolId: process.env.REACT_APP_COGNITOR_IDENTITY_POOL_ID,
      isObjectLockEnabled: true,
    },
  },
  API: {
    endpoints: [
      {
        name: process.env.REACT_APP_API_NAME,
        endpoint: process.env.REACT_APP_GATEWAY_URL,
        region: process.env.REACT_APP_GATEWAY_REGION,
      },
    ],
  },
});

root.render(
  // <React.StrictMode>
  <StyledEngineProvider injectFirst>
    <GlobalContextProvider>
      <App />
    </GlobalContextProvider>
  </StyledEngineProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
