interface StyledCuttingToolValueProps {
  tool: any;
  valueKey: string;
}

const StyledCuttingToolValue: React.FC<StyledCuttingToolValueProps> = ({
  tool,
  valueKey,
}) => {
  return (
    <div>
      {(() => {
        switch (valueKey) {
          case "libraries":
            return (
              <div>
                <p className="text-sm underline">{tool?.libraryTitle}</p>
                <a href="" className="text-xs text-surface-default">
                  {tool.libraryLink}
                </a>
              </div>
            );
          default:
            if (tool?.hasOwnProperty(valueKey)) {
              return <>{tool?.[valueKey]}</>;
            }
            return <>-</>;
        }
      })()}
    </div>
  );
};

export default StyledCuttingToolValue;
