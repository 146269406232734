export const backupMachineListMapping: any = {
  machine_type: [
    {
      id: 2,
      machine_type: "CNC Milling",
      machine_sub_types: [
        {
          id: 12,
          machine_sub_type: "Horizontal Machining Center (HMC)",
        },
        {
          id: 13,
          machine_sub_type: "Vertical Machining Center (VMC)",
        },
      ],
    },
    {
      id: 1,
      machine_type: "Lathe",
      machine_sub_types: [
        {
          id: 1,
          machine_sub_type: "CNC Lathe",
        },
      ],
    },
  ],
  worktable_type: [
    {
      id: 5,
      work_table_type: "Cross-Slide Table",
    },
    {
      id: 8,
      work_table_type: "Fixed and movable Bridge Tables",
    },
    {
      id: 1,
      work_table_type: "Flat",
    },
    {
      id: 7,
      work_table_type: "Gantry Table",
    },
    {
      id: 4,
      work_table_type: "Indexing Table",
    },
    {
      id: 9,
      work_table_type: "Magnetic Table",
    },
    {
      id: 2,
      work_table_type: "Rotary Table",
    },
    {
      id: 6,
      work_table_type: "Tombstone",
    },
    {
      id: 3,
      work_table_type: "Trunnion Table",
    },
    {
      id: 10,
      work_table_type: "Vacuum Table",
    },
  ],
  worktable_size_unit: [
    {
      "1": "mm",
    },
  ],
  cutting_feed_unit: [
    {
      "1": "inch / minute",
    },
  ],
  spindle_speed_unit: [
    {
      "1": "RPM",
    },
  ],
  spindle_power_unit: [
    {
      "1": "Lb-ft",
    },
  ],
};

export function getBackupMachineList(
  machineTypeId: number,
  machineSubtypeId: number,
  numberOfAxis: number
) {
  switch (true) {
    case machineTypeId === 2 && machineSubtypeId === 12 && numberOfAxis === 3:
      return [
        {
          id: 39,
          machine_name: "NL250007_013",
          organization_id: 1,
          updated_at: "2024-10-17 06:09:14",
          estimated_time: "2024-06-22 19:09:03",
          machine_type: 2,
          machine_sub_type: 12,
          updated_by_user: "Daniel Radcliffe",
          number_of_axes: 3,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 37,
              max_spindle_speed: "20000.00",
              spindle_speed_unit: 1,
              max_spindle_power: "20000.00",
              spindle_power_unit: 1,
              machine: 39,
              worktable: [
                {
                  worktable_id: 37,
                  worktable_name: '["NL250007_013","W",1]',
                  worktable_length: null,
                  worktable_breadth: null,
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "10000.00",
          cutting_feed_lower: "4356.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 4,
          tool_cutting_library: [
            {
              id: 1,
              file_name: "machin-June 25th 2024, 1_28_50 pm.xlsx",
              organization_id: 1,
              uuid_field: "507048ab-34d1-4b33-be80-a8207e74f36a.xlsx",
              tool_library_url:
                "https://tool-library.s3.amazonaws.com/507048ab-34d1-4b33-be80-a8207e74f36a.xlsx?AWSAccessKeyId=AKIA5HP5SJY7XQ5ZUIVF&Signature=oA7%2Fnu8Aj4ZTzNnw5%2Bzdz%2BbSy9k%3D&Expires=1729246407",
              size: 5,
            },
            {
              id: 2,
              file_name: "sample-file (4).xls",
              organization_id: 1,
              uuid_field: "7b79cb69-42fa-4720-94c5-a238159a7653.xlsx",
              tool_library_url:
                "https://tool-library.s3.amazonaws.com/7b79cb69-42fa-4720-94c5-a238159a7653.xlsx?AWSAccessKeyId=AKIA5HP5SJY7XQ5ZUIVF&Signature=V7YrVMstUjk%2FTP%2FRKHrN9W6gHqQ%3D&Expires=1729246407",
              size: 27,
            },
            {
              id: 3,
              file_name: "sample-file (1).xls",
              organization_id: 1,
              uuid_field: "1b5a4dc3-960d-4bed-bc12-4abdb3ff02cd.xlsx",
              tool_library_url:
                "https://tool-library.s3.amazonaws.com/1b5a4dc3-960d-4bed-bc12-4abdb3ff02cd.xlsx?AWSAccessKeyId=AKIA5HP5SJY7XQ5ZUIVF&Signature=FeFvWmTRBau0ehRo%2Fy8vJ9tQJ0I%3D&Expires=1729246407",
              size: 27,
            },
            {
              id: 4,
              file_name: "Cutting performance - potential time savings.xlsx",
              organization_id: 1,
              uuid_field: "00bcd287-4dde-4577-aab9-3241f0808683.xlsx",
              tool_library_url:
                "https://tool-library.s3.amazonaws.com/00bcd287-4dde-4577-aab9-3241f0808683.xlsx?AWSAccessKeyId=AKIA5HP5SJY7XQ5ZUIVF&Signature=g9Z7%2BWBw04A8iudS5vmo5tZImno%3D&Expires=1729246407",
              size: 87,
            },
          ],
          status: 1,
          execution: "ACTIVE",
          connection_detail_machine: [
            {
              machine: 39,
              ip_address: "192.168.24.20",
              tcp_port: 1223,
              protocol: 1,
              controller: 1,
              data_communication_check: true,
              internet_connectivity_check: true,
              data_transmission_check: true,
            },
          ],
        },
        {
          id: 334,
          machine_name: "HSM 700",
          organization_id: 1,
          updated_at: "2024-12-07 06:09:33",
          estimated_time: null,
          machine_type: 2,
          machine_sub_type: 12,
          updated_by_user: "Sukhpreet Singh",
          number_of_axes: 3,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 351,
              max_spindle_speed: "42000.00",
              spindle_speed_unit: 1,
              max_spindle_power: "19.00",
              spindle_power_unit: 1,
              machine: 334,
              worktable: [
                {
                  worktable_id: 420,
                  worktable_name: '["HSM 700","W",1]',
                  worktable_length: "35.43",
                  worktable_breadth: "21.65",
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "787.00",
          cutting_feed_lower: "0.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: null,
          connection_detail_machine: [],
        },
        {
          id: 354,
          machine_name: "PERFORMA H4-XP",
          organization_id: 1,
          updated_at: "2024-12-07 06:10:07",
          estimated_time: null,
          machine_type: 2,
          machine_sub_type: 12,
          updated_by_user: "Sukhpreet Singh",
          number_of_axes: 3,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 371,
              max_spindle_speed: "10000.00",
              spindle_speed_unit: 1,
              max_spindle_power: "20.00",
              spindle_power_unit: 1,
              machine: 354,
              worktable: [
                {
                  worktable_id: 440,
                  worktable_name: '["PERFORMA H4-XP","W",1]',
                  worktable_length: "15.80",
                  worktable_breadth: "15.80",
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "600.00",
          cutting_feed_lower: "0.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: null,
          connection_detail_machine: [],
        },
        {
          id: 394,
          machine_name: "RFM 1000",
          organization_id: 1,
          updated_at: "2024-12-07 06:13:14",
          estimated_time: null,
          machine_type: 2,
          machine_sub_type: 12,
          updated_by_user: "Sukhpreet Singh",
          number_of_axes: 3,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 411,
              max_spindle_speed: "30000.00",
              spindle_speed_unit: 1,
              max_spindle_power: "5.00",
              spindle_power_unit: 1,
              machine: 394,
              worktable: [
                {
                  worktable_id: 480,
                  worktable_name: '["RFM 1000","W",1]',
                  worktable_length: "47.24",
                  worktable_breadth: "39.37",
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "10000.00",
          cutting_feed_lower: "0.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: null,
          connection_detail_machine: [],
        },
        {
          id: 397,
          machine_name: "RFM 600",
          organization_id: 1,
          updated_at: "2024-12-07 06:13:17",
          estimated_time: null,
          machine_type: 2,
          machine_sub_type: 12,
          updated_by_user: "Sukhpreet Singh",
          number_of_axes: 3,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 414,
              max_spindle_speed: "30000.00",
              spindle_speed_unit: 1,
              max_spindle_power: "5.00",
              spindle_power_unit: 1,
              machine: 397,
              worktable: [
                {
                  worktable_id: 483,
                  worktable_name: '["RFM 600","W",1]',
                  worktable_length: "27.56",
                  worktable_breadth: "21.65",
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "10000.00",
          cutting_feed_lower: "0.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: null,
          connection_detail_machine: [],
        },
        {
          id: 398,
          machine_name: "RFM 760",
          organization_id: 1,
          updated_at: "2024-12-07 06:13:18",
          estimated_time: null,
          machine_type: 2,
          machine_sub_type: 12,
          updated_by_user: "Sukhpreet Singh",
          number_of_axes: 3,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 415,
              max_spindle_speed: "30000.00",
              spindle_speed_unit: 1,
              max_spindle_power: "5.00",
              spindle_power_unit: 1,
              machine: 398,
              worktable: [
                {
                  worktable_id: 484,
                  worktable_name: '["RFM 760","W",1]',
                  worktable_length: "33.86",
                  worktable_breadth: "25.59",
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "10000.00",
          cutting_feed_lower: "0.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: null,
          connection_detail_machine: [],
        },
        {
          id: 356,
          machine_name: "SH 400",
          organization_id: 1,
          updated_at: "2024-12-07 06:10:09",
          estimated_time: null,
          machine_type: 2,
          machine_sub_type: 12,
          updated_by_user: "Sukhpreet Singh",
          number_of_axes: 3,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 373,
              max_spindle_speed: "12000.00",
              spindle_speed_unit: 1,
              max_spindle_power: "20.00",
              spindle_power_unit: 1,
              machine: 356,
              worktable: [
                {
                  worktable_id: 442,
                  worktable_name: '["SH 400","W",1]',
                  worktable_length: "15.80",
                  worktable_breadth: "15.80",
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "700.00",
          cutting_feed_lower: "0.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: null,
          connection_detail_machine: [],
        },
        {
          id: 366,
          machine_name: "SH 500",
          organization_id: 1,
          updated_at: "2024-12-07 06:10:20",
          estimated_time: null,
          machine_type: 2,
          machine_sub_type: 12,
          updated_by_user: "Sukhpreet Singh",
          number_of_axes: 3,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 383,
              max_spindle_speed: "12000.00",
              spindle_speed_unit: 1,
              max_spindle_power: "20.00",
              spindle_power_unit: 1,
              machine: 366,
              worktable: [
                {
                  worktable_id: 452,
                  worktable_name: '["SH 500","W",1]',
                  worktable_length: "19.70",
                  worktable_breadth: "19.70",
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "700.00",
          cutting_feed_lower: "0.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: null,
          connection_detail_machine: [],
        },
        {
          id: 393,
          machine_name: "Test",
          organization_id: 1,
          updated_at: "2024-12-07 06:13:13",
          estimated_time: null,
          machine_type: 2,
          machine_sub_type: 12,
          updated_by_user: "Sukhpreet Singh",
          number_of_axes: 3,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 410,
              max_spindle_speed: "10000.00",
              spindle_speed_unit: 1,
              max_spindle_power: "15.00",
              spindle_power_unit: 1,
              machine: 393,
              worktable: [
                {
                  worktable_id: 479,
                  worktable_name: '["Test","W",1]',
                  worktable_length: "40.00",
                  worktable_breadth: "20.00",
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "10000.00",
          cutting_feed_lower: "0.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: null,
          connection_detail_machine: [],
        },
        {
          id: 427,
          machine_name: "THV-430",
          organization_id: 1,
          updated_at: "2024-12-07 06:34:34",
          estimated_time: null,
          machine_type: 2,
          machine_sub_type: 12,
          updated_by_user: "Daniel Radcliffe",
          number_of_axes: 3,
          number_of_spindles: 2,
          machine_spindle: [
            {
              id: 465,
              max_spindle_speed: "3000.00",
              spindle_speed_unit: 1,
              max_spindle_power: "15.00",
              spindle_power_unit: 1,
              machine: 427,
              worktable: [
                {
                  worktable_id: 512,
                  worktable_name: '["THV-430","W",1]',
                  worktable_length: "19.67",
                  worktable_breadth: "19.67",
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: false,
                },
              ],
            },
            {
              id: 466,
              max_spindle_speed: "3000.00",
              spindle_speed_unit: 1,
              max_spindle_power: "15.00",
              spindle_power_unit: 1,
              machine: 427,
              worktable: [
                {
                  worktable_id: 512,
                  worktable_name: '["THV-430","W",1]',
                  worktable_length: "19.67",
                  worktable_breadth: "19.67",
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: true,
                },
              ],
            },
          ],
          cutting_feed_upper: "10000.00",
          cutting_feed_lower: "0.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: null,
          connection_detail_machine: [],
        },
        {
          id: 383,
          machine_name: "UCP 1350",
          organization_id: 1,
          updated_at: "2024-12-07 06:13:02",
          estimated_time: null,
          machine_type: 2,
          machine_sub_type: 12,
          updated_by_user: "Sukhpreet Singh",
          number_of_axes: 3,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 400,
              max_spindle_speed: "12000.00",
              spindle_speed_unit: 1,
              max_spindle_power: "32.00",
              spindle_power_unit: 1,
              machine: 383,
              worktable: [
                {
                  worktable_id: 469,
                  worktable_name: '["UCP 1350","W",1]',
                  worktable_length: "43.31",
                  worktable_breadth: "43.31",
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "10000.00",
          cutting_feed_lower: "0.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: null,
          connection_detail_machine: [],
        },
      ];
    case machineTypeId === 2 && machineSubtypeId === 13 && numberOfAxis === 3:
      return [
        {
          id: 208,
          machine_name: "DMU 50",
          organization_id: 1,
          updated_at: "2024-09-06 05:13:06",
          estimated_time: "2024-07-11 05:23:08",
          machine_type: 2,
          machine_sub_type: 13,
          updated_by_user: "Daniel Radcliffe",
          number_of_axes: 3,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 220,
              max_spindle_speed: "15000.00",
              spindle_speed_unit: 1,
              max_spindle_power: "10000.00",
              spindle_power_unit: 1,
              machine: 208,
              worktable: [
                {
                  worktable_id: 276,
                  worktable_name: '["DMU 50","W",1]',
                  worktable_length: "630.00",
                  worktable_breadth: null,
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 2,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "1000.00",
          cutting_feed_lower: "1.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: null,
          connection_detail_machine: [
            {
              machine: 208,
              ip_address: "1.1.1.1",
              tcp_port: 111,
              protocol: 1,
              controller: 6,
              data_communication_check: true,
              internet_connectivity_check: true,
              data_transmission_check: true,
            },
          ],
        },
        {
          id: 265,
          machine_name: "VX500",
          organization_id: 1,
          updated_at: "2024-09-06 03:15:57",
          estimated_time: "2024-07-14 02:43:56",
          machine_type: 2,
          machine_sub_type: 13,
          updated_by_user: "Daniel Radcliffe",
          number_of_axes: 3,
          number_of_spindles: 3,
          machine_spindle: [
            {
              id: 277,
              max_spindle_speed: "10000.00",
              spindle_speed_unit: 1,
              max_spindle_power: "234455.00",
              spindle_power_unit: 1,
              machine: 265,
              worktable: [
                {
                  worktable_id: 386,
                  worktable_name: '["VX500","W",1]',
                  worktable_length: "1020.00",
                  worktable_breadth: "500.00",
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: false,
                },
                {
                  worktable_id: 387,
                  worktable_name: '["VX500","W",2]',
                  worktable_length: null,
                  worktable_breadth: null,
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 8,
                  is_linked: false,
                },
                {
                  worktable_id: 388,
                  worktable_name: '["VX500","W",3]',
                  worktable_length: null,
                  worktable_breadth: null,
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 8,
                  is_linked: false,
                },
              ],
            },
            {
              id: 278,
              max_spindle_speed: "543.00",
              spindle_speed_unit: 1,
              max_spindle_power: "567.00",
              spindle_power_unit: 1,
              machine: 265,
              worktable: [
                {
                  worktable_id: 389,
                  worktable_name: '["VX500","W",4]',
                  worktable_length: null,
                  worktable_breadth: null,
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 8,
                  is_linked: false,
                },
                {
                  worktable_id: 390,
                  worktable_name: '["VX500","W",5]',
                  worktable_length: null,
                  worktable_breadth: null,
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 8,
                  is_linked: false,
                },
              ],
            },
            {
              id: 279,
              max_spindle_speed: "654.00",
              spindle_speed_unit: 1,
              max_spindle_power: "345.00",
              spindle_power_unit: 1,
              machine: 265,
              worktable: [
                {
                  worktable_id: 386,
                  worktable_name: '["VX500","W",1]',
                  worktable_length: "1020.00",
                  worktable_breadth: "500.00",
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: true,
                },
                {
                  worktable_id: 387,
                  worktable_name: '["VX500","W",2]',
                  worktable_length: null,
                  worktable_breadth: null,
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 8,
                  is_linked: true,
                },
                {
                  worktable_id: 389,
                  worktable_name: '["VX500","W",4]',
                  worktable_length: null,
                  worktable_breadth: null,
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 8,
                  is_linked: true,
                },
              ],
            },
          ],
          cutting_feed_upper: "40000.00",
          cutting_feed_lower: "-40000.00",
          cutting_feed_unit: 1,
          number_of_worktables: 5,
          number_of_tool_library: 2,
          tool_cutting_library: [
            {
              id: 4,
              file_name: "Cutting performance - potential time savings.xlsx",
              organization_id: 1,
              uuid_field: "00bcd287-4dde-4577-aab9-3241f0808683.xlsx",
              tool_library_url:
                "https://tool-library.s3.amazonaws.com/00bcd287-4dde-4577-aab9-3241f0808683.xlsx?AWSAccessKeyId=AKIA5HP5SJY7XQ5ZUIVF&Signature=9eF9sfJWhWnLpcbNfPZLGhq1eJw%3D&Expires=1729249887",
              size: 87,
            },
            {
              id: 7,
              file_name: "machin-July 12th 2024, 2_30_27 am.csv",
              organization_id: 1,
              uuid_field: "3de33852-356b-466c-8ae6-6dae40313b4a.xlsx",
              tool_library_url:
                "https://tool-library.s3.amazonaws.com/3de33852-356b-466c-8ae6-6dae40313b4a.xlsx?AWSAccessKeyId=AKIA5HP5SJY7XQ5ZUIVF&Signature=oFConetBuXN40Ve5%2FzgovSC9DA0%3D&Expires=1729249887",
              size: 2,
            },
          ],
          status: 1,
          execution: null,
          connection_detail_machine: [
            {
              machine: 265,
              ip_address: "197.86.98.87",
              tcp_port: 9876,
              protocol: 6,
              controller: 8,
              data_communication_check: true,
              internet_connectivity_check: true,
              data_transmission_check: true,
            },
          ],
        },
        {
          id: 1,
          machine_name: "Liz DV100",
          organization_id: 1,
          updated_at: "2024-09-06 03:15:57",
          estimated_time: "2024-07-14 02:43:56",
          machine_type: 2,
          machine_sub_type: 13,
          updated_by_user: "Daniel Radcliffe",
          number_of_axes: 3,
          number_of_spindles: 3,
          machine_spindle: [
            {
              id: 277,
              max_spindle_speed: "10000.00",
              spindle_speed_unit: 1,
              max_spindle_power: "234455.00",
              spindle_power_unit: 1,
              machine: 265,
              worktable: [
                {
                  worktable_id: 386,
                  worktable_name: '["VX500","W",1]',
                  worktable_length: "1020.00",
                  worktable_breadth: "500.00",
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "40000.00",
          cutting_feed_lower: "-40000.00",
          cutting_feed_unit: 1,
          number_of_worktables: 5,
          number_of_tool_library: 2,
          tool_cutting_library: [
            {
              id: 4,
              file_name: "Cutting performance - potential time savings.xlsx",
              organization_id: 1,
              uuid_field: "00bcd287-4dde-4577-aab9-3241f0808683.xlsx",
              tool_library_url:
                "https://tool-library.s3.amazonaws.com/00bcd287-4dde-4577-aab9-3241f0808683.xlsx?AWSAccessKeyId=AKIA5HP5SJY7XQ5ZUIVF&Signature=9eF9sfJWhWnLpcbNfPZLGhq1eJw%3D&Expires=1729249887",
              size: 87,
            },
            {
              id: 7,
              file_name: "machin-July 12th 2024, 2_30_27 am.csv",
              organization_id: 1,
              uuid_field: "3de33852-356b-466c-8ae6-6dae40313b4a.xlsx",
              tool_library_url:
                "https://tool-library.s3.amazonaws.com/3de33852-356b-466c-8ae6-6dae40313b4a.xlsx?AWSAccessKeyId=AKIA5HP5SJY7XQ5ZUIVF&Signature=oFConetBuXN40Ve5%2FzgovSC9DA0%3D&Expires=1729249887",
              size: 2,
            },
          ],
          status: 1,
          execution: null,
          connection_detail_machine: [
            {
              machine: 265,
              ip_address: "197.86.98.87",
              tcp_port: 9876,
              protocol: 6,
              controller: 8,
              data_communication_check: true,
              internet_connectivity_check: true,
              data_transmission_check: true,
            },
          ],
        },
        {
          id: 2,
          machine_name: "tesla-test-1",
          organization_id: 1,
          updated_at: "2024-09-06 05:13:06",
          estimated_time: "2024-07-11 05:23:08",
          machine_type: 2,
          machine_sub_type: 13,
          updated_by_user: "Daniel Radcliffe",
          number_of_axes: 3,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 220,
              max_spindle_speed: "26400.00",
              spindle_speed_unit: 1,
              max_spindle_power: "10000.00",
              spindle_power_unit: 1,
              machine: 208,
              worktable: [
                {
                  worktable_id: 276,
                  worktable_name: '["DMU 50","W",1]',
                  worktable_length: "630.00",
                  worktable_breadth: null,
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 2,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "1000.00",
          cutting_feed_lower: "1.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: null,
          connection_detail_machine: [
            {
              machine: 208,
              ip_address: "1.1.1.1",
              tcp_port: 111,
              protocol: 1,
              controller: 6,
              data_communication_check: true,
              internet_connectivity_check: true,
              data_transmission_check: true,
            },
          ],
        },
        {
          id: 389,
          machine_name: "1000 LC",
          organization_id: 1,
          updated_at: "2024-12-07 06:13:09",
          estimated_time: null,
          machine_type: 2,
          machine_sub_type: 13,
          updated_by_user: "Sukhpreet Singh",
          number_of_axes: 3,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 406,
              max_spindle_speed: "3000.00",
              spindle_speed_unit: 1,
              max_spindle_power: "10.00",
              spindle_power_unit: 1,
              machine: 389,
              worktable: [
                {
                  worktable_id: 475,
                  worktable_name: '["1000 LC","W",1]',
                  worktable_length: "39.37",
                  worktable_breadth: "35.43",
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "10000.00",
          cutting_feed_lower: "0.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: null,
          connection_detail_machine: [],
        },
        {
          id: 407,
          machine_name: "1054-3V",
          organization_id: 1,
          updated_at: "2024-12-07 06:13:28",
          estimated_time: null,
          machine_type: 2,
          machine_sub_type: 13,
          updated_by_user: "Sukhpreet Singh",
          number_of_axes: 3,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 424,
              max_spindle_speed: "4200.00",
              spindle_speed_unit: 1,
              max_spindle_power: "3.00",
              spindle_power_unit: 1,
              machine: 407,
              worktable: [
                {
                  worktable_id: 493,
                  worktable_name: '["1054-3V","W",1]',
                  worktable_length: "54.00",
                  worktable_breadth: "10.00",
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "10000.00",
          cutting_feed_lower: "0.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: null,
          connection_detail_machine: [],
        },
        {
          id: 405,
          machine_name: "1152-3V",
          organization_id: 1,
          updated_at: "2024-12-07 06:13:26",
          estimated_time: null,
          machine_type: 2,
          machine_sub_type: 13,
          updated_by_user: "Sukhpreet Singh",
          number_of_axes: 3,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 422,
              max_spindle_speed: "4200.00",
              spindle_speed_unit: 1,
              max_spindle_power: "3.00",
              spindle_power_unit: 1,
              machine: 405,
              worktable: [
                {
                  worktable_id: 491,
                  worktable_name: '["1152-3V","W",1]',
                  worktable_length: "52.00",
                  worktable_breadth: "11.00",
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "10000.00",
          cutting_feed_lower: "0.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: null,
          connection_detail_machine: [],
        },
        {
          id: 403,
          machine_name: "1152-4V",
          organization_id: 1,
          updated_at: "2024-12-07 06:13:24",
          estimated_time: null,
          machine_type: 2,
          machine_sub_type: 13,
          updated_by_user: "Sukhpreet Singh",
          number_of_axes: 3,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 420,
              max_spindle_speed: "3600.00",
              spindle_speed_unit: 1,
              max_spindle_power: "4.00",
              spindle_power_unit: 1,
              machine: 403,
              worktable: [
                {
                  worktable_id: 489,
                  worktable_name: '["1152-4V","W",1]',
                  worktable_length: "52.00",
                  worktable_breadth: "11.00",
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "10000.00",
          cutting_feed_lower: "0.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: null,
          connection_detail_machine: [],
        },
        {
          id: 406,
          machine_name: "1152-5V",
          organization_id: 1,
          updated_at: "2024-12-07 06:13:27",
          estimated_time: null,
          machine_type: 2,
          machine_sub_type: 13,
          updated_by_user: "Sukhpreet Singh",
          number_of_axes: 3,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 423,
              max_spindle_speed: "3600.00",
              spindle_speed_unit: 1,
              max_spindle_power: "5.00",
              spindle_power_unit: 1,
              machine: 406,
              worktable: [
                {
                  worktable_id: 492,
                  worktable_name: '["1152-5V","W",1]',
                  worktable_length: "52.00",
                  worktable_breadth: "11.00",
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "10000.00",
          cutting_feed_lower: "0.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: null,
          connection_detail_machine: [],
        },
        {
          id: 388,
          machine_name: "1500",
          organization_id: 1,
          updated_at: "2024-12-07 06:13:08",
          estimated_time: null,
          machine_type: 2,
          machine_sub_type: 13,
          updated_by_user: "Sukhpreet Singh",
          number_of_axes: 3,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 405,
              max_spindle_speed: "2500.00",
              spindle_speed_unit: 1,
              max_spindle_power: "14.00",
              spindle_power_unit: 1,
              machine: 388,
              worktable: [
                {
                  worktable_id: 474,
                  worktable_name: '["1500","W",1]',
                  worktable_length: "59.05",
                  worktable_breadth: "37.40",
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "10000.00",
          cutting_feed_lower: "0.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: null,
          connection_detail_machine: [],
        },
        {
          id: 384,
          machine_name: "2000",
          organization_id: 1,
          updated_at: "2024-12-07 06:13:03",
          estimated_time: null,
          machine_type: 2,
          machine_sub_type: 13,
          updated_by_user: "Sukhpreet Singh",
          number_of_axes: 3,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 401,
              max_spindle_speed: "2500.00",
              spindle_speed_unit: 1,
              max_spindle_power: "14.00",
              spindle_power_unit: 1,
              machine: 384,
              worktable: [
                {
                  worktable_id: 470,
                  worktable_name: '["2000","W",1]',
                  worktable_length: "78.74",
                  worktable_breadth: "37.40",
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "10000.00",
          cutting_feed_lower: "0.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: null,
          connection_detail_machine: [],
        },
        {
          id: 390,
          machine_name: "2000L",
          organization_id: 1,
          updated_at: "2024-12-07 06:13:10",
          estimated_time: null,
          machine_type: 2,
          machine_sub_type: 13,
          updated_by_user: "Sukhpreet Singh",
          number_of_axes: 3,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 407,
              max_spindle_speed: "2500.00",
              spindle_speed_unit: 1,
              max_spindle_power: "14.00",
              spindle_power_unit: 1,
              machine: 390,
              worktable: [
                {
                  worktable_id: 476,
                  worktable_name: '["2000L","W",1]',
                  worktable_length: "78.74",
                  worktable_breadth: "55.10",
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "10000.00",
          cutting_feed_lower: "0.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: null,
          connection_detail_machine: [],
        },
        {
          id: 345,
          machine_name: "3000",
          organization_id: 1,
          updated_at: "2024-12-07 06:09:53",
          estimated_time: null,
          machine_type: 2,
          machine_sub_type: 13,
          updated_by_user: "Sukhpreet Singh",
          number_of_axes: 3,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 362,
              max_spindle_speed: "2500.00",
              spindle_speed_unit: 1,
              max_spindle_power: "14.00",
              spindle_power_unit: 1,
              machine: 345,
              worktable: [
                {
                  worktable_id: 431,
                  worktable_name: '["3000","W",1]',
                  worktable_length: "118.11",
                  worktable_breadth: "37.40",
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "394.00",
          cutting_feed_lower: "0.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: null,
          connection_detail_machine: [],
        },
        {
          id: 344,
          machine_name: "3000L",
          organization_id: 1,
          updated_at: "2024-12-07 06:09:51",
          estimated_time: null,
          machine_type: 2,
          machine_sub_type: 13,
          updated_by_user: "Sukhpreet Singh",
          number_of_axes: 3,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 361,
              max_spindle_speed: "2500.00",
              spindle_speed_unit: 1,
              max_spindle_power: "14.00",
              spindle_power_unit: 1,
              machine: 344,
              worktable: [
                {
                  worktable_id: 430,
                  worktable_name: '["3000L","W",1]',
                  worktable_length: "118.11",
                  worktable_breadth: "55.10",
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "394.00",
          cutting_feed_lower: "0.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: null,
          connection_detail_machine: [],
        },
        {
          id: 404,
          machine_name: "942-3V",
          organization_id: 1,
          updated_at: "2024-12-07 06:13:25",
          estimated_time: null,
          machine_type: 2,
          machine_sub_type: 13,
          updated_by_user: "Sukhpreet Singh",
          number_of_axes: 3,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 421,
              max_spindle_speed: "4200.00",
              spindle_speed_unit: 1,
              max_spindle_power: "3.00",
              spindle_power_unit: 1,
              machine: 404,
              worktable: [
                {
                  worktable_id: 490,
                  worktable_name: '["942-3V","W",1]',
                  worktable_length: "42.00",
                  worktable_breadth: "9.00",
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "10000.00",
          cutting_feed_lower: "0.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: null,
          connection_detail_machine: [],
        },
        {
          id: 402,
          machine_name: "949-3V",
          organization_id: 1,
          updated_at: "2024-12-07 06:13:22",
          estimated_time: null,
          machine_type: 2,
          machine_sub_type: 13,
          updated_by_user: "Sukhpreet Singh",
          number_of_axes: 3,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 419,
              max_spindle_speed: "4200.00",
              spindle_speed_unit: 1,
              max_spindle_power: "3.00",
              spindle_power_unit: 1,
              machine: 402,
              worktable: [
                {
                  worktable_id: 488,
                  worktable_name: '["949-3V","W",1]',
                  worktable_length: "49.00",
                  worktable_breadth: "9.00",
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "10000.00",
          cutting_feed_lower: "0.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: null,
          connection_detail_machine: [],
        },
        {
          id: 425,
          machine_name: "A-5",
          organization_id: 1,
          updated_at: "2024-12-07 06:13:47",
          estimated_time: null,
          machine_type: 2,
          machine_sub_type: 13,
          updated_by_user: "Sukhpreet Singh",
          number_of_axes: 3,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 442,
              max_spindle_speed: "6000.00",
              spindle_speed_unit: 1,
              max_spindle_power: "25.00",
              spindle_power_unit: 1,
              machine: 425,
              worktable: [
                {
                  worktable_id: 511,
                  worktable_name: '["A-5","W",1]',
                  worktable_length: "51.00",
                  worktable_breadth: "28.00",
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "10000.00",
          cutting_feed_lower: "0.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: null,
          connection_detail_machine: [],
        },
        {
          id: 419,
          machine_name: "BAZ 15 CNC",
          organization_id: 1,
          updated_at: "2024-12-07 06:13:41",
          estimated_time: null,
          machine_type: 2,
          machine_sub_type: 13,
          updated_by_user: "Sukhpreet Singh",
          number_of_axes: 3,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 436,
              max_spindle_speed: "8000.00",
              spindle_speed_unit: 1,
              max_spindle_power: "10.00",
              spindle_power_unit: 1,
              machine: 419,
              worktable: [
                {
                  worktable_id: 505,
                  worktable_name: '["BAZ 15 CNC","W",1]',
                  worktable_length: "29.53",
                  worktable_breadth: "17.72",
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "10000.00",
          cutting_feed_lower: "0.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: null,
          connection_detail_machine: [],
        },
        {
          id: 414,
          machine_name: "BAZ 35 CNC",
          organization_id: 1,
          updated_at: "2024-12-07 06:13:35",
          estimated_time: null,
          machine_type: 2,
          machine_sub_type: 13,
          updated_by_user: "Sukhpreet Singh",
          number_of_axes: 3,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 431,
              max_spindle_speed: "4000.00",
              spindle_speed_unit: 1,
              max_spindle_power: "22.00",
              spindle_power_unit: 1,
              machine: 414,
              worktable: [
                {
                  worktable_id: 500,
                  worktable_name: '["BAZ 35 CNC","W",1]',
                  worktable_length: "33.46",
                  worktable_breadth: "24.80",
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "10000.00",
          cutting_feed_lower: "0.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: null,
          connection_detail_machine: [],
        },
        {
          id: 418,
          machine_name: "BAZ 35 CNC LB",
          organization_id: 1,
          updated_at: "2024-12-07 06:13:40",
          estimated_time: null,
          machine_type: 2,
          machine_sub_type: 13,
          updated_by_user: "Sukhpreet Singh",
          number_of_axes: 3,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 435,
              max_spindle_speed: "4000.00",
              spindle_speed_unit: 1,
              max_spindle_power: "22.00",
              spindle_power_unit: 1,
              machine: 418,
              worktable: [
                {
                  worktable_id: 504,
                  worktable_name: '["BAZ 35 CNC LB","W",1]',
                  worktable_length: "104.00",
                  worktable_breadth: "22.05",
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "10000.00",
          cutting_feed_lower: "0.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: null,
          connection_detail_machine: [],
        },
        {
          id: 280,
          machine_name: "CNC-17",
          organization_id: 1,
          updated_at: "2024-12-06 07:05:21",
          estimated_time: "2024-12-08 06:24:11",
          machine_type: 2,
          machine_sub_type: 13,
          updated_by_user: "Daniel Radcliffe",
          number_of_axes: 3,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 297,
              max_spindle_speed: "12000.00",
              spindle_speed_unit: 1,
              max_spindle_power: "1000.00",
              spindle_power_unit: 1,
              machine: 280,
              worktable: [
                {
                  worktable_id: 417,
                  worktable_name: '["CNC-17","W",1]',
                  worktable_length: "600.00",
                  worktable_breadth: "500.00",
                  worktable_height: "450.00",
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "787.40",
          cutting_feed_lower: "0.04",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: null,
          connection_detail_machine: [
            {
              machine: 280,
              ip_address: "192.14.25.6",
              tcp_port: 8193,
              protocol: 1,
              controller: 1,
              data_communication_check: true,
              internet_connectivity_check: true,
              data_transmission_check: true,
            },
          ],
        },
        {
          id: 401,
          machine_name: "CNC-WOLVERINE",
          organization_id: 1,
          updated_at: "2024-12-07 06:13:21",
          estimated_time: null,
          machine_type: 2,
          machine_sub_type: 13,
          updated_by_user: "Sukhpreet Singh",
          number_of_axes: 3,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 418,
              max_spindle_speed: "5200.00",
              spindle_speed_unit: 1,
              max_spindle_power: "1.00",
              spindle_power_unit: 1,
              machine: 401,
              worktable: [
                {
                  worktable_id: 487,
                  worktable_name: '["CNC-WOLVERINE","W",1]',
                  worktable_length: "12.00",
                  worktable_breadth: "6.00",
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "10000.00",
          cutting_feed_lower: "0.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: null,
          connection_detail_machine: [],
        },
        {
          id: 421,
          machine_name: "CS 1000",
          organization_id: 1,
          updated_at: "2024-12-07 06:13:43",
          estimated_time: null,
          machine_type: 2,
          machine_sub_type: 13,
          updated_by_user: "Sukhpreet Singh",
          number_of_axes: 3,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 438,
              max_spindle_speed: "8000.00",
              spindle_speed_unit: 1,
              max_spindle_power: "17.00",
              spindle_power_unit: 1,
              machine: 421,
              worktable: [
                {
                  worktable_id: 507,
                  worktable_name: '["CS 1000","W",1]',
                  worktable_length: "47.24",
                  worktable_breadth: "19.60",
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "10000.00",
          cutting_feed_lower: "0.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: null,
          connection_detail_machine: [],
        },
        {
          id: 422,
          machine_name: "CS 1200",
          organization_id: 1,
          updated_at: "2024-12-07 06:13:44",
          estimated_time: null,
          machine_type: 2,
          machine_sub_type: 13,
          updated_by_user: "Sukhpreet Singh",
          number_of_axes: 3,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 439,
              max_spindle_speed: "9000.00",
              spindle_speed_unit: 1,
              max_spindle_power: "17.00",
              spindle_power_unit: 1,
              machine: 422,
              worktable: [
                {
                  worktable_id: 508,
                  worktable_name: '["CS 1200","W",1]',
                  worktable_length: "49.21",
                  worktable_breadth: "24.80",
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "10000.00",
          cutting_feed_lower: "0.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: null,
          connection_detail_machine: [],
        },
        {
          id: 412,
          machine_name: "CS 600",
          organization_id: 1,
          updated_at: "2024-12-07 06:13:33",
          estimated_time: null,
          machine_type: 2,
          machine_sub_type: 13,
          updated_by_user: "Sukhpreet Singh",
          number_of_axes: 3,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 429,
              max_spindle_speed: "9000.00",
              spindle_speed_unit: 1,
              max_spindle_power: "17.00",
              spindle_power_unit: 1,
              machine: 412,
              worktable: [
                {
                  worktable_id: 498,
                  worktable_name: '["CS 600","W",1]',
                  worktable_length: "29.52",
                  worktable_breadth: "17.71",
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "10000.00",
          cutting_feed_lower: "0.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: null,
          connection_detail_machine: [],
        },
        {
          id: 409,
          machine_name: "CU 1007",
          organization_id: 1,
          updated_at: "2024-12-07 06:13:30",
          estimated_time: null,
          machine_type: 2,
          machine_sub_type: 13,
          updated_by_user: "Sukhpreet Singh",
          number_of_axes: 3,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 426,
              max_spindle_speed: "30000.00",
              spindle_speed_unit: 1,
              max_spindle_power: "4.00",
              spindle_power_unit: 1,
              machine: 409,
              worktable: [
                {
                  worktable_id: 495,
                  worktable_name: '["CU 1007","W",1]',
                  worktable_length: "5.00",
                  worktable_breadth: "5.00",
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "10000.00",
          cutting_feed_lower: "0.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: null,
          connection_detail_machine: [],
        },
        {
          id: 410,
          machine_name: "CU 2007",
          organization_id: 1,
          updated_at: "2024-12-07 06:13:31",
          estimated_time: null,
          machine_type: 2,
          machine_sub_type: 13,
          updated_by_user: "Sukhpreet Singh",
          number_of_axes: 3,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 427,
              max_spindle_speed: "12000.00",
              spindle_speed_unit: 1,
              max_spindle_power: "4.90",
              spindle_power_unit: 1,
              machine: 410,
              worktable: [
                {
                  worktable_id: 496,
                  worktable_name: '["CU 2007","W",1]',
                  worktable_length: "25.59",
                  worktable_breadth: "15.70",
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "10000.00",
          cutting_feed_lower: "0.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: null,
          connection_detail_machine: [],
        },
        {
          id: 408,
          machine_name: "CU 3005",
          organization_id: 1,
          updated_at: "2024-12-07 06:13:29",
          estimated_time: null,
          machine_type: 2,
          machine_sub_type: 13,
          updated_by_user: "Sukhpreet Singh",
          number_of_axes: 3,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 425,
              max_spindle_speed: "30000.00",
              spindle_speed_unit: 1,
              max_spindle_power: "2.70",
              spindle_power_unit: 1,
              machine: 408,
              worktable: [
                {
                  worktable_id: 494,
                  worktable_name: '["CU 3005","W",1]',
                  worktable_length: "13.77",
                  worktable_breadth: "8.98",
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "10000.00",
          cutting_feed_lower: "0.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: null,
          connection_detail_machine: [],
        },
        {
          id: 411,
          machine_name: "CU 3007",
          organization_id: 1,
          updated_at: "2024-12-07 06:13:32",
          estimated_time: null,
          machine_type: 2,
          machine_sub_type: 13,
          updated_by_user: "Sukhpreet Singh",
          number_of_axes: 3,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 428,
              max_spindle_speed: "12000.00",
              spindle_speed_unit: 1,
              max_spindle_power: "4.90",
              spindle_power_unit: 1,
              machine: 411,
              worktable: [
                {
                  worktable_id: 497,
                  worktable_name: '["CU 3007","W",1]',
                  worktable_length: "33.46",
                  worktable_breadth: "15.70",
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "10000.00",
          cutting_feed_lower: "0.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: null,
          connection_detail_machine: [],
        },
      ];
    case machineTypeId === 2 && machineSubtypeId === 12 && numberOfAxis === 5:
      return [
        {
          id: 207,
          machine_name: "Bavius Aerocell 200 I 400",
          organization_id: 1,
          updated_at: "2024-09-09 06:51:54",
          estimated_time: "2024-07-11 05:10:27",
          machine_type: 2,
          machine_sub_type: 12,
          updated_by_user: "Daniel Radcliffe",
          number_of_axes: 5,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 219,
              max_spindle_speed: "30000.00",
              spindle_speed_unit: 1,
              max_spindle_power: "1.32",
              spindle_power_unit: 1,
              machine: 207,
              worktable: [
                {
                  worktable_id: 275,
                  worktable_name: '["Bavius Aerocell 200 I 400","W",1]',
                  worktable_length: "4000.00",
                  worktable_breadth: "2000.00",
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "121.00",
          cutting_feed_lower: "2.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 1,
          tool_cutting_library: [
            {
              id: 5,
              file_name: "sample-tool-library-file.xlsx",
              organization_id: 1,
              uuid_field: "27514b97-f279-4b86-a457-be1fdccb47c0.xlsx",
              tool_library_url:
                "https://tool-library.s3.amazonaws.com/27514b97-f279-4b86-a457-be1fdccb47c0.xlsx?AWSAccessKeyId=AKIA5HP5SJY7XQ5ZUIVF&Signature=hYr%2BgOoms%2FQCbhsfp4vYNtkwWa4%3D&Expires=1729250646",
              size: 1492,
            },
          ],
          status: 1,
          execution: null,
          connection_detail_machine: [
            {
              machine: 207,
              ip_address: "123.123.123.123",
              tcp_port: 44818,
              protocol: 6,
              controller: 3,
              data_communication_check: true,
              internet_connectivity_check: true,
              data_transmission_check: true,
            },
          ],
        },
        {
          id: 270,
          machine_name: "HELLER_HF5500",
          organization_id: 1,
          updated_at: "2024-09-09 07:31:58",
          estimated_time: "2024-09-11 06:53:21",
          machine_type: 2,
          machine_sub_type: 12,
          updated_by_user: "Daniel Radcliffe",
          number_of_axes: 5,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 287,
              max_spindle_speed: "13000.00",
              spindle_speed_unit: 1,
              max_spindle_power: "2.00",
              spindle_power_unit: 1,
              machine: 270,
              worktable: [
                {
                  worktable_id: 407,
                  worktable_name: '["HELLER_HF5500","W",1]',
                  worktable_length: "500.38",
                  worktable_breadth: "629.92",
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "2.00",
          cutting_feed_lower: "2.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: null,
          connection_detail_machine: [
            {
              machine: 270,
              ip_address: "123.123.123.123",
              tcp_port: 44818,
              protocol: 6,
              controller: 3,
              data_communication_check: true,
              internet_connectivity_check: true,
              data_transmission_check: true,
            },
          ],
        },
        {
          id: 45,
          machine_name: "NL300005_007",
          organization_id: 1,
          updated_at: "2024-10-03 18:03:58",
          estimated_time: "2024-06-22 19:09:35",
          machine_type: 2,
          machine_sub_type: 12,
          updated_by_user: "Daniel Radcliffe",
          number_of_axes: 5,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 43,
              max_spindle_speed: "20000.00",
              spindle_speed_unit: 1,
              max_spindle_power: "20000.00",
              spindle_power_unit: 1,
              machine: 45,
              worktable: [
                {
                  worktable_id: 43,
                  worktable_name: '["NL300005_007","W",1]',
                  worktable_length: null,
                  worktable_breadth: null,
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "10000.00",
          cutting_feed_lower: "2000.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: "READY",
          connection_detail_machine: [
            {
              machine: 45,
              ip_address: "192.168.24.24",
              tcp_port: 1223,
              protocol: 1,
              controller: 1,
              data_communication_check: true,
              internet_connectivity_check: true,
              data_transmission_check: true,
            },
          ],
        },
        {
          id: 48,
          machine_name: "NMV_001",
          organization_id: 1,
          updated_at: "2024-10-17 07:23:34",
          estimated_time: "2024-06-22 19:09:49",
          machine_type: 2,
          machine_sub_type: 12,
          updated_by_user: "Daniel Radcliffe",
          number_of_axes: 5,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 46,
              max_spindle_speed: "20000.00",
              spindle_speed_unit: 1,
              max_spindle_power: "150000.00",
              spindle_power_unit: 1,
              machine: 48,
              worktable: [
                {
                  worktable_id: 46,
                  worktable_name: '["NMV_001","W",1]',
                  worktable_length: null,
                  worktable_breadth: null,
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "10000.00",
          cutting_feed_lower: "0.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: "ACTIVE",
          connection_detail_machine: [
            {
              machine: 48,
              ip_address: "192.168.24.23",
              tcp_port: 1223,
              protocol: 1,
              controller: 1,
              data_communication_check: true,
              internet_connectivity_check: true,
              data_transmission_check: true,
            },
          ],
        },
        {
          id: 480,
          machine_name: "GROB-G550",
          organization_id: 1,
          updated_at: "2024-10-17 07:23:34",
          estimated_time: "2024-06-22 19:09:49",
          machine_type: 2,
          machine_sub_type: 12,
          updated_by_user: "Daniel Radcliffe",
          number_of_axes: 5,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 46,
              max_spindle_speed: "12000.00",
              spindle_speed_unit: 1,
              max_spindle_power: "150000.00",
              spindle_power_unit: 1,
              machine: 48,
              worktable: [
                {
                  worktable_id: 46,
                  worktable_name: '["NMV_001","W",1]',
                  worktable_length: null,
                  worktable_breadth: null,
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "10000.00",
          cutting_feed_lower: "0.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: "ACTIVE",
          connection_detail_machine: [
            {
              machine: 48,
              ip_address: "192.168.24.23",
              tcp_port: 1223,
              protocol: 1,
              controller: 1,
              data_communication_check: true,
              internet_connectivity_check: true,
              data_transmission_check: true,
            },
          ],
        },
        {
          id: 580,
          machine_name: "GROB-G500",
          organization_id: 1,
          updated_at: "2024-10-17 07:23:34",
          estimated_time: "2024-06-22 19:09:49",
          machine_type: 2,
          machine_sub_type: 12,
          updated_by_user: "Daniel Radcliffe",
          number_of_axes: 5,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 46,
              max_spindle_speed: "18000.00",
              spindle_speed_unit: 1,
              max_spindle_power: "150000.00",
              spindle_power_unit: 1,
              machine: 48,
              worktable: [
                {
                  worktable_id: 46,
                  worktable_name: '["NMV_001","W",1]',
                  worktable_length: null,
                  worktable_breadth: null,
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "10000.00",
          cutting_feed_lower: "0.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: "ACTIVE",
          connection_detail_machine: [
            {
              machine: 48,
              ip_address: "192.168.24.23",
              tcp_port: 1223,
              protocol: 1,
              controller: 1,
              data_communication_check: true,
              internet_connectivity_check: true,
              data_transmission_check: true,
            },
          ],
        },
        {
          id: 680,
          machine_name: "tesla-test-2",
          organization_id: 1,
          updated_at: "2024-10-03 18:03:58",
          estimated_time: "2024-06-22 19:09:35",
          machine_type: 2,
          machine_sub_type: 12,
          updated_by_user: "Daniel Radcliffe",
          number_of_axes: 5,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 43,
              max_spindle_speed: "26400.00",
              spindle_speed_unit: 1,
              max_spindle_power: "20000.00",
              spindle_power_unit: 1,
              machine: 45,
              worktable: [
                {
                  worktable_id: 43,
                  worktable_name: '["NL300005_007","W",1]',
                  worktable_length: null,
                  worktable_breadth: null,
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "10000.00",
          cutting_feed_lower: "2000.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: "READY",
          connection_detail_machine: [
            {
              machine: 45,
              ip_address: "192.168.24.24",
              tcp_port: 1223,
              protocol: 1,
              controller: 1,
              data_communication_check: true,
              internet_connectivity_check: true,
              data_transmission_check: true,
            },
          ],
        },
        {
          id: 399,
          machine_name: "CNC - AS600",
          organization_id: 1,
          updated_at: "2024-12-07 06:13:19",
          estimated_time: null,
          machine_type: 2,
          machine_sub_type: 12,
          updated_by_user: "Sukhpreet Singh",
          number_of_axes: 5,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 416,
              max_spindle_speed: "16000.00",
              spindle_speed_unit: 1,
              max_spindle_power: "48.00",
              spindle_power_unit: 1,
              machine: 399,
              worktable: [
                {
                  worktable_id: 485,
                  worktable_name: '["CNC - AS600","W",1]',
                  worktable_length: "25.50",
                  worktable_breadth: "25.50",
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "10000.00",
          cutting_feed_lower: "0.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: null,
          connection_detail_machine: [],
        },
        {
          id: 431,
          machine_name: "New CNC - AS600",
          organization_id: 1,
          updated_at: "2024-12-07 06:42:58",
          estimated_time: null,
          machine_type: 2,
          machine_sub_type: 12,
          updated_by_user: "Daniel Radcliffe",
          number_of_axes: 5,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 477,
              max_spindle_speed: "16000.00",
              spindle_speed_unit: 1,
              max_spindle_power: "36.00",
              spindle_power_unit: 1,
              machine: 431,
              worktable: [
                {
                  worktable_id: 516,
                  worktable_name: '["New CNC - AS600","W",1]',
                  worktable_length: "630.00",
                  worktable_breadth: null,
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "10000.00",
          cutting_feed_lower: "0.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: null,
          connection_detail_machine: [],
        },
        {
          id: 396,
          machine_name: "RFM 1000S",
          organization_id: 1,
          updated_at: "2024-12-07 06:13:16",
          estimated_time: null,
          machine_type: 2,
          machine_sub_type: 12,
          updated_by_user: "Sukhpreet Singh",
          number_of_axes: 5,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 413,
              max_spindle_speed: "30000.00",
              spindle_speed_unit: 1,
              max_spindle_power: "5.00",
              spindle_power_unit: 1,
              machine: 396,
              worktable: [
                {
                  worktable_id: 482,
                  worktable_name: '["RFM 1000S","W",1]',
                  worktable_length: "33.86",
                  worktable_breadth: "25.59",
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "10000.00",
          cutting_feed_lower: "0.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: null,
          connection_detail_machine: [],
        },
        {
          id: 395,
          machine_name: "RFM 760S",
          organization_id: 1,
          updated_at: "2024-12-07 06:13:15",
          estimated_time: null,
          machine_type: 2,
          machine_sub_type: 12,
          updated_by_user: "Sukhpreet Singh",
          number_of_axes: 5,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 412,
              max_spindle_speed: "30000.00",
              spindle_speed_unit: 1,
              max_spindle_power: "5.00",
              spindle_power_unit: 1,
              machine: 395,
              worktable: [
                {
                  worktable_id: 481,
                  worktable_name: '["RFM 760S","W",1]',
                  worktable_length: "33.86",
                  worktable_breadth: "25.59",
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "10000.00",
          cutting_feed_lower: "0.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: null,
          connection_detail_machine: [],
        },
        {
          id: 382,
          machine_name: "UCP 1150",
          organization_id: 1,
          updated_at: "2024-12-07 06:13:01",
          estimated_time: null,
          machine_type: 2,
          machine_sub_type: 12,
          updated_by_user: "Sukhpreet Singh",
          number_of_axes: 5,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 399,
              max_spindle_speed: "12000.00",
              spindle_speed_unit: 1,
              max_spindle_power: "32.00",
              spindle_power_unit: 1,
              machine: 382,
              worktable: [
                {
                  worktable_id: 468,
                  worktable_name: '["UCP 1150","W",1]',
                  worktable_length: "39.30",
                  worktable_breadth: "39.30",
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "10000.00",
          cutting_feed_lower: "0.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: null,
          connection_detail_machine: [],
        },
      ];
    case machineTypeId === 2 && machineSubtypeId === 13 && numberOfAxis === 5:
      return [
        {
          id: 267,
          machine_name: "CNC-1",
          organization_id: 1,
          updated_at: "2024-09-16 15:57:34",
          estimated_time: "2024-08-30 06:34:21",
          machine_type: 2,
          machine_sub_type: 13,
          updated_by_user: "Daniel Radcliffe",
          number_of_axes: 5,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 284,
              max_spindle_speed: "10000.00",
              spindle_speed_unit: 1,
              max_spindle_power: "10000.00",
              spindle_power_unit: 1,
              machine: 267,
              worktable: [
                {
                  worktable_id: 404,
                  worktable_name: '["CNC-1","W",1]',
                  worktable_length: "2350.00",
                  worktable_breadth: null,
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 2,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "1000.00",
          cutting_feed_lower: "1000.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: null,
          connection_detail_machine: [
            {
              machine: 267,
              ip_address: "203.12.25.26",
              tcp_port: 8193,
              protocol: 1,
              controller: 6,
              data_communication_check: true,
              internet_connectivity_check: true,
              data_transmission_check: true,
            },
          ],
        },
        {
          id: 269,
          machine_name: "DMU-50",
          organization_id: 1,
          updated_at: "2024-09-09 03:08:49",
          estimated_time: "2024-09-11 03:08:48",
          machine_type: 2,
          machine_sub_type: 13,
          updated_by_user: "Daniel Radcliffe",
          number_of_axes: 5,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 286,
              max_spindle_speed: "15001.00",
              spindle_speed_unit: 1,
              max_spindle_power: "10000.00",
              spindle_power_unit: 1,
              machine: 269,
              worktable: [
                {
                  worktable_id: 406,
                  worktable_name: '["DMU-50","W",1]',
                  worktable_length: "630.00",
                  worktable_breadth: "500.00",
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 2,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "1000.00",
          cutting_feed_lower: "1.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: null,
          connection_detail_machine: [
            {
              machine: 269,
              ip_address: "129.98.98.98",
              tcp_port: 7878,
              protocol: 2,
              controller: 3,
              data_communication_check: true,
              internet_connectivity_check: true,
              data_transmission_check: true,
            },
          ],
        },
        {
          id: 268,
          machine_name: "Mazak INTEGREX i",
          organization_id: 1,
          updated_at: "2024-09-06 05:47:53",
          estimated_time: "2024-09-08 05:43:57",
          machine_type: 2,
          machine_sub_type: 13,
          updated_by_user: "Daniel Radcliffe",
          number_of_axes: 5,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 285,
              max_spindle_speed: "10000.00",
              spindle_speed_unit: 1,
              max_spindle_power: "100.00",
              spindle_power_unit: 1,
              machine: 268,
              worktable: [
                {
                  worktable_id: 405,
                  worktable_name: '["Mazak INTEGREX i","W",1]',
                  worktable_length: "1050.00",
                  worktable_breadth: null,
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 2,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "20.00",
          cutting_feed_lower: "10.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: null,
          connection_detail_machine: [
            {
              machine: 268,
              ip_address: "23.123.123.123",
              tcp_port: 44818,
              protocol: 6,
              controller: 3,
              data_communication_check: true,
              internet_connectivity_check: true,
              data_transmission_check: true,
            },
          ],
        },
        {
          id: 47,
          machine_name: "NL300021_012",
          organization_id: 1,
          updated_at: "2024-10-09 11:42:25",
          estimated_time: "2024-06-22 19:09:46",
          machine_type: 2,
          machine_sub_type: 13,
          updated_by_user: "Daniel Radcliffe",
          number_of_axes: 5,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 45,
              max_spindle_speed: "20000.00",
              spindle_speed_unit: 1,
              max_spindle_power: "150000.00",
              spindle_power_unit: 1,
              machine: 47,
              worktable: [
                {
                  worktable_id: 45,
                  worktable_name: '["NL300021_012","W",1]',
                  worktable_length: null,
                  worktable_breadth: null,
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "10000.00",
          cutting_feed_lower: "0.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: "ACTIVE",
          connection_detail_machine: [
            {
              machine: 47,
              ip_address: "192.168.24.18",
              tcp_port: 1234,
              protocol: 1,
              controller: 1,
              data_communication_check: true,
              internet_connectivity_check: true,
              data_transmission_check: true,
            },
          ],
        },
        {
          id: 41,
          machine_name: "SL40309_015",
          organization_id: 1,
          updated_at: "2024-10-17 07:09:04",
          estimated_time: "2024-06-22 19:09:14",
          machine_type: 2,
          machine_sub_type: 13,
          updated_by_user: "Daniel Radcliffe",
          number_of_axes: 5,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 39,
              max_spindle_speed: "20000.00",
              spindle_speed_unit: 1,
              max_spindle_power: "20000.00",
              spindle_power_unit: 1,
              machine: 41,
              worktable: [
                {
                  worktable_id: 39,
                  worktable_name: '["SL40309_015","W",1]',
                  worktable_length: null,
                  worktable_breadth: null,
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "10000.00",
          cutting_feed_lower: "0.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: "READY",
          connection_detail_machine: [
            {
              machine: 41,
              ip_address: "192.168.24.26",
              tcp_port: 1223,
              protocol: 1,
              controller: 1,
              data_communication_check: true,
              internet_connectivity_check: true,
              data_transmission_check: true,
            },
          ],
        },
        {
          id: 417,
          machine_name: "GS 1000",
          organization_id: 1,
          updated_at: "2024-12-07 06:13:39",
          estimated_time: null,
          machine_type: 2,
          machine_sub_type: 13,
          updated_by_user: "Sukhpreet Singh",
          number_of_axes: 5,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 434,
              max_spindle_speed: "12000.00",
              spindle_speed_unit: 1,
              max_spindle_power: "39.00",
              spindle_power_unit: 1,
              machine: 417,
              worktable: [
                {
                  worktable_id: 503,
                  worktable_name: '["GS 1000","W",1]',
                  worktable_length: "36.22",
                  worktable_breadth: "35.43",
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "10000.00",
          cutting_feed_lower: "0.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: null,
          connection_detail_machine: [],
        },
        {
          id: 416,
          machine_name: "GS 1200",
          organization_id: 1,
          updated_at: "2024-12-07 06:13:37",
          estimated_time: null,
          machine_type: 2,
          machine_sub_type: 13,
          updated_by_user: "Sukhpreet Singh",
          number_of_axes: 5,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 433,
              max_spindle_speed: "12000.00",
              spindle_speed_unit: 1,
              max_spindle_power: "39.00",
              spindle_power_unit: 1,
              machine: 416,
              worktable: [
                {
                  worktable_id: 502,
                  worktable_name: '["GS 1200","W",1]',
                  worktable_length: "47.20",
                  worktable_breadth: "43.30",
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "10000.00",
          cutting_feed_lower: "0.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: null,
          connection_detail_machine: [],
        },
        {
          id: 415,
          machine_name: "GS 1400",
          organization_id: 1,
          updated_at: "2024-12-07 06:13:36",
          estimated_time: null,
          machine_type: 2,
          machine_sub_type: 13,
          updated_by_user: "Sukhpreet Singh",
          number_of_axes: 5,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 432,
              max_spindle_speed: "14000.00",
              spindle_speed_unit: 1,
              max_spindle_power: "39.00",
              spindle_power_unit: 1,
              machine: 415,
              worktable: [
                {
                  worktable_id: 501,
                  worktable_name: '["GS 1400","W",1]',
                  worktable_length: "51.18",
                  worktable_breadth: "55.11",
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "10000.00",
          cutting_feed_lower: "0.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: null,
          connection_detail_machine: [],
        },
        {
          id: 420,
          machine_name: "GS 600",
          organization_id: 1,
          updated_at: "2024-12-07 06:13:42",
          estimated_time: null,
          machine_type: 2,
          machine_sub_type: 13,
          updated_by_user: "Sukhpreet Singh",
          number_of_axes: 5,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 437,
              max_spindle_speed: "10000.00",
              spindle_speed_unit: 1,
              max_spindle_power: "21.00",
              spindle_power_unit: 1,
              machine: 420,
              worktable: [
                {
                  worktable_id: 506,
                  worktable_name: '["GS 600","W",1]',
                  worktable_length: "12.60",
                  worktable_breadth: "12.60",
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "10000.00",
          cutting_feed_lower: "0.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: null,
          connection_detail_machine: [],
        },
        {
          id: 413,
          machine_name: "GS 800",
          organization_id: 1,
          updated_at: "2024-12-07 06:13:34",
          estimated_time: null,
          machine_type: 2,
          machine_sub_type: 13,
          updated_by_user: "Sukhpreet Singh",
          number_of_axes: 5,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 430,
              max_spindle_speed: "10000.00",
              spindle_speed_unit: 1,
              max_spindle_power: "21.00",
              spindle_power_unit: 1,
              machine: 413,
              worktable: [
                {
                  worktable_id: 499,
                  worktable_name: '["GS 800","W",1]',
                  worktable_length: "18.50",
                  worktable_breadth: "18.50",
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "10000.00",
          cutting_feed_lower: "0.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: null,
          connection_detail_machine: [],
        },
        {
          id: 368,
          machine_name: "RXP 500 DS",
          organization_id: 1,
          updated_at: "2024-12-07 06:10:22",
          estimated_time: null,
          machine_type: 2,
          machine_sub_type: 13,
          updated_by_user: "Sukhpreet Singh",
          number_of_axes: 5,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 385,
              max_spindle_speed: "42000.00",
              spindle_speed_unit: 1,
              max_spindle_power: "18.77",
              spindle_power_unit: 1,
              machine: 368,
              worktable: [
                {
                  worktable_id: 454,
                  worktable_name: '["RXP 500 DS","W",1]',
                  worktable_length: "10.00",
                  worktable_breadth: "10.00",
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "1575.00",
          cutting_feed_lower: "0.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: null,
          connection_detail_machine: [],
        },
        {
          id: 333,
          machine_name: "UCP 600",
          organization_id: 1,
          updated_at: "2024-12-07 06:09:32",
          estimated_time: null,
          machine_type: 2,
          machine_sub_type: 13,
          updated_by_user: "Sukhpreet Singh",
          number_of_axes: 5,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 350,
              max_spindle_speed: "12000.00",
              spindle_speed_unit: 1,
              max_spindle_power: "20.00",
              spindle_power_unit: 1,
              machine: 333,
              worktable: [
                {
                  worktable_id: 419,
                  worktable_name: '["UCP 600","W",1]',
                  worktable_length: "11.02",
                  worktable_breadth: "11.02",
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "590.00",
          cutting_feed_lower: "0.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: null,
          connection_detail_machine: [],
        },
        {
          id: 338,
          machine_name: "UCP 710",
          organization_id: 1,
          updated_at: "2024-12-07 06:09:38",
          estimated_time: null,
          machine_type: 2,
          machine_sub_type: 13,
          updated_by_user: "Sukhpreet Singh",
          number_of_axes: 5,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 355,
              max_spindle_speed: "10000.00",
              spindle_speed_unit: 1,
              max_spindle_power: "21.00",
              spindle_power_unit: 1,
              machine: 338,
              worktable: [
                {
                  worktable_id: 424,
                  worktable_name: '["UCP 710","W",1]',
                  worktable_length: "23.50",
                  worktable_breadth: "23.50",
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "790.00",
          cutting_feed_lower: "0.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: null,
          connection_detail_machine: [],
        },
        {
          id: 347,
          machine_name: "UM-350",
          organization_id: 1,
          updated_at: "2024-12-07 06:10:00",
          estimated_time: null,
          machine_type: 2,
          machine_sub_type: 13,
          updated_by_user: "Sukhpreet Singh",
          number_of_axes: 5,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 364,
              max_spindle_speed: "12000.00",
              spindle_speed_unit: 1,
              max_spindle_power: "25.00",
              spindle_power_unit: 1,
              machine: 347,
              worktable: [
                {
                  worktable_id: 433,
                  worktable_name: '["UM-350","W",1]',
                  worktable_length: "13.78",
                  worktable_breadth: "13.78",
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "472.00",
          cutting_feed_lower: "0.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: null,
          connection_detail_machine: [],
        },
        {
          id: 352,
          machine_name: "UM-500",
          organization_id: 1,
          updated_at: "2024-12-07 06:10:05",
          estimated_time: null,
          machine_type: 2,
          machine_sub_type: 13,
          updated_by_user: "Sukhpreet Singh",
          number_of_axes: 5,
          number_of_spindles: 1,
          machine_spindle: [
            {
              id: 369,
              max_spindle_speed: "12000.00",
              spindle_speed_unit: 1,
              max_spindle_power: "25.00",
              spindle_power_unit: 1,
              machine: 352,
              worktable: [
                {
                  worktable_id: 438,
                  worktable_name: '["UM-500","W",1]',
                  worktable_length: "23.60",
                  worktable_breadth: "23.60",
                  worktable_height: null,
                  worktable_units: 1,
                  worktable_type: 1,
                  is_linked: false,
                },
              ],
            },
          ],
          cutting_feed_upper: "472.00",
          cutting_feed_lower: "0.00",
          cutting_feed_unit: 1,
          number_of_worktables: 1,
          number_of_tool_library: 0,
          tool_cutting_library: [],
          status: 1,
          execution: null,
          connection_detail_machine: [],
        },
      ];
    default:
      return [];
  }
}
