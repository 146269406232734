import Modal from "components/Modal";
import Table from "components/Table";
import { CUTTING_TOOL_TABLE_COLUMNS, SET_CUTTING_TOOL } from "constant";
import { GlobalContext } from "context/GlobalContext";
import { useContext } from "react";
import { FiX } from "react-icons/fi";
import StyledCuttingToolValue from "./StyledCuttingToolValue";

const staticData = [
  {
    libraryTitle: "3mm R4mm (4mm Radius Mill)",
    libraryLink: "#Milling Tools (Metric)",
    type: "Radius mill",
    cornorRadius: "4mm",
    diameter: "3mm",
    fluteLength: "4mm",
  },
  {
    libraryTitle: "3mm R4mm (4mm Radius Mill)",
    libraryLink: "#Milling Tools (Metric)",
    type: "Radius mill",
    cornorRadius: "4mm",
    diameter: "3mm",
    fluteLength: "4mm",
  },
];

const CuttingToolModal = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const { cuttingToolOpen, cuttingToolContent } = state;

  const handleClose = () => {
    dispatch({
      type: SET_CUTTING_TOOL,
      payload: {
        open: false,
        content: null,
      },
    });
  };

  return (
    <Modal isOpen={cuttingToolOpen} setIsOpen={handleClose} size="large">
      <Modal.Header>
        <div className="flex flex-row items-center justify-between pb-4">
          <h3 className="text-lg font-medium leading-6 text-black-222">
            {cuttingToolContent?.title || ""}
          </h3>
          <FiX
            className="w-6 h-6 cursor-pointer text-black-222"
            onClick={handleClose}
          />
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="border-gray-eac rounded-xl border-solid w-full border">
          <Table className="w-full">
            <Table.Thead>
              <Table.Tr className="!border-solid">
                {CUTTING_TOOL_TABLE_COLUMNS.map((column, idx) => (
                  <Table.Td
                    key={idx}
                    className="!p-0 !border-b !border-solid !border-gray-eac"
                    divClassName="!h-auto !py-3 !bg-white text-xs font-medium"
                  >
                    {column.label}
                  </Table.Td>
                ))}
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {staticData.map((item, idx) => (
                <Table.Tr key={idx} className="!border-solid">
                  {CUTTING_TOOL_TABLE_COLUMNS.map((column, idx1) => (
                    <Table.Td
                      key={idx1}
                      className="!p-0 !border-t !border-solid !border-gray-eac"
                      divClassName="!h-auto !py-4 !bg-white text-sm font-medium"
                    >
                      <StyledCuttingToolValue
                        tool={item}
                        valueKey={column.id}
                      />
                    </Table.Td>
                  ))}
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CuttingToolModal;
