import Button from "components/Button";
import MachiningStrategySummaryBox from "components/MachiningStrategySummaryBox";
import { ACTION_BY_KEY, SET_LOADING_BAR } from "constant";
import { GlobalContext } from "context/GlobalContext";
import { useContext, useEffect, useState } from "react";
import SummaryImg from "assets/images/summary.svg";
import DoubleCheckIcon from "assets/images/icons/double-check.svg";
import { useApiCall } from "hooks";
import { getCuttingToolAPI } from "services";

interface IChangeStrategy {
  setShowChangeCategory?: any;
  selectedItem: any;
  handleStrategy?: any;
}

const ChangeStrategy: React.FC<IChangeStrategy> = ({
  setShowChangeCategory,
  selectedItem,
  handleStrategy,
}) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { projectInfo } = state;
  const [selectedSummary, setSelectedSummary] = useState<any>(
    state[selectedItem?.key][selectedItem?.index]?.selected_strategy
  );
  const data: any =
    state[selectedItem?.key][selectedItem?.index]?.strategy || null;
  const [fetchData]: any = useApiCall(getCuttingToolAPI);
  const handleSummary = (res: any) => {
    setSelectedSummary(res);
  };
  const [strategyData, setStrategyData] = useState<any>(null);

  useEffect(() => {
    if (!selectedItem) {
      setStrategyData(null);
      return;
    }
    const new_data = state[selectedItem?.key][
      selectedItem?.index
    ]?.strategy?.strategies?.[0]?.machining_strategy?.map(
      (item: any, idx: number) => ({ ...item, index: idx })
    );
    setStrategyData(new_data);
    handleSummary({
      ...data?.strategies?.[0]?.machining_strategy?.[0],
      index: 0,
    });
  }, [selectedItem]);

  const handleContinue = () => {
    try {
      dispatch({
        type: SET_LOADING_BAR,
        payload: true,
      });
      if (
        state[selectedItem?.key][selectedItem?.index]?.selected_strategy
          ?.kind === selectedSummary?.kind
      ) {
        dispatch({
          type: SET_LOADING_BAR,
          payload: false,
        });
        setShowChangeCategory(false);
        return;
      }

      let payload: any = {};
      dispatch({
        type: ACTION_BY_KEY[selectedItem?.type],
        payload: {
          index: selectedItem?.index,
          key: "selected_strategy",
          value: selectedSummary,
        },
      });
      const machining_strategy: any = { passes: [] };
      selectedItem?.selected_strategy?.passes?.forEach((strategy: any) => {
        machining_strategy.passes.push({
          ...strategy,
          operations: [
            {
              operation: strategy?.operations?.[0]?.operation,
              location: strategy?.operations?.[0]?.location,
              tool_path_type:
                strategy?.operations?.[0]?.tool_paths?.[0]?.tool_path_type ||
                strategy?.operations?.[0]?.tool_paths?.[0]?.type,
              tool_path:
                strategy?.operations?.[0]?.tool_paths?.[0]?.tool_path ||
                strategy?.operations?.[0]?.tool_paths?.[0]?.name,
              tool_type:
                strategy?.operations?.[0]?.tool_paths?.[0]
                  ?.selected_tool_type ||
                strategy?.operations?.[0]?.tool_paths?.[0]?.tool_types[0],
            },
          ],
        });
      });

      switch (selectedItem?.type) {
        case "hole":
          payload = [
            {
              feature_type: selectedItem?.type,
              feature_name:
                state[selectedItem?.key][selectedItem?.index]["type"],
              feature_id: state[selectedItem?.key][selectedItem?.index]["id"],
              material_info: {
                material: projectInfo?.material || "aluminium",
                sub_material: projectInfo?.subMaterial || "high alloy steel",
              },
              machine_info: {
                name: projectInfo?.machineName || "Hermle c800",
                rpm: projectInfo?.rpm || 1000,
                axes: projectInfo?.numberOfAxis?.toString() || "3",
              },
            },
          ];

          if (
            state[selectedItem?.key][selectedItem?.index]["type"] ===
            "open_hole"
          ) {
            payload[0] = {
              ...payload[0],
              feature_info: {
                diameter: Number(
                  state[selectedItem?.key][selectedItem?.index]["diameter"]
                ),
                depth: Number(
                  state[selectedItem?.key][selectedItem?.index]["depth"]
                ),
                quantity: Number(
                  state[selectedItem?.key][selectedItem?.index]["quantity"]
                ),
                bottom_type:
                  state[selectedItem?.key][selectedItem?.index]["bottom_type"],
                ...(state[selectedItem?.key][selectedItem?.index][
                  "bottom_type"
                ] === "Blind" && {
                  bottom:
                    state[selectedItem?.key][selectedItem?.index]["bottom"],
                  ...(state[selectedItem?.key][selectedItem?.index][
                    "bottom"
                  ] === "Flat"
                    ? {
                        bottom_radius: Number(
                          state[selectedItem?.key][selectedItem?.index][
                            "bottom_radius"
                          ]
                        ),
                      }
                    : {
                        bottom_angle: Number(
                          state[selectedItem?.key][selectedItem?.index][
                            "bottom_angle"
                          ]
                        ),
                      }),
                }),
                deburring: state[selectedItem?.key][selectedItem?.index][
                  "deburring"
                ]
                  ? "yes"
                  : "no",
                additional_length: Number(
                  state[selectedItem?.key][selectedItem?.index][
                    "additional_length"
                  ]
                ),
              },
              machining_strategy,
            };
          } else if (
            state[selectedItem?.key][selectedItem?.index]["type"] ===
            "finish_hole"
          ) {
            payload[0] = {
              ...payload[0],
              feature_info: {
                diameter: Number(
                  state[selectedItem?.key][selectedItem?.index]["diameter"]
                ),
                depth: Number(
                  state[selectedItem?.key][selectedItem?.index]["depth"]
                ),
                quantity: Number(
                  state[selectedItem?.key][selectedItem?.index]["quantity"]
                ),
                bottom_type:
                  state[selectedItem?.key][selectedItem?.index]["bottom_type"],
                ...(state[selectedItem?.key][selectedItem?.index][
                  "bottom_type"
                ] === "Blind" && {
                  bottom:
                    state[selectedItem?.key][selectedItem?.index]["bottom"],
                  ...(state[selectedItem?.key][selectedItem?.index][
                    "bottom"
                  ] === "Flat"
                    ? {
                        bottom_radius: Number(
                          state[selectedItem?.key][selectedItem?.index][
                            "bottom_radius"
                          ]
                        ),
                      }
                    : {
                        bottom_angle: Number(
                          state[selectedItem?.key][selectedItem?.index][
                            "bottom_angle"
                          ]
                        ),
                      }),
                }),
                deburring: state[selectedItem?.key][selectedItem?.index][
                  "deburring"
                ]
                  ? "yes"
                  : "no",
                additional_length: Number(
                  state[selectedItem?.key][selectedItem?.index][
                    "additional_length"
                  ]
                ),
                finish_type:
                  state[selectedItem?.key][selectedItem?.index][
                    "finish_type"
                  ] === "custom"
                    ? String(
                      state[selectedItem?.key][selectedItem?.index][
                        "finishing_tolerance"
                      ]
                    )
                    : state[selectedItem?.key][selectedItem?.index][
                      "finish_type"
                    ],
              },
              machining_strategy,
            };
          }
          break;

        case "thread_hole":
          payload = [
            {
              feature_type: selectedItem?.type,
              feature_name:
                state[selectedItem?.key][selectedItem?.index]["type"],
              feature_id: state[selectedItem?.key][selectedItem?.index]["id"],
              material_info: {
                material: projectInfo?.material || "aluminium",
                sub_material: projectInfo?.subMaterial || "any",
              },
              machine_info: {
                name: projectInfo?.machineName || "Hermle c800",
                rpm: projectInfo?.rpm || 1000,
                axes: projectInfo?.numberOfAxis?.toString() || "3",
              },
              feature_info: {
                thread_type:
                  state[selectedItem?.key][selectedItem?.index]["thread_type"],
                thread_size: Number(
                  state[selectedItem?.key][selectedItem?.index]["thread_size"]
                ),
                thread_depth: Number(
                  state[selectedItem?.key][selectedItem?.index]["thread_depth"]
                ),
                thread_pitch: Number(
                  state[selectedItem?.key][selectedItem?.index]["thread_pitch"]
                ),
                depth: Number(
                  state[selectedItem?.key][selectedItem?.index]["depth"]
                ),
                bottom_type:
                  state[selectedItem?.key][selectedItem?.index]["bottom_type"],
                ...(state[selectedItem?.key][selectedItem?.index][
                  "bottom_type"
                ] === "Blind" && {
                  bottom:
                    state[selectedItem?.key][selectedItem?.index]["bottom"],
                  ...(state[selectedItem?.key][selectedItem?.index][
                    "bottom"
                  ] === "Flat"
                    ? {
                        bottom_radius: Number(
                          state[selectedItem?.key][selectedItem?.index][
                            "bottom_radius"
                          ]
                        ),
                      }
                    : {
                        bottom_angle: Number(
                          state[selectedItem?.key][selectedItem?.index][
                            "bottom_angle"
                          ]
                        ),
                      }),
                }),
                deburring: state[selectedItem?.key][selectedItem?.index][
                  "deburring"
                ]
                  ? "yes"
                  : "no",
                additional_length: Number(
                  state[selectedItem?.key][selectedItem?.index][
                    "additional_length"
                  ]
                ),
                quantity: Number(
                  state[selectedItem?.key][selectedItem?.index][
                    "quantity"
                  ]
                ),
              },
              machining_strategy,
            },
          ];
          break;

        case "face":
          payload = [
            {
              feature_type: selectedItem?.type,
              feature_name:
                state[selectedItem?.key][selectedItem?.index]["type"],
              feature_id: state[selectedItem?.key][selectedItem?.index]["id"],
              material_info: {
                material: projectInfo?.material || "aluminium",
                sub_material: projectInfo?.subMaterial || "any",
              },
              machine_info: {
                name: projectInfo?.machineName || "Hermle c800",
                rpm: projectInfo?.rpm || 1000,
                axes: projectInfo?.numberOfAxis?.toString() || "3",
              },
            },
          ];

          if (
            state[selectedItem?.key][selectedItem?.index]["type"] === "top_face"
          ) {
            payload[0] = {
              ...payload[0],
              feature_info: {
                length: Number(
                  state[selectedItem?.key][selectedItem?.index]["length"]
                ),
                breadth: Number(
                  state[selectedItem?.key][selectedItem?.index]["breadth"]
                ),
                stock_on_face: Number(
                  state[selectedItem?.key][selectedItem?.index]["stock_on_face"]
                ),
                quantity: Number(
                  state[selectedItem?.key][selectedItem?.index]["quantity"]
                ),
              },
              machining_strategy,
            };
          } else if (
            state[selectedItem?.key][selectedItem?.index]["type"] ===
            "bottom_face"
          ) {
            payload[0] = {
              ...payload[0],
              feature_info: {
                length: Number(
                  state[selectedItem?.key][selectedItem?.index]["length"]
                ),
                breadth: Number(
                  state[selectedItem?.key][selectedItem?.index]["breadth"]
                ),
                stock_on_face: Number(
                  state[selectedItem?.key][selectedItem?.index]["stock_on_face"]
                ),
                ...(!!state[selectedItem?.key][selectedItem?.index]["islands"]
                    ?.length ? {
                      island_info: {
                        islands:
                          state[selectedItem?.key][selectedItem?.index][
                            "islands"
                          ].map((island: any) => Object.fromEntries(
                              Object.entries(island).map(
                                ([key, value]) => [key, Number(value)]
                              )
                            )
                          ),
                        min_distance_between_islands: Number(
                          state[selectedItem?.key][selectedItem?.index][
                            "min_distance_between_islands"
                          ]
                        ),
                      },
                    } : {
                      island_info: {islands: []},
                    }),
                quantity: Number(
                  state[selectedItem?.key][selectedItem?.index]["quantity"]
                ),
              },
              machining_strategy,
            };
          } else if (
            state[selectedItem?.key][selectedItem?.index]["type"] ===
            "side_face"
          ) {
            payload[0] = {
              ...payload[0],
              feature_info: {
                length: Number(
                  state[selectedItem?.key][selectedItem?.index]["length"]
                ),
                breadth: Number(
                  state[selectedItem?.key][selectedItem?.index]["breadth"]
                ),
                depth: Number(
                  state[selectedItem?.key][selectedItem?.index]["depth"]
                ),
                stock_on_face: Number(
                  state[selectedItem?.key][selectedItem?.index]["stock_on_face"]
                ),
                floor_type:
                  state[selectedItem?.key][selectedItem?.index]["floor_type"],
                ...(state[selectedItem?.key][selectedItem?.index]["floor_type"] 
                  === "Solid" && {
                    floor_radius: Number(
                      state[selectedItem?.key][selectedItem?.index][
                        "floor_radius"
                      ]
                    ),
                  }
                ),
                position: 
                  state[selectedItem?.key][selectedItem?.index]["position"],
                profile:
                  state[selectedItem?.key][selectedItem?.index]["profile"],
                quantity: Number(
                  state[selectedItem?.key][selectedItem?.index]["quantity"]
                ),
                ...(state[selectedItem?.key][selectedItem?.index][
                  "use_corner_radius"
                ] && {
                  corner_radius: Number(
                    state[selectedItem?.key][selectedItem?.index][
                      "corner_radius"
                    ]
                  ),
                }
              ),
              },
              machining_strategy,
            };
          }
          break;

        case "pocket":
          payload = [
            {
              feature_type: selectedItem?.type,
              feature_name:
                state[selectedItem?.key][selectedItem?.index]["type"],
              feature_id: state[selectedItem?.key][selectedItem?.index]["id"],
              material_info: {
                material: projectInfo?.material || "aluminium",
                sub_material: projectInfo?.subMaterial || "any",
              },
              machine_info: {
                name: projectInfo?.machineName || "Hermle c800",
                rpm: projectInfo?.rpm || 1000,
                axes: projectInfo?.numberOfAxis?.toString() || "3",
              },
              feature_info: {
                length: Number(
                  state[selectedItem?.key][selectedItem?.index]["length"]
                ),
                breadth: Number(
                  state[selectedItem?.key][selectedItem?.index]["breadth"]
                ),
                depth: Number(
                  state[selectedItem?.key][selectedItem?.index]["depth"]
                ),
                pocket_type:
                  state[selectedItem?.key][selectedItem?.index]["pocket_type"],
                walls: state[selectedItem?.key][selectedItem?.index]["walls"],
                ...(state[selectedItem?.key][selectedItem?.index]
                    ["walls"] === "Multiple" && {
                      corner_radius: Number(
                        state[selectedItem?.key][selectedItem?.index][
                          "corner_radius"
                        ]
                      ),
                    }),
                floor_type:
                  state[selectedItem?.key][selectedItem?.index]["floor_type"],
                ...(state[selectedItem?.key][selectedItem?.index][
                  "floor_type"
                ] === "Solid" && {
                  floor_radius: Number(
                    state[selectedItem?.key][selectedItem?.index][
                      "floor_radius"
                    ]
                  ),
                }),
                ...(state[selectedItem?.key][selectedItem?.index]
                    ["pocket_type"] === "Closed" && {
                      min_distance: Number(
                        state[selectedItem?.key][selectedItem?.index][
                          "min_distance"
                        ]
                      ),
                    }),
                ...(!!state[selectedItem?.key][selectedItem?.index]["islands"]
                  ?.length && {
                  island_info: {
                    islands:
                      state[selectedItem?.key][selectedItem?.index]["islands"],
                    min_distance_between_islands: Number(
                      state[selectedItem?.key][selectedItem?.index][
                        "min_distance_between_islands"
                      ]
                    ),
                  },
                }),
                walls_profile: state[selectedItem?.key][selectedItem?.index][
                  "walls_profile"
                ],
                quantity: Number(
                  state[selectedItem?.key][selectedItem?.index]["quantity"]
                ),
              },
              machining_strategy,
            },
          ];
          break;

        case "edge":
          payload = [
            {
              feature_type: selectedItem?.type,
              feature_name:
                state[selectedItem?.key][selectedItem?.index]["type"],
              feature_id: state[selectedItem?.key][selectedItem?.index]["id"],
              material_info: {
                material: projectInfo?.material || "aluminium",
                sub_material: projectInfo?.subMaterial || "any",
              },
              machine_info: {
                name: projectInfo?.machineName || "Hermle c800",
                rpm: projectInfo?.rpm || 1000,
                axes: projectInfo?.numberOfAxis?.toString() || "3",
              },
            },
          ];
          if (
            state[selectedItem?.key][selectedItem?.index]["type"] === "fillet"
          ) {
            payload[0] = {
              ...payload[0],
              feature_info: {
                length: Number(
                  state[selectedItem?.key][selectedItem?.index]["length"]
                ),
                depth: Number(
                  state[selectedItem?.key][selectedItem?.index]["depth"]
                ),
                type: state[selectedItem?.key][selectedItem?.index][
                  "fillet_type"
                ],
                radius: Number(
                  state[selectedItem?.key][selectedItem?.index]["radius"]
                ),
                floor_type:
                  state[selectedItem?.key][selectedItem?.index]["floor_type"],
                floor_radius: Number(
                  state[selectedItem?.key][selectedItem?.index]["floor_radius"]
                ),
                location:
                  state[selectedItem?.key][selectedItem?.index]["location"],
                quantity: Number(
                  state[selectedItem?.key][selectedItem?.index]["quantity"]
                ),
                ...(state[selectedItem?.key][selectedItem?.index][
                    "location"
                  ] === "Inside" && {
                    corner_radius: Number(
                      state[selectedItem?.key][selectedItem?.index][
                        "corner_radius"
                      ]
                    ),
                  }
                ),
              },
              machining_strategy,
            };
          } else if (
            state[selectedItem?.key][selectedItem?.index]["type"] === "slot"
          ) {
            payload[0] = {
              ...payload[0],
              feature_info: {
                open_sides:
                  state[selectedItem?.key][selectedItem?.index]["open_sides"],
                length: Number(
                  state[selectedItem?.key][selectedItem?.index]["length"]
                ),
                breadth: Number(
                  state[selectedItem?.key][selectedItem?.index]["breadth"]
                ),
                depth: Number(
                  state[selectedItem?.key][selectedItem?.index]["depth"]
                ),
                floor_radius: Number(
                  state[selectedItem?.key][selectedItem?.index]["floor_radius"]
                ),
                ...(state[selectedItem?.key][selectedItem?.index]["open_sides"] 
                  !== "Both" && {
                    corner_radius: Number(
                      state[selectedItem?.key][selectedItem?.index][
                        "corner_radius"
                      ]
                    ),
                  }
                ),
                quantity: Number(
                  state[selectedItem?.key][selectedItem?.index]["quantity"]
                ),
                floor_type: state[selectedItem?.key][selectedItem?.index][
                  "floor_type"
                ],
                surface_type: state[selectedItem?.key][selectedItem?.index][
                  "surface_type"
                ],
              },
              machining_strategy,
            };
          } else if (
            state[selectedItem?.key][selectedItem?.index]["type"] === "chamfer"
          ) {
            payload[0] = {
              ...payload[0],
              feature_info: {
                type: state[selectedItem?.key][selectedItem?.index][
                  "chamfer_type"
                ],
                length: Number(
                  state[selectedItem?.key][selectedItem?.index]["length"]
                ),
                breadth: Number(
                  state[selectedItem?.key][selectedItem?.index]["breadth"]
                ),
                angle: Number(
                  state[selectedItem?.key][selectedItem?.index]["angle"]
                ),
                location:
                  state[selectedItem?.key][selectedItem?.index]["location"],
                corner_radius: Number(
                  state[selectedItem?.key][selectedItem?.index]["corner_radius"]
                ),
                vertical_edges: state[selectedItem?.key][selectedItem?.index][
                  "vertical_edges"
                ]
                  ? "yes"
                  : "no",
                quantity: Number(
                  state[selectedItem?.key][selectedItem?.index]["quantity"]
                ),
              },
              machining_strategy,
            };
          }
          break;
      }

      fetchData(payload)
        .then((response: any) => {
          dispatch({
            type: ACTION_BY_KEY[selectedItem?.type],
            payload: {
              index: selectedItem?.index,
              key: "tool",
              value: response?.cutting_tools?.[0]?.cutting_tools,
            },
          });
          setTimeout(() => {
            handleStrategy(selectedSummary);
          }, 100);
        })
        .catch((err: any) => {
          console.log(err);
        })
        .finally(() => {
          dispatch({
            type: SET_LOADING_BAR,
            payload: false,
          });
          setShowChangeCategory(false);
        });
    } catch (err) {
      dispatch({
        type: SET_LOADING_BAR,
        payload: false,
      });
    }
  };

  const handleStrategyData = (
    index: number,
    value: any,
    isSelected: boolean
  ) => {
    const new_data = [...strategyData];
    new_data[index].passes = value?.passes;
    setStrategyData(new_data);
    if (isSelected) {
      handleSummary(value);
    }
  };

  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 bg-white z-10">
      <div className="w-full flex flex-col items-center justify-center py-7">
        <img src={SummaryImg} alt="summary img" className="w-10 h-10 mb-2" />
        <p className="text-black text-lg font-semibold text-center mb-1">
          Change Machining Strategy
        </p>
        <p className="text-sm text-black">
          Please change machining strategy for current machining feature as
          needed.
        </p>
      </div>

      <div className="px-5 h-[calc(100vh-210px)] overflow-y-auto absolute left-0 right-0">
        <div className="mb-4">
          <p className="font-semibold text-black mb-2">Machining Strategies</p>
          <div className="flex flex-row overflow-x-auto py-2 px-px">
            {/* {strategyData &&
              !!strategyData?.length &&
              strategyData?.map((item: any, idx: number) => (
                <MachiningStrategySummaryBox
                  initData={item?.passes || []}
                  checked={selectedSummary?.index === idx}
                  key={idx}
                  type={item?.recommended && "recommend"}
                  setSelectedSummary={(value: any) => {
                    handleSummary({ ...item, passes: value });
                  }}
                  setStrategyData={(value: any) =>
                    handleStrategyData(
                      idx,
                      { ...item, passes: value },
                      selectedSummary?.index === idx
                    )
                  }
                  selectedItem={selectedItem}
                  index={idx}
                />
              ))} */}
            {/* {state[selectedItem?.key][selectedItem?.index]?.strategy
              ?.default && (
              <MachiningStrategySummaryBox
                type="recommend"
                data={data?.default}
                checked={selectedSummary?.kind === "default"}
                kind="default"
                setSelectedSummary={handleSummary}
                selectedItem={selectedItem}
              />
            )}
            {data?.alternate &&
              Object.keys(data?.alternate)?.length > 0 &&
              Object.keys(data?.alternate)?.map((key, idx) => (
                <MachiningStrategySummaryBox
                  type="alternate"
                  data={data?.alternate[key]}
                  checked={selectedSummary?.kind === key}
                  key={idx}
                  kind={key}
                  setSelectedSummary={handleSummary}
                  selectedItem={selectedItem}
                />
              ))}
            <MachiningStrategySummaryBox
              type="coming"
              setSelectedSummary={() => {}}
              selectedItem={() => {}}
            /> */}
          </div>
        </div>

        <div>
          <p className="text-black font-semibold mb-[10px]">Rationale</p>
          <div>
            {selectedSummary?.rationale &&
              selectedSummary?.rationale?.map(
                (item: string, idx: number) => {
                  return (
                    <div className="flex flex-row items-start" key={idx}>
                      <img
                        src={DoubleCheckIcon}
                        alt="double check"
                        className="mr-[6px]"
                      />
                      <p className="text-gray-737 text-sm font-medium">
                        {item}
                      </p>
                    </div>
                  );
                }
              )}
          </div>
        </div>
      </div>

      <div className="fixed bottom-0 left-0 right-0 h-[54px] px-5 flex justify-center items-center border-t border-gray-efe bg-white">
        <Button
          size="md"
          className="!rounded-full py-[6px] w-[150px] bg-gray-f2f text-black-222 mr-6"
          onClick={() => setShowChangeCategory(false)}
        >
          Cancel
        </Button>
        <Button
          size="md"
          className="!rounded-full py-[6px] w-[150px] whitespace-nowrap"
          onClick={handleContinue}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export default ChangeStrategy;
