import Thead from "./Thead";
import Tbody from "./Tbody";
import Tr from "./Tr";
import Th from "./Th";
import Td from "./Td";
import clsx from "clsx";

export interface TableProps
  extends React.TableHTMLAttributes<HTMLTableElement> {
  className?: string;
  children: any;
}

function Table<T extends object>({
  children,
  className,
}: TableProps) {
  return (
    <table className={clsx("w-full border-collapse border-hidden", className)} cellPadding={0}>
      {children}
    </table>
  );
}

Table.Thead = Thead;
Table.Th = Th;
Table.Tbody = Tbody;
Table.Tr = Tr;
Table.Td = Td;

export default Table;
