import clsx from "clsx";

interface ModalBodyProps {
  className?: string;
  children: any;
}

function ModalBody({
  className,
  children
}: ModalBodyProps) {
  return (
    <div className={clsx('max-h-[70vh] overflow-y-auto p-1', className)}>
      {children}
    </div>
  )
}

export default ModalBody;
