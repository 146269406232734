export function addPhaseNo(draggableItems: any[]) {
  let idx = 1;
  for (const item of draggableItems) {
    item.operationObj.pass_number = idx;
    idx++;
  }
}

export function reorderArrayElement(
  originalArray: any[],
  currentIndex: number,
  destinationIndex: number
) {
  const arrayCopy = Array.from(originalArray);
  const [removed] = arrayCopy.splice(currentIndex, 1);
  arrayCopy.splice(destinationIndex, 0, removed);
  return arrayCopy;
}

export function constructTitle(
  type: string,
  featureTypeToTitleMap: any,
  featureTypeToCountMap: any
) {
  return (
    (featureTypeToTitleMap[type] || "N/A") + " " + featureTypeToCountMap[type]
  );
}
