import type { FC } from "react";

export interface TheadProps {
  className?: string;
  children: any;
}

const Thead: FC<TheadProps> = ({ children, className }) => {
  return <thead className={className}>{children}</thead>;
};

export default Thead;
