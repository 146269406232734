import { Button, Menu, MenuItem } from "@mui/material";
import clsx from "clsx";
import { useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import { v4 as uuid } from "uuid";

interface IToolPathMenu {
  isEdit: boolean;
  value: any;
  options: any;
  onChange: any;
  isValid: boolean;
}

const ToolPathMenu: React.FC<IToolPathMenu> = ({
  isEdit,
  value,
  options,
  onChange,
  isValid,
}) => {
  const id = uuid();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className="w-full relative">
      <Button
        color="inherit"
        sx={{
          textTransform: "none",
          textAlign: "left",
          lineHeight: 1.5,
          padding: "0 0",
          fontSynthesisWeight: "none",
          fontWeight:"500",
          width: "100%",
        }}
        disableRipple={true}
        id={`basic-button-${id}`}
        aria-controls={open ? `basic-menu-${id}` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <div className={clsx("flex justify-between items-baseline w-full")}>
          <fieldset
            className={clsx(
              "px-[4px] pb-[4px] w-full rounded-md flex flex-col",
              {
                "bg-gray-100": !isEdit,
              }
            )}
          >
            <legend
              className={clsx(
                "text-[8px] px-[4px] py-[2px] border border-gray-100 bg-white rounded-md",
                {
                  "text-gray-700": isValid,
                  "text-gray-400": !isValid,
                }
              )}
            >
              Toolpath{" "}
            </legend>
            <div className="flex flex-row">
              <div className="w-full">
                <p
                  className={clsx("text-[10px]", {
                    "text-blue-687": isValid,
                    "text-gray-400": !isValid,
                  })}
                >
                  <span className={isValid ? "text-gray-700" : "text-gray-400"}>
                    Type:
                  </span>{" "}
                  {value.label.tool_path_type}
                </p>
                <p
                  className={`text-[10px] ${
                    isValid ? "text-blue-687" : "text-gray-400"
                  }`}
                >
                  <span className={isValid ? "text-gray-700" : "text-gray-400"}>
                    Name:
                  </span>{" "}
                  {value.label.tool_path}
                </p>
                <p
                  className={`text-[10px] ${
                    isValid ? "text-blue-687" : "text-gray-400"
                  }`}
                >
                  <span className={isValid ? "text-gray-700" : "text-gray-400"}>
                    Style:
                  </span>{" "}
                  {value.label.tool_path_style ?? "N/A"}
                </p>
              </div>
              {isEdit && (
                <FiChevronDown className="h-[14px] w-[14px] text-gray-475" />
              )}
            </div>
          </fieldset>
        </div>
      </Button>
      {isEdit && (
        <Menu
          id={`basic-menu-${id}`}
          anchorEl={anchorEl}
          MenuListProps={{
            "aria-labelledby": "basic-button",
            sx: {
              minWidth: anchorEl && anchorEl.offsetWidth,
              py: 0,
            },
          }}
          open={open}
          onClose={handleClose}
        >
          {options.map((option: any) => (
            <MenuItem
              key={option.value}
              value={option}
              onClick={() => {
                onChange(option);
                handleClose();
              }}
              disableRipple={true}
              className={clsx(
                "rounded-sm text-xs hover:opacity-80 text-gray-475 px-1.5 py-1 cursor-pointer border-b border-gray-100",
                {
                  "bg-gray-100": value.value === option.value,
                }
              )}
            >
              <div className="">
                <div>Type : {option.label.tool_path_type}</div>
                <div>Name : {option.label.tool_path}</div>
                <div>Style : {option.label.tool_path_style ?? "N/A"}</div>
              </div>
            </MenuItem>
          ))}
        </Menu>
      )}
    </div>
  );
};

export default ToolPathMenu;
