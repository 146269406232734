export const loginInfo = {
  email: "david@lambdafunction.ai",
  password: "david",
};

export const BASE_URL = "https://nc-auto-demo.lambdafunction.ai";

export const S3_CONFIG = {
  S3_ID: 'AKIA3D2QRA3DP43M4O2N',
  S3_SECRET: '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',
  S3_BUCKET: 'autodesk-cnc',
  S3_REGION: 'us-east-1',
}

