import React, { ReactNode } from "react";
import clsx from "clsx";

interface IButton {
  variant?:
    | "primary"
    | "secondary"
    | "ghost"
    | "danger"
    | "gray"
    | "black"
    | "grayborder"
    | "genric"
    | "modalGray";
  size?: "sm" | "md" | "lg";
  children?: ReactNode | string;
  className?: string;
  disabled?: boolean;
  onClick?: any;
  type?: "button" | "reset" | "submit";
  id?: string;
  onMouseOver?: any;
  onMouseLeave?: any;
  onDoubleClick?: any;
}

const Button: React.FC<IButton> = ({
  variant = "primary",
  children,
  size = "md",
  className = "",
  disabled = false,
  type = "button",
  onClick,
  ...props
}) => {
  let variantClassName = "";
  switch (variant) {
    case "primary":
      variantClassName = "bg-surface-default text-white";
      break;
    case "secondary":
      variantClassName = "border-surface-default text-surface-default border";
      break;
    case "ghost":
      variantClassName = "text-surface-default";
      break;
    case "danger":
      variantClassName = "bg-red-ff0 text-white";
      break;
    case "gray":
      variantClassName = "bg-gray-200 text-gray-475";
      break;
    case "grayborder":
      variantClassName = "bg-white border border-gray-400 text-gray-475";
      break;
    case "modalGray":
      variantClassName = "bg-gray-100 text-gray-700";
      break;
    case "black":
      variantClassName = "border-gray-700 bg-gray-700";
      break;
  }
  const classes = clsx({
    "flex justify-center items-center": true,
    "px-2 py-0.5 text-xs": size === "sm",
    "px-4 py-2 text-sm rounded": size === "md",
    "px-6 py-4 text-2xl font-semibold rounded-xl": size === "lg",
    [className]: className,
    [variantClassName]: variantClassName,
    "hover:opacity-80 active:opacity-50": !disabled,
    "cursor-not-allowed opacity-50": disabled,
  });
  return (
    <button
      className={classes}
      disabled={disabled}
      type={type}
      onClick={onClick}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
