import { useCallback, useState } from "react";
import { v4 as uuid } from "uuid";
declare const window: any;

export const useCustomEventsApiCall = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [data, setData] = useState<any>(null);

  const fetchData = useCallback(
    (type: string, args: any, timeoutThreshold: number = 600000) => {
      const callbackId = "lfReactPluginEvents_" + uuid();
      let timeoutId: NodeJS.Timeout;
      let isResolved = false;
      return new Promise<void>((resolve) => {
        function handleReturnEvent(event: any) {
          if (!isResolved) {
            let response: any;
            try {
              response = JSON.parse(event.data);
              setData(response);
            } catch (e) {
              setError("Error occured while trying to parse JSON");
            } finally {
              window.removeEventListener(callbackId, handleReturnEvent);
              clearTimeout(timeoutId);
              setLoading(false);
              isResolved = true;
              resolve(response);
            }
          }
        }

        function timeoutEventListner() {
          if (!isResolved) {
            window.removeEventListener(callbackId, handleReturnEvent);
            setError("Event timeout error");
            setLoading(false);
            isResolved = true;
            resolve();
          }
        }

        setLoading(true);
        if (window.chrome?.webview) {
          window.addEventListener(callbackId, handleReturnEvent);
          window.chrome.webview.postMessage({ type, args, callbackId });
          // Removing the Event Listener after a certain threshold time for cases
          // where we do not recieve back any event so that the impact on the
          // memory comsumption is minimum. (default threshold -> 10 mins)
          timeoutId = setTimeout(() => {
            timeoutEventListner();
          }, timeoutThreshold);
        } else {
          setLoading(false);
          resolve();
        }
      });
    },
    []
  );

  return [fetchData as Function, loading, data, error];
};
