import clsx from "clsx";
import { useEffect, useState } from "react";
import uniqid from "uniqid";
import "./input.css";
import FeatureInfoIcon from "assets/images/icons/feature-info.svg";
import TooltipV2 from "components/ToolTipV2";

interface IInput {
  placeholder?: string;
  value?: any;
  onChange?: any;
  className?: string;
  onBlur?: any;
  unit?: string;
  subClassName?: string;
  infoTooltipTitle?: React.ReactNode;
  inputClassName?: string;
  placeholderClassName?: string;
  label?: string;
  size?: "default" | "small";
  inputType?: "input" | "textarea";
  [name: string]: any;
  regex?: RegExp;
  disabled?: boolean;
  handleFocus?: any;
  isErrorMessageAbsolute?: boolean;
}
const Input: React.FC<IInput> = ({
  placeholder = "",
  value,
  onChange,
  className = "",
  helperText,
  invalid,
  success,
  warning,
  disabled,
  onBlur,
  unit,
  inputClassName,
  infoTooltipTitle,
  placeholderClassName,
  subClassName,
  label,
  handleFocus,
  inputType = "input",
  size = "default",
  regex,
  isErrorMessageAbsolute = false,
  ...rest
}) => {
  const [focus, setFocus] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const classes = clsx(subClassName, "relative", {
    "px-5 py-[14px] rounded-[8px] input-field h-[52px]": size === "default",
    "px-[12px] py-[8px] rounded-[8px] input-field h-[36px]": size === "small",
    "!h-fit": inputType === "textarea",
    "border-surface-default border border-solid": focus,
    "!pt-[16px] !pb-[12px]": size === "default" && placeholder,
    // "!pt-[16px] !pb-[12px]": size === 'default' && placeholder,
    "shadow-input-2": !focus,
    "input-invalid": invalid,
    "input-success": success,
    "input-warning": warning,
    "input-disabled !p-0": disabled,
  });

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handle = (e: any) => {
    // Not making changes to the inputValue if the input's target value fails to
    // match with the regular expression.
    if (regex && e?.target?.value && !regex.test(e.target.value)) {
      return;
    }
    setInputValue(e?.target?.value);
    if (onChange) onChange(e);
  };

  const handleBlue = (e: any) => {
    setFocus(false);
    onBlur(e);
  };

  const name = uniqid();

  return (
    <div
      className={clsx(className, {
        "relative mb-2": isErrorMessageAbsolute,
      })}
    >
      {label && (
        <p className="mb-1.5 text-gray-696 font-semibold text-sm">{label}</p>
      )}
      <div className={clsx(classes, "flex")}>
        <label
          className={clsx(
            placeholderClassName,
            "absolute pointer-events-none z-10 transition-all",
            {
              "left-[20px] font-medium": size === "default",
              "left-[10px] font-normal text-sm": size === "small",
              "text-[10px] top-[2px]":
                size === "default" &&
                (!!inputValue?.toString()?.trim()?.length || focus),
              "text-[8px] top-[0px] opacity-0":
                size === "small" &&
                (!!inputValue?.toString()?.trim()?.length || focus),
              "top-[16px] text-gray-b9b text-sm":
                size === "default" &&
                !(!!inputValue?.toString()?.trim()?.length || focus),
              "top-[9px] text-gray-b9b text-sm":
                size === "small" &&
                !(!!inputValue?.toString()?.trim()?.length || focus),
            }
          )}
          htmlFor={name}
        >
          {placeholder}
        </label>
        {inputType === "input" && (
          <input
            id={name}
            className={clsx(" w-full outline-none", inputClassName, {
              "pr-6": !!unit,
              "font-medium text-sm": size === "default",
              "font-normal text-[16px]": size === "small",
              "!p-2.5": disabled,
            })}
            value={inputValue}
            onChange={handle}
            onFocus={(e) => {
              setFocus(true);
              handleFocus?.(e);
            }}
            onBlur={handleBlue}
            disabled={disabled}
            {...rest}
          />
        )}
        {inputType === "textarea" && (
          <textarea
            id={name}
            className={clsx("w-full outline-none mt-3", inputClassName, {
              "pr-6": !!unit,
              "font-medium text-sm": size === "default",
              "font-normal text-[16px]": size === "small",
            })}
            value={inputValue}
            onChange={handle}
            onFocus={() => setFocus(true)}
            onBlur={handleBlue}
            disabled={disabled}
            {...rest}
          />
        )}
        {!!unit && (
          <span
            className={`text-sm absolute ${
              infoTooltipTitle ? "right-[55px]" : "right-[10px]"
            }`}
          >
            {unit}
          </span>
        )}
        {infoTooltipTitle && (
          <div>
            <TooltipV2 title={infoTooltipTitle} openOnHover={false}>
              <div className="absolute right-0 top-0 bottom-0 bg-gray-100 px-[14px] rounded-r-lg flex align-center">
                <img src={FeatureInfoIcon} alt="" height={16} width={16} />
              </div>
            </TooltipV2>
          </div>
        )}
      </div>
      {helperText && (
        <p
          className={clsx("input-helper-text", {
            "!text-[12px]": size === "small",
            absolute: isErrorMessageAbsolute,
          })}
        >
          {helperText}
        </p>
      )}
    </div>
  );
};

export default Input;
