import Button from "components/Button";
import SummaryExport from "components/SummaryExport";
import {
  keyList,
  SET_ASSIGNED_SETUPS,
  SET_LOADING_BAR,
  SET_MACHINE_SETUP,
  SET_ORIGIN_ASSIGNED_SETUPS,
  SET_ORIGIN_MACHINE_SETUP,
} from "constant";
import { EXPORT_TABLE, EXPORT_TABLE_KEYS } from "constant/exportTable";
import { GlobalContext } from "context/GlobalContext";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AWS from "aws-sdk";
import { S3_CONFIG } from "config";
import ShareURLModal from "./ShareURLModal";
import { Storage } from "aws-amplify";
import {
  useApiCall,
  useLocalFusionApiCall,
  useLocalMasterCamApiCall,
  useLocalSiemensNxApiCall,
} from "hooks";
import { makeLabels } from "constant";
import DefaultLayout from "Layouts/DefaultLayout";
import {
  compareFuncForObjectsWithPassNumber,
  convertKeyToLabel,
  createDeepCopy,
  extractToolPathDataFromMachiningSetups,
} from "utils";
import AccordionItem from "components/AccordionItem";
import FinalSummaryToolPathItem from "components/FinalSummaryToolPathItem";
import FinalSummarySetupLabel from "components/FinalSummarySetupLabel";
import FeatureDataSideBar from "components/FeatureDataSideBar";
import { sendUserSelectionsToICSApi } from "services";
import PreviousIcon from "assets/images/icons/previous-icon.svg";
import { toast } from "react-toastify";
import CheckIcon from "assets/images/icons/check-icon.svg";

const TableToExcel = require("@stanlystark/table-to-excel");

const s3 = new AWS.S3({
  accessKeyId: S3_CONFIG.S3_ID,
  secretAccessKey: S3_CONFIG.S3_SECRET,
});

const showGCodeFileNameReminder = () => {
  toast(
    <div className="flex flex-row gap-2">
      <img src={CheckIcon} className="mb-auto mt-1" />
      <div>
        Please ensure the G-Code file name is same as the program name defined.
      </div>
    </div>,
    {
      className: "!bg-grean-039 !rounded-lg w-[280px] height-[50px]",
      bodyClassName: "text-white text-[14px]",
      position: "bottom-right",
    }
  );
};

const Summary = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const {
    projectInfo,
    visiblefeature_ids,
    machiningSetups,
    setupNumber,
    assignedMachiningSetups,
    mappingForMachiningSetups,
    featureMapping,
    userInfo,
  } = state;
  const navigate = useNavigate();
  const location = useLocation();
  // Use the hook only when callApi prop is true
  const [fetchFunsionData] = useLocalFusionApiCall();
  const [fetchSiemensNxData] = useLocalSiemensNxApiCall();
  const [fetchMasterCamData] = useLocalMasterCamApiCall();
  const [sendData] = useApiCall(sendUserSelectionsToICSApi, false);
  const [tableData, setTableData] = useState([]);
  const tableRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState<any>(null);
  const [availableList, setAvailableList] = useState([]);
  const [dataToDisplay, setDataToDisplay] = useState<any[]>([]);
  const [excelData, setExcelData] = useState<any>({});
  const excelRefsMap = useRef<any>(null);
  const dataSent = useRef(false);
  const visiblefeatureIdsSet: any = new Set(visiblefeature_ids?.[0]);

  function getExcelRefMap() {
    if (!excelRefsMap.current) {
      excelRefsMap.current = new Map();
    }
    return excelRefsMap.current;
  }

  const downloadToolpaths = useCallback(() => {
    const a = document.createElement("a");
    const fileContent = {
      organizationId: projectInfo?.organizationId,
      selectionId: projectInfo?.userSelectionId,
      ncProjectId: projectInfo?.ncProjectId,
      userRecordId: projectInfo?.userRecordId,
      workpieceMaterial: projectInfo?.material,
      workpieceSubMaterial: projectInfo?.subMaterial,
      machiningSetups,
      projectInfo,
      mappingForMachiningSetups,
      assignedMachiningSetups,
    };
    document.body.appendChild(a);
    const json = JSON.stringify(fileContent, null, 4),
      blob = new Blob([json], { type: "application/json" }),
      url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = "toolpaths";
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }, [
    assignedMachiningSetups,
    machiningSetups,
    mappingForMachiningSetups,
    projectInfo,
  ]);
  const [showFeatureData, setShowFeatureData] = useState(false);
  const [featureData, setFeatureData] = useState<any>();

  useEffect(() => {
    const setupIdx = machiningSetups?.findIndex(
      (setup: any) => setup?.machiningSetupNumber === setupNumber
    );

    if (setupIdx < 0 || !visiblefeature_ids?.[setupNumber]?.length) return;

    let arr: any = [];
    keyList?.forEach(({ title, type }) => {
      const existing_features = machiningSetups?.[setupIdx]?.features?.filter(
        (feature: any) =>
          feature?.featureInformation?.feature_data?.feature_type === type
      );
      if (!existing_features?.length) return;
      let detailData: any[] = [];
      existing_features?.forEach((feature: any, idx1: number) => {
        let subData: any[] = [];
        const labels = makeLabels(type, {
          ...feature?.featureInformation?.feature_data?.feature_info,
        });
        labels?.forEach((summary: any, idx2: number) => {
          subData.push({
            key: summary?.label,
            inputKey: summary?.key,
            value:
              feature?.featureInformation?.feature_data?.feature_info?.[
                summary?.key
              ],
          });
        });
        if (
          !visiblefeature_ids?.[setupNumber]?.includes(feature?.feature_id) ||
          !feature?.featureInformation?.strategies?.find((strategy: any) => {
            return !!strategy?.machining_strategy?.find(
              (mach: any) =>
                !!mach?.isSelected &&
                !!mach?.passes?.find((pass: any) =>
                  pass?.operations?.find(
                    (operation: any) => !!operation?.["selectedToolId"]
                  )
                )
            );
          })
        )
          return;

        detailData.push({
          title: `${title} ${idx1 + 1}`,
          index: idx1,
          data: subData,
          feature_id: feature?.feature_id,
          featureInformation: feature?.featureInformation,
        });
      });
      arr.push({
        title,
        type,
        data: detailData,
      });
    });
    setAvailableList(arr);
  }, [machiningSetups, visiblefeature_ids, setupNumber]);

  useEffect(() => {
    if (!availableList) return;
    let totalData: any = [];
    EXPORT_TABLE?.filter(({ key }: any) =>
      availableList?.find(
        (list: any) => list?.type === key && !!list?.data?.length
      )
    )?.forEach(({ subTypes, key, label }: any) => {
      // state[key]
      // Sub Type : 'open_hole', 'finish_hole'
      subTypes?.forEach(({ subKey, subLabel, operations }: any) => {
        const typeList: any = availableList?.find(
          (list: any) => list?.type === key
        );
        const subData = typeList?.data?.filter(
          (list1: any) =>
            list1?.featureInformation?.feature_data?.feature_name === subKey
        );
        if (!subData?.length) return;
        subData?.forEach((subDataItem: any, idx1: number) => {
          let idx2 = 0;
          operations?.forEach(({ operationLabel, operationKey }: any) => {
            const selected_strategy =
              subDataItem?.featureInformation?.strategies?.[0]?.machining_strategy?.find(
                (strategy: any) => strategy?.isSelected
              );
            const operations = selected_strategy?.passes?.find(
              (pass: any) => pass?.pass === operationLabel
            );
            operations?.operations?.forEach((operation: any) => {
              idx2++;
              const parameter = operation?.parameters?.find(
                (param: any) =>
                  param?.["Tool ID"] ===
                  operation?.tools?.[operation?.selectedTool]?.["Tool ID"]
              );

              const operationData = {
                ...parameter,
                ...operation?.tools?.[operation?.selectedTool],
              };
              if (!operationData || !Object.keys(operationData)?.length) return;
              let subOpData: any = [];
              EXPORT_TABLE_KEYS?.forEach(({ key, bgColor, type }: any) => {
                if (typeof operationData?.[key] === "object") {
                  subOpData.push({
                    value: operationData?.[key]?.value,
                    bgColor,
                    type,
                  });
                } else
                  subOpData.push({
                    value: operationData?.[key],
                    bgColor,
                    type,
                  });
              });
              totalData.push({
                label: label,
                sub_label: subLabel,
                number_label: idx1 + 1,
                operation_label: operationLabel,
                operation_step: idx2,
                data: subOpData,
                pass_number: operation.pass_number,
              });
            });
          });
        });
      });
    });
    totalData.sort(compareFuncForObjectsWithPassNumber);
    setTableData(totalData);
    extractDataToDisplay();
  }, [availableList]);

  useEffect(() => {
    const excelData: any = {};
    for (const setupId in mappingForMachiningSetups) {
      excelData[setupId] = [];
      assignedMachiningSetups[setupId]?.forEach((operation: any) => {
        const feature =
          machiningSetups?.[0]?.features?.[
            featureMapping?.[operation.featureId]?.index
          ];
        const currentOperation =
          feature?.featureInformation?.strategies?.[0]?.machining_strategy?.[
            operation.strategyIndex
          ]?.passes?.[operation.passIndex]?.operations?.[
            operation.operationIndex
          ];
        let parameter: any, tool: any;
        if (currentOperation?.selectedToolId) {
          parameter =
            currentOperation?.parameters?.[currentOperation?.selectedTool] ??
            {};
          tool =
            currentOperation?.tools?.[currentOperation?.selectedTool] ?? {};
        }

        const operationData = {
          ...parameter,
          ...tool,
        };
        let subOpData: any = [];
        EXPORT_TABLE_KEYS?.forEach(({ key, bgColor, type }: any) => {
          if (typeof operationData?.[key] === "object") {
            subOpData.push({
              value: operationData?.[key]?.value,
              bgColor,
              type,
            });
          } else
            subOpData.push({
              value: operationData?.[key],
              bgColor,
              type,
            });
        });

        let operationStep = 0;
        for (let i = 0; i < operation?.passIndex; i++) {
          operationStep +=
            feature?.featureInformation?.strategies?.[0]?.machining_strategy?.[
              operation.strategyIndex
            ]?.passes?.[i]?.operations?.length;
        }
        operationStep += operation.operationIndex + 1;
        excelData[setupId].push({
          label: convertKeyToLabel(
            feature?.featureInformation?.feature_data?.feature_type || ""
          ),
          sub_label: convertKeyToLabel(
            feature?.featureInformation?.feature_data?.feature_name || ""
          ),
          number_label: featureMapping?.[operation.featureId]?.serialNumber,
          operation_label:
            feature?.featureInformation?.strategies?.[0]?.machining_strategy?.[
              operation.strategyIndex
            ]?.passes?.[operation.passIndex]?.pass,
          operation_step: operationStep,
          data: subOpData,
          pass_number: currentOperation?.pass_number,
        });
      });
    }
    setExcelData(excelData);
  }, []);

  const handleExit = (flag: boolean) => {
    setIsOpen(flag);
    navigate("/projects");
  };

  if (!availableList?.length) {
    navigate("/projects");
  }

  // TODO reduce cognitive complexity of this fucntion
  function extractDataToDisplay() {
    const rowsArray: any[] = [];
    availableList?.forEach(({ data }: any) => {
      data?.forEach((featureData: any) => {
        const item =
          featureData?.featureInformation?.strategies?.[0]?.machining_strategy?.find(
            (strategy: any) => strategy?.isSelected
          );
        item?.passes
          ?.filter((pass: any) =>
            pass?.operations?.find(
              (operation: any) => !!operation?.selectedToolId
            )
          )
          ?.forEach((pass: any) => {
            pass?.operations?.forEach((operation: any) => {
              if (!operation?.parameters?.[operation?.selectedTool]) return;
              rowsArray.push({
                feature_data: featureData.data,
                feature_title: featureData.title,
                pass: pass.pass,
                ...operation,
              });
            });
          });
      });
    });
    rowsArray.sort(compareFuncForObjectsWithPassNumber);
    setDataToDisplay(rowsArray);
  }

  const handleFusion = () => {
    try {
      if (!dataSent.current) {
        sendData({
          organization_id: userInfo["custom:organizationId"],
          project_id: projectInfo.ncProjectId,
        });
        dataSent.current = true;
      }
      sendData({ organization_id: "", project_id: "" });
      fetchFunsionData("stream", machiningSetups);
    } catch (error: any) {
      console.error(error.message);
    }
  };

  const handleSiemensNx = () => {
    try {
      if (!dataSent.current) {
        sendData({
          organization_id: userInfo["custom:organizationId"],
          project_id: projectInfo.ncProjectId,
        });
        dataSent.current = true;
      }
      const setupIdx = machiningSetups?.findIndex(
        (setup: any) => setup?.machiningSetupNumber === setupNumber
      );
      const toolpaths = extractToolPathDataFromMachiningSetups(
        setupIdx,
        machiningSetups,
        featureMapping,
        visiblefeatureIdsSet,
        projectInfo?.unit,
        projectInfo?.numberOfAxis,
        mappingForMachiningSetups
      );
      fetchSiemensNxData("generate_tool_path", toolpaths);
    } catch (error: any) {
      console.error(error.message);
    }
  };

  const handlemasterCam = () => {
    try {
      if (!dataSent.current) {
        sendData({
          organization_id: userInfo["custom:organizationId"],
          project_id: projectInfo.ncProjectId,
        });
        dataSent.current = true;
      }
      const setupIdx = machiningSetups?.findIndex(
        (setup: any) => setup?.machiningSetupNumber === setupNumber
      );
      const toolpaths = extractToolPathDataFromMachiningSetups(
        setupIdx,
        machiningSetups,
        featureMapping,
        visiblefeatureIdsSet,
        projectInfo?.unit,
        projectInfo?.numberOfAxis,
        mappingForMachiningSetups
      );
      fetchMasterCamData("generate_tool_path", toolpaths);
    } catch (error: any) {
      console.error(error.message);
    }
  };

  const setupDownloadHandler = (setupId: string) => {
    const tableRefMap: any = getExcelRefMap();
    try {
      let wb = TableToExcel.default.tableToBook(tableRefMap?.get(setupId), {
        sheet: {
          name: `Setup ${mappingForMachiningSetups[setupId]?.index + 1}`,
        },
      });
      TableToExcel.default.save(
        wb,
        `setup-${mappingForMachiningSetups[setupId]?.index + 1}.xlsx`
      );
    } catch (err) {}
  };

  return (
    <DefaultLayout>
      <div className="flex flex-col h-full">
        <div className="flex flex-row justify-between items-center mt-4 mx-4 my-4">
          <span
            className="font-semibold text-xl"
            onClick={() => setShowFeatureData(true)}
          >
            Summary
          </span>
        </div>
        <div className="flex">
          <div className="mx-4 h-[calc(100vh-200px)] overflow-y-scroll flex flex-col gap-[8px] w-full">
            {Object.keys(mappingForMachiningSetups).map((setupId: any) => {
              const setupDataArray = assignedMachiningSetups[setupId];
              return (
                <div key={setupId}>
                  <AccordionItem
                    tabLabelChild={
                      <FinalSummarySetupLabel
                        setupData={mappingForMachiningSetups[setupId]}
                        setupId={setupId}
                      />
                    }
                    downloadHandler={() => setupDownloadHandler(setupId)}
                    className="p-0 border border-gray-200 rounded-[8px] overflow-hidden"
                    labelClass="py-[8px] px-[8px] border-b border-gray-200"
                    isFinalScreenAccordian={true}
                    setupData={mappingForMachiningSetups[setupId]}
                  >
                    <div className="flex flex-col gap-[8px]">
                      {setupDataArray?.map?.((data: any, idx: number) => {
                        const featureIndex =
                          featureMapping[data.featureId].index;
                        const featureTitle =
                          featureMapping[data.featureId].title;
                        const featureInfo =
                          machiningSetups?.[0]?.features?.[featureIndex]
                            ?.featureInformation?.feature_data?.feature_info;
                        const passData =
                          machiningSetups?.[0]?.features?.[featureIndex]
                            ?.featureInformation?.strategies?.[0]
                            ?.machining_strategy?.[data.strategyIndex]
                            ?.passes?.[data.passIndex];
                        const toolPathData =
                          passData?.operations?.[data.operationIndex];
                        const featureName =
                          machiningSetups?.[0]?.features?.[featureIndex]
                            ?.featureInformation?.feature_data?.feature_name;
                        return (
                          <FinalSummaryToolPathItem
                            key={`${data.featureId}-${data.strategyIndex}-${data.passIndex}-${data.operationIndex}`}
                            featureTitle={featureTitle}
                            data={toolPathData}
                            cuttingPassName={passData.pass}
                            setFeatureData={() => {
                              const dataObj = {
                                setupId,
                                featureInfo,
                                posNo: idx + 1,
                                totalNo: setupDataArray?.length ?? 0,
                                operation: toolPathData?.operation,
                                featureTitle,
                                featureName,
                              };
                              setFeatureData(dataObj);
                            }}
                            setShowFeatureData={setShowFeatureData}
                            posNo={idx + 1}
                            totalNo={setupDataArray?.length ?? 0}
                            setupId={setupId}
                          />
                        );
                      })}
                    </div>
                  </AccordionItem>
                </div>
              );
            })}
          </div>
          {showFeatureData && (
            <FeatureDataSideBar
              featureData={featureData}
              hideSidebar={() => setShowFeatureData(false)}
            />
          )}
        </div>
        <div className="hidden">
          {Object.keys(mappingForMachiningSetups ?? {}).map(
            (setupId: string) => (
              <SummaryExport
                ref={(node) => {
                  const map: any = getExcelRefMap();
                  if (node) {
                    map.set(setupId, node);
                  } else {
                    map.delete(setupId);
                  }
                }}
                setupId={setupId}
                data={excelData[setupId]}
                projectInfo={projectInfo}
              />
            )
          )}
        </div>
        <ShareURLModal
          isOpen={isOpen}
          setIsOpen={handleExit}
          data={modalData}
        />
      </div>
      <div className="bg-white border-t justify-between mt-auto border-gray-200 px-4 py-2 flex ">
        <div>
          {location.state?.referrer === "tool-and-params" && (
            <button
              className="text-xs text-default-surface"
              onClick={() => {
                if (location.state?.referrer === "default-recommendation") {
                  navigate(-2);
                }
                navigate(-1);
              }}
            >
              <div className="flex gap-1 items-baseline text-surface-default font-semibold">
                <img src={PreviousIcon} alt="" /> Previous
              </div>
            </button>
          )}
        </div>
        <div className="flex items-center font-semibold gap-4">
          {location.state?.referrer === "tool-and-params" && (
            <Button
              size="md"
              variant="gray"
              onClick={() => {
                navigate("/projects");
              }}
            >
              Save & Exit
            </Button>
          )}
          {location.state?.referrer === "feature-recognition" && (
            <Button
              size="md"
              variant="gray"
              onClick={() => {
                const newMachiningSetups = createDeepCopy(machiningSetups);
                const newAssignedSetups = createDeepCopy(
                  assignedMachiningSetups
                );
                Object.keys(newAssignedSetups).forEach((setupId) => {
                  newAssignedSetups[setupId] = [];
                });
                newMachiningSetups?.forEach((setupInfo: any) => {
                  for (const feature of setupInfo?.features ?? []) {
                    delete feature?.featureInformation?.strategies;
                  }
                });
                dispatch({
                  type: SET_MACHINE_SETUP,
                  payload: [...newMachiningSetups],
                });
                dispatch({
                  type: SET_ORIGIN_MACHINE_SETUP,
                  payload: [...newMachiningSetups],
                });
                dispatch({
                  type: SET_ORIGIN_ASSIGNED_SETUPS,
                  payload: newAssignedSetups,
                });
                dispatch({
                  type: SET_ASSIGNED_SETUPS,
                  payload: newAssignedSetups,
                });
                navigate("/project?tab=feature");
              }}
            >
              Edit
            </Button>
          )}
          {projectInfo.cam_software === "siemens" && (
            <Button
              size="md"
              onClick={() => {
                showGCodeFileNameReminder();
                handleSiemensNx();
              }}
              onDoubleClick={downloadToolpaths}
            >
              Next: Generate Toolpath
            </Button>
          )}
          {projectInfo.cam_software === "master cam" && (
            <Button
              size="md"
              onClick={() => {
                showGCodeFileNameReminder();
                handlemasterCam();
              }}
              onDoubleClick={downloadToolpaths}
            >
              Next: Generate Toolpath
            </Button>
          )}
          {projectInfo.cam_software === "fusion 360" && (
            <Button
              size="md"
              onClick={() => {
                showGCodeFileNameReminder();
                handleFusion();
              }}
              onDoubleClick={downloadToolpaths}
            >
              Next: Generate Toolpath
            </Button>
          )}
        </div>
      </div>
    </DefaultLayout>
  );
};

export default Summary;
