import Button from "components/Button";
import {
  SET_LOADING_BAR,
  SET_MACHINE_SETUP,
  SET_ORIGIN_ASSIGNED_SETUPS,
  SET_PROJECT_INFO,
} from "constant";
import { GlobalContext } from "context";
import DefaultLayout from "Layouts/DefaultLayout";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { compareSetups } from "../ManufacturingProcess/utils";
import { createDeepCopy } from "utils";
import { useApiCall } from "hooks";
import { getStockToRemoveValuesAPI } from "services";
import ManufacturingSummaryAccordian from "components/ManufacturingSummaryAccordian";

const ManufacturingProcessSummary = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(GlobalContext);
  const [getStockToRemoveValues] = useApiCall(getStockToRemoveValuesAPI);
  const {
    machiningSetups,
    mappingForMachiningSetups,
    assignedMachiningSetups,
    projectInfo,
    originAssignedMachiningSetups,
    visiblefeature_ids,
  } = state;

  const handleRedirect = (tab: string) => {
    navigate(`/project?tab=${tab}`);
  };

  let operationsCount = 1;
  const visiblefeatureIdsSet = new Set(visiblefeature_ids?.[0]);

  const handleContinue = async () => {
    dispatch({
      type: SET_PROJECT_INFO,
      payload: {
        ...projectInfo,
        numberOfSetups: Object.keys(mappingForMachiningSetups).length,
      },
    });
    // Removing stock to remove functionality for specific release
    handleRedirect("tool");
    return;

    let makeRequestForStockToRemoveValues = false;
    // Compare assigned setups with origin setups
    for (const setupId in assignedMachiningSetups) {
      const assignedSetup = assignedMachiningSetups[setupId] || [];
      const originSetup = originAssignedMachiningSetups?.[setupId] || [];

      if (compareSetups(assignedSetup, originSetup)) {
        makeRequestForStockToRemoveValues = true;
        break;
      }
    }

    if (!makeRequestForStockToRemoveValues) {
      handleRedirect("tool");
      return;
    }
    // Make API call for stockToRemove values
    const requestPayload: {
      features: any[];
      assignedMachiningSetups: { [setupId: string]: any[] };
    } = {
      features: [],
      assignedMachiningSetups: createDeepCopy(assignedMachiningSetups),
    };

    const machiningSetupsCopy = createDeepCopy(machiningSetups);

    machiningSetupsCopy?.[0]?.features.forEach((feature: any) => {
      const featureInformation = feature?.featureInformation;

      if (!visiblefeatureIdsSet.has(feature.feature_id)) return;

      const selected_strategy =
        feature.featureInformation?.strategies?.[0]?.machining_strategy?.find(
          (item: any) => item?.isSelected
        );

      if (!selected_strategy) return;

      const featureObject = {
        feature_name: featureInformation.feature_data?.feature_name,
        feature_type: featureInformation.feature_data?.feature_type,
        feature_id: feature.feature_id,
        feature_info: featureInformation.feature_data?.feature_info,
        ...(featureInformation.coordinate_system && {
          coordinate_system: featureInformation.coordinate_system,
        }),
        ...(featureInformation.position_info && {
          position_info: featureInformation.position_info,
        }),
        machining_strategy: {
          passes: [...selected_strategy.passes],
        },
      };

      requestPayload.features.push(featureObject);
    });

    dispatch({
      type: SET_LOADING_BAR,
      payload: true,
    });

    try {
      const resp = await getStockToRemoveValues(requestPayload);
      if (!resp) return;
      resp.forEach((updatedFeature: any) => {
        const featureObject = machiningSetupsCopy?.[0]?.features?.find(
          (feature: any) => updatedFeature?.feature_id === feature.feature_id
        );

        const originalStrategy =
          featureObject.featureInformation?.strategies?.[0]?.machining_strategy?.find(
            (item: any) => item?.isSelected
          );

        if (!originalStrategy) return;

        updatedFeature?.machining_strategy?.passes?.forEach(
          (pass: any, ind1: number) => {
            pass.operations?.forEach((operation: any, ind2: number) => {
              if (!operation.stock_to_remove) return;

              const stockToRemove =
                originalStrategy.passes?.[ind1]?.operations?.[ind2]
                  ?.stock_to_remove;

              if (
                !stockToRemove ||
                stockToRemove.axial !== operation.stock_to_remove.axial ||
                stockToRemove.radial !== operation.stock_to_remove.radial ||
                stockToRemove.volume !== operation.stock_to_remove.volume
              ) {
                delete operation.parameters;
                delete operation.selectedTool;
                delete operation.selectedToolId;
              }

              originalStrategy.passes[ind1].operations[ind2] = operation;
            });
          }
        );
      });

      dispatch({
        type: SET_MACHINE_SETUP,
        payload: machiningSetupsCopy,
      });
      dispatch({
        type: SET_ORIGIN_ASSIGNED_SETUPS,
        payload: assignedMachiningSetups,
      });
    } catch (error: any) {
    } finally {
      dispatch({
        type: SET_LOADING_BAR,
        payload: false,
      });
      handleRedirect("tool");
    }
  };

  return (
    <DefaultLayout>
      <div className="flex flex-col h-full overflow-hidden w-full">
        <div className="flex flex-row justify-between items-center mt-4 mx-4 my-4">
          <span className="font-semibold text-xl">
            Manufacturing Process Summary
          </span>
        </div>
        <div className="flex px-[16px] pb-[8px] ">
          <button
            type="button"
            className="text-blue-700 text-[16px] font-semibold px-[40px] py-[16px] border-b-[4px] border-blue-700 cursor-pointer"
          >
            Setups Summary
          </button>
          {/* <button
            type="button"
            className="text-gray-700 text-[16px] font-semibold px-[40px] py-[16px] border-b-[4px] border-transparent cursor-pointer "
          >
            Feature Summary
          </button> */}
        </div>
        <div className="flex w-full flex-col gap-[8px] px-[16px] h-full pb-[16px] overflow-auto items-start">
          {Object.keys(mappingForMachiningSetups)?.map((setupId: any) => {
            let startNumber = operationsCount;
            operationsCount += assignedMachiningSetups[setupId].length;
            return (
              <ManufacturingSummaryAccordian
                checked={false}
                setupId={setupId}
                operationStartingNumber={startNumber}
              />
            );
          })}
        </div>
      </div>
      <div className="bg-white border-t justify-end mt-auto border-gray-200 px-4 py-2 flex gap-4 font-semibold">
        <Button
          type="button"
          variant="gray"
          onClick={() => {
            handleRedirect("manufacturing-process");
          }}
        >
          Edit
        </Button>
        <Button type="button" onClick={() => handleContinue()}>
          Next: Cutting Tools & Parameters
        </Button>
      </div>
    </DefaultLayout>
  );
};

export default ManufacturingProcessSummary;
