import DarkLogo from "assets/images/logo/dark-logo.svg";
import HomeIcon from "assets/images/icons/home-icon.svg";
import { Link, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import UserImg from "assets/images/user.png";
import { Auth } from "aws-amplify";
import { GlobalContext } from "context/GlobalContext";
import { useApiCall } from "hooks";
import { getUserAPI } from "services";
import { Storage } from "aws-amplify";
import React from "react";
import { SET_PROFILE_IMG } from "constant";
import { Button, Menu, MenuItem } from "@mui/material";

const MENU_LIST = [
  {
    label: "Setting",
    url: "/setting",
  },
];

const TopNavigation = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const { userInfo, profileImgUrl } = state;
  const navigate = useNavigate();
  const [getUser, loading, userData] = useApiCall(getUserAPI);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!userInfo || !profileImgUrl) return;
    getUser({
      userRecordId: userInfo?.["sub"],
      organizationId: userInfo?.["custom:organizationId"],
    });
  }, [userInfo]);

  useEffect(() => {
    if (!userData?.profileImgPath || !!profileImgUrl) return;
    getImageUrl(userData.profileImgPath);
  }, [userData?.profileImgPath]);

  const handleSignOut = async () => {
    try {
      await Auth.signOut();
      navigate("/login");
    } catch (err) {
      console.log("Sign Out Err", err);
    }
  };

  const getImageUrl = async (key: string) => {
    try {
      const url = await Storage.get(key);
      dispatch({
        type: SET_PROFILE_IMG,
        payload: url,
      });
    } catch (error) {
      dispatch({
        type: SET_PROFILE_IMG,
        payload: "",
      });
    }
  };

  const handleLogo = () => {
    if (!!userInfo) {
      navigate("/projects");
    } else {
      navigate("/");
    }
  };

  return (
    <div className="w-full h-[48px] px-4 py-2 flex flex-row shadow-nav justify-between">
      <div
        className="flex flex-row items-center cursor-pointer"
        onClick={handleLogo}
      >
        <img src={DarkLogo} alt="logo" />
        <p className="text-black font-semibold text-xl ml-[10px] mr-[10px]">
          Generative Programming
        </p>
        <img src={HomeIcon} alt="logo" />
      </div>
      <div className="flex flx-row items-center space-x-2 font-medium">
        <Button
          color="inherit"
          disableRipple={true}
          id={`profile-button`}
          aria-controls={open ? `profile-menu` : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <div className="w-8 h-8 rounded-lg drop-shadow-avatar">
            <img
              src={profileImgUrl}
              alt="avatar"
              className="w-full h-full object-cover rounded-lg"
              onError={(
                event: React.SyntheticEvent<HTMLImageElement, Event>
              ) => {
                event.currentTarget.onerror = null;
                event.currentTarget.src = UserImg;
              }}
            />
          </div>
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          id="profile-menu"
          MenuListProps={{
            "aria-labelledby": "profile-button",
            sx: {
              py: 0,
              fontWeight: "medium",
            },
          }}
        >
          {MENU_LIST.map((menu, idx) => (
            <Link to={menu.url}>
              <MenuItem
                key={idx}
                sx={[
                  {
                    "&:hover": {
                      color: "#1570EF",
                      backgroundColor: "#EFF8FF",
                    },
                  },
                  {
                    py: "0.5rem",
                    px: "0.75erm",
                    width: "12rem",
                    fontWeight: 500,
                  },
                ]}
              >
                <p>{menu.label}</p>
              </MenuItem>
            </Link>
          ))}
          <MenuItem
            sx={[
              {
                "&:hover": {
                  color: "#1570EF",
                  backgroundColor: "#EFF8FF",
                },
              },
              {
                py: "0.5rem",
                px: "0.75erm",
                width: "12rem",
                fontWeight: 500,
              },
            ]}
            onClick={handleSignOut}
          >
            <p onClick={handleSignOut} className="!font-medium">
              Sign Out
            </p>
          </MenuItem>
        </Menu>
        {/* <div>
          <img src={CharmCirleIcon} alt="charm-circle" />
        </div> */}
      </div>
    </div>
  );
};

export default React.memo(TopNavigation);
