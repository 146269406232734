const ProjectIcon = ({ color = 'black' }) => {
  return <svg
    width="20"
    height="17"
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.25"
      y="0.25"
      width="19.5"
      height="12.0878"
      rx="1.75"
      stroke={color}
      strokeWidth="0.5"
    />
    <rect
      x="1.44995"
      y="1.41797"
      width="17.1"
      height="9.49237"
      stroke={color}
      strokeWidth="0.5"
    />
    <rect
      x="7.98328"
      y="12.4485"
      width="3.9"
      height="3.13359"
      stroke={color}
      strokeWidth="0.5"
    />
    <path
      d="M3.87385 16.75C3.99051 16.1476 4.52075 15.6927 5.15723 15.6927H14.5761C15.2125 15.6927 15.7428 16.1476 15.8594 16.75H3.87385Z"
      stroke={color}
      strokeWidth="0.5"
    />
    <line
      x1="3.71667"
      y1="1.16797"
      x2="3.71667"
      y2="10.771"
      stroke={color}
      strokeWidth="0.5"
    />
    <line
      x1="1.19995"
      y1="8.4447"
      x2="18.4"
      y2="8.4447"
      stroke={color}
      strokeWidth="0.5"
    />
    <line
      x1="3.84998"
      y1="5.979"
      x2="11.4833"
      y2="5.979"
      stroke={color}
      strokeWidth="0.5"
      strokeLinecap="round"
      strokeDasharray="1 1"
    />
    <line
      x1="5.05005"
      y1="8.16602"
      x2="5.05005"
      y2="8.70418"
      stroke={color}
      strokeWidth="0.5"
      strokeLinecap="round"
    />
    <line
      x1="5.71667"
      y1="8.16602"
      x2="5.71667"
      y2="8.70418"
      stroke={color}
      strokeWidth="0.5"
      strokeLinecap="round"
    />
    <line
      x1="6.38342"
      y1="8.16602"
      x2="6.38342"
      y2="8.70418"
      stroke={color}
      strokeWidth="0.5"
      strokeLinecap="round"
    />
    <line
      x1="7.05005"
      y1="8.16602"
      x2="7.05005"
      y2="8.70418"
      stroke={color}
      strokeWidth="0.5"
      strokeLinecap="round"
    />
    <line
      x1="7.71667"
      y1="8.16602"
      x2="7.71667"
      y2="8.70418"
      stroke={color}
      strokeWidth="0.5"
      strokeLinecap="round"
    />
    <line
      x1="8.38342"
      y1="8.16602"
      x2="8.38342"
      y2="8.70418"
      stroke={color}
      strokeWidth="0.5"
      strokeLinecap="round"
    />
    <line
      x1="9.05005"
      y1="8.16602"
      x2="9.05005"
      y2="8.70418"
      stroke={color}
      strokeWidth="0.5"
      strokeLinecap="round"
    />
    <line
      x1="9.71667"
      y1="8.16602"
      x2="9.71667"
      y2="8.70418"
      stroke={color}
      strokeWidth="0.5"
      strokeLinecap="round"
    />
    <line
      x1="10.3834"
      y1="8.16602"
      x2="10.3834"
      y2="8.70418"
      stroke={color}
      strokeWidth="0.5"
      strokeLinecap="round"
    />
    <line
      x1="11.05"
      y1="8.16602"
      x2="11.05"
      y2="8.70418"
      stroke={color}
      strokeWidth="0.5"
      strokeLinecap="round"
    />
    <line
      x1="11.7167"
      y1="8.16602"
      x2="11.7167"
      y2="8.70418"
      stroke={color}
      strokeWidth="0.5"
      strokeLinecap="round"
    />
    <line
      x1="12.3834"
      y1="8.16602"
      x2="12.3834"
      y2="8.70418"
      stroke={color}
      strokeWidth="0.5"
      strokeLinecap="round"
    />
    <line
      x1="13.05"
      y1="8.16602"
      x2="13.05"
      y2="8.70418"
      stroke={color}
      strokeWidth="0.5"
      strokeLinecap="round"
    />
    <line
      x1="13.7167"
      y1="8.16602"
      x2="13.7167"
      y2="8.70418"
      stroke={color}
      strokeWidth="0.5"
      strokeLinecap="round"
    />
    <line
      x1="14.3834"
      y1="8.16602"
      x2="14.3834"
      y2="8.70418"
      stroke={color}
      strokeWidth="0.5"
      strokeLinecap="round"
    />
    <line
      x1="15.05"
      y1="8.16602"
      x2="15.05"
      y2="8.70418"
      stroke={color}
      strokeWidth="0.5"
      strokeLinecap="round"
    />
    <line
      x1="15.7167"
      y1="8.16602"
      x2="15.7167"
      y2="8.70418"
      stroke={color}
      strokeWidth="0.5"
      strokeLinecap="round"
    />
    <line
      x1="16.3834"
      y1="8.16602"
      x2="16.3834"
      y2="8.70418"
      stroke={color}
      strokeWidth="0.5"
      strokeLinecap="round"
    />
    <line
      x1="17.05"
      y1="8.16602"
      x2="17.05"
      y2="8.70418"
      stroke={color}
      strokeWidth="0.5"
      strokeLinecap="round"
    />
    <line
      x1="17.7167"
      y1="8.16602"
      x2="17.7167"
      y2="8.70418"
      stroke={color}
      strokeWidth="0.5"
      strokeLinecap="round"
    />
    <line
      x1="3.44995"
      y1="2.08594"
      x2="4.01662"
      y2="2.08594"
      stroke={color}
      strokeWidth="0.5"
      strokeLinecap="round"
    />
    <line
      x1="3.44995"
      y1="2.86462"
      x2="4.01662"
      y2="2.86462"
      stroke={color}
      strokeWidth="0.5"
      strokeLinecap="round"
    />
    <line
      x1="3.44995"
      y1="3.64319"
      x2="4.01662"
      y2="3.64319"
      stroke={color}
      strokeWidth="0.5"
      strokeLinecap="round"
    />
    <line
      x1="3.44995"
      y1="4.42188"
      x2="4.01662"
      y2="4.42188"
      stroke={color}
      strokeWidth="0.5"
      strokeLinecap="round"
    />
    <line
      x1="3.44995"
      y1="5.20044"
      x2="4.01662"
      y2="5.20044"
      stroke={color}
      strokeWidth="0.5"
      strokeLinecap="round"
    />
    <line
      x1="3.44995"
      y1="5.97913"
      x2="4.01662"
      y2="5.97913"
      stroke={color}
      strokeWidth="0.5"
      strokeLinecap="round"
    />
    <line
      x1="3.44995"
      y1="6.75769"
      x2="4.01662"
      y2="6.75769"
      stroke={color}
      strokeWidth="0.5"
      strokeLinecap="round"
    />
    <rect
      x="11.3167"
      y="1.54773"
      width="2.16667"
      height="2.09542"
      stroke={color}
      strokeWidth="0.5"
    />
    <path
      d="M11.85 3.75378H12.8833V4.73965L12.3921 5.2815L11.85 4.73336V3.75378Z"
      stroke={color}
      strokeWidth="0.5"
    />
    <line
      x1="12.3833"
      y1="6.60876"
      x2="12.3833"
      y2="6.88739"
      stroke={color}
      strokeWidth="0.5"
      strokeLinecap="round"
    />
  </svg>;
}

export default ProjectIcon
