import { useState, useCallback } from "react";
import { toast } from "react-toastify";

export const useApiCall = (service: any, showToastError: boolean = true) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const fetchData: any = useCallback(async (param: any) => {
    setLoading(true);
    try {
      const result = await service(param);
      setLoading(false);
      if (result?.status || result?.data || result?.body) {
        setData(result?.data || result?.result || result?.body);
        return result?.data || result?.result || result?.body;
      } else {
        if (showToastError) {
          toast(result?.message);
        }
        return;
      }
    } catch (error: any) {
      setLoading(false);
      setError(error?.message);
      if (showToastError) {
        toast(error?.message);
      }
      return;
    }
  }, []);

  return [fetchData, loading, data, error];
};
