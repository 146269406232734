import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { ClickAwayListener } from "@mui/material";
import { useState } from "react";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#D1E9FF",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#D1E9FF",
    color: "black",
    maxWidth: "200px",
    padding: "8px",
    fontSize: theme.typography.pxToRem(12),
  },
}));

interface ITooltipV2 {
  title: React.ReactNode;
  openOnHover?: boolean;
  children: React.ReactNode;
}
const TooltipV2: React.FC<ITooltipV2> = ({
  title,
  openOnHover = true,
  children,
}) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <HtmlTooltip
          slotProps={{ popper: { disablePortal: true } }}
          onClose={handleTooltipClose}
          placement="top"
          disableFocusListener
          disableTouchListener
          open={openOnHover ? undefined : open}
          disableHoverListener={!openOnHover}
          title={title}
          arrow
        >
          <div className="inline-block">
            {openOnHover ? (
              children
            ) : (
              <button
                onClick={() => {
                  setOpen((open) => !open);
                }}
              >
                {children}
              </button>
            )}
          </div>
        </HtmlTooltip>
      </div>
    </ClickAwayListener>
  );
};

export default TooltipV2;
