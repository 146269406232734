import Button from "components/Button";
import Modal from "components/Modal";
import WarningSign from "assets/images/icons/uil_exclamation-triangle.svg";

interface IErrorModal {
  isOpen: boolean;
  setIsOpen: any;
  errorMessages: any;
}

const SetupsAssignErrorModal: React.FC<IErrorModal> = ({
  isOpen,
  setIsOpen,
  errorMessages = [],
}) => {
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} size="default">
      <Modal.Header>
        <div className="flex flex-col items-center justify-between pb-[16px] gap-2">
          <img src={WarningSign} alt="" />
          <h3 className="text-lgleading-6 text-gray-700 font-bold">
            Setups Assignment Failed
          </h3>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="mt-0">
          <ul className="list-disc pl-[15px]">
            {errorMessages?.map((error: any, idx: number) => {
              return (
                <li
                  key={idx}
                  className={`${
                    idx !== errorMessages.length - 1
                      ? "border-b border-gray-300 py-2"
                      : ""
                  }`}
                >
                  <h2 className=" text-gray-700 font-semibold text-[14px]">
                    {error.heading}
                  </h2>
                  <div
                    className="text-[12px] text-gray-700"
                    dangerouslySetInnerHTML={{ __html: error.message }}
                  ></div>
                </li>
              );
            })}
          </ul>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex flex-row justify-center space-x-4 w-full">
          <Button
            size="md"
            variant="modalGray"
            className="modal-button"
            onClick={() => setIsOpen(false)}
          >
            Ok
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default SetupsAssignErrorModal;
