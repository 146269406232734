import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import uniqid from "uniqid";
import "./style.css";
import FeatureCheckbox from "components/FeatureCheckbox";
import { GlobalContext } from "context";
import { assignSetup, unAssignSetup } from "../ManufacturingProcess/utils";
import { SET_ASSIGNED_SETUPS, SET_MACHINE_SETUP } from "constant";
import { Tooltip } from "react-tooltip";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import TooltipIslandInfo from "components/TooltipIslandInfo";
import FeatureInfoContent from "components/FeatureInfoToolTip";
import FeatureInfoIcon from "assets/images/icons/feature-info.svg";
import DropDownMenu from "components/DropdownMenu";
import { toast } from "react-toastify";
import SetupAssignmentStatusIcon from "components/SetupAssignmentStatusIcon";
import TooltipV2 from "components/ToolTipV2";

export interface IAccordionProps {
  checked: boolean;
  index?: number;
  feature?: any;
  selectedOperations?: Set<string>;
  setSelectedOperations?: any;
  setErrorMessages?: any;
  errorModalIsOpen?: boolean;
  setErrorModalIsOpen?: any;
}

const FeatureAccordian: React.FC<IAccordionProps> = ({
  checked,
  index,
  feature,
  selectedOperations,
  setSelectedOperations,
  setErrorMessages,
  setErrorModalIsOpen,
  errorModalIsOpen,
}) => {
  const { state, dispatch } = useContext(GlobalContext);
  const {
    mappingForMachiningSetups,
    assignedMachiningSetups,
    machiningSetups,
    projectInfo,
  } = state;
  const [isChecked, setIsChecked] = useState(checked);
  const [selectedOperationsCount, setSelectedOperationsCount] = useState(0);
  const [featureSelected, setFeatureSelected] = useState(false);
  const [numberOfUnassignedOperations, setNumberofUnassignedOperations] =
    useState(0);
  const totalOperationsCount = feature?.operations?.length;
  let assignmentStatus: 0 | 1 | 2;
  switch (true) {
    case numberOfUnassignedOperations === totalOperationsCount:
      assignmentStatus = 0;
      break;
    case numberOfUnassignedOperations >= 1:
      assignmentStatus = 1;
      break;
    case numberOfUnassignedOperations === 0:
      assignmentStatus = 2;
      break;
    default:
      assignmentStatus = 0;
  }

  const handleChange = (e: any) => {
    setIsChecked(e.target.checked);
  };
  useEffect(() => {
    let selectedOperationsCount = 0;
    feature?.operations?.forEach((operation: any) => {
      selectedOperationsCount += selectedOperations?.has(
        `${feature.feature_id}<>${operation.strategyIndex}<>${operation.passIndex}<>${operation.operationIndex}`
      )
        ? 1
        : 0;
    });
    setSelectedOperationsCount(selectedOperationsCount);
    if (!selectedOperationsCount) {
      setFeatureSelected(false);
    } else if (selectedOperationsCount === numberOfUnassignedOperations)
      setFeatureSelected(true);
  }, [selectedOperations]);
  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  useEffect(() => {
    let numberOfUnassignedOperations = 0;
    feature?.operations?.forEach((op: any) => {
      numberOfUnassignedOperations += op.assignSetUpId ? 0 : 1;
    });
    setNumberofUnassignedOperations(numberOfUnassignedOperations);
  }, [feature]);

  const _uniqid = uniqid();
  const islandInfo = feature?.feature_info?.island_info;

  function selectAllOperations() {
    feature?.operations?.forEach((operation: any) => {
      if (!operation.assignSetUpId)
        setSelectedOperations((selectedOperations: any) => {
          let newSelectedOperations = new Set(selectedOperations);
          newSelectedOperations.add(
            `${feature.feature_id}<>${operation.strategyIndex}<>${operation.passIndex}<>${operation.operationIndex}`
          );
          return newSelectedOperations;
        });
    });
    setSelectedOperationsCount(numberOfUnassignedOperations);
  }

  function removeAllOperations() {
    feature?.operations?.forEach((operation: any) => {
      setSelectedOperations((selectedOperations: any) => {
        let newSelectedOperations = new Set(selectedOperations);
        newSelectedOperations.delete(
          `${feature.feature_id}<>${operation.strategyIndex}<>${operation.passIndex}<>${operation.operationIndex}`
        );
        return newSelectedOperations;
      });
    });
    setSelectedOperationsCount(0);
  }
  return (
    <>
      <tr className="bg-gray-200 border-b border-gray-300 dark:bg-gray-800 dark:border-gray-700 w-full">
        <td className="w-[68px] p-[8px] ">
          <div className="flex items-center h-full gap-[12px]">
            <input
              type="checkbox"
              id={_uniqid}
              name={_uniqid}
              className="popup-input absolute opacity-0 -z-1"
              checked={isChecked}
              onChange={handleChange}
            />
            <label
              className={clsx(
                "tab-heading flex justify-between cursor-pointer relative bg-gray-200 border-b border-gray-200",
                {
                  "!border-b-0": !isChecked,
                }
              )}
              htmlFor={_uniqid}
            ></label>
            <FeatureCheckbox
              checked={!!featureSelected || selectedOperationsCount > 0}
              notAll={
                selectedOperationsCount > 0 &&
                selectedOperationsCount !== numberOfUnassignedOperations
              }
              disabled={!numberOfUnassignedOperations}
              onChange={(event: any) => {
                if (event.target.checked) {
                  selectAllOperations();
                } else {
                  removeAllOperations();
                }
                setFeatureSelected(event.target.checked);
              }}
              darkBorder={!!numberOfUnassignedOperations}
            />
          </div>
        </td>
        <td
          colSpan={4}
          className="p-[8px] text-gray-600 text-[14px] font-semibold"
        >
          <div className="flex gap-[4px] items-center">
            Feature Name: {feature?.name}
            <img
              src={FeatureInfoIcon}
              alt=""
              id={`feature-${feature?.feature_id}`}
            />
            <Tooltip
              anchorSelect={`#feature-${feature?.feature_id}`}
              style={{
                backgroundColor: "#D1E9FF",
                color: "#344054",
                boxShadow:
                  "1px 0px 4px rgba(0, 0, 0, .15), 0px 1px 4px rgba(0, 0, 0, .25)",
                width: "240px",
                padding: "8px",
                borderRadius: "4px",
              }}
              place={"bottom"}
              positionStrategy="fixed"
              clickable
            >
              {!islandInfo?.islands?.length ? (
                <FeatureInfoContent
                  feature_info={feature?.feature_info}
                  feature_name={feature?.feature_name}
                  measurement_unit={projectInfo?.unit}
                />
              ) : (
                <Carousel
                  showArrows={false}
                  showThumbs={false}
                  showIndicators={true}
                  showStatus={false}
                  transitionTime={0}
                >
                  <FeatureInfoContent
                    feature_info={feature?.feature_info}
                    feature_name={feature?.feature_name}
                    measurement_unit={projectInfo?.unit}
                  />
                  {islandInfo?.islands?.length &&
                    islandInfo?.islands?.map?.(
                      (islandData: any, idx2: number) => {
                        return (
                          <TooltipIslandInfo
                            key={`${feature.feature_id}_${idx2}`}
                            island={islandData}
                            index={idx2}
                            unit={" " + projectInfo?.unit}
                          ></TooltipIslandInfo>
                        );
                      }
                    )}
                </Carousel>
              )}
            </Tooltip>
          </div>
        </td>
        <td className="px-5 py-auto">
          <TooltipV2
            title={
              <div className="text-gray-600 text-[14px]">
                {`${
                  totalOperationsCount - numberOfUnassignedOperations
                }/${totalOperationsCount} ops assigned`}
              </div>
            }
          >
            <SetupAssignmentStatusIcon assignmentStatus={assignmentStatus} />
          </TooltipV2>
        </td>
      </tr>
      {feature?.operations?.map((operation: any, ind: number) => {
        return (
          <tr
            className={`bg-gray-50 border-b border-gray-200 dark:bg-gray-800 ${
              isChecked ? "" : "hidden"
            } duration-150`}
            key={ind}
          >
            <td className="w-[68px] p-[8px]"></td>
            <td className="p-[8px] text-gray-600 text-[14px] font-semibold">
              <FeatureCheckbox
                onChange={(event: any) => {
                  if (event.target.checked) {
                    setSelectedOperations((selectedOperations: Set<string>) => {
                      let newSelectedOperations = new Set(selectedOperations);
                      newSelectedOperations?.add(
                        `${feature.feature_id}<>${operation.strategyIndex}<>${operation.passIndex}<>${operation.operationIndex}`
                      );
                      return newSelectedOperations;
                    });
                    setSelectedOperationsCount(selectedOperationsCount + 1);
                  } else {
                    setSelectedOperations((selectedOperations: Set<string>) => {
                      let newSelectedOperations = new Set(selectedOperations);
                      newSelectedOperations?.delete(
                        `${feature.feature_id}<>${operation.strategyIndex}<>${operation.passIndex}<>${operation.operationIndex}`
                      );
                      return newSelectedOperations;
                    });
                    setSelectedOperationsCount(selectedOperationsCount - 1);
                    setFeatureSelected(false);
                  }
                }}
                checked={
                  !!selectedOperations?.has(
                    `${feature.feature_id}<>${operation.strategyIndex}<>${operation.passIndex}<>${operation.operationIndex}`
                  )
                }
                disabled={operation.assignSetUpId}
                label={`${operation?.tool_path} (${operation?.tool_path_style})`}
              />
            </td>
            <td className="p-[8px] text-gray-600 text-[14px] font-semibold">
              {operation?.pass}
            </td>
            <td className="p-[8px] text-gray-600 text-[14px] font-semibold">
              {`${operation?.operation} (${operation?.location})`}
            </td>
            <td className="p-[8px] text-gray-600 text-[14px] font-semibold">
              {operation?.tool_type}
            </td>
            <td className="p-[8px] text-gray-600 text-[14px] font-semibold !w-[200px]">
              {operation.assignSetUpId ? (
                <button
                  className="inline-flex items-center gap-[6px] bg-blue-100 text-blue-600 !h-[26px] px-[8px] py-[4px] rounded-[4px] text-[14px] font-semibold my-1.5"
                  onClick={() => {
                    const { changedAssignedSetups, changedMachiningSetups } =
                      unAssignSetup(
                        operation.pass_number,
                        operation.assignSetUpId,
                        machiningSetups,
                        assignedMachiningSetups
                      );
                    dispatch({
                      type: SET_ASSIGNED_SETUPS,
                      payload: JSON.parse(
                        JSON.stringify(changedAssignedSetups)
                      ),
                    });
                    dispatch({
                      type: SET_MACHINE_SETUP,
                      payload: JSON.parse(
                        JSON.stringify(changedMachiningSetups)
                      ),
                    });
                  }}
                >
                  <span>
                    Setup{" "}
                    {mappingForMachiningSetups[operation.assignSetUpId]?.index +
                      1}
                  </span>

                  <svg
                    width="13"
                    height="14"
                    viewBox="0 0 13 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.78906 9.70866L9.20573 4.29199L3.78906 9.70866ZM3.78906 4.29199L9.20573 9.70866L3.78906 4.29199Z"
                      fill="#2563EB"
                    />
                    <path
                      d="M3.78906 4.29199L9.20573 9.70866M3.78906 9.70866L9.20573 4.29199L3.78906 9.70866Z"
                      stroke="#2563EB"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              ) : (
                <DropDownMenu
                  handleButtonClick={
                    Object.keys(mappingForMachiningSetups ?? {}).length === 0
                      ? () => {
                          toast.error(
                            "Please create alteast 1 setup before assignment."
                          );
                        }
                      : null
                  }
                  id={`${feature?.feature_id}-${operation?.passIndex}`}
                  className="border !rounded-[16px] border-dashed bg-gray-100 border-gray-400 !h-[26px] !w-[120px] font-semibold text-gray-600 text-[12px]
                  px-[5px] py-[2px] !m-0"
                  label="Assign Setup..."
                  useArrowIcon={true}
                  menuItems={Object.keys(mappingForMachiningSetups ?? {})?.map(
                    (setupId: string) => {
                      return {
                        label: `Setup
                      ${mappingForMachiningSetups[setupId]?.index + 1}`,
                        value: setupId,
                      };
                    }
                  )}
                  onClick={(setupId: string) => {
                    const {
                      changedMachiningSetups,
                      changedAssignedMachiningSetups,
                      errorMessage,
                    } = assignSetup(
                      operation.operationIndex,
                      operation.passIndex,
                      operation.strategyIndex,
                      feature.feature_id,
                      assignedMachiningSetups,
                      machiningSetups,
                      setupId,
                      mappingForMachiningSetups
                    );
                    if (!errorMessage) {
                      dispatch({
                        type: SET_MACHINE_SETUP,
                        payload: JSON.parse(
                          JSON.stringify(changedMachiningSetups)
                        ),
                      });
                      dispatch({
                        type: SET_ASSIGNED_SETUPS,
                        payload: JSON.parse(
                          JSON.stringify(changedAssignedMachiningSetups)
                        ),
                      });
                      if (
                        selectedOperations?.delete(
                          `${feature.feature_id}<>${operation.strategyIndex}<>${operation.passIndex}<>${operation.operationIndex}`
                        )
                      ) {
                        setSelectedOperationsCount(
                          (selectedCount) => selectedCount - 1
                        );
                        if (selectedOperationsCount === 1)
                          setFeatureSelected(false);
                      }
                      setNumberofUnassignedOperations(
                        (assignedCount) => assignedCount - 1
                      );
                    } else {
                      setErrorMessages([
                        {
                          heading: `${feature?.name} : ${operation.operation}`,
                          message: errorMessage,
                        },
                      ]);
                      setErrorModalIsOpen(true);
                    }
                  }}
                />
              )}
            </td>
          </tr>
        );
      })}
    </>
  );
};
export default FeatureAccordian;
