import { v4 as uuid } from "uuid";
export function prepareSetupData(numberOfSetups: number) {
  let newMachiningSetups: any = [
      {
        machiningSetupNumber: 0,
        toolLibraryId: "",
        toolLibraryName: "",
        features: [],
      },
    ],
    newAssignedMachiningSetups: any = {},
    newMappingForMachiningSetups: any = {};
  for (let i = 1; i <= numberOfSetups; i++) {
    const setupId = "setupId" + uuid();
    newAssignedMachiningSetups[setupId] = [];
    newMappingForMachiningSetups[setupId] = {
      name: `OP${i}`,
      index: i - 1,
      programName: `P${i}`,
    };
  }
  return {
    newMachiningSetups,
    newAssignedMachiningSetups,
    newMappingForMachiningSetups,
  };
}

export function modifyCloneSetups(
  machiningSetups: any,
  projectInputValues: any
) {
  const newMachiningSetups: any = machiningSetups ?? [
    {
      machiningSetupNumber: 0,
      toolLibraryId: "",
      toolLibraryName: "",
      features: [],
    },
  ];

  // Update machiningSetups according to changed input values.
  // Change material_info, machine_info and empty strategies

  newMachiningSetups[0]?.features?.forEach((feature: any) => {
    feature.featureInformation.strategies = [];
    feature.featureInformation.feature_data.machine_info = {
      name: projectInputValues?.machineName || "Hermle c800",
      rpm: projectInputValues?.max_spindle_speed?.max_spindle_speed || 1000,
      axes: projectInputValues?.numberOfAxis?.toString() || "3",
    };
    feature.featureInformation.feature_data.material_info = {
      material: projectInputValues?.material || "p | steel",
      sub_material: projectInputValues?.subMaterial || "alloy steel",
    };
  });

  return newMachiningSetups;
}

export async function createAndUpdateUserSelection(
  userInfo: any,
  ncProjectId: any,
  values: any,
  machiningSetups: any,
  mappingForMachiningSetups: any,
  assignedMachiningSetups: any,
  postCreateUserSelection: any,
  putProject: any
) {
  const payload: any = {
    organizationId: userInfo?.["custom:organizationId"],
    streamStep: "initial-trigger",
    selectionId: "",
    ncProjectId: ncProjectId,
    userRecordId: userInfo?.sub,
    machineId: "",
    machineType: "",
    workpieceCADfile: "",
    workpieceId: "",
    workpiecePathToCADfile: "",
    workpieceMaterial: values?.material,
    workpieceSubMaterial: values?.subMaterial,
    workpieceRawDimensions: {
      length: "",
      width: "",
      height: "",
    },
    machiningSetups,
    mappingForMachiningSetups,
    assignedMachiningSetups,
  };
  try {
    let userSelectionResp: any = await postCreateUserSelection(payload);
    const putProjectPayload = {
      organizationId: userInfo?.["custom:organizationId"],
      ncProjectId,
      userRecordId: userInfo?.sub,
      userSelectionId: userSelectionResp?.selectionId,
    };
    await putProject(putProjectPayload);
  } catch (err) {}
}

// Code for downloading excel file with seperate worksheets for different setups
// try {
//   dispatch({
//     type: SET_LOADING_BAR,
//     payload: true,
//   });
//   let wb = TableToExcel.default.initWorkBook();
//   for (const setupId in mappingForMachiningSetups) {
//     wb = TableToExcel.default.tableToSheet(
//       wb,
//       excelDataRefs?.current?.[setupId],
//       {
//         sheet: {
//           name: `Setup ${mappingForMachiningSetups[setupId]?.index + 1}`,
//         },
//       }
//     );
//   }
//   TableToExcel.default.save(wb, "file.xlsx");
//   dispatch({
//     type: SET_LOADING_BAR,
//     payload: false,
//   });
// } catch (err) {
//   console.error(err);
//   dispatch({
//     type: SET_LOADING_BAR,
//     payload: false,
//   });
// }
