import { useState, useEffect, useContext, useCallback } from "react";
import Modal from "components/Modal";
import copy from "copy-to-clipboard";
import { FiCopy, FiX } from "react-icons/fi";
import Button from "components/Button";
import {
  useLocalFusionApiCall,
  useLocalSiemensNxApiCall,
  useLocalMasterCamApiCall,
} from "hooks";
import { GlobalContext } from "context";
import { extractToolPathDataFromMachiningSetups } from "utils";

interface IShareURLModal {
  data?: any;
  isOpen: boolean;
  setIsOpen?: any;
}

const ShareURLModal: React.FC<IShareURLModal> = ({
  setIsOpen,
  isOpen,
  data,
}) => {
  const { state } = useContext(GlobalContext);
  const {
    machiningSetups,
    streamStep,
    featureMapping,
    visiblefeature_ids,
    projectInfo,
    setupNumber,
    mappingForMachiningSetups,
  } = state;
  const [copied, setCopied] = useState(false);
  const [fetchFunsionData, fushionloading, fushionData, fushionError] =
    useLocalFusionApiCall();
  const [fetchSiemensNxData, siemensNxLoading, siemensNxData, siemensNxError] =
    useLocalSiemensNxApiCall();
  const [fetchMasterCamData, MasterCamLoading, MasterCamData, MasterCamError] =
    useLocalMasterCamApiCall();
  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
  }, [copied]);

  const downloadToolpaths = useCallback(() => {
    const a = document.createElement("a");
    const fileContent = {
      organizationId: projectInfo?.organizationId,
      streamStep: "initial-trigger",
      selectionId: projectInfo?.userSelectionId,
      ncProjectId: projectInfo?.ncProjectId,
      userRecordId: projectInfo?.userRecordId,
      machineId: "",
      machineType: "",
      workpieceCADfile: "",
      workpieceId: "",
      workpiecePathToCADfile: "",
      workpieceMaterial: projectInfo?.material,
      workpieceSubMaterial: projectInfo?.subMaterial,

      workpieceRawDimensions: {
        length: "",
        width: "",
        height: "",
      },
      machiningSetups,
      projectInfo,
    };
    document.body.appendChild(a);
    const json = JSON.stringify(fileContent, null, 4),
      blob = new Blob([json], { type: "application/json" }),
      url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = "toolpaths";
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }, [machiningSetups, projectInfo]);

  const handleOpen = (e: boolean) => {
    if (!e) {
      setIsOpen(false);
    }
  };

  const visiblefeatureIdsSet: any = new Set(visiblefeature_ids?.[0]);

  const handleFusion = () => {
    fetchFunsionData("stream", machiningSetups);
  };

  const handleSiemensNx = () => {
    const setupIdx = machiningSetups?.findIndex(
      (setup: any) => setup?.machiningSetupNumber === setupNumber
    );
    const toolpaths = extractToolPathDataFromMachiningSetups(
      setupIdx,
      machiningSetups,
      featureMapping,
      visiblefeatureIdsSet,
      projectInfo?.unit,
      projectInfo?.numberOfAxis,
      mappingForMachiningSetups
    );
    fetchSiemensNxData("generate_tool_path", toolpaths);
  };

  const handlemasterCam = () => {
    const setupIdx = machiningSetups?.findIndex(
      (setup: any) => setup?.machiningSetupNumber === setupNumber
    );
    const toolpaths = extractToolPathDataFromMachiningSetups(
      setupIdx,
      machiningSetups,
      featureMapping,
      visiblefeatureIdsSet,
      projectInfo?.unit,
      projectInfo?.numberOfAxis,
      mappingForMachiningSetups
    );
    fetchMasterCamData("generate_tool_path", toolpaths);
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={handleOpen} size="default">
      <Modal.Header>
        <div className="flex flex-row items-center justify-between pb-4">
          <h3 className="text-lg font-medium leading-6 text-black-222">
            Excel File Download
          </h3>
          <FiX
            className="w-6 h-6 cursor-pointer text-black-222"
            onClick={() => setIsOpen(false)}
          />
        </div>
      </Modal.Header>
      <Modal.Body className="p-0">
        <div>
          <div className="p-4 border-surface-default border border-solid rounded flex flex-nowrap flex-row justify-between items-center">
            <span className="truncate w-[calc(100%-24px)] mr-1">
              {data?.url}
            </span>
            <FiCopy
              className="h-5 w-5 cursor-pointer text-black-222"
              onClick={() => {
                copy(data?.url);
                setCopied(true);
              }}
            />
          </div>
          {copied && (
            <div className="flex w-full justify-end">
              <span className="text-surface-default text-sm ">Copied!</span>
            </div>
          )}

          {streamStep && streamStep?.toString()?.toLowerCase() !== "" && (
            <div className="flex justify-center space-x-4">
              {projectInfo.cam_software === "siemens" && (
                <Button
                  className="!rounded-full py-1.5 w-[180px] mt-6"
                  onClick={handleSiemensNx}
                  onDoubleClick={downloadToolpaths}
                >
                  Generate Toolpath
                </Button>
              )}
              {projectInfo.cam_software === "master cam" && (
                <Button
                  className="!rounded-full py-1.5 w-[180px] mt-6"
                  onClick={handlemasterCam}
                  onDoubleClick={downloadToolpaths}
                >
                  Generate Toolpath
                </Button>
              )}
              {projectInfo.cam_software === "fusion 360" && (
                <Button
                  className="!rounded-full py-1.5 w-[180px] mt-6"
                  onClick={handleFusion}
                  onDoubleClick={downloadToolpaths}
                >
                  Generate Toolpath
                </Button>
              )}
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ShareURLModal;
