// assignmentStatus = 0, when no operation is assigned
// assignmentStatus = 1, when one or more operations are assigned
// assignmentStatus = 2, when all operations are assigned

const SetupAssignmentStatusIcon: React.FC<{ assignmentStatus: 0 | 1 | 2 }> = ({
  assignmentStatus,
}) => {
  let fillColor: string;
  switch (assignmentStatus) {
    case 0:
      fillColor = "#344054";
      break;
    case 1:
      fillColor = "#DC6803";
      break;
    case 2:
      fillColor = "#12B76A";
      break;
  }

  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_11539_113116"
        className="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="14"
        height="14"
      >
        <path
          d="M5.66927 2.33333C5.66927 3.06971 6.26622 3.66667 7.0026 3.66667C7.73898 3.66667 8.33594 3.06971 8.33594 2.33333C8.33594 1.59695 7.73898 1 7.0026 1C6.26622 1 5.66927 1.59695 5.66927 2.33333Z"
          fill="#555555"
          stroke="white"
        />
        <path
          d="M10.3333 11.6663C10.3333 12.4027 10.9303 12.9997 11.6667 12.9997C12.403 12.9997 13 12.4027 13 11.6663C13 10.93 12.403 10.333 11.6667 10.333C10.9303 10.333 10.3333 10.93 10.3333 11.6663Z"
          fill="#555555"
          stroke="white"
        />
        <path
          d="M5.66927 11.6663C5.66927 12.4027 6.26622 12.9997 7.0026 12.9997C7.73898 12.9997 8.33594 12.4027 8.33594 11.6663C8.33594 10.93 7.73898 10.333 7.0026 10.333C6.26622 10.333 5.66927 10.93 5.66927 11.6663Z"
          fill="#555555"
          stroke="white"
        />
        <path
          d="M0.997396 11.6663C0.997396 12.4027 1.59435 12.9997 2.33073 12.9997C3.06711 12.9997 3.66406 12.4027 3.66406 11.6663C3.66406 10.93 3.06711 10.333 2.33073 10.333C1.59435 10.333 0.997396 10.93 0.997396 11.6663Z"
          fill="#555555"
          stroke="white"
        />
        <path
          d="M2.33073 10.3337V6.33366H11.6641V10.3337M6.9974 3.66699V10.3337"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </mask>
      <g mask="url(#mask0_11539_113116)">
        <path d="M15 -1L15 15H-1L-1 -1L15 -1Z" fill={fillColor} />
      </g>
    </svg>
  );
};

export default SetupAssignmentStatusIcon;
