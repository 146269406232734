import { Button, Menu, MenuItem } from "@mui/material";
import clsx from "clsx";
import { useState } from "react";

interface IDropDownMenu {
  id: string;
  className?: string;
  menuItems?: any;
  label: string;
  handleButtonClick?: Function | null;
  disabled?: boolean;
  useArrowIcon?: boolean;
  onClick: any;
}

const DropDownMenu: React.FC<IDropDownMenu> = ({
  id,
  className = "",
  label,
  menuItems,
  useArrowIcon = false,
  disabled = false,
  onClick = () => {},
  handleButtonClick = null,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (handleButtonClick) {
      handleButtonClick();
    }
    if (menuItems?.length) setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Button
        color="inherit"
        disabled={disabled}
        sx={{ textTransform: "none" }}
        disableRipple={true}
        id={`basic-button-${id}`}
        aria-controls={open ? `basic-menu-${id}` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <div className={clsx("flex justify-between items-baseline", className)}>
          <div>{label}</div>
          {useArrowIcon && (
            <div className="my-auto">
              <svg
                className={`${open ? "rotate-180" : ""}`}
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.08594 5.83301L7.0026 8.74967L9.91927 5.83301"
                  stroke="#475467"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          )}
        </div>
      </Button>
      <Menu
        id={`basic-menu-${id}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
          sx: { py: 0 },
        }}
      >
        {menuItems.map((menuItem: any, index: number) => (
          <MenuItem
            key={`menuItem-${id}-${index}`}
            className="w-[120px] text-[14px] z-10"
            onClick={() => {
              onClick(menuItem.value);
              handleClose();
            }}
          >
            <p>{menuItem.label}</p>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default DropDownMenu;
