import { useCallback, useState } from "react";
import { v4 as uuid } from "uuid";
declare const window: any;

export const useLocalMasterCamApiCall = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [data, setData] = useState<any>(null);

  const fetchData = useCallback((type: string, args: any) => {
    const callbackId = "lfReactPluginEvents_" + uuid();
    let timeoutId: NodeJS.Timeout;
    let isResolved = false;
    return new Promise<void>((resolve, reject) => {
      function handleReturnEvent(event: any) {
        if (!isResolved) {
          let response: any;
          try {
            response = JSON.parse(event.data);
            setData(response?.features || []);
          } catch (e) {
            setError("Error occured while trying to parse JSON");
          } finally {
            window.removeEventListener(callbackId, handleReturnEvent);
            clearTimeout(timeoutId);
            setLoading(false);
            isResolved = true;
            if (response?.statusCode !== 900) {
              reject(response);
            } else resolve(response.features);
          }
        }
      }

      function timeoutEventListner() {
        if (!isResolved) {
          window.removeEventListener(callbackId, handleReturnEvent);
          setError("Event timeout error");
          setLoading(false);
          isResolved = true;
          // Message when there is no response from CAM within the specified time limit.
          reject({
            message: "Please try again.",
          });
        }
      }

      setLoading(true);
      if (window.chrome?.webview) {
        window.addEventListener(callbackId, handleReturnEvent);
        window.chrome.webview.postMessage({ type, args, callbackId });
        // Removing the Event Listener after a certain threshold time for cases
        // where we do not recieve back any event so that the impact on the
        // memory comsumption is minimum. (default threshold -> 10 mins)
        timeoutId = setTimeout(() => {
          timeoutEventListner();
          // TODO: update threshold time when auto feature recognition is implemented for master cam
        }, 600000);
      } else {
        setLoading(false);
        reject({
          message: "Please open a 3D model to enable Auto Feature Recognition.",
        });
      }
    });
  }, []);

  return [fetchData as Function, loading, data, error];
};
