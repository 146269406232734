/**
 * Important ->
 * These are general validations for input fields. More accurate rules will be
 * present in each input form file specific to that feature type.
 */

import {
  BOTTOM_FACE_WALL_TYPE_OPTIONS,
  CHAMFER_HOLE_TYPE_OPTIONS,
  CHAMFER_TYPE_OPTIONS,
  FILLET_BOTTOM_TYPE_OPTIONS,
  FILLET_LOCATION_OPTIONS,
  FILLET_ORIENTATION_OPTIONS,
  FILLET_SHAPE_OPTIONS,
  FINISH_TYPE_OPTIONS,
  HOLE_BOTTOM_OPTIONS,
  HOLE_BOTTOM_TYPE_OPTIONS,
  HOLE_SURFACE_TYPE_OPTIONS,
  HOLE_TYPE_OPTIONS,
  POCKET_FLOOR_TYPE_OPTIONS,
  POCKET_TYPE_OPTIONS,
  POCKET_WALL_TYPE_OPTIONS,
  SIDE_FACE_BOTTOM_TYPE_OPTIONS,
  SIDE_FACE_SURFACE_TYPE_OPTIONS,
  SLOT_LOCATION_OPTIONS,
  SLOT_OPEN_SIDES_OPTIONS,
  SLOT_SLOT_TYPE_OPTIONS,
  SLOT_TYPE_OPTIONS,
  TOP_FACE_FINISHING_SURFACE_OPTIONS,
} from "constant";
import * as Yup from "yup";

const commonInputsValidation: any = {
  quantity: Yup.number().integer().positive().nullable(),
  vertical_clearance: Yup.number().min(0).nullable(),
  horizontal_clearance: Yup.number().min(0).nullable(),
};

export function simpleHoleFeatureDataValidation() {
  return Yup.object().shape({
    diameter: Yup.number().min(0).nullable(),
    depth: Yup.number().min(0).nullable(),
    hole_type: Yup.string()
      .oneOf([...HOLE_TYPE_OPTIONS.map((option: any) => option.value), null])
      .nullable(),
    finish_type: Yup.string()
      .oneOf([
        ...FINISH_TYPE_OPTIONS.map((option) => option.value).filter(
          (value) => value !== "custom"
        ),
        null,
      ])
      .nullable(),
    upper_tolerance: Yup.number().when("finish_type", {
      is: (finish_type: any) => {
        return [
          ...FINISH_TYPE_OPTIONS.map((option) => option.value).filter(
            (value) => value !== "custom"
          ),
        ].includes(finish_type);
      },
      then: Yup.number().strip(),
      otherwise: Yup.number().min(0).nullable(),
    }),
    lower_tolerance: Yup.number().when("finish_type", {
      is: (finish_type: any) => {
        return [
          ...FINISH_TYPE_OPTIONS.map((option) => option.value).filter(
            (value) => value !== "custom"
          ),
        ].includes(finish_type);
      },
      then: Yup.number().strip(),
      otherwise: Yup.number().nullable(),
    }),
    bottom_type: Yup.string()
      .oneOf([...HOLE_BOTTOM_TYPE_OPTIONS.map((elem) => elem.value), null])
      .nullable(),
    stock_to_remove: Yup.number().when("hole_type", {
      is: "hollow",
      then: Yup.number().min(0).nullable(),
      otherwise: Yup.number().strip(),
    }),
    bottom: Yup.string().when("bottom_type", {
      is: "blind",
      then: Yup.string()
        .oneOf([...HOLE_BOTTOM_OPTIONS.map((elem) => elem.value), null])
        .nullable(),
      otherwise: Yup.string().strip(),
    }),
    bottom_radius: Yup.number().when(["bottom_type", "bottom"], {
      is: (bottom_type: string, bottom: string) =>
        bottom_type === "blind" && bottom === "flat",
      then: Yup.number().min(0).nullable(),
      otherwise: Yup.number().strip(),
    }),
    bottom_angle: Yup.number().when(["bottom_type", "bottom"], {
      is: (bottom_type: string, bottom: string) =>
        bottom_type === "blind" && bottom === "v shape",
      then: Yup.number().integer().min(0).nullable(),
      otherwise: Yup.number().strip(),
    }),
    deburring: Yup.string().oneOf(["yes", "no", null]).nullable(),
    sequential: Yup.string().oneOf(["yes", "no", null]).nullable(),
    surface_type: Yup.string()
      .oneOf([...HOLE_SURFACE_TYPE_OPTIONS.map((elem) => elem.value), null])
      .nullable(),
    ...commonInputsValidation,
  });
}

export function threadHoleFeatureDataValidation() {
  return Yup.object().shape({
    thread_size: Yup.number().min(0).nullable(),
    minor_diameter: Yup.number().min(0).nullable(),
    thread_pitch: Yup.number().min(0).nullable(),
    thread_depth: Yup.number().min(0).nullable(),
    depth: Yup.number().min(0).nullable(),
    thread_angle: Yup.number().integer().min(0).nullable(),
    bottom_type: Yup.string()
      .oneOf([...HOLE_BOTTOM_TYPE_OPTIONS.map((elem) => elem.value), null])
      .nullable(),
    bottom: Yup.string().when("bottom_type", {
      is: "blind",
      then: Yup.string()
        .oneOf([...HOLE_BOTTOM_OPTIONS.map((elem) => elem.value), null])
        .nullable(),
      otherwise: Yup.string().strip(),
    }),
    bottom_radius: Yup.number().when(["bottom_type", "bottom"], {
      is: (bottom_type: string, bottom: string) =>
        bottom_type === "blind" && bottom === "flat",
      then: Yup.number().min(0).nullable(),
      otherwise: Yup.number().strip(),
    }),
    bottom_angle: Yup.number().when(["bottom_type", "bottom"], {
      is: (bottom_type: string, bottom: string) =>
        bottom_type === "blind" && bottom === "v shape",
      then: Yup.number().integer().min(0).nullable(),
      otherwise: Yup.number().strip(),
    }),
    deburring: Yup.string().oneOf(["yes", "no", null]).nullable(),
    sequential: Yup.string().oneOf(["yes", "no", null]).nullable(),
    surface_type: Yup.string()
      .oneOf([...HOLE_SURFACE_TYPE_OPTIONS.map((elem) => elem.value), null])
      .nullable(),
    hole_type: Yup.string()
      .oneOf([...HOLE_TYPE_OPTIONS.map((option: any) => option.value), null])
      .nullable(),
    stock_to_remove: Yup.number().when("hole_type", {
      is: "hollow",
      then: Yup.number().min(0).nullable(),
      otherwise: Yup.number().strip(),
    }),
    finish_type: Yup.string()
      .oneOf([
        ...FINISH_TYPE_OPTIONS.map((option) => option.value).filter(
          (value) => value !== "custom"
        ),
        null,
      ])
      .nullable(),
    upper_tolerance: Yup.number().when("finish_type", {
      is: (finish_type: any) => {
        return [
          ...FINISH_TYPE_OPTIONS.map((option) => option.value).filter(
            (value) => value !== "custom"
          ),
        ].includes(finish_type);
      },
      then: Yup.number().strip(),
      otherwise: Yup.number().min(0).nullable(),
    }),
    lower_tolerance: Yup.number().when("finish_type", {
      is: (finish_type: any) => {
        return [
          ...FINISH_TYPE_OPTIONS.map((option) => option.value).filter(
            (value) => value !== "custom"
          ),
        ].includes(finish_type);
      },
      then: Yup.number().strip(),
      otherwise: Yup.number().nullable(),
    }),
    ...commonInputsValidation,
  });
}

export function topFaceFeatureDataValidation() {
  return Yup.object().shape({
    length: Yup.number().min(0).nullable(),
    width: Yup.number().min(0).nullable(),
    stock_to_remove: Yup.number().min(0).nullable(),
    finishing_surface: Yup.string()
      .oneOf([
        ...TOP_FACE_FINISHING_SURFACE_OPTIONS.map((elem) => elem.value),
        null,
      ])
      .nullable(),
    ...commonInputsValidation,
  });
}

export function bottomFaceFeatureDataValidation() {
  return Yup.object().shape({
    length: Yup.number().min(0).nullable(),
    width: Yup.number().min(0).nullable(),
    wall_height: Yup.number().when("wall_type", {
      is: (wall_type: string) =>
        ["tapered", "flat", "curved"].includes(wall_type),
      then: Yup.number().integer().min(0).nullable(),
      otherwise: Yup.number().strip(),
    }),
    floor_radius: Yup.number().when("wall_type", {
      is: (wall_type: string) =>
        ["tapered", "flat", "curved"].includes(wall_type),
      then: Yup.number().integer().min(0).nullable(),
      otherwise: Yup.number().strip(),
    }),
    wall_type: Yup.string()
      .oneOf([...BOTTOM_FACE_WALL_TYPE_OPTIONS.map((elem) => elem.value), null])
      .nullable(),
    wall_angle: Yup.number().when("wall_type", {
      is: (wall_type: string) => wall_type === "tapered",
      then: Yup.number().integer().min(0).nullable(),
      otherwise: Yup.number().strip(),
    }),
    ...commonInputsValidation,
  });
}

export function sideFaceFeatureDataValidation() {
  return Yup.object().shape({
    length: Yup.number().min(0).nullable(),
    depth: Yup.number().min(0).nullable(),
    stock_to_remove: Yup.number().min(0).nullable(),
    surface_type: Yup.string()
      .oneOf([
        ...SIDE_FACE_SURFACE_TYPE_OPTIONS.map((elem) => elem.value),
        null,
      ])
      .nullable(),
    taper_angle: Yup.number().when("surface_type", {
      is: (surface_type: string) => surface_type === "tapered",
      then: Yup.number().min(0).nullable(),
      otherwise: Yup.number().strip(),
    }),
    taper_length: Yup.number().when("surface_type", {
      is: (surface_type: string) => surface_type === "tapered",
      then: Yup.number().min(0).nullable(),
      otherwise: Yup.number().strip(),
    }),
    bottom_type: Yup.string()
      .oneOf([...SIDE_FACE_BOTTOM_TYPE_OPTIONS.map((elem) => elem.value), null])
      .nullable(),
    bottom_radius: Yup.number().when("bottom_type", {
      is: (bottom_type: string) => bottom_type === "solid",
      then: Yup.number().min(0).nullable(),
      otherwise: Yup.number().strip(),
    }),
    bottom_width: Yup.number().when("bottom_type", {
      is: (bottom_type: string) => bottom_type === "solid",
      then: Yup.number().min(0).nullable(),
      otherwise: Yup.number().strip(),
    }),
    ...commonInputsValidation,
  });
}

export function slotFeatureDataValidation() {
  return Yup.object().shape({
    length: Yup.number().min(0).nullable(),
    width: Yup.number().min(0).nullable(),
    depth: Yup.number().min(0).nullable(),
    slot_type: Yup.string()
      .oneOf([...SLOT_SLOT_TYPE_OPTIONS.map((elem) => elem.value), null])
      .nullable(),
    type: Yup.string().when("slot_type", {
      is: (slot_type: string) =>
        slot_type === "t-slot" || slot_type === "dovetail",
      then: Yup.string().oneOf(["open"]).required(),
      otherwise: Yup.string()
        .oneOf([...SLOT_TYPE_OPTIONS.map((elem) => elem.value), null])
        .nullable(),
    }),
    open_sides: Yup.string()
      .when("slot_type", {
        is: (slot_type: string) =>
          slot_type === "t-slot" || slot_type === "dovetail",
        then: Yup.string().oneOf(["both"]).required(),
      })
      .when(["slot_type", "type"], {
        is: (slot_type: string, type: string) =>
          slot_type === "simple" && type === "open",
        then: Yup.string()
          .oneOf([...SLOT_OPEN_SIDES_OPTIONS.map((elem) => elem.value), null])
          .nullable(),
        otherwise: Yup.string().strip(),
      }),
    section_depth: Yup.number().when("slot_type", {
      is: (slot_type: string) =>
        slot_type === "t-slot" || slot_type === "dovetail",
      then: Yup.number().min(0).nullable(),
      otherwise: Yup.number().strip(),
    }),
    section_length: Yup.number().when("slot_type", {
      is: (slot_type: string) =>
        slot_type === "t-slot" || slot_type === "dovetail",
      then: Yup.number().min(0).nullable(),
      otherwise: Yup.number().strip(),
    }),
    corner_radius: Yup.number().when(["type", "open_sides"], {
      is: (type: string, open_sides: string) => {
        return type === "closed" || open_sides === "one";
      },
      then: Yup.number().min(0).nullable(),
      otherwise: Yup.number().strip(),
    }),
    location: Yup.string().when(["slot_type", "open_sides"], {
      is: (slot_type: string, open_sides: string) =>
        slot_type === "simple" && open_sides === "both",
      then: Yup.string()
        .oneOf([...SLOT_LOCATION_OPTIONS.map((elem) => elem.value), null])
        .nullable(),
      otherwise: Yup.string().strip(),
    }),
    bottom_type: Yup.string()
      .oneOf([...SIDE_FACE_BOTTOM_TYPE_OPTIONS.map((elem) => elem.value), null])
      .nullable(),
    bottom_radius: Yup.number().when("bottom_type", {
      is: (bottom_type: string) => bottom_type === "solid",
      then: Yup.number().min(0).nullable(),
      otherwise: Yup.number().strip(),
    }),
    ...commonInputsValidation,
  });
}

export function filletFeatureDataValidation() {
  return Yup.object().shape({
    location: Yup.string()
      .oneOf([...FILLET_LOCATION_OPTIONS.map((elem) => elem.value), null])
      .nullable(),
    orientation: Yup.string().when("location", {
      is: (location: string) => location === "hole top",
      then: Yup.string().oneOf(["horizontal"]).required(),
      otherwise: Yup.string()
        .oneOf([...FILLET_ORIENTATION_OPTIONS.map((ele) => ele.value), null])
        .nullable(),
    }),
    shape: Yup.string().when("location", {
      is: (location: string) => location === "hole top",
      then: Yup.string().oneOf(["convex"]).required(),
      otherwise: Yup.string()
        .oneOf([...FILLET_SHAPE_OPTIONS.map((ele) => ele.value), null])
        .nullable(),
    }),
    bottom_type: Yup.string().when(["location", "orientation", "shape"], {
      is: (location: string, orientation: string, shape: string) =>
        location === "edge" &&
        orientation === "horizontal" &&
        shape === "convex",
      then: Yup.string()
        .oneOf([...FILLET_BOTTOM_TYPE_OPTIONS.map((ele) => ele.value), null])
        .nullable(),
      otherwise: Yup.string().strip(),
    }),
    length: Yup.number().when("location", {
      is: (location: string) => location === "edge",
      then: Yup.number().min(0).nullable(),
      otherwise: Yup.number().strip(),
    }),
    hole_diameter: Yup.number().when("location", {
      is: (location: string) => location === "hole top",
      then: Yup.number().min(0).nullable(),
      otherwise: Yup.number().strip(),
    }),
    radius: Yup.number().min(0).nullable(),
    ...commonInputsValidation,
  });
}

export function chamferFeatureDataValidation() {
  return Yup.object().shape({
    type: Yup.string()
      .oneOf([...CHAMFER_TYPE_OPTIONS.map((elem) => elem.value), null])
      .nullable(),
    edge_length: Yup.number().when("type", {
      is: (type: string) => type === "edge chamfer",
      then: Yup.number().min(0).nullable(),
      otherwise: Yup.number().strip(),
    }),
    hole_diameter: Yup.number().when("type", {
      is: (type: string) => type === "hole chamfer",
      then: Yup.number().min(0).nullable(),
      otherwise: Yup.number().strip(),
    }),
    hole_type: Yup.string().when("type", {
      is: "hole chamfer",
      then: Yup.string()
        .oneOf([
          ...CHAMFER_HOLE_TYPE_OPTIONS.map((option: any) => option.value),
          null,
        ])
        .nullable(),
      otherwise: Yup.string().strip(),
    }),
    hole_depth: Yup.number().when("type", {
      is: "hole chamfer",
      then: Yup.number().min(0).nullable(),
      otherwise: Yup.number().strip(),
    }),
    length: Yup.number().min(0).nullable(),
    angle: Yup.number().integer().min(0).nullable(),
    ...commonInputsValidation,
  });
}

export function grooveFeaureDataValidation() {
  return Yup.object().shape({
    outer_diameter: Yup.number().min(0).nullable(),
    width: Yup.number().min(0).nullable(),
    depth: Yup.number().min(0).nullable(),
    bottom_radius: Yup.number().min(0).nullable(),
    ...commonInputsValidation,
  });
}

export function pocketFeatureDataValidation() {
  return Yup.object().shape({
    length: Yup.number().min(0).nullable(),
    width: Yup.number().min(0).nullable(),
    depth: Yup.number().min(0).nullable(),
    pocket_type: Yup.string()
      .oneOf([...POCKET_TYPE_OPTIONS.map((elem) => elem.value), null])
      .nullable(),
    no_of_walls: Yup.number().when("pocket_type", {
      is: (pocket_type: string) => pocket_type === "open",
      then: Yup.number().oneOf([2, 3, null]).nullable(),
      otherwise: Yup.number().strip(),
    }),
    min_distance: Yup.number().when("pocket_type", {
      is: (pocket_type: string) => pocket_type === "closed",
      then: Yup.number().min(0).nullable(),
      otherwise: Yup.number().strip(),
    }),
    corner_radius: Yup.number().min(0).nullable(),
    wall_type: Yup.string()
      .oneOf([...POCKET_WALL_TYPE_OPTIONS.map((elem) => elem.value), null])
      .nullable(),
    taper_angle: Yup.number().when("wall_type", {
      is: (wall_type: string) => wall_type === "tapered",
      then: Yup.number().min(0).nullable(),
      otherwise: Yup.number().strip(),
    }),
    floor_type: Yup.string()
      .oneOf([...POCKET_FLOOR_TYPE_OPTIONS.map((elem) => elem.value), null])
      .nullable(),
    floor_radius: Yup.number().when("floor_type", {
      is: (floor_type: string) => floor_type === "solid",
      then: Yup.number().min(0).nullable(),
      otherwise: Yup.number().strip(),
    }),
    island_info: Yup.object().when("floor_type", {
      is: (floor_type: string) => floor_type === "solid",
      then: Yup.object()
        .shape({
          islands: Yup.array().of(
            Yup.object().shape({
              length: Yup.number().min(0).nullable(),
              width: Yup.number().min(0).nullable(),
              height: Yup.number().min(0).nullable(),
              floor_radius: Yup.number().min(0).nullable(),
              min_x_distance_to_face_edges: Yup.number().min(0).nullable(),
              min_y_distance_to_face_edges: Yup.number().min(0).nullable(),
            })
          ),
          min_distance_between_islands: Yup.number().when("islands", {
            is: (islands: any) => islands?.length > 1,
            then: Yup.number().min(0).required(),
            otherwise: Yup.number().strip(),
          }),
        })
        .nullable(),
      otherwise: Yup.object().strip(),
    }),
    ...commonInputsValidation,
  });
}
