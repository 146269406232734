const PocketIcon = ({ active = false }) => {
  const fillClass = active ? "#1570EF" : "black";
  const fillClass2 = active ? "#FFF2C3" : "#F5F5F5";
  const strokeClass = active ? "#1570EF" : "black";
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="4"
        width="11"
        height="11"
        rx="0.5"
        fill={fillClass2}
        stroke={strokeClass}
      />
      <path
        d="M11 15L14.0812 12.7991C14.344 12.6114 14.5 12.3083 14.5 11.9854V2C14.5 1.44772 14.0523 1 13.5 1H3.51462C3.19167 1 2.8886 1.15597 2.70088 1.41876L0.5 4.5"
        stroke={strokeClass}
      />
      <path d="M11.4999 4L13.9998 1.50002" stroke={strokeClass} />
      <rect
        x="2.5"
        y="6"
        width="7"
        height="7"
        fill={fillClass}
        stroke={strokeClass}
      />
    </svg>
  );
};

export default PocketIcon;
