import React from 'react'
import YellowLogoSmall from 'assets/images/logo/blue-logo-small.svg'

const Footer = () => {
  return (
    <div className="flex flex-row justify-between items-center px-[8px] py-[2px] w-full mt-auto bg-gray-100">
      <div className="flex flex-row">
        <span className="text-[12px]">Powered By</span>
        <img src={YellowLogoSmall} alt="logo" className="mx-1" />
        <span className="text-[12px] text-surface-default font-semibold">Lambda Function, Inc.</span>
      </div>
    </div>
  )
}

export default Footer
