const GrooveIcon = ({ active = false }) => {
  const fillClass = active ? "#1570EF" : "black";
  const strokeClass = active ? "#1570EF" : "black";

  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 2.8V14.8C1 14.8 5.51306 16.3 8.5 16.3C11.4869 16.3 16 14.8 16 14.8V10C16 10 13.9366 11.5633 12.4 12.1C9.74548 13.0272 5.2 12.7372 5.2 12.1V7.9C5.2 7.58515 5.67582 7.417 6.42771 7.31947C7.73532 7.14984 9.8779 7.1938 11.8048 7.05121C12.006 7.03632 12.2048 7.01941 12.4 7C14.3828 6.80293 16 5.8 16 5.8V2.8C16 2.8 11.2576 5.2 8.5 5.2C5.74244 5.2 1 2.8 1 2.8Z"
        fill={fillClass}
      />
      <path
        d="M5.2 7.9V12.1C5.2 12.7372 9.74548 13.0272 12.4 12.1C13.9366 11.5633 16 10 16 10L11.8048 7.05121C11.841 7.44913 12.0537 10.0463 11.5 10.6C10.9 11.2 6.40061 10.8998 6.40061 10.8998L6.42771 7.31947C5.67582 7.417 5.2 7.58515 5.2 7.9Z"
        fill={fillClass}
        fillOpacity="0.5"
      />
      <path
        d="M8.5 1C5.4879 1 1 2.8 1 2.8C1 2.8 5.74244 5.2 8.5 5.2C11.2576 5.2 16 2.8 16 2.8C16 2.8 11.5121 1 8.5 1Z"
        fill={fillClass}
        fillOpacity="0.5"
      />
      <path
        d="M6.40061 10.8998C6.40061 10.8998 10.9 11.2 11.5 10.6C12.0537 10.0463 11.841 7.44913 11.8048 7.05121C9.8779 7.1938 7.73532 7.14984 6.42771 7.31947L6.40061 10.8998Z"
        fill={fillClass}
        fillOpacity="0.3"
      />
      <path
        d="M1 2.8V14.8C1 14.8 5.51306 16.3 8.5 16.3C11.4869 16.3 16 14.8 16 14.8V10M1 2.8C1 2.8 5.4879 1 8.5 1C11.5121 1 16 2.8 16 2.8M1 2.8C1 2.8 5.74244 5.2 8.5 5.2C11.2576 5.2 16 2.8 16 2.8M16 2.8V5.8C16 5.8 14.3828 6.80293 12.4 7C12.2048 7.01941 12.006 7.03632 11.8048 7.05121M16 10C16 10 13.9366 11.5633 12.4 12.1C9.74548 13.0272 5.2 12.7372 5.2 12.1V7.9C5.2 7.58515 5.67582 7.417 6.42771 7.31946M16 10L11.8048 7.05121M11.8 7C11.8 7 11.8018 7.0179 11.8048 7.05121M6.42792 7.29191L6.42771 7.31946M11.8048 7.05121C9.8779 7.1938 7.73532 7.14984 6.42771 7.31946M11.8048 7.05121C11.841 7.44913 12.0537 10.0463 11.5 10.6C10.9 11.2 6.40061 10.8998 6.40061 10.8998L6.42771 7.31946"
        stroke={strokeClass}
        strokeWidth="0.5"
      />
    </svg>
  );
};

export default GrooveIcon;
