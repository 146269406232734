import { useState, useCallback, useEffect } from "react";
// import { toast } from 'react-toastify';

export const useLocalFusionApiCall = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState<any>(null);

  // const handleFusion = useCallback(() => {
  //   // @ts-ignore
  //   window.fusionJavaScriptHandler = {
  //     handle: (action: any, data: any) => {
  //       try {
  //         // switch (action) {
  //         //   case type:
  //         console.log("data", data);
  //           // setData(JSON.parse(data));
  //         //     break;
  //         // }
  //         // setLoading(false);
  //       } catch (e: any) {
  //         console.log(e);
  //         console.log(
  //           "exception caught with action: " + action + ", data: " + data
  //         );
  //         // setError(e);
  //         return "FAILED";
  //       }
  //       return "OK";
  //     },
  //   };
  // }, []);

  // useEffect(() => {
  //   handleFusion();
  // }, []);

  const fetchData = useCallback((type: string, args: any) => {
    setLoading(true);
    // @ts-ignore
    const localadsk = window.adsk;
    localadsk.fusionSendData(type, JSON.stringify(args)).then((res: any) => {
      setData(JSON.parse(res));
    });
  }, []);

  return [fetchData as Function, loading, data, error];
};
