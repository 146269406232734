import { useContext, useEffect, useMemo } from "react";
import Input from "components/Input";
import RadioComponent from "components/RadioComponent";
import {
  CHAMFER_HOLE_TYPE_OPTIONS,
  CHAMFER_TYPE_OPTIONS,
  CHAMFER_VALIDATION,
  POSITIVE_VALIDATION,
  QUANTITY_VALIDATION,
  SET_MACHINE_SETUP,
  decimalRegExp,
  onlyNumberRegexExp,
} from "constant";
import { useFormik } from "formik";
import * as Yup from "yup";
import { GlobalContext } from "context";
import TooltipContent from "../../FeatureToolTipContent";
import FeatureInfoIcon from "assets/images/icons/feature-info.svg";
import TooltipV2 from "components/ToolTipV2";

interface IChamferForm {
  feature_info?: any;
  feature_id?: string;
}

const init_chamfer_edge = {
  type: "",
  length: "",
  angle: "",
  quantity: "",
  hole_diameter: "",
  edge_length: "",
  hole_type: "",
  hole_depth: "",
  horizontal_clearance: "",
  vertical_clearance: "",
};

const ChamferForm: React.FC<IChamferForm> = ({ feature_info, feature_id }) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { machiningSetups, projectInfo, setupNumber } = state;

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        length: Yup.number().required("This field is required"),
        // .min(
        //   CHAMFER_VALIDATION.length.min[projectInfo?.unit || "mm"],
        //   `Value must be at least ${
        //     CHAMFER_VALIDATION.length.min[projectInfo?.unit || "mm"]
        //   }`
        // ),
        angle: Yup.number().required("This field is required"),
        // .min(1, "Value must be greater than 0")
        // .max(89, "Value must be less than 90"),
        quantity: QUANTITY_VALIDATION,
        edge_length: Yup.number().required("This field is required"),
        // .min(
        //   CHAMFER_VALIDATION.edge_length.min[projectInfo?.unit || "mm"],
        //   `Value must be at least ${
        //     CHAMFER_VALIDATION.edge_length.min[projectInfo?.unit || "mm"]
        //   }`
        // ),
        hole_diameter: Yup.number().required("This field is required"),
        // .min(
        //   CHAMFER_VALIDATION.hole_diameter.min[projectInfo?.unit || "mm"],
        //   `Value must be at least ${
        //     CHAMFER_VALIDATION.hole_diameter.min[projectInfo?.unit || "mm"]
        //   }`
        // ),
        hole_depth: Yup.number().required("This field is required"),
        // .min(
        //   CHAMFER_VALIDATION.hole_depth.min[projectInfo?.unit || "mm"],
        //   `Value must be at least ${
        //     CHAMFER_VALIDATION.hole_depth.min[projectInfo?.unit || "mm"]
        //   }`
        // ),
        horizontal_clearance: POSITIVE_VALIDATION,
        vertical_clearance: POSITIVE_VALIDATION,
      }),
    []
  );

  useEffect(() => {
    if (!feature_info) return;
    let formValues: any = {};
    Object.keys(init_chamfer_edge).forEach((key: string) => {
      formValues = {
        ...formValues,
        [key]: feature_info?.[key] ?? "",
      };
    });
    setValues(formValues);
  }, [feature_info]);

  const {
    handleChange,
    handleBlur,
    setValues,
    setFieldValue,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: init_chamfer_edge,
    validationSchema,
    onSubmit: () => {},
  });

  const handleChangeFunc = (key: string, value: any, flag: boolean = false) => {
    if (flag) setFieldValue(key, value);

    let updated_setups = [...machiningSetups];

    const setupIdx = updated_setups?.findIndex(
      (setup: any) => setup?.machiningSetupNumber === setupNumber
    );

    const feature_idx = updated_setups?.[setupIdx]?.features?.findIndex(
      (feature: any) => feature?.feature_id === feature_id
    );

    let updated_feature = {
      ...updated_setups?.[setupIdx]?.features?.[feature_idx]?.featureInformation
        ?.feature_data?.feature_info,
    };
    switch (key) {
      case "type":
        if (value === "edge chamfer") {
          delete updated_feature.hole_diameter;
          delete updated_feature.hole_type;
          delete updated_feature.hole_depth;
          setFieldValue("hole_diameter", "");
          setFieldValue("hole_type", "");
          setFieldValue("hole_depth", "");
        } else if (value === "hole chamfer") {
          delete updated_feature.edge_length;
          setFieldValue("edge_length", "");
        }
        updated_feature = {
          ...updated_feature,
          [key]: value,
        };
        break;
      case "hole_type":
        updated_feature = {
          ...updated_feature,
          [key]: value,
        };
        break;
      default:
        if (value !== "") {
          updated_feature = {
            ...updated_feature,
            [key]: Number(value),
          };
        } else delete updated_feature[key];
    }

    updated_setups[setupIdx].features[
      feature_idx
    ].featureInformation.feature_data.feature_info = updated_feature;

    dispatch({
      type: SET_MACHINE_SETUP,
      payload: updated_setups,
    });
  };

  return (
    <div className="relative">
      <div className="mt-4">
        <p className="font-semibold text-base text-gray-202">
          Feature Dimensions
        </p>
        <div className="flex flex-row mt-4">
          <div className="w-6/12">
            <Input
              handleFocus={handleBlur}
              placeholder="Length"
              className="mr-2"
              type="text"
              unit={projectInfo?.unit || "mm"}
              name="length"
              value={values?.length}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc("length", values?.length);
              }}
              infoTooltipTitle={TooltipContent("chamfer", "length")}
              invalid={touched.length && errors.length}
              helperText={touched.length && errors.length}
              regex={decimalRegExp}
            />
          </div>
          <div className="w-6/12">
            <Input
              handleFocus={handleBlur}
              placeholder="Angle"
              className="ml-2"
              type="text"
              name="angle"
              value={values?.angle}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc("angle", values?.angle);
              }}
              infoTooltipTitle={TooltipContent("chamfer", "angle")}
              invalid={touched.angle && errors.angle}
              helperText={touched.angle && errors.angle}
              regex={onlyNumberRegexExp}
            />
          </div>
        </div>
      </div>

      <div className="mt-4">
        <p className="font-semibold text-base text-gray-202">Other Inputs</p>
        <div className="flex flex-row mt-4">
          <div className="w-6/12">
            <Input
              handleFocus={handleBlur}
              placeholder="Quantity"
              className="mr-2"
              type="text"
              name="quantity"
              value={values?.quantity}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc("quantity", values?.quantity);
              }}
              infoTooltipTitle={TooltipContent("chamfer", "quantity")}
              invalid={touched.quantity && errors.quantity}
              helperText={touched.quantity && errors.quantity}
              regex={onlyNumberRegexExp}
            />
          </div>
          <div className="flex justify-between items-center w-6/12 py-[14px]">
            <div className="flex align-baseline ml-2">
              <label className="font-semibold text-gray-475 truncate mr-2">
                Type
              </label>
              <TooltipV2
                openOnHover={false}
                title={TooltipContent("chamfer", "type")}
              >
                <div className="my-auto">
                  <img
                    className="inline-block"
                    src={FeatureInfoIcon}
                    alt=""
                    height={16}
                    width={16}
                  />
                </div>
              </TooltipV2>
            </div>
            <RadioComponent
              options={CHAMFER_TYPE_OPTIONS}
              setValue={(val: any) => handleChangeFunc("type", val, true)}
              value={values?.type}
            />
          </div>
        </div>
        {values?.type === "hole chamfer" && (
          <>
            <div className="flex flex-row mt-4">
              <div className="w-6/12">
                <Input
                  handleFocus={handleBlur}
                  placeholder="Hole Diameter"
                  className="mr-2"
                  type="text"
                  unit={projectInfo?.unit || "mm"}
                  name="hole_diameter"
                  value={values?.hole_diameter}
                  onChange={handleChange}
                  onBlur={(e: any) => {
                    handleBlur(e);
                    handleChangeFunc("hole_diameter", values?.hole_diameter);
                  }}
                  infoTooltipTitle={TooltipContent("chamfer", "holeDiameter")}
                  invalid={touched.hole_diameter && errors.hole_diameter}
                  helperText={touched.hole_diameter && errors.hole_diameter}
                  regex={decimalRegExp}
                />
              </div>
              <div className="flex justify-between items-center w-6/12 py-[14px]">
                <div className="flex align-baseline">
                  <label className="font-semibold text-gray-475 truncate mr-2">
                    Hole type
                  </label>
                  <TooltipV2
                    openOnHover={false}
                    title={TooltipContent("chamfer", "holeType")}
                  >
                    <div className="my-auto">
                      <img
                        className="inline-block"
                        src={FeatureInfoIcon}
                        alt=""
                        height={16}
                        width={16}
                      />
                    </div>
                  </TooltipV2>
                </div>
                <RadioComponent
                  options={CHAMFER_HOLE_TYPE_OPTIONS}
                  setValue={(val: any) =>
                    handleChangeFunc("hole_type", val, true)
                  }
                  value={values?.hole_type}
                />
              </div>
            </div>
            <div className="flex flex-row mt-4">
              <div className="w-6/12">
                <Input
                  handleFocus={handleBlur}
                  placeholder="Hole Depth"
                  className="mr-2"
                  type="text"
                  unit={projectInfo?.unit || "mm"}
                  name="hole_depth"
                  value={values?.hole_depth}
                  onChange={handleChange}
                  onBlur={(e: any) => {
                    handleBlur(e);
                    handleChangeFunc("hole_depth", values?.hole_depth);
                  }}
                  infoTooltipTitle={TooltipContent("chamfer", "holeDepth")}
                  invalid={touched.hole_depth && errors.hole_depth}
                  helperText={touched.hole_depth && errors.hole_depth}
                  regex={decimalRegExp}
                />
              </div>
            </div>
          </>
        )}
        {values?.type === "edge chamfer" && (
          <div className="flex flex-row mt-4">
            <div className="w-6/12">
              <Input
                handleFocus={handleBlur}
                placeholder="Edge Length"
                className="mr-2"
                type="text"
                unit={projectInfo?.unit || "mm"}
                name="edge_length"
                value={values?.edge_length}
                onChange={handleChange}
                onBlur={(e: any) => {
                  handleBlur(e);
                  handleChangeFunc("edge_length", values?.edge_length);
                }}
                infoTooltipTitle={TooltipContent("chamfer", "edgeLength")}
                invalid={touched.edge_length && errors.edge_length}
                helperText={touched.edge_length && errors.edge_length}
                regex={decimalRegExp}
              />
            </div>
          </div>
        )}
      </div>
      <div className="mt-4">
        <p className="font-semibold text-base text-gray-202">
          Clearance Inputs
        </p>
        <div className="flex flex-row mt-4">
          <div className="flex w-6/12">
            <Input
              handleFocus={handleBlur}
              placeholder="Vertical Clearance"
              className="w-full mr-2"
              type="text"
              unit={projectInfo?.unit || "mm"}
              name="vertical_clearance"
              value={values?.vertical_clearance}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc(
                  "vertical_clearance",
                  values?.vertical_clearance
                );
              }}
              infoTooltipTitle={TooltipContent("chamfer", "verticalClearance")}
              invalid={touched.vertical_clearance && errors.vertical_clearance}
              helperText={
                touched.vertical_clearance && errors.vertical_clearance
              }
              regex={decimalRegExp}
            />
          </div>
          <div className="flex w-6/12">
            <Input
              handleFocus={handleBlur}
              placeholder="Horizontal Clearance"
              className="w-full ml-2"
              type="text"
              unit={projectInfo?.unit || "mm"}
              name="horizontal_clearance"
              value={values?.horizontal_clearance}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc(
                  "horizontal_clearance",
                  values?.horizontal_clearance
                );
              }}
              infoTooltipTitle={TooltipContent(
                "chamfer",
                "horizontalClearance"
              )}
              invalid={
                touched.horizontal_clearance && errors.horizontal_clearance
              }
              helperText={
                touched.horizontal_clearance && errors.horizontal_clearance
              }
              regex={decimalRegExp}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChamferForm;
