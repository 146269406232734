import Button from "components/Button";
import Input from "components/Input";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import LogoImg from "assets/images/logo.svg";
import { FiArrowLeft } from "react-icons/fi";
import { toast } from "react-toastify";
import { useContext } from "react";
import { GlobalContext } from "context/GlobalContext";
import { SET_FORGOT_FORM, SET_LOADING_BAR, emailValidation } from "constant";
import { Auth } from "aws-amplify";

const validationSchema = Yup.object().shape({
  email: emailValidation,
});

const ForgotPassword = () => {
  const { dispatch } = useContext(GlobalContext);
  const navigate = useNavigate();

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    values,
    errors,
    touched,
    isValid,
    dirty,
  } = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        dispatch({
          type: SET_LOADING_BAR,
          payload: true,
        });
        const response = await Auth.forgotPassword(values?.email.toLowerCase());
        dispatch({
          type: SET_LOADING_BAR,
          payload: false,
        });
        if (!response) return;
        dispatch({
          type: SET_FORGOT_FORM,
          payload: values,
        });
        navigate("/verify-password");
      } catch (err: any) {
        err?.message && toast.error(err?.message);
        dispatch({
          type: SET_LOADING_BAR,
          payload: false,
        });
      }
    },
  });

  return (
    <div className="flex items-center justify-center w-full h-full relative">
      <div className="fixed top-0 right-0 left-0 bottom-0 bg-auth-background w-full h-full bg-cover "></div>
      <div className="fixed top-0 right-0 left-0 bottom-0 splash-background"></div>
      <div className="w-6/12 h-full flex flex-col justify-between px-5 py-10 z-[1]">
        <img src={LogoImg} alt="logo" className="max-w-[180px] h-fit" />
        <div className="flex flex-col">
          <p className="text-surface-default font-semibold text-lg">
            Welcome to
          </p>
          <p className="text-black font-bold text-[40px] mb-6">
            Generative Programming
          </p>
          <p className="text-gray-494 font-medium">
            A tool that brings the power of artificial intelligence to CNC
            programmers by recommending machining strategies, optimal cutting
            tools & machining parameters, and more based on the specific
            features in your CAD files.
          </p>
        </div>
        <div className="flex flex-row justify-between items-center">
          <span className="text-gray-424 text-xs font-semibold">
            2024 © Lambda Function, Inc.
          </span>
          <span className="text-gray-424 text-xs font-semibold">v1.0.0</span>
        </div>
      </div>
      <div className="w-6/12 h-full flex justify-center relative pt-10 pb-16">
        <div className="w-full h-full overflow-auto px-5 flex justify-center items-center">
          <div className="flex flex-col max-w-[50vw] p-px my-auto h-fit w-8/12 min-w-[350px]">
            <div className="mb-[30px]">
              <p className="font-semibold text-[27px] text-black mb-1">
                Forgot password?
              </p>
              <div className="rounded bg-surface-default w-[48px] h-1"></div>
            </div>
            <p className="font-semibold text-gray-696 text-sm mb-4 max-w-[420px]">
              No worries, we'll send you reset instructions.
            </p>
            <form onSubmit={handleSubmit}>
              <Input
                handleFocus={handleBlur}
                label="Enter your registered email"
                type="email"
                name="email"
                className="min-w-[350px] mb-4"
                subClassName="!shadow-form-input !bg-white !rounded-xl"
                inputClassName="bg-transparent"
                value={values?.email}
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={touched.email && errors.email}
                helperText={touched.email && errors.email}
              />
              <Button
                className="w-full rounded-xl text-lg py-4 font-semibold mb-4"
                type="submit"
                disabled={!isValid}
              >
                Reset password
              </Button>
              <div className="flex justify-center items-center flex-row">
                <Link
                  to="/login"
                  className="text-blue-033 font-semibold text-sm flex flex-row items-center"
                >
                  <FiArrowLeft className="mr-2 font-bold" />
                  Back to login
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
