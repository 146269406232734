import cn from "classnames";

export interface TbodyProps {
  className?: string;
  children: any;
}

const Tbody: React.FC<TbodyProps> = ({ children, className }) => {
  return <tbody className={cn("bg-white", className)}>{children}</tbody>;
};

export default Tbody;
