import Button from "components/Button";
import Input from "components/Input";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import LogoImg from "assets/images/logo.svg";
import { useContext } from "react";
import { GlobalContext } from "context/GlobalContext";
import { SET_REGISTER_FORM } from "constant";
import PhoneInput from "components/PhoneInput";

const phoneRegExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{5,7}$/;

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("This field is required")
    .matches(/^[A-Za-z ]*$/, "Please enter valid name"),
  lastName: Yup.string()
    .required("This field is required")
    .matches(/^[A-Za-z ]*$/, "Please enter valid name"),
  orgFullName: Yup.string().required("This field is required"),
  adminEmail: Yup.string()
    .required("This field is required")
    .email("Invalid email"),
  adminPhone: Yup.string()
    .required("This field is required")
    .matches(phoneRegExp, "Phone number is not valid"),
});

const Register = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(GlobalContext);
  const { registerForm } = state;

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    values,
    errors,
    touched,
    isValid,
    dirty,
  } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      orgFullName: "",
      adminEmail: "",
      adminPhone: "",
    },
    validationSchema,
    onSubmit: () => {
      dispatch({
        type: SET_REGISTER_FORM,
        payload: { ...registerForm, ...values },
      });
      navigate("/signup-2");
    },
  });

  return (
    <div className="flex items-center justify-center w-full h-full relative">
      <div className="fixed top-0 right-0 left-0 bottom-0 bg-auth-background w-full h-full bg-cover "></div>
      <div className="fixed top-0 right-0 left-0 bottom-0 splash-background"></div>
      <div className="w-6/12 h-full flex flex-col justify-between px-5 py-10 z-[1]">
        <img src={LogoImg} alt="logo" className="max-w-[180px] h-fit" />
        <div className="flex flex-col">
          <p className="text-surface-default font-semibold text-lg">
            Welcome to
          </p>
          <p className="text-black font-bold text-[40px] mb-6">
            Generative Programming
          </p>
          <p className="text-gray-494 font-medium">
            A tool that brings the power of artificial intelligence to CNC
            programmers by recommending machining strategies, optimal cutting
            tools & machining parameters, and more based on the specific
            features in your CAD files.
          </p>
        </div>
        <div className="flex flex-row justify-between items-center">
          <span className="text-gray-424 text-xs font-semibold">
            2024 © Lambda Function, Inc.
          </span>
          <span className="text-gray-424 text-xs font-semibold">v1.0.0</span>
        </div>
      </div>
      <div className="w-6/12 h-full flex justify-center relative pt-10 pb-16">
        <div className="w-full h-full overflow-auto px-5 flex justify-center items-center">
          <div className="flex flex-col max-w-[50vw] p-px my-auto h-fit w-8/12 min-w-[350px]">
            <div className="mb-[30px]">
              <p className="font-semibold text-[27px] text-black mb-1">
                Get started
              </p>
              <div className="rounded bg-surface-default w-[48px] h-1"></div>
            </div>
            <form onSubmit={handleSubmit}>
              <Input
                handleFocus={handleBlur}
                label="First Name"
                type="string"
                name="firstName"
                className="min-w-[350px] mb-2"
                placeholderClassName="text-black-222"
                subClassName="!shadow-form-input !bg-white !rounded-xl"
                inputClassName="bg-transparent"
                value={values?.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={touched.firstName && errors.firstName}
                helperText={touched.firstName && errors.firstName}
              />
              <Input
                handleFocus={handleBlur}
                label="Last Name"
                type="string"
                name="lastName"
                className="min-w-[350px] mb-2"
                placeholderClassName="text-black-222"
                subClassName="!shadow-form-input !bg-white !rounded-xl"
                inputClassName="bg-transparent"
                value={values?.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={touched.lastName && errors.lastName}
                helperText={touched.lastName && errors.lastName}
              />
              <Input
                handleFocus={handleBlur}
                label="Company Name"
                name="orgFullName"
                className="min-w-[350px] mb-2"
                subClassName="!shadow-form-input !bg-white !rounded-xl"
                inputClassName="bg-transparent"
                value={values?.orgFullName}
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={touched.orgFullName && errors.orgFullName}
                helperText={touched.orgFullName && errors.orgFullName}
              />
              <Input
                handleFocus={handleBlur}
                label="Company Email"
                type="email"
                name="adminEmail"
                className="min-w-[350px] mb-2"
                subClassName="!shadow-form-input !bg-white !rounded-xl"
                inputClassName="bg-transparent"
                value={values?.adminEmail}
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={touched.adminEmail && errors.adminEmail}
                helperText={touched.adminEmail && errors.adminEmail}
              />
              <PhoneInput
                onlyCountries={["us", "ca", "de", "fr", "es", "gb", "in"]}
                country="us"
                label="Company Phone"
                className="min-w-[350px] mb-4"
                subClassName="!shadow-form-input !bg-white !rounded-xl"
                name="adminPhone"
                value={values?.adminPhone}
                onChange={(val: string) => {
                  setFieldValue("adminPhone", val);
                }}
                onBlur={handleBlur}
                invalid={touched.adminPhone && errors.adminPhone}
                helperText={touched.adminPhone && errors.adminPhone}
              />
              <Button
                className="w-full rounded-xl text-lg py-4 font-semibold mb-[10px]"
                type="submit"
                disabled={!isValid}
              >
                Get started
              </Button>
              <p className="font-semibold text-gray-696 text-sm text-center">
                Already have an account?{" "}
                <Link to="/login" className="text-blue-033">
                  Login
                </Link>
              </p>
            </form>
          </div>
        </div>
        <p className="text-gray-696 font-semibold text-xs fixed w-6/12 text-center bottom-[40px]">
          <a
            href="mailto:info@lambdafunction.ai"
            className="text-surface-default underline"
          >
            Get in Touch! to make it simple
          </a>
        </p>
      </div>
    </div>
  );
};

export default Register;
