import clsx from "clsx";
import { useContext, useEffect, useState } from "react";
import uniqid from "uniqid";
import "./index.css";
import { GlobalContext } from "context";
import { Tooltip } from "react-tooltip";
import FeatureInfoContent from "components/FeatureInfoToolTip";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import TooltipIslandInfo from "components/TooltipIslandInfo";

interface IManufacturingSummaryAccordian {
  checked: boolean;
  setupId: string;
  operationStartingNumber: number;
}

const ManufacturingSummaryAccordian: React.FC<
  IManufacturingSummaryAccordian
> = ({ checked, setupId, operationStartingNumber }) => {
  const { state } = useContext(GlobalContext);
  const {
    mappingForMachiningSetups,
    assignedMachiningSetups,
    machiningSetups,
    featureMapping,
    projectInfo,
  } = state;
  const [isChecked, setIsChecked] = useState(checked);

  const handleChange = (e: any) => {
    setIsChecked(e.target.checked);
  };

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);
  const _uniqid = uniqid();
  return (
    <div className="w-full flex flex-col gap-[4px] border border-gray-200 rounded-[8px] w-full">
      <input
        type="checkbox"
        id={_uniqid}
        name={_uniqid}
        className="popup-accordion-input absolute opacity-0 -z-1"
        checked={isChecked}
        onChange={handleChange}
      />
      <label
        className={clsx(
          "setup-tab-label flex justify-between bg-white cursor-pointer relative ml-5 pl-8",
          {
            "!border-b-0": !isChecked,
          }
        )}
        htmlFor={_uniqid}
      >
        <div className="w-full flex items-center text-gray-700 text-[16px] font-semibold gap-[4px] p-[8px]">
          Setup {` ${mappingForMachiningSetups?.[setupId]?.index + 1}`}
          {assignedMachiningSetups?.[setupId]?.length > 0 && (
            <span className="text-[14px] font-semibold text-gray-600">
              {`(${operationStartingNumber} - ${
                assignedMachiningSetups?.[setupId]?.length +
                operationStartingNumber -
                1
              })`}
            </span>
          )}
        </div>
      </label>
      <div className="tab-content max-h-0 bg-white text-ellipsis whitespace-nowrap duration-150 overflow-auto">
        <div className="relative w-full overflow-hidden pl-[60px] py-[4px] pr-[4px]">
          <div className="w-full overflow-x-auto">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-100 rounded-[12px] ">
                <tr>
                  <th
                    scope="col"
                    className="pl-[21px] pr-[8px] py-[17px] text-[14px] font-semibold text-gray-600 rounded-tl-[8px] rounded-bl-[8px] bg-gray-200"
                  >
                    Feature name
                  </th>
                  <th
                    scope="col"
                    className="px-[8px] py-[17px] text-[14px] font-semibold text-gray-600 bg-gray-200"
                  >
                    Tool path
                  </th>
                  <th
                    scope="col"
                    className="px-[8px] py-[17px] text-[14px] font-semibold text-gray-600 bg-gray-200"
                  >
                    Cutting pass
                  </th>
                  <th
                    scope="col"
                    className="px-[8px] py-[17px] text-[14px] font-semibold text-gray-600 bg-gray-200"
                  >
                    Operation
                  </th>
                  <th
                    scope="col"
                    className="pr-[21px] pl-[8px] py-[17px] text-[14px] font-semibold text-gray-600 bg-gray-200 rounded-tr-[8px] rounded-br-[8px]"
                  >
                    Tool type
                  </th>
                </tr>
              </thead>
              <tbody>
                {assignedMachiningSetups?.[setupId]?.map((operation: any) => {
                  const passObject =
                    machiningSetups?.[0]?.features[
                      featureMapping?.[operation.featureId]?.index
                    ]?.featureInformation?.strategies?.[0]?.machining_strategy[
                      operation.strategyIndex
                    ]?.passes[operation.passIndex];
                  const selectedToolPath =
                    passObject?.operations?.[operation.operationIndex]
                      ?.tool_paths?.[
                      passObject?.operations?.[operation.operationIndex]
                        ?.selectedToolPathInd ?? 0
                    ];
                  const feature =
                    machiningSetups?.[0]?.features[
                      featureMapping?.[operation.featureId]?.index
                    ]?.featureInformation;
                  const islandInfo =
                    feature?.feature_data?.feature_info?.island_info;
                  return (
                    <>
                      <tr>
                        <td colSpan={5} className="h-[4px] "></td>
                      </tr>
                      <tr className="">
                        <td className="pl-[21px] pr-[8px] py-[17px] text-gray-600 text-[14px] font-semibold  rounded-tl-[8px] rounded-bl-[8px] bg-gray-100">
                          <div className="flex gap-[4px] items-center justify-right">
                            {featureMapping?.[operation.featureId]?.title}
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              id={`feature-${operation.featureId}${operation.operationIndex}`}
                            >
                              <path
                                d="M8.0026 11.333C8.19149 11.333 8.34994 11.269 8.47794 11.141C8.60549 11.0135 8.66927 10.8552 8.66927 10.6663V7.98301C8.66927 7.79412 8.60549 7.63856 8.47794 7.51634C8.34994 7.39412 8.19149 7.33301 8.0026 7.33301C7.81371 7.33301 7.65549 7.39679 7.52794 7.52434C7.39994 7.65234 7.33594 7.81079 7.33594 7.99967V10.683C7.33594 10.8719 7.39994 11.0275 7.52794 11.1497C7.65549 11.2719 7.81371 11.333 8.0026 11.333ZM8.0026 5.99967C8.19149 5.99967 8.34994 5.93567 8.47794 5.80767C8.60549 5.68012 8.66927 5.5219 8.66927 5.33301C8.66927 5.14412 8.60549 4.98567 8.47794 4.85767C8.34994 4.73012 8.19149 4.66634 8.0026 4.66634C7.81371 4.66634 7.65549 4.73012 7.52794 4.85767C7.39994 4.98567 7.33594 5.14412 7.33594 5.33301C7.33594 5.5219 7.39994 5.68012 7.52794 5.80767C7.65549 5.93567 7.81371 5.99967 8.0026 5.99967ZM8.0026 14.6663C7.08038 14.6663 6.21372 14.4912 5.4026 14.141C4.59149 13.7912 3.88594 13.3163 3.28594 12.7163C2.68594 12.1163 2.21105 11.4108 1.86127 10.5997C1.51105 9.78856 1.33594 8.9219 1.33594 7.99967C1.33594 7.07745 1.51105 6.21079 1.86127 5.39967C2.21105 4.58856 2.68594 3.88301 3.28594 3.28301C3.88594 2.68301 4.59149 2.2079 5.4026 1.85767C6.21372 1.5079 7.08038 1.33301 8.0026 1.33301C8.92483 1.33301 9.79149 1.5079 10.6026 1.85767C11.4137 2.2079 12.1193 2.68301 12.7193 3.28301C13.3193 3.88301 13.7942 4.58856 14.1439 5.39967C14.4942 6.21079 14.6693 7.07745 14.6693 7.99967C14.6693 8.9219 14.4942 9.78856 14.1439 10.5997C13.7942 11.4108 13.3193 12.1163 12.7193 12.7163C12.1193 13.3163 11.4137 13.7912 10.6026 14.141C9.79149 14.4912 8.92483 14.6663 8.0026 14.6663ZM8.0026 13.333C9.48038 13.333 10.7388 12.8137 11.7779 11.775C12.8166 10.7359 13.3359 9.47745 13.3359 7.99967C13.3359 6.5219 12.8166 5.26345 11.7779 4.22434C10.7388 3.18567 9.48038 2.66634 8.0026 2.66634C6.52483 2.66634 5.2666 3.18567 4.22794 4.22434C3.18883 5.26345 2.66927 6.5219 2.66927 7.99967C2.66927 9.47745 3.18883 10.7359 4.22794 11.775C5.2666 12.8137 6.52483 13.333 8.0026 13.333Z"
                                fill="#475467"
                              />
                            </svg>
                            <Tooltip
                              anchorSelect={`#feature-${operation.featureId}${operation.operationIndex}`}
                              style={{
                                backgroundColor: "#D1E9FF",
                                color: "#344054",
                                boxShadow:
                                  "1px 0px 4px rgba(0, 0, 0, .15), 0px 1px 4px rgba(0, 0, 0, .25)",
                                width: "240px",
                                padding: "8px",
                                borderRadius: "4px",
                              }}
                              place={"bottom"}
                              positionStrategy="fixed"
                              clickable
                            >
                              {!islandInfo?.islands?.length ? (
                                <FeatureInfoContent
                                  feature_info={
                                    feature?.feature_data?.feature_info
                                  }
                                  feature_name={
                                    feature?.feature_data?.feature_name
                                  }
                                  measurement_unit={projectInfo?.unit}
                                />
                              ) : (
                                <Carousel
                                  showArrows={false}
                                  showThumbs={false}
                                  showIndicators={true}
                                  showStatus={false}
                                  transitionTime={0}
                                >
                                  <FeatureInfoContent
                                    feature_info={
                                      feature?.feature_data?.feature_info
                                    }
                                    feature_name={
                                      feature?.feature_data?.feature_name
                                    }
                                    measurement_unit={projectInfo?.unit}
                                  />
                                  {islandInfo?.islands?.length &&
                                    islandInfo?.islands?.map?.(
                                      (islandData: any, idx2: number) => {
                                        return (
                                          <TooltipIslandInfo
                                            key={`${operation.featureId}${operation.operationIndex}${idx2}`}
                                            island={islandData}
                                            index={idx2}
                                            unit={" " + projectInfo?.unit}
                                          ></TooltipIslandInfo>
                                        );
                                      }
                                    )}
                                </Carousel>
                              )}
                            </Tooltip>
                          </div>
                        </td>
                        <td className="px-[8px] py-[17px] text-gray-600 text-[14px] font-semibold bg-gray-100">
                          {`${selectedToolPath?.tool_path} (${
                            selectedToolPath?.tool_path_style ?? "N/A"
                          })`}
                        </td>
                        <td className="px-[8px] py-[17px] text-gray-600 text-[14px] font-semibold bg-gray-100">
                          {passObject.pass}
                        </td>
                        <td className="px-[8px] py-[17px] text-gray-600 text-[14px] font-semibold bg-gray-100">
                          {`${
                            passObject?.operations?.[operation.operationIndex]
                              ?.operation
                          } (${
                            passObject?.operations?.[operation.operationIndex]
                              ?.location
                          })`}
                        </td>
                        <td className="pl-[8px] pr-[21px] py-[17px] text-gray-600 text-[14px] font-semibold   rounded-tr-[8px] rounded-br-[8px] bg-gray-100">
                          {
                            selectedToolPath?.tool_types?.[
                              passObject?.operations?.[operation.operationIndex]
                                ?.selectedToolTypeInd ?? 0
                            ]
                          }
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={5} className="h-[4px]"></td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManufacturingSummaryAccordian;
