import { format, formatDistance } from "date-fns";

export * from "./toolReach";
export * from "./point";
export * from "./commonUtils";
export * from "./cloneProjectUtils";
export * from "./toolpaths";
export * from "./featureDataValidation";
export * from "./finalFeatureDataValidation";
export * from "./extractEssentialData";

export const getHumanDate = (value: any) => {
  if (!value) return "";
  const result = format(
    new Date(new Date(value)?.toLocaleString("en-US", { timeZone: "UTC" })),
    "MMMM d, yyyy"
  );
  return result;
};

export const getSplitValue = (value: string | number) => {
  if (!value) return [];
  const arr = value?.toString().split(" ");
  return [parseFloat(arr[0]), arr[1]];
};

export const isObj = (value: any) => {
  return value && Object.keys(value)?.length > 0 ? true : false;
};

export const checkSelectedParameter = (parameter: any) => {
  if (!parameter) return false;
  let flag = false;
  Object?.keys(parameter).forEach((key) => {
    if (!!parameter[key]?.find((subParam: any) => subParam?.selected))
      flag = true;
  });
  return flag;
};

export const getSelectedParameter = (parameter: any) => {
  let data: any = {};
  Object?.keys(parameter).forEach((key) => {
    if (!!parameter[key]?.find((subParam: any) => subParam?.selected)) {
      data[key] = parameter[key]?.find((subParam: any) => subParam?.selected);
    }
  });
  return data;
};

export const getLowestValue = (arr: any, key: string) => {
  if (!arr?.length) return null;
  const lowest = arr?.reduce((previous: any, current: any) => {
    return current[key] < previous[key] ? current : previous;
  });
  return lowest[key];
};

export const capitalize = (str: any) => {
  if (!str?.length) return str;
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const convertKeyToLabel = (str: string): string => {
  const arr = str.split("_");
  return (
    capitalize(arr[0]) +
    (arr[1]
      ? ` ${capitalize(arr[1])}` + (arr[2] ? ` ${capitalize(arr[2])}` : "")
      : "")
  );
};

export const getFileExtension = (filename: string | undefined) => {
  if (!filename) return "";
  const parts = filename.split(".");
  const extension = parts[parts.length - 1];
  return extension;
};

export const fetchByDotOperator = (object: any, value: any) => {
  if (!value) return "";
  return value.split(".").reduce((acc: any, curr: any) => acc?.[curr], object);
};
