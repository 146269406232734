import Button from "components/Button";
import Input from "components/Input";
import Modal from "components/Modal";
import { useFormik } from "formik";
import { useEffect, useMemo } from "react";
import { FiX } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

interface ICloneProjectModal {
  isOpen: boolean;
  setIsOpen: any;
  projectToClone: any;
  projectNameSet: any;
}

const CloneProjectModal: React.FC<ICloneProjectModal> = ({
  isOpen,
  setIsOpen,
  projectToClone,
  projectNameSet,
}) => {
  const navigate = useNavigate();
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        projectName: Yup.string()
          .min(1)
          .max(20, "Project name cannot be more than 20 characters")
          .test(
            "unique-validation",
            "Project name already exists",
            (val: any) => !projectNameSet?.has(val?.trim())
          )
          .required("This field is required"),
        cadPartId: Yup.string().min(1).required("This field is required"),
      }),
    [projectNameSet]
  );
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setValues,
    setFieldTouched,
    values,
    errors,
    touched,
    isValid,
  } = useFormik({
    initialValues: {
      projectName: "",
      cadPartId: projectToClone?.cadPartId,
    },
    validationSchema,
    validateOnChange: true,
    onSubmit: () => {
      setIsOpen(false);
      navigate("/project?isEdit=true", {
        state: {
          project: {
            ...projectToClone,
            projectName: values?.projectName,
            cadPartId: values?.cadPartId,
          },
        },
      });
    },
  });
  useEffect(() => {
    if (!isOpen) return;
    setFieldTouched("projectName", false);
    setFieldTouched("cadPartId", false);
    setValues({
      projectName: "",
      cadPartId: projectToClone?.cadPartId,
    });
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} size="medium">
      <Modal.Header>
        <div className="flex flex-row items-center justify-between p-[16px] border-b border-gray-300">
          <h3 className="text-xl font-medium leading-6 text-black-222">
            Clone Project
          </h3>
          <button data-autofocus>
            <FiX
              className="w-5 h-5 cursor-pointer text-black-222"
              onClick={() => setIsOpen(false)}
            />
          </button>
        </div>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body className="p-[16px]">
          <div className="w-full flex flex-col gap-[16px]">
            <div>
              <div className="text-[14px] text-[#667085] font-medium mb-1">
                Project name <span className="text-red-ff0">*</span>
              </div>
              <Input
                handleFocus={handleBlur}
                className="w-full"
                type="input"
                name="projectName"
                placeholder="Project Name"
                value={values?.projectName}
                onChange={handleChange}
                isErrorMessageAbsolute={true}
                onBlur={handleBlur}
                inputClassName="leading-[20px]"
                regex={/^.{0,20}$/}
                size="small"
                invalid={touched.projectName && errors.projectName}
                helperText={touched.projectName && errors.projectName}
                autocomplete="off"
              />
            </div>
            <div>
              <div className="text-[14px] text-[#667085] font-medium mb-1">
                CAD Part ID <span className="text-red-ff0">*</span>
              </div>
              <Input
                handleFocus={handleBlur}
                className="w-full"
                type="input"
                name="cadPartId"
                placeholder="CAD File Name"
                value={values?.cadPartId}
                onChange={handleChange}
                isErrorMessageAbsolute={true}
                onBlur={handleBlur}
                inputClassName="leading-[20px]"
                size="small"
                invalid={touched.cadPartId && errors.cadPartId}
                helperText={touched.cadPartId && errors.cadPartId}
                autocomplete="off"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="!mt-0">
          <div className="flex justify-center w-full p-[16px] border-t border-gray-300">
            <Button
              size="md"
              className="modal-button"
              type="submit"
              disabled={!isValid}
            >
              Add
            </Button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default CloneProjectModal;
