import { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import { GlobalContext } from "context/GlobalContext";
import { makeLabels, featureInputsWithNoUnits } from "constant";
import { capitalize } from "utils";
import { Tooltip } from "react-tooltip";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import AccordionItem from "components/AccordionItem";
import TooltipIslandInfo from "components/TooltipIslandInfo";

interface ISummaryItem {
  key: string;
  value: string | number;
}
interface ISummaryBox {
  label: string;
  data?: ISummaryItem[];
  selectedItem?: any;
  className?: string;
  title?: string;
}

const SummaryBox: React.FC<ISummaryBox> = ({
  label,
  selectedItem,
  className,
  title,
}) => {
  const { state } = useContext(GlobalContext);
  const { setupNumber, machiningSetups, projectInfo } = state;
  const [selectedFeature, setSelectedFeature] = useState<any>(null);

  useEffect(() => {
    if (!selectedItem?.feature_id) {
      setSelectedFeature(null);
      return;
    }
    const setupIdx = machiningSetups?.findIndex(
      (setup: any) => setup?.machiningSetupNumber === setupNumber
    );
    const feature_idx = machiningSetups?.[setupIdx]?.features?.findIndex(
      (feature: any) => feature?.feature_id === selectedItem?.feature_id
    );
    const feature_data = {
      ...machiningSetups?.[setupIdx]?.features?.[feature_idx]
        ?.featureInformation?.feature_data,
    };
    setSelectedFeature(feature_data);
  }, [selectedItem?.feature_id]);

  const labelsArray = makeLabels(selectedFeature?.feature_name, {
    ...selectedFeature?.feature_info,
  });
  const islandInfo = selectedFeature?.feature_info?.island_info;

  return (
    <AccordionItem
      tabLabelChild={
        <p className="font-semibold text-black mb-2">
          {label} <span className="text-surface-default">{title}</span>
        </p>
      }
      key={selectedItem?.feature_id}
      checked={false}
      labelClass="!bg-gray-50"
      className="!bg-gray-50"
      dragIcon={false}
      labelChildClass="text-[16px] pl-1 pt-2"
      tabContentClassName="bg-gray-50 !px-1 !py-0"
    >
      <div
        className={`flex flex-wrap ${
          selectedItem?.type === "core_cavity" ? "flex-col" : "flex-row"
        }`}
      >
        {selectedFeature &&
          labelsArray?.map(
            ({ key, label }: { key: string; label: string }, idx: number) => {
              const seperator = idx === labelsArray.length - 1 ? "" : ", ";
              const unit = !featureInputsWithNoUnits.has(key)
                ? " " + projectInfo?.unit
                : "";
              return key === "island_info" ? (
                <>
                  <div key={idx} id="showTooltipForIslandsDataId">
                    <span className="font-semibold text-sm text-gray-b9b mr-1">
                      {label} :
                    </span>
                    <span className="font-semibold text-sm text-black mr-[10px]">
                      {islandInfo?.islands?.length ?? 0}
                      {islandInfo?.islands?.length > 1 && " ,"}
                    </span>
                  </div>
                  {islandInfo?.islands?.length > 0 && (
                    <Tooltip
                      anchorSelect="#showTooltipForIslandsDataId"
                      style={{
                        backgroundColor: "#D1E9FF",
                        color: "#344054",
                        boxShadow:
                          "1px 0px 4px rgba(0, 0, 0, .15), 0px 1px 4px rgba(0, 0, 0, .25)",
                        minWidth: "200px",
                        maxWidth: "230px",
                        padding: "8px",
                        borderRadius: "4px",
                      }}
                      place={"bottom"}
                      clickable
                    >
                      {islandInfo?.islands?.length > 1 ? (
                        <div>
                          <Carousel
                            showArrows={false}
                            showThumbs={false}
                            showIndicators={true}
                            showStatus={false}
                            // infiniteLoop={true}
                            // autoPlay={true}
                            // interval={2000}
                            transitionTime={0}
                          >
                            {islandInfo?.islands?.map?.(
                              (islandData: any, idx2: number) => (
                                <TooltipIslandInfo
                                  key={`${idx}_${idx2}`}
                                  island={islandData}
                                  index={idx2}
                                  unit={unit}
                                ></TooltipIslandInfo>
                              )
                            )}
                          </Carousel>
                        </div>
                      ) : (
                        <TooltipIslandInfo
                          island={islandInfo?.islands[0]}
                          index={0}
                          unit={unit}
                        ></TooltipIslandInfo>
                      )}
                    </Tooltip>
                  )}

                  {islandInfo?.islands?.length > 1 && (
                    <div key={idx + 1} className="">
                      <span className="font-semibold text-sm text-gray-b9b mr-1">
                        Min Distance between Islands :
                      </span>
                      <span className="font-semibold text-sm text-black mr-[10px]">
                        {isNaN(islandInfo?.min_distance_between_islands)
                          ? ""
                          : islandInfo?.min_distance_between_islands +
                            " " +
                            unit}
                      </span>
                    </div>
                  )}
                </>
              ) : (
                <div key={idx} className="">
                  <span className="font-semibold text-sm text-gray-b9b mr-1">
                    {label}:{" "}
                  </span>
                  <span className="font-semibold text-sm text-black mr-[10px]">
                    {`${capitalize(selectedFeature?.feature_info[key])}` +
                      unit +
                      seperator}
                  </span>
                </div>
              );
            }
          )}
      </div>
    </AccordionItem>
  );
};

export default SummaryBox;
