import { featureInputsWithNoUnits, makeLabels } from "constant";
import React from "react";
import { capitalize } from "utils";

interface IFeatureInfoContent {
  feature_info: any;
  measurement_unit: string;
  feature_name: string;
}

const FeatureInfoContent: React.FC<IFeatureInfoContent> = ({
  feature_info,
  measurement_unit,
  feature_name,
}) => {
  const islandInfo = feature_info?.island_info;
  const labelsArray = makeLabels(feature_name, feature_info);
  return (
    <p className="font-semibold leading-[16.94px] text-left text-gray-700 text-xs">
      {labelsArray?.map(
        ({ key, label }: { key: string; label: string }, idx: number) => {
          const seperator = idx === labelsArray.length - 1 ? "" : " ";
          const unit = !featureInputsWithNoUnits.has(key)
            ? " " + measurement_unit
            : "";
          return key === "island_info" ? (
            <>
              <div key={idx}>
                <span className="mr-1">{label} :</span>
                <span className="font-semibold mr-[10px]">
                  {islandInfo?.islands?.length ?? 0}
                  {islandInfo?.islands?.length > 1 && " "}
                </span>
              </div>
              {islandInfo?.islands?.length > 1 && (
                <div key={idx + 1} className="">
                  <span className="mr-1">Min Distance between Islands :</span>
                  <span className="font-semibold mr-[10px]">
                    {isNaN(islandInfo?.min_distance_between_islands)
                      ? ""
                      : islandInfo?.min_distance_between_islands + " " + unit}
                  </span>
                </div>
              )}
            </>
          ) : (
            <div key={idx}>
              <span className="mr-1">{label}: </span>
              <span className="mr-[10px]">
                {`${capitalize(feature_info[key])}` + unit + seperator}
              </span>
            </div>
          );
        }
      )}
    </p>
  );
};

export default FeatureInfoContent;
