export interface ICreateCustomStrategyForm {
  templateData: any;
  editRowData: any;
  pass: string;
  showCancelButton: boolean;
  onCancel: any;
  onSave: any;
}

export const initFormValues = {
  operation: "",
  location: "",
  toolType: "",
  toolPathType: "",
  toolPathName: "",
  toolPathStyle: "",
};

export const formStateReducer = (state: any, action: any): any => {
  switch (action.type) {
    case "SET-INPUT-FIELD-DISPLAY-STATUS": {
      const { key, value } = action.payload;
      state.inputFieldDisplayStatus[key] = value;
      return {
        ...state,
      };
    }
    case "SET-OPERATION-OPTIONS":
      return {
        ...state,
        operationOptions: [
          ...action.payload,
          { label: "+ Other...", value: "other", style: "text-blue-500" },
        ],
      };
    case "SET-LOCATION-OPTIONS":
      return {
        ...state,
        locationOptions: [
          ...action.payload,
          { label: "+ Other...", value: "other", style: "text-blue-500" },
        ],
      };
    case "SET-TOOL-TYPE-OPTIONS":
      return {
        ...state,
        toolTypeOptions: [
          ...action.payload,
          { label: "+ Other...", value: "other", style: "text-blue-500" },
        ],
      };
    case "SET-TOOLPATH-TYPE-OPTIONS":
      return {
        ...state,
        toolPathTypeOptions: action.payload,
      };
    case "SET-TOOLPATH-NAME-OPTIONS":
      return {
        ...state,
        toolPathNameOptions: action.payload,
      };
    case "SET-TOOLPATH-STYLE-OPTIONS":
      return {
        ...state,
        toolPathStyleOptions: action.payload,
      };
    case "RESTORE-FORM": {
      const { preFilledData, templateData, pass, operationOptions } =
        action.payload;
      let locations, toolTypes;

      if (
        preFilledData.operation &&
        !operationOptions.includes(preFilledData.operation)
      ) {
        state.inputFieldDisplayStatus.operation = true;
        locations = templateData.custom_cutting_passes[pass].location;
        toolTypes = templateData.custom_cutting_passes[pass].tool_types;
      } else {
        locations =
          templateData.recommended_cutting_passes[pass][
            preFilledData?.operation ?? ""
          ].location;
        toolTypes =
          templateData.recommended_cutting_passes[pass][
            preFilledData?.operation ?? ""
          ].tool_types;
      }

      if (
        preFilledData.location &&
        !locations.includes(preFilledData.location)
      ) {
        state.inputFieldDisplayStatus.location = true;
      }
      const locationOptions = locations.map((item: string) => ({
        // We require a new array. Do not update the original array.
        label: item,
        value: item,
      }));
      locationOptions.push({
        label: "+ Other...",
        value: "other",
        style: "text-blue-500",
      });

      if (
        preFilledData.toolType &&
        !toolTypes.includes(preFilledData.toolType)
      ) {
        state.inputFieldDisplayStatus.toolType = true;
      }
      const toolTypeOptions = toolTypes.map((item: string) => ({
        // We require a new array. Do not update the original array.
        label: item,
        value: item,
      }));
      toolTypeOptions.push({
        label: "+ Other...",
        value: "other",
        style: "text-blue-500",
      });

      const toolPathTypeOptions = formulateToolPathTypeOptions(
        templateData,
        pass,
        preFilledData?.operation ?? ""
      );

      const toolPathNameOptions = formulateToolPathNameOptions(
        templateData,
        pass,
        preFilledData?.operation ?? "",
        preFilledData?.toolPathType ?? ""
      );

      const toolPathStyleOptions = formulateToolPathStyleOptions(
        templateData,
        pass,
        preFilledData?.operation ?? "",
        preFilledData?.toolPathType ?? "",
        preFilledData?.toolPathName ?? ""
      );

      return {
        ...state,
        locationOptions,
        toolTypeOptions,
        toolPathTypeOptions,
        toolPathNameOptions,
        toolPathStyleOptions,
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export const formulateToolPathTypeOptions = (
  templateData: any,
  pass: string,
  operation: string
) => {
  const recommendedToolpathTypes =
    templateData.recommended_cutting_passes[pass][operation]?.tool_paths ?? {};
  const supportedBucket: any = [];
  const unsupportedBucket: any = [];
  const recommendedBucket: any = [];
  templateData.tool_path_mapping.forEach((item: any) => {
    const dataObj = {
      label: item.name,
      value: item.name,
    };
    if (item.name in recommendedToolpathTypes) {
      recommendedBucket.push(dataObj);
    } else if (item.unsupported) {
      unsupportedBucket.push(dataObj);
    } else {
      supportedBucket.push(dataObj);
    }
  });
  const finalArray: any = [];
  if (recommendedBucket.length) {
    finalArray.push({
      label: "Recommended",
      value: "Recommended",
      type: 1,
    });
    finalArray.push(...recommendedBucket);
  }
  if (supportedBucket.length) {
    finalArray.push({
      label: "Supported",
      value: "Supported",
      type: 1,
    });
    finalArray.push(...supportedBucket);
  }
  if (unsupportedBucket.length) {
    finalArray.push({
      label: "Unsupported",
      value: "Unsupported",
      type: 1,
    });
    finalArray.push(...unsupportedBucket);
  }
  return finalArray;
};

export const formulateToolPathNameOptions = (
  templateData: any,
  pass: string,
  operation: string,
  toolPathType: string
) => {
  const recommendedToolpathNames =
    templateData.recommended_cutting_passes[pass][operation]?.tool_paths?.[
      toolPathType
    ] ?? {};
  const supportedBucket: any = [];
  const unsupportedBucket: any = [];
  const recommendedBucket: any = [];
  templateData.tool_path_mapping
    .find((item: any) => {
      return item.name === toolPathType;
    })
    ?.tool_paths?.forEach((item: any) => {
      const dataObj = {
        label: item.name,
        value: item.name,
      };
      if (item.name in recommendedToolpathNames) {
        recommendedBucket.push(dataObj);
      } else if (item.unsupported) {
        unsupportedBucket.push(dataObj);
      } else {
        supportedBucket.push(dataObj);
      }
    });
  const finalArray: any = [];
  if (recommendedBucket.length) {
    finalArray.push({
      label: "Recommended",
      value: "Recommended",
      type: 1,
    });
    finalArray.push(...recommendedBucket);
  }
  if (supportedBucket.length) {
    finalArray.push({
      label: "Supported",
      value: "Supported",
      type: 1,
    });
    finalArray.push(...supportedBucket);
  }
  if (unsupportedBucket.length) {
    finalArray.push({
      label: "Unsupported",
      value: "Unsupported",
      type: 1,
    });
    finalArray.push(...unsupportedBucket);
  }
  return finalArray;
};

export const formulateToolPathStyleOptions = (
  templateData: any,
  pass: string,
  operation: string,
  toolPathType: string,
  toolPathName: string
) => {
  const recommendedToolpathStyles = new Set(
    templateData.recommended_cutting_passes[pass][operation]?.tool_paths?.[
      toolPathType
    ]?.[toolPathName] ?? []
  );
  const supportedBucket: any = [];
  const unsupportedBucket: any = [];
  const recommendedBucket: any = [];
  templateData.tool_path_mapping
    .find((item: any) => item.name === toolPathType)
    ?.tool_paths.find((item: any) => item.name === toolPathName)
    ?.tool_path_styles.forEach((item: any) => {
      const dataObj = {
        label: item.name,
        value: item.name,
      };
      if (recommendedToolpathStyles.has(item.name)) {
        recommendedBucket.push(dataObj);
      } else if (item.unsupported) {
        unsupportedBucket.push(dataObj);
      } else {
        supportedBucket.push(dataObj);
      }
    });
  const finalArray: any = [];
  if (recommendedBucket.length) {
    finalArray.push({
      label: "Recommended",
      value: "Recommended",
      type: 1,
    });
    finalArray.push(...recommendedBucket);
  }
  if (supportedBucket.length) {
    finalArray.push({
      label: "Supported",
      value: "Supported",
      type: 1,
    });
    finalArray.push(...supportedBucket);
  }
  if (unsupportedBucket.length) {
    finalArray.push({
      label: "Unsupported",
      value: "Unsupported",
      type: 1,
    });
    finalArray.push(...unsupportedBucket);
  }
  return finalArray;
};
