import clsx from "clsx";

interface TrProps {
  className?: string;
  children?: any;
  onClick?: () => void;
}

const Tr: React.FC<TrProps> = ({ className, children, onClick }) => {
  return (
    <tr onClick={onClick} className={clsx("border-hidden", className)}>
      {children}
    </tr>
  );
};

export default Tr;
