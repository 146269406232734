import {
  Fragment,
  useState,
  useRef,
  Dispatch,
  SetStateAction,
  useEffect,
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import { BsXLg } from "react-icons/bs";
import Input from "components/Input";
import { onlyNumberRegexExp } from "constant";
import Button from "components/Button";

interface ICustomOrderModal {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  data: any;
  onConfirm: (selectedId: string, type: number, position: number) => void;
}

const CustomOrderModal: React.FC<ICustomOrderModal> = ({
  open,
  setOpen,
  data,
  onConfirm,
}) => {
  const cancelButtonRef = useRef(null);
  const [position, setPosition] = useState<number>();

  useEffect(() => {
    if (!open) {
      setPosition(undefined);
    }
  }, [open]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-[16px] bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white border-b border-gray-200 px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start w-full">
                    <div className="flex w-full justify-between items-center gap-3">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-[20px] text-gray-700 flex flex-col items-start leading-none"
                      >
                        <div className="leading-tight text-gray-700">
                          {data.title || "N/A"}
                        </div>
                        <div className="flex flex-row gap-2">
                          <div className="flex-inline bg-gray-200 text-gray-600 text-[10px] text-gray-500 px-[8px] py-[2px] rounded-[4px] inline-flex leading-tight">
                            Tool path:{" "}
                            <strong className="text-gray-700 ml-[2px] font-semibold ">
                              {data.tool_path || "N/A"}{" "}
                              {`(${data.tool_path_style || "N/A"})`}
                            </strong>
                          </div>
                          <div className="flex-inline bg-gray-200 text-gray-600 text-[10px] text-gray-500 px-[8px] py-[2px] rounded-[4px] inline-flex leading-tight">
                            Operation:{" "}
                            <strong className="text-gray-700 ml-[2px] font-semibold ">
                              {data.operation || "N/A"}
                            </strong>
                          </div>
                        </div>
                      </Dialog.Title>
                      <button
                        type="button"
                        className="inline-flex w-8 ml-auto h-8 justify-center  items-center rounded-full bg-white text-md font-semibold text-gray-700"
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                      >
                        <BsXLg />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="mt-0 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div className="sm:col-span-12">
                      <div className="mt-1">
                        <div className="mb-4">
                          <Input
                            className="w-full"
                            label="Position"
                            type="text"
                            name="position"
                            regex={onlyNumberRegexExp}
                            value={position}
                            onChange={(e: any) => setPosition(e?.target?.value)}
                            onBlur={() => {}}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-white border-t border-gray-200 px-4 py-3 flex justify-center">
                  <Button
                    className="modal-button"
                    onClick={() => {
                      setOpen(false);
                      onConfirm(data.id, 4, (position ?? 1) - 1);
                    }}
                  >
                    Update
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CustomOrderModal;
