import clsx from "clsx";
import { useEffect, useState } from "react";
import uniqid from "uniqid";
import "./index.css";
import "react-phone-input-2/lib/bootstrap.css";
import { default as ReactPhoneInput } from "react-phone-input-2";

interface IInput {
  placeholder?: string;
  value?: any;
  onChange?: any;
  className?: string;
  onBlur?: any;
  unit?: string;
  subClassName?: string;
  inputClassName?: string;
  placeholderClassName?: string;
  label?: string;
  [name: string]: any;
}
const PhoneInput: React.FC<IInput> = ({
  placeholder = "",
  value,
  onChange,
  className = "",
  helperText,
  invalid,
  success,
  warning,
  disabled,
  onBlur,
  unit,
  inputClassName,
  placeholderClassName,
  subClassName,
  label,
  ...rest
}) => {
  const [focus, setFocus] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const classes = clsx(subClassName, {
    "relative rounded input-field h-[52px]": true,
    "border-surface-default border border-solid": focus,
    "!pt-[16px] !pb-[12px]": placeholder,
    "shadow-input-2": !focus,
    "input-invalid": invalid,
    "input-success": success,
    "input-warning": warning,
    "input-disabled": disabled,
  });

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handle = (e: any) => {
    setInputValue(e);
    if (onChange) onChange(e);
  };

  const handleBlue = (e: any) => {
    setFocus(false);
    onBlur(e);
  };

  const name = uniqid();

  return (
    <div className={className}>
      {label && (
        <p className="mb-1.5 text-gray-696 font-semibold text-sm">{label}</p>
      )}
      <div className={classes}>
        <label
          className={clsx(placeholderClassName, {
            "left-[20px] font-medium absolute pointer-events-none z-10 transition-all":
              true,
            "text-[10px] top-[2px]":
              !!inputValue?.toString()?.trim()?.length || focus,
            "top-[16px] text-gray-b9b text-sm": !(
              !!inputValue?.toString()?.trim()?.length || focus
            ),
          })}
          htmlFor={name}
        >
          {placeholder}
        </label>
        <ReactPhoneInput
          {...rest}
          value={inputValue}
          onChange={handle}
          onFocus={() => setFocus(true)}
          inputProps={{
            name: name,
            id: name,
            onBlur:handleBlue
          }}
        />
        {!!unit && (
          <span className="text-sm absolute right-[10px]">{unit}</span>
        )}
      </div>
      {helperText && <p className="input-helper-text">{helperText}</p>}
    </div>
  );
};

export default PhoneInput;
