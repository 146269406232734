import { Dispatch, SetStateAction } from "react";

export interface IAddToolModal {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  selectedStep: any;
  setSelectedStep: Dispatch<SetStateAction<any>>;
  toolData: any;
  setToolData: Dispatch<SetStateAction<any>>;
}

export const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "600px",
  boxShadow: "none",
  outline: "none",
  width: "100%",
};

export const formInitValues = {
  tool_id: "",
  oem_tool_id: "",
  tool_class: "",
  body_material_code: "",
  cutting_diameter: "",
  shaft_diameter: "",
  corner_radius: "",
  flute_count: "",
  flute_length: "",
  shoulder_length: "",
  shank_length: "",
  body_length: "",
  point_angle: "",
  taper_angle: "",
  thread_pitch: "",
  cut_width: "",
};