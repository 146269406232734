import { Box } from "@mui/material";
import Modal from "@mui/material/Modal";
import Button from "components/Button";
import Input from "components/Input";
import { SelectV2 } from "components/Select";
import {
  decimalRegExp,
  onlyNumberRegexExp,
  POSSIBLE_TOOL_SPECS,
  tool_body_material_code_options,
  tool_indexability_options,
  TOOL_TYPE_TO_TOOL_SPECS_MAPPING,
} from "constant";
import { useFormik } from "formik";
import { useApiCall } from "hooks";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { saveCustomTool } from "services";
import { labelToKey } from "utils";
import * as Yup from "yup";
import { formInitValues, IAddToolModal, modalStyle } from "./utils";
import { GlobalContext } from "context";

const excludedfromToolSpecs = new Set([
  "Tool ID",
  "Tool type",
  "Tool class",
  "Body material code",
  "Description",
]);

const AddToolModal: React.FC<IAddToolModal> = ({
  isOpen,
  setIsOpen,
  selectedStep,
  setSelectedStep,
  toolData,
}) => {
  const [saveTool] = useApiCall(saveCustomTool);
  const [requiredFields, setRequiredFields] = useState<Set<string>>(new Set());
  const [visibleFields, setVisibleFields] = useState<Set<string>>(new Set());
  const { state } = useContext(GlobalContext);
  const { userInfo, projectInfo } = state;

  const validationSchema = useMemo(() => {
    return Yup.object()
      .shape({
        tool_id: Yup.string().required("This field is required"),
        oem_tool_id: Yup.string().required("This field is required"),
        tool_class: Yup.string()
          .oneOf(tool_indexability_options.map((item: any) => item.value))
          .required("This field is required"),
        body_material_code: Yup.string()
          .oneOf(tool_body_material_code_options.map((item: any) => item.value))
          .required("This field is required"),
        ...(requiredFields.has("Cutting diameter") && {
          cutting_diameter: Yup.number()
            .min(0)
            .required("This field is required"),
        }),
        ...(requiredFields.has("Shaft diameter") && {
          shaft_diameter: Yup.number()
            .min(0)
            .required("This field is required"),
        }),
        ...(requiredFields.has("Corner radius") && {
          corner_radius: Yup.number().min(0).required("This field is required"),
        }),
        ...(requiredFields.has("Flute count") && {
          flute_count: Yup.number().min(0).required("This field is required"),
        }),
        ...(requiredFields.has("Flute length") && {
          flute_length: Yup.number().min(0).required("This field is required"),
        }),
        ...(requiredFields.has("Shoulder length") && {
          shoulder_length: Yup.number()
            .min(0)
            .required("This field is required"),
        }),
        ...(requiredFields.has("Shank length") && {
          shank_length: Yup.number().min(0).required("This field is required"),
        }),
        ...(requiredFields.has("Body length") && {
          body_length: Yup.number().min(0).required("This field is required"),
        }),
        ...(requiredFields.has("Point angle") && {
          point_angle: Yup.number().min(0).required("This field is required"),
        }),
        ...(requiredFields.has("Taper angle") && {
          taper_angle: Yup.number().min(0).required("This field is required"),
        }),
        ...(requiredFields.has("Thread pitch") && {
          thread_pitch: Yup.number().min(0).required("This field is required"),
        }),
        ...(requiredFields.has("Cut width") && {
          cut_width: Yup.number().min(0).required("This field is required"),
        }),
      })
      .noUnknown(true);
  }, [requiredFields]);

  const onSubmit = useCallback(
    (values: any) => {
      const finalObject: any = { isCustom: true };
      visibleFields.forEach((key) => {
        const value = values[labelToKey(key)];
        // Sending tool id as a string
        const parsedValue = key === "Tool ID" || isNaN(+value) ? value : +value;

        if (requiredFields.has(key) || value !== "") {
          finalObject[key] = parsedValue;
        }
      });
      finalObject["Tool type"] =
        selectedStep?.operations?.[
          selectedStep?.subIndex
        ]?.tool_type?.toLowerCase();
      const existingTools =
        toolData?.data?.passes?.[selectedStep.index - 1]?.operations?.[
          selectedStep?.subIndex
        ]?.tools;
      if (existingTools) {
        existingTools.push(finalObject);
        delete toolData?.data?.passes?.[selectedStep.index - 1]?.operations?.[
          selectedStep?.subIndex
        ]?.parameters;
      }
      setSelectedStep({ ...selectedStep, requestPath: "newToolAdded" });
      setIsOpen(false);
      const payloadData = {
        ...finalObject,
        oem_tool_id: values.oem_tool_id,
        toolType:
          selectedStep?.operations?.[
            selectedStep?.subIndex
          ]?.tool_type?.toLowerCase(),
        material: projectInfo.material,
        subMaterial: projectInfo.subMaterial,
        measurement_unit: projectInfo.unit ?? "mm",
      };
      delete payloadData["Tool type"];
      saveTool({
        organizationId: userInfo?.["custom:organizationId"],
        userRecordId: userInfo?.["sub"],
        toolData: payloadData,
      });
      setIsOpen(false);
    },
    [
      projectInfo,
      requiredFields,
      saveTool,
      selectedStep,
      setIsOpen,
      setSelectedStep,
      toolData,
      userInfo,
      visibleFields,
    ]
  );

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
    setFieldValue,
    setFieldTouched,
    setValues,
  } = useFormik({
    initialValues: { ...formInitValues },
    validateOnChange: true,
    onSubmit,
    validationSchema,
  });

  useEffect(() => {
    if (!selectedStep) return;

    const toolType =
      selectedStep.operations?.[selectedStep.subIndex]?.tool_type;

    if (!toolType) {
      setVisibleFields(new Set());
      setRequiredFields(new Set());
      return;
    }

    const toolSpecs =
      TOOL_TYPE_TO_TOOL_SPECS_MAPPING[toolType] || POSSIBLE_TOOL_SPECS;
    setVisibleFields(new Set(...[toolSpecs]));
    setRequiredFields(
      TOOL_TYPE_TO_TOOL_SPECS_MAPPING[toolType] ||
        new Set([
          "Tool ID",
          "Tool type",
          "Tool class",
          "Body material code",
          "Description",
          "Cutting diameter",
          "Body length",
        ])
    );
  }, [selectedStep]);

  useEffect(() => {
    if (isOpen) resetForm();
  }, [isOpen, setValues, resetForm]);

  const visibleToolSpecs = Array.from(visibleFields).filter(
    (spec: string) => !excludedfromToolSpecs.has(spec)
  );

  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)}>
      <Box sx={modalStyle}>
        <div className="max-w-[600px] rounded-[16px] bg-white">
          <div className="flex items-center justify-between p-[16px] border-b border-gray-300">
            <h2 className="text-[20px] text-gray-700 font-medium">
              Add Tool (
              {selectedStep?.operations?.[selectedStep?.subIndex]?.tool_type})
            </h2>
            <Button
              className="!bg-transparent !p-0 !rounded-0 !border-0 !shadow-none"
              onClick={() => setIsOpen(false)}
            >
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.63281 14.8692L10.002 10.5L14.3711 14.8692M14.3711 6.13086L10.0011 10.5L5.63281 6.13086"
                  stroke="#475467"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Button>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="flex flex-wrap gap-[16px] p-[16px] max-h-[60vh] overflow-auto">
              <div className="w-full gap-[4px] flex flex-col">
                <label className="leading-[15px] left-[10px] font-semibold text-[12px] text-gray-500">
                  User Tool ID <sup className="text-red-600">*</sup>
                </label>
                <Input
                  handleFocus={handleBlur}
                  className="w-full"
                  type="input"
                  name="tool_id"
                  value={values.tool_id}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  inputClassName="leading-[20px]"
                  size="small"
                  invalid={touched.tool_id && errors.tool_id}
                  helperText={touched.tool_id && errors.tool_id}
                />
              </div>
              <div className="w-full flex items-center gap-[10px]">
                <span className="text-gray-500 text-[14px] font-semibold whitespace-nowrap">
                  Tool Details
                </span>
                <div className="bg-gray-300 h-[1px] w-full"></div>
              </div>
              <div className="w-full md:w-[calc(50%_-_8px)] gap-[4px] flex flex-col">
                <label className="leading-[15px] left-[10px] font-semibold text-[12px] text-gray-500">
                  OEM Tool ID <sup className="text-red-600">*</sup>
                </label>
                <Input
                  handleFocus={handleBlur}
                  className="w-full"
                  type="input"
                  name="oem_tool_id"
                  value={values.oem_tool_id}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  inputClassName="leading-[20px]"
                  size="small"
                  invalid={touched.oem_tool_id && errors.oem_tool_id}
                  helperText={touched.oem_tool_id && errors.oem_tool_id}
                />
              </div>
              <div className="w-full md:w-[calc(50%_-_8px)] gap-[4px] flex flex-col">
                <label className="leading-[15px] left-[10px] font-semibold text-[12px] text-gray-500">
                  Tool Indexability <sup className="text-red-600">*</sup>
                </label>
                <SelectV2
                  name="tool_class"
                  value={values.tool_class}
                  options={tool_indexability_options}
                  size="small"
                  onChange={(val: any) => setFieldValue("tool_class", val)}
                  onBlur={handleBlur}
                  handleClose={(value: string) => {
                    if (!value) setFieldTouched("tool_class", true);
                  }}
                  helperText={touched.tool_class && errors.tool_class}
                />
              </div>
              <div className="w-full md:w-[calc(50%_-_8px)] gap-[4px] flex flex-col">
                <label className="leading-[15px] left-[10px] font-semibold text-[12px] text-gray-500">
                  Tool Body material code <sup className="text-red-600">*</sup>
                </label>
                <SelectV2
                  name="body_material_code"
                  value={values.body_material_code}
                  options={tool_body_material_code_options}
                  size="small"
                  onChange={(val: any) =>
                    setFieldValue("body_material_code", val)
                  }
                  handleClose={(value: string) => {
                    if (!value) setFieldTouched("body_material_code", true);
                  }}
                  onBlur={handleBlur}
                  helperText={
                    touched.body_material_code && errors.body_material_code
                  }
                />
              </div>
              <div className="w-full flex items-center gap-[10px]">
                <span className="text-gray-500 text-[14px] font-semibold whitespace-nowrap">
                  Tool Specs
                </span>
                <div className="bg-gray-300 h-[1px] w-full"></div>
              </div>
              {visibleToolSpecs.map((label: string) => {
                return (
                  <div
                    key={label}
                    className="w-full md:w-[calc(50%_-_8px)] lg:w-[calc(33.333%_-_11px)] gap-[4px] flex flex-col"
                  >
                    <label className="leading-[15px] left-[10px] font-semibold text-[12px] text-gray-500">
                      {`${label} `}
                      {requiredFields.has(label) && (
                        <sup className="text-red-600">*</sup>
                      )}
                    </label>
                    <Input
                      handleFocus={handleBlur}
                      className="w-full"
                      type="input"
                      name={labelToKey(label)}
                      value={values?.[labelToKey(label) as keyof typeof values]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputClassName="leading-[20px]"
                      size="small"
                      invalid={
                        touched[labelToKey(label) as keyof typeof values] &&
                        errors[labelToKey(label) as keyof typeof values]
                      }
                      helperText={
                        touched[labelToKey(label) as keyof typeof values] &&
                        errors[labelToKey(label) as keyof typeof values]
                      }
                      regex={
                        label === "Flute count"
                          ? onlyNumberRegexExp
                          : decimalRegExp
                      }
                    />
                  </div>
                );
              })}
            </div>
            <div className="flex items-center justify-center p-[16px] border-t border-gray-300">
              <Button className="min-w-[140px]" type="submit">
                Add
              </Button>
            </div>
          </form>
        </div>
      </Box>
    </Modal>
  );
};

export default AddToolModal;
