import {
  OPERATIONS_NOT_SUPPORTING_STEPDOWN,
  OPERATIONS_NOT_SUPPORTING_STEPOVER,
} from "constant";
import { Dispatch, SetStateAction } from "react";
import { v4 as uuid } from "uuid";
import * as Yup from "yup";

export interface IEditCuttingParametersModal {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  data: any;
  toolId: string;
  selectedStep: any;
  boxData: any;
  setBoxData: Dispatch<SetStateAction<any>>;
  index: number;
  isAddModal?: boolean;
}

export interface IformValuesInterface {
  spindle_speed: number;
  feed_rate: number;
  stepover_ae: number;
  stepdown_ap: number;
}

export const formInitValues = {
  spindle_speed: "",
  feed_rate: "",
  stepover_ae: "",
  stepdown_ap: "",
};

export const getValidationSchema = (operation: string) =>
  Yup.object().shape({
    spindle_speed: Yup.number().min(0).required("This field is required"),
    feed_rate: Yup.number().min(0).required("This field is required"),
    ...(!OPERATIONS_NOT_SUPPORTING_STEPOVER.has(operation) && {
      stepover_ae: Yup.number().min(0).required("This field is required"),
    }),
    ...(!OPERATIONS_NOT_SUPPORTING_STEPDOWN.has(operation) && {
      stepdown_ap: Yup.number().min(0).required("This field is required"),
    }),
  });

export const getFormFieldsTemplate = (operation: string, unit: string) => {
  const formFieldsTemplate: any = [
    {
      label: "Spindle speed",
      name: "spindle_speed",
      units: "rpm",
      key: `1_${uuid()}`,
      request_key: "cutting_speed",
    },
    {
      label: "Feed rate",
      name: "feed_rate",
      units: `${unit}/mm`,
      key: `2_${uuid()}`,
      request_key: "cutting_feed",
    },
  ];
  if (!OPERATIONS_NOT_SUPPORTING_STEPOVER.has(operation)) {
    formFieldsTemplate.push({
      label: "Stepover [Ae]",
      name: "stepover_ae",
      units: unit,
      key: `3_${uuid()}`,
      request_key: "radial_depth_of_cut",
    });
  }
  if (!OPERATIONS_NOT_SUPPORTING_STEPDOWN.has(operation)) {
    formFieldsTemplate.push({
      label: "Stepdown [Ap]",
      name: "stepdown_ap",
      units: unit,
      key: `4_${uuid()}`,
      request_key: "axial_depth_of_cut",
    });
  }
  return formFieldsTemplate;
};

export const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "400px",
};
