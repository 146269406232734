// import LoadingImg from "assets/images/loading-icon.gif";
import CircularProgress from "@mui/material/CircularProgress";
import clsx from "clsx";
import { GlobalContext } from "context";
import { useContext } from "react";

const LoadingBar = () => {
  const { state } = useContext(GlobalContext);
  const { loadingBarStyle } = state;
  return (
    <div
      className={clsx(
        "absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-20 flex justify-center items-center z-20",
        loadingBarStyle
      )}
    >
      <CircularProgress size={80} />
    </div>
  );
};

export default LoadingBar;
