import Button from "components/Button";
import Modal from "components/Modal";
import WarningSign from "assets/images/icons/uil_exclamation-triangle.svg";

interface IMachineNotAvailableModal {
  isOpen: boolean;
  setIsOpen: any;
  machineName: string;
}

const MachineNotAvailableModal: React.FC<IMachineNotAvailableModal> = ({
  isOpen,
  setIsOpen,
  machineName,
}) => {
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} size="medium">
      <Modal.Body className="flex flex-col gap-[32px] text-[14px] gap-[32px] p-[14px]">
        <div className="flex flex-col justify-between">
          <img src={WarningSign} alt="Err" className="mx-auto" />
          <div className="text-center">
            <p>
              Machine <b>{machineName}</b> has been deleted.
            </p>
            <p>Please enter another machine.</p>
          </div>
        </div>
        <div className="flex flex-row justify-center w-full">
          <Button
            size="md"
            className="modal-button !bg-gray-100 !text-gray-700"
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default MachineNotAvailableModal;
