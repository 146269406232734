export const extractSelectedDataFromMachiningSetups = (
  machiningSetups: any
) => {
  const newMachiningSetups = JSON.parse(JSON.stringify(machiningSetups));
  newMachiningSetups?.forEach((setupInfo: any) => {
    for (const feature of setupInfo?.features ?? []) {
      const selectedStrategy =
        feature.featureInformation?.strategies?.[0]?.machining_strategy?.find?.(
          (item: any) => item?.isSelected
        );
      if (!selectedStrategy) {
        delete feature?.featureInformation?.strategies;
      } else {
        delete selectedStrategy.feedback;
        delete selectedStrategy.isValid;
        feature.featureInformation.strategies[0].machining_strategy = [
          selectedStrategy,
        ];
        selectedStrategy?.passes?.forEach((pass: any) => {
          pass.operations?.forEach((operation: any) => {
            if (!operation.selectedToolId) {
              delete operation.tools;
              delete operation.parameters;
              delete operation.selectedToolId;
              delete operation.selectedTool;
            } else {
              const selectedTool = getSelectedTool(
                operation.tools,
                operation.selectedToolId
              );
              const selectedToolParameters = getSelectedToolParams(
                operation.parameters,
                operation.selectedToolId
              );
              delete selectedTool.feedback;
              delete selectedTool.isValid;
              delete selectedToolParameters.valid_data;

              operation.tools = [selectedTool];
              operation.parameters = [selectedToolParameters];
              operation.selectedTool = 0;
            }
            delete operation.feedback;
            delete operation.isValid;
          });
        });
      }
    }
  });
  return newMachiningSetups;
};

const getSelectedTool = (toolsArray: any[], selectedToolId: string) => {
  return toolsArray?.find((tool: any) => tool?.["Tool ID"] === selectedToolId);
};

const getSelectedToolParams = (paramsArray: any[], selectedToolId: string) => {
  return (
    paramsArray?.find(
      (params: any) => params?.["Tool ID"] === selectedToolId
    ) ?? {}
  );
};
