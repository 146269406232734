import clsx from "clsx";

interface IStepItem {
  index: number;
  label: string;
  key: string;
  isNull?: boolean;
  operations?: any;
}
interface IStep {
  data: IStepItem[];
  current: { index: number; subIndex: number };
  className?: string;
  setSelectedStep?: any;
}

interface ISubDot {
  className?: string;
  styles?: any;
  isActive?: boolean;
  setSelectedStep?: any;
  data?: any;
  subIndex?: number;
}

const SubDot: React.FC<ISubDot> = ({
  className,
  isActive,
  data,
  subIndex,
  setSelectedStep,
}) => {
  return (
    <div
      className={clsx("w-4 h-4 cursor-pointer", className)}
      onClick={() => {
        setSelectedStep({
          ...data,
          subIndex,
        });
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <circle cx="8" cy="8" r="5" fill={isActive ? "#1570EF" : "#98A2B3"} />
        <circle
          cx="8"
          cy="8"
          r="6.5"
          stroke={isActive ? "#1570EF" : "#98A2B3"}
          stroke-opacity="0.27"
          stroke-width="3"
        />
      </svg>
    </div>
  );
};

// index - passes
// subIndex - operations
const Step: React.FC<IStep> = ({
  data,
  current,
  className,
  setSelectedStep,
}) => {
  let count: number = 0;
  return (
    <div
      className={clsx("flex flex-row mb-3 mx-6 flex-wrap gap-y-8", className)}
    >
      {data?.map((item, idx) => {
        return (
          <>
            {!!item?.operations?.length &&
              item?.operations?.map((operation: any, idx1: number) => {
                count++;
                return (
                  <div
                    className="flex flex-row items-center gap-0"
                    key={`${idx}-${idx1}`}
                  >
                    {count > 1 && (
                      <div
                        className={clsx("w-full h-1 w-[70px] rounded mx-1.5", {
                          "bg-gray-d9d": idx < data?.length,
                          "!bg-surface-default":
                            item?.index < current?.index ||
                            (item?.index <= current?.index &&
                              idx1 <= current?.subIndex),
                        })}
                      ></div>
                    )}
                    <div
                      className="flex flex-row items-center cursor-pointer"
                      onClick={() => {
                        if (
                          current?.index === item.index &&
                          current?.subIndex === idx1
                        )
                          return;
                        setSelectedStep({ ...item, subIndex: idx1 });
                      }}
                    >
                      <div className="flex flex-col justify-center items-center relative">
                        <SubDot
                          key={idx1}
                          isActive={
                            item?.index < current?.index ||
                            (item?.index <= current?.index &&
                              idx1 <= current?.subIndex)
                          }
                          data={item}
                          subIndex={idx1}
                          setSelectedStep={
                            current?.index === item.index &&
                            current?.subIndex === idx1
                              ? () => {}
                              : setSelectedStep
                          }
                        />
                        <span
                          className={clsx(
                            "absolute text-xs whitespace-nowrap font-medium text-gray-344 top-[20px]"
                          )}
                        >
                          {`Step ${count}`}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
          </>
        );
        // return (
        //   <div
        //     className={clsx("flex flex-row items-center", {
        //       "w-full": idx < data?.length,
        //     })}
        //     key={idx}
        //   >
        //     { idx >= 1 && <div className="mx-1.5 flex flex-row w-full">
        //       {/* {item?.operations?.map((operation: any, idx1: number) => (
        //         <>
        //           <div
        //             className={clsx("w-full h-1 rounded mt-1.5", {
        //               "bg-gray-d9d": idx < data?.length,
        //               "!bg-surface-default":
        //                 item?.index <= current?.index &&
        //                 idx1 <= current?.subIndex,
        //             })}
        //           ></div>
        //           <SubDot
        //             key={idx1}
        //             isActive={
        //               item?.index <= current?.index && idx1 <= current?.subIndex
        //             }
        //             data={item}
        //             subIndex={idx1}
        //             setSelectedStep={setSelectedStep}
        //           />
        //         </>
        //       ))} */}
        //       <div
        //         className={clsx("w-full h-1 w-[70px] rounded mt-0.5", {
        //           "bg-gray-d9d": idx < data?.length,
        //           "!bg-surface-default": item?.index <= current?.index,
        //         })}
        //       ></div>
        //     </div>}

        //     <div
        //       className="flex flex-row items-center cursor-pointer"
        //       onClick={() => setSelectedStep({ ...item, subIndex: 0 })}
        //     >
        //       <div className="flex flex-col justify-center items-center relative">
        //         {/* <div
        //           className={clsx(
        //             "w-6 h-6 flex justify-center items-center rounded-full font-semibold",
        //             {
        //               "text-white bg-surface-default":
        //                 item?.index <= current?.index,
        //               "text-gray-807 bg-gray-d9d": item?.index > current?.index,
        //             }
        //           )}
        //         /> */}
        //         <SubDot
        //           key={idx}
        //           isActive={item?.index <= current?.index}
        //           data={item}
        //           subIndex={idx}
        //           setSelectedStep={setSelectedStep}
        //         />
        //         <span
        //           className={clsx(
        //             "absolute text-xs whitespace-nowrap font-medium text-gray-344 top-[28px]"
        //           )}
        //         >
        //           {/* {item?.label} */} {`Step ${idx + 1}`}
        //         </span>
        //       </div>
        //     </div>

        //   </div>
        // );
      })}
    </div>
  );
};

export default Step;
