import Modal from "react-modal";
export interface IAdditionalToolParamsModal {
  popUpOpen?: any;
  handleClose?: any;
  tool?: any;
}

export const POPUP_STYLES: Modal.Styles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    padding: 0,
    marginRight: "-50%",
    width: "310px",
    borderRadius: "16px",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 999,
  },
};
