import CustomCheckboxIcon from "components/CustomCheckboxIcon";
import { useState, useEffect } from "react";
import uniqid from "uniqid";

interface ICheckbox {
  checked: boolean;
  onChange: any;
  disabled?: boolean;
  label?: string;
  notAll?: boolean;
  darkBorder?: boolean;
}

const FeatureCheckbox: React.FC<ICheckbox> = ({
  disabled = false,
  checked,
  onChange,
  label = "",
  notAll = false,
  darkBorder,
}) => {
  const [isCheck, setIsCheck] = useState(checked);

  const handleChange = (e: any) => {
    setIsCheck(e.target.checked);
    onChange(e);
  };

  useEffect(() => {
    setIsCheck(checked);
  }, [checked]);

  const name = `check-${uniqid()}`;

  return (
    <div className="checkboxinput inline-flex gap-[4px] items-center w-[100%]">
      <input
        id={name}
        type="checkbox"
        checked={isCheck}
        onChange={handleChange}
        disabled={disabled}
        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 hidden"
      />
      <label
        htmlFor={name}
        className="font-semibold text-gray-600 text-[14px] gap-[12px] inline-flex items-center mb-0"
      >
        <CustomCheckboxIcon
          isCheck={isCheck}
          notAll={notAll}
          disabled={disabled}
          darkBorder={darkBorder}
        />
        {label}
      </label>
    </div>
  );
};

export default FeatureCheckbox;
