import Button from "components/Button";
import Modal from "components/Modal";
import WarningSign from "assets/images/icons/uil_exclamation-triangle.svg";

interface IErrorModal {
  isOpen: boolean;
  setIsOpen: any;
  errorMessage: any;
}

const EditStrategyErrorModal: React.FC<IErrorModal> = ({
  isOpen,
  setIsOpen,
  errorMessage,
}) => {
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} size="default">
      <Modal.Header>
        <div className="flex flex-col items-center justify-between pb-[16px] gap-2">
          <img src={WarningSign} alt="" />
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="mt-0">
          <div
            className="text-[14px] text-center text-gray-700"
            dangerouslySetInnerHTML={{ __html: errorMessage }}
          ></div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex flex-row justify-center space-x-4 w-full">
          <Button
            size="md"
            variant="primary"
            className="!rounded-[4px] py-[6px] w-[150px] font-bold"
            onClick={() => setIsOpen(false)}
          >
            Ok
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default EditStrategyErrorModal;
