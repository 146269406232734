import { useContext, useEffect, useState } from "react";
import { RouterProvider } from "react-router-dom";
import RootRoutes from "routes/RootRoutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { GlobalContext } from "context/GlobalContext";
import LoadingBar from "components/LoadingBar";
import { SET_TOOLTIP } from "constant";

const App = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const { loadingBar } = state;
  const [anchorId, setAnchorId] = useState("");
  const [toolTipCSS, setToolTipCSS] = useState({});
  const showTooltip = (id: string, toolTipBgColor: any = {}) => {
    setToolTipCSS(toolTipBgColor);
    setAnchorId(id);
  };

  const hideTooltip = () => {
    setAnchorId("");
  };

  useEffect(() => {
    dispatch({
      type: SET_TOOLTIP,
      payload: { showTooltip, hideTooltip },
    });
  }, [dispatch]);

  return (
    <div className="w-full h-full">
      <RouterProvider router={RootRoutes} />
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar
        theme="colored"
      />
      <Tooltip
        anchorId={anchorId}
        place="top"
        variant="dark"
        isOpen={!!anchorId}
        style={{
          backgroundColor: "#D1E9FF",
          color: "#344054",
          fontWeight: "500",
          ...toolTipCSS,
        }}
      />
      {loadingBar && <LoadingBar />}
    </div>
  );
};

export default App;
