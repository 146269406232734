import cn from "classnames";

interface ModalBodyProps {
  className?: string;
  children: any;
}

function ModalBody({
  className,
  children
}: ModalBodyProps) {
  return (
    <div className={cn('max-h-[70vh] overflow-y-auto', className)}>
      {children}
    </div>
  )
}

export default ModalBody;
