import Button from "components/Button";
import Input from "components/Input";
import Modal from "components/Modal";
import {
  SET_ASSIGNED_SETUPS,
  SET_MACHINE_SETUP,
  SET_SETUP_MAPPING,
} from "constant";
import { GlobalContext } from "context";
import { useFormik } from "formik";
import { deleteSetups } from "pages/project/ManufacturingProcess/utils";
import { useContext, useEffect, useState } from "react";
import { FiX } from "react-icons/fi";
import { toast } from "react-toastify";
import { createDeepCopy } from "utils";
import * as Yup from "yup";

interface IEditSetupModal {
  isOpen: boolean;
  setIsOpen: any;
}

const EditSetupModal: React.FC<IEditSetupModal> = ({ isOpen, setIsOpen }) => {
  const { state, dispatch } = useContext(GlobalContext);
  const {
    machiningSetups,
    mappingForMachiningSetups,
    assignedMachiningSetups,
  } = state;
  const [deletedSetups, setDeletedSetups] = useState<string[]>([]);
  const [updatedSetupsMapping, setUpdatedSetupsMapping] = useState(
    createDeepCopy(mappingForMachiningSetups)
  );

  const validationSchema = Yup.lazy((updatedSetupsMapping) => {
    const validationObject = {
      name: Yup.string()
        .test(
          "unique-validation",
          "Setup name should be unique",
          (val: any) => {
            let count = 0;
            for (const setupId in updatedSetupsMapping) {
              if (val?.trim() === updatedSetupsMapping?.[setupId]?.name?.trim())
                count += 1;
            }
            return count === 1;
          }
        )
        .required("This field is required"),
      index: Yup.number().required(),
      programName: Yup.string()
        .test(
          "unique-validation",
          "Program name should be unique",
          (val: any) => {
            let count = 0;
            for (const setupId in updatedSetupsMapping) {
              if (
                val?.trim() ===
                updatedSetupsMapping?.[setupId]?.programName?.trim()
              )
                count += 1;
            }
            return count === 1;
          }
        )
        .required("This field is required"),
    };
    const setupEntries = Object.keys(updatedSetupsMapping)?.reduce(
      (acc, val) => ({
        ...acc,
        [val]: Yup.object(validationObject),
      }),
      {}
    );
    return Yup.object().shape(setupEntries);
  });

  useEffect(() => {
    if (!isOpen) return;
    setUpdatedSetupsMapping(createDeepCopy(mappingForMachiningSetups));
    setValues({ ...createDeepCopy(mappingForMachiningSetups) });
    setDeletedSetups([]);
  }, [isOpen]);

  const {
    handleSubmit,
    handleBlur,
    setFieldValue,
    setValues,
    setTouched,
    values,
    isValid,
    errors,
  } = useFormik({
    initialValues: {
      ...updatedSetupsMapping,
    },
    validationSchema,
    onSubmit: () => {
      const mappingForMachininingSetupsCopy: any = createDeepCopy(
        mappingForMachiningSetups
      );
      Object.keys(values)?.forEach((setupId: string) => {
        mappingForMachininingSetupsCopy[setupId].name = values[setupId]?.name;
        mappingForMachininingSetupsCopy[setupId].programName =
          values[setupId]?.programName;
      });
      const {
        changedMachiningSetups,
        changedAssignedMachiningSetups,
        changedMappingForMachiningSetups,
      } = deleteSetups(
        deletedSetups,
        createDeepCopy(machiningSetups),
        mappingForMachininingSetupsCopy,
        createDeepCopy(assignedMachiningSetups)
      );
      dispatch({
        type: SET_SETUP_MAPPING,
        payload: changedMappingForMachiningSetups,
      });
      dispatch({
        type: SET_ASSIGNED_SETUPS,
        payload: changedAssignedMachiningSetups,
      });
      dispatch({
        type: SET_MACHINE_SETUP,
        payload: changedMachiningSetups,
      });
      setIsOpen(false);
    },
  });
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} size="custom">
      <Modal.Header>
        <div className="flex flex-row items-center justify-between  p-[16px] border-b border-gray-300">
          <h3 className="text-lg font-medium leading-6 text-black-222">
            Edit Setups
          </h3>
          <FiX
            className="w-6 h-6 cursor-pointer text-black-222"
            onClick={() => setIsOpen(false)}
          />
        </div>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body className="p-[16px]">
          <table className="w-full">
            <thead>
              <tr>
                <th className="text-[14px] font-medium text-gray-700 p-[4px]">
                  Setup
                </th>
                <th className="text-[14px] font-medium text-gray-700 p-[4px]">
                  Setup name
                </th>
                <th className="text-[14px] font-medium text-gray-700 p-[4px]">
                  Program name
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {updatedSetupsMapping &&
                Object.keys(updatedSetupsMapping)?.map(
                  (setupId: string, idx: number) => {
                    return (
                      <tr key={`setup-${idx}`}>
                        <td className="p-[4px] w-[42px] align-top">
                          <div className="bg-gray-100 border  border-gray-200  text-gray-700 p-[8px] rounded-[8px] text-[14px] font-semibold leading-[20px]">
                            S{updatedSetupsMapping?.[setupId]?.index + 1}
                          </div>
                        </td>
                        <td className="p-[4px] align-top">
                          <Input
                            handleFocus={handleBlur}
                            value={values?.[setupId]?.name}
                            onChange={(event: any) => {
                              setFieldValue(setupId, {
                                ...values?.[setupId],
                                name: event.target.value,
                              });
                            }}
                            onBlur={handleBlur}
                            invalid={(errors?.[setupId] as any)?.name}
                            helperText={(errors?.[setupId] as any)?.name}
                            type="input"
                            className="w-full"
                            size="small"
                            inputClassName="leading-[20px]"
                          />
                        </td>
                        <td className="p-[4px] align-top">
                          <Input
                            handleFocus={handleBlur}
                            value={values?.[setupId]?.programName}
                            onChange={(event: any) => {
                              setFieldValue(setupId, {
                                ...values?.[setupId],
                                programName: event.target.value,
                              });
                            }}
                            invalid={(errors?.[setupId] as any)?.programName}
                            helperText={(errors?.[setupId] as any)?.programName}
                            onBlur={handleBlur}
                            type="input"
                            className="w-full"
                            size="small"
                            inputClassName="leading-[20px]"
                          />
                        </td>
                        <td className="pl-[4px]">
                          <svg
                            onClick={() => {
                              let updatedSetupsMapping: any = { ...values };
                              if (assignedMachiningSetups?.[setupId]?.length)
                                toast.error(
                                  "Sorry! Operations will get unassigned from the linked setup."
                                );
                              delete updatedSetupsMapping[setupId];
                              setDeletedSetups([...deletedSetups, setupId]);
                              setUpdatedSetupsMapping(updatedSetupsMapping);
                              setValues({ ...updatedSetupsMapping });
                            }}
                            className="cursor-pointer"
                            width="17"
                            height="18"
                            viewBox="0 0 17 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1 4.5H16M7 8.25V12M10 8.25V12M2.5 4.5H14.5L13.315 15.165C13.2744 15.532 13.0998 15.8712 12.8247 16.1175C12.5496 16.3638 12.1933 16.5 11.824 16.5H5.176C4.80673 16.5 4.45042 16.3638 4.17531 16.1175C3.9002 15.8712 3.72562 15.532 3.685 15.165L2.5 4.5ZM5.00875 2.36025C5.13006 2.10298 5.32203 1.8855 5.56225 1.73318C5.80248 1.58087 6.08106 1.5 6.3655 1.5H10.6345C10.9191 1.49985 11.1978 1.58066 11.4382 1.73298C11.6785 1.88531 11.8706 2.10286 11.992 2.36025L13 4.5H4L5.00875 2.36025Z"
                              stroke="#F04438"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </td>
                      </tr>
                    );
                  }
                )}
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer className="!mt-0">
          <div className="flex justify-center w-full p-[16px] border-t border-gray-300">
            <Button
              size="md"
              className="modal-button"
              type="submit"
              disabled={!isValid}
            >
              Update
            </Button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default EditSetupModal;
