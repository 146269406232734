import clsx from "clsx";
import { useState, useRef, useEffect, useCallback } from "react";
import { useClickOutside } from "hooks";
import { DefaultToggler } from "./DefaultToggler";
import "./select.css";

export interface IOption {
  label: string | number;
  value: string | boolean | number | object;
  tag?: string;
  textColor?: string;
  bgColor?: string;
  image?: any;
}

interface ISelect {
  value?: string | number | boolean | object;
  onChange?: any;
  onBlur?: any;
  options: IOption[];
  className?: string;
  infoTooltipTitle?: React.ReactNode;
  placeholder?: string;
  invalid?: any;
  helperText?: any;
  disabled?: boolean;
  handleClose?: any;
  size?: any;
  isTag?: boolean;
}

const Select: React.FC<ISelect> = ({
  value,
  onChange = () => {},
  onBlur = () => {},
  options,
  placeholder,
  infoTooltipTitle,
  className,
  invalid,
  helperText,
  disabled = false,
  isTag,
  handleClose,
  size = "default",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState<any>(value);
  const selectRef = useRef(null);
  const valueRef = useRef<any>("");

  const toggleSelect = useCallback(() => {
    if (isOpen) handleClose?.(valueRef.current);
    setIsOpen((isOpen) => !isOpen);
  }, [isOpen, handleClose]);

  useEffect(() => {
    valueRef.current = value;
    setSelected(value);
  }, [value]);

  useClickOutside(selectRef, (event: any) => {
    isOpen && onBlur(event);
    setIsOpen(false);
    isOpen && handleClose?.(valueRef.current);
  });

  const handleOptionClick = useCallback(
    (value: string | boolean | number | object) => {
      valueRef.current = value;
      setSelected(value);
      toggleSelect();
      onChange(value);
    },
    [onChange]
  );

  const classes = clsx({
    select: true,
    // States
    "select-invalid": invalid,
  });

  const optionsClsx = clsx({
    "select-options": true,
  });

  return (
    <div className={className}>
      <div ref={selectRef} className={clsx("relative", classes)}>
        <DefaultToggler
          isOpen={isOpen}
          toggleSelect={toggleSelect}
          selected={selected}
          placeholder={placeholder}
          disabled={disabled}
          options={options}
          infoTooltipTitle={infoTooltipTitle}
          className={clsx(
            isOpen
              ? "border-surface-default border border-solid"
              : "shadow-input-2",
            {
              "smallinput !px-[12px] !py-[8px] !rounded-[8px] input-field !h-[36px]":
                size === "small",
            }
          )}
          isTag
        />
        {(() => {
          if (!isOpen) return;
          const child = (
            <ul className={clsx("top-0 absolute left-0 right-0", optionsClsx)}>
              {options?.map((option, idx) => {
                const { label, value } = option;
                const optionClsx = clsx({
                  "select-option": true,
                  "select-option-border": idx !== options.length - 1,
                  "select-option-active":
                    JSON.stringify(selected) === JSON.stringify(value),
                });
                return (
                  <li
                    key={idx}
                    className={optionClsx}
                    onClick={() => handleOptionClick(value)}
                  >
                    {option?.tag && (
                      <div
                        className="w-6 h-6 rounded-sm font-medium flex justify-center items-center mr-2"
                        style={{
                          color: option?.textColor,
                          background: option?.bgColor,
                        }}
                      >
                        {option?.tag}
                      </div>
                    )}
                    {option?.image && (
                      <img src={option?.image} alt="option" className="mr-2" />
                    )}
                    {label}
                  </li>
                );
              })}
            </ul>
          );

          return child;
        })()}
      </div>
      {helperText && <span className="select-helper-text">{helperText}</span>}
    </div>
  );
};

export default Select;
