import clsx from "clsx";
import { useEffect, useState } from "react";
import Slider from "react-rangeslider";
// To include the default styles
import "react-rangeslider/lib/index.css";
import "./stepSlider.css";

interface IStepSlider {
  min: number;
  max: number;
  step?: number;
  className?: string;
  value: number;
  onChange?: any;
  onChangeStart?: any;
  onChangeComplete?: any;
}

const StepSlider: React.FC<IStepSlider> = ({
  min,
  max,
  step,
  className,
  value,
  onChange,
  ...props
}) => {
  const [rangeValue, setRangeValue] = useState(value || 0);

  useEffect(() => {
    setRangeValue(value);
  }, [value]);

  const handleValue = (val: number) => {
    onChange(val);
  };

  return (
    <div className={clsx("w-full", className)}>
      <Slider
        min={min}
        max={max}
        value={rangeValue}
        step={step}
        onChange={handleValue}
        {...props}
      />
    </div>
  );
};

export default StepSlider;
