const FaceIcon = ({ active = false }) => {
  const fillClass = active ? "#1570EF" : "black";
  const strokeClass = active ? "#1570EF" : "black";
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="6.08862"
        y="7.2738"
        width="2.64516"
        height="2.59524"
        rx="1.29762"
        stroke={strokeClass}
        strokeWidth="0.5"
      />
      <path
        d="M8.89945 4.06835C8.05238 3.81154 7.14849 3.7946 6.28887 4.01943C5.42924 4.24425 4.64775 4.70197 4.03177 5.34142C3.41579 5.98087 2.98961 6.77684 2.80086 7.64033C2.61212 8.50382 2.66826 9.4008 2.96301 10.231C3.25775 11.0612 3.77949 11.7918 4.46988 12.3413C5.16027 12.8907 5.9921 13.2373 6.87236 13.3422C7.75263 13.4472 8.64664 13.3063 9.45443 12.9355C10.2622 12.5647 10.952 11.9785 11.4465 11.2424"
        stroke={strokeClass}
        strokeWidth="0.5"
      />
      <path
        d="M4.14516 8.86906L4.14516 8.53851C4.14516 7.42659 4.77628 6.40415 5.77822 5.89287V5.89287"
        stroke={strokeClass}
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse
        cx="6.50421"
        cy="5.53575"
        rx="0.181452"
        ry="0.178571"
        fill={fillClass}
      />
      <path
        d="M7.65323 2.32141H7.14113C6.58884 2.32141 6.14113 2.76913 6.14113 3.32141V3.52423C6.14113 3.84318 5.95536 4.13292 5.66552 4.26603V4.26603C5.3987 4.38857 5.08662 4.35837 4.84825 4.18694L4.37184 3.84432C3.96504 3.55176 3.40452 3.60545 3.06064 3.9699L2.67499 4.37863C2.33735 4.73646 2.31016 5.28673 2.61086 5.67612L2.9953 6.17392C3.14517 6.36799 3.17875 6.62779 3.08315 6.85359V6.85359C2.99136 7.07038 2.79382 7.22414 2.56114 7.25992L1.84802 7.36958C1.36015 7.4446 1 7.86436 1 8.35796V8.9039C1 9.3975 1.36015 9.81726 1.84802 9.89228L2.56548 10.0026C2.79547 10.038 2.99072 10.19 3.08144 10.4042V10.4042C3.17779 10.6318 3.14126 10.8939 2.98636 11.0865L2.65579 11.4974C2.33384 11.8976 2.36745 12.4767 2.73354 12.837L3.2049 13.3008C3.56804 13.6582 4.1418 13.6854 4.53714 13.364L4.92258 13.0507C5.11349 12.8955 5.3755 12.861 5.60008 12.9614V12.9614C5.78827 13.0456 5.92556 13.2138 5.97029 13.4151L6.14853 14.217C6.25023 14.6745 6.65603 15 7.12471 15H7.74352C8.24556 15 8.66977 14.6278 8.73504 14.13L8.80506 13.5959C8.84068 13.3243 9.02281 13.0942 9.27906 12.9972V12.9972C9.51044 12.9096 9.77024 12.9429 9.97211 13.0859L10.4783 13.4446C10.8735 13.7247 11.4126 13.6812 11.7579 13.3414L12.6129 12.5"
        stroke={strokeClass}
        strokeWidth="0.5"
      />
      <rect
        x="8.75"
        y="0.25"
        width="7"
        height="2.59524"
        rx="0.75"
        stroke={strokeClass}
        strokeWidth="0.5"
      />
      <rect
        x="9.83862"
        y="5.72607"
        width="4.82258"
        height="1.04762"
        rx="0.523809"
        stroke={strokeClass}
        strokeWidth="0.5"
      />
      <rect
        x="10.6855"
        y="7.15479"
        width="3.25"
        height="1.04762"
        rx="0.523809"
        stroke={strokeClass}
        strokeWidth="0.5"
      />
      <path
        d="M11.1694 9.33325C11.1694 8.91904 11.5052 8.58325 11.9194 8.58325H12.5807C12.9949 8.58325 13.3307 8.91904 13.3307 9.33325V9.44626C13.3307 9.70461 13.1978 9.94478 12.9788 10.0819L12.6482 10.289C12.4047 10.4415 12.0955 10.4415 11.852 10.289L11.5214 10.0819C11.3024 9.94478 11.1694 9.70461 11.1694 9.44626V9.33325Z"
        stroke={strokeClass}
        strokeWidth="0.5"
      />
      <rect
        x="12.1894"
        y="10.7747"
        width="0.120968"
        height="0.831413"
        stroke={strokeClass}
        strokeWidth="0.120968"
      />
      <path
        d="M10.3535 5.34524L9.37473 3.2262H15.1171L14.0894 5.34524H10.3535Z"
        stroke={strokeClass}
        strokeWidth="0.5"
      />
    </svg>
  );
};

export default FaceIcon;
