import { useContext, useEffect, useMemo } from "react";
import Input from "components/Input";
import {
  POSITIVE_VALIDATION,
  QUANTITY_VALIDATION,
  SET_MACHINE_SETUP,
  TOP_FACE_FINISHING_SURFACE_OPTIONS,
  TOP_FACE_VALIDATION,
  decimalRegExp,
  onlyNumberRegexExp,
} from "constant";
import { useFormik } from "formik";
import * as Yup from "yup";
import { GlobalContext } from "context/GlobalContext";
import RadioComponent from "components/RadioComponent";
import TooltipContent from "../../FeatureToolTipContent";
import FeatureInfoIcon from "assets/images/icons/feature-info.svg";
import TooltipV2 from "components/ToolTipV2";

interface ITopFace {
  feature_info?: any;
  feature_id?: string;
}

const init_top_face = {
  length: "",
  width: "",
  stock_to_remove: "",
  finishing_surface: "",
  vertical_clearance: "",
  quantity: "",
  horizontal_clearance: "",
};

const TopFace: React.FC<ITopFace> = ({ feature_info, feature_id }) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { machiningSetups, projectInfo, setupNumber } = state;

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        length: Yup.number().required("This field is required"),
        // .min(
        //   TOP_FACE_VALIDATION.length.min[projectInfo?.unit || "mm"],
        //   `Value must be at least ${
        //     TOP_FACE_VALIDATION.length.min[projectInfo?.unit || "mm"]
        //   }`
        // ),
        width: Yup.number().required("This field is required"),
        // .min(
        //   TOP_FACE_VALIDATION.width.min[projectInfo?.unit || "mm"],
        //   `Value must be at least ${
        //     TOP_FACE_VALIDATION.width.min[projectInfo?.unit || "mm"]
        //   }`
        // ),
        stock_to_remove: Yup.number().required("This field is required"),
        // .min(
        //   TOP_FACE_VALIDATION.stock_to_remove.min[projectInfo?.unit || "mm"],
        //   `Value must be at least ${
        //     TOP_FACE_VALIDATION.stock_to_remove.min[projectInfo?.unit || "mm"]
        //   }`
        // ),
        quantity: QUANTITY_VALIDATION,
        vertical_clearance: POSITIVE_VALIDATION,
        horizontal_clearance: POSITIVE_VALIDATION,
      }),
    []
  );

  useEffect(() => {
    if (!feature_info) return;
    let formValues: any = {};
    Object.keys(init_top_face).forEach((key: string) => {
      formValues = {
        ...formValues,
        [key]: feature_info?.[key] ?? "",
      };
    });
    setValues(formValues);
  }, [feature_info]);

  const {
    handleChange,
    handleBlur,
    setValues,
    setFieldValue,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: init_top_face,
    validationSchema,
    onSubmit: () => {},
  });

  const handleChangeFunc = (key: string, value: any, flag: boolean = false) => {
    if (flag) setFieldValue(key, value);

    let updated_setups = [...machiningSetups];

    const setupIdx = updated_setups?.findIndex(
      (setup: any) => setup?.machiningSetupNumber === setupNumber
    );

    const feature_idx = updated_setups?.[setupIdx]?.features?.findIndex(
      (feature: any) => feature?.feature_id === feature_id
    );

    let updated_feature = {
      ...updated_setups?.[setupIdx]?.features?.[feature_idx]?.featureInformation
        ?.feature_data?.feature_info,
    };

    if (key === "finishing_surface") {
      updated_feature = {
        ...updated_feature,
        [key]: value,
      };
    } else {
      if (value !== "") {
        updated_feature = {
          ...updated_feature,
          [key]: Number(value),
        };
      } else delete updated_feature[key];
    }

    updated_setups[setupIdx].features[
      feature_idx
    ].featureInformation.feature_data.feature_info = updated_feature;

    dispatch({
      type: SET_MACHINE_SETUP,
      payload: updated_setups,
    });
  };

  return (
    <div className="relative">
      <div className="mt-4">
        <p className="font-semibold text-base text-gray-202">
          Feature Dimensions
        </p>
        <div className="flex flex-row mt-4">
          <div className="w-6/12">
            <Input
              handleFocus={handleBlur}
              placeholder="Face Length"
              className="mr-2"
              type="text"
              unit={projectInfo?.unit || "mm"}
              name="length"
              value={values?.length}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc("length", values?.length);
              }}
              infoTooltipTitle={TooltipContent("top_face", "length")}
              invalid={touched.length && errors.length}
              helperText={touched.length && errors.length}
              regex={decimalRegExp}
            />
          </div>

          <div className="w-6/12">
            <Input
              handleFocus={handleBlur}
              placeholder="Face Width"
              className="ml-2"
              type="text"
              name="width"
              unit={projectInfo?.unit || "mm"}
              value={values?.width}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc("width", values?.width);
              }}
              infoTooltipTitle={TooltipContent("top_face", "width")}
              invalid={touched.width && errors.width}
              helperText={touched.width && errors.width}
              regex={decimalRegExp}
            />
          </div>
        </div>
      </div>

      <div className="mt-4">
        <p className="font-semibold text-base text-gray-202">Stock Inputs</p>
        <div className="flex flex-row mt-4">
          <div className="w-6/12">
            <Input
              handleFocus={handleBlur}
              placeholder="Stock to remove"
              className="pr-2"
              type="text"
              unit={projectInfo?.unit || "mm"}
              name="stock_to_remove"
              value={values?.stock_to_remove}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc("stock_to_remove", values?.stock_to_remove);
              }}
              infoTooltipTitle={TooltipContent("top_face", "stockToRemove")}
              invalid={touched.stock_to_remove && errors.stock_to_remove}
              helperText={touched.stock_to_remove && errors.stock_to_remove}
              regex={decimalRegExp}
            />
          </div>
        </div>
      </div>

      <div className="mt-4">
        <p className="font-semibold text-base text-gray-202">Other Inputs</p>
        <div className="flex flex-row mt-4">
          <div className="w-6/12">
            <Input
              handleFocus={handleBlur}
              placeholder="Quantity"
              className="pr-2"
              type="text"
              name="quantity"
              value={values?.quantity}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc("quantity", values?.quantity);
              }}
              infoTooltipTitle={TooltipContent("top_face", "quantity")}
              invalid={touched.quantity && errors.quantity}
              helperText={touched.quantity && errors.quantity}
              regex={onlyNumberRegexExp}
            />
          </div>

          <div className="flex justify-between items-center w-6/12 py-[14px]">
            <div className="flex align-baseline ml-2">
              <label className="font-semibold text-gray-475 truncate mr-2">
                Finishing Surface
              </label>
              <TooltipV2
                openOnHover={false}
                title={TooltipContent("top_face", "finishingSurface")}
              >
                <div className="my-auto">
                  <img
                    className="inline-block"
                    src={FeatureInfoIcon}
                    alt=""
                    height={16}
                    width={16}
                  />
                </div>
              </TooltipV2>
            </div>
            <RadioComponent
              options={TOP_FACE_FINISHING_SURFACE_OPTIONS}
              setValue={(val: any) =>
                handleChangeFunc("finishing_surface", val, true)
              }
              value={values?.finishing_surface}
            />
          </div>
        </div>

        <div className="mt-4">
          <p className="font-semibold text-base text-gray-202">
            Clearance Inputs
          </p>
          <div className="flex flex-row mt-4">
            <div className="flex w-6/12">
              <Input
                handleFocus={handleBlur}
                placeholder="Vertical Clearance"
                className="w-full mr-2"
                type="text"
                unit={projectInfo?.unit || "mm"}
                name="vertical_clearance"
                value={values?.vertical_clearance}
                onChange={handleChange}
                onBlur={(e: any) => {
                  handleBlur(e);
                  handleChangeFunc(
                    "vertical_clearance",
                    values?.vertical_clearance
                  );
                }}
                infoTooltipTitle={TooltipContent(
                  "top_face",
                  "verticalClearance"
                )}
                invalid={
                  touched.vertical_clearance && errors.vertical_clearance
                }
                helperText={
                  touched.vertical_clearance && errors.vertical_clearance
                }
                regex={decimalRegExp}
              />
            </div>
            <div className="flex w-6/12">
              <Input
                handleFocus={handleBlur}
                placeholder="Horizontal Clearance"
                className="w-full ml-2"
                type="text"
                unit={projectInfo?.unit || "mm"}
                name="horizontal_clearance"
                value={values?.horizontal_clearance}
                onChange={handleChange}
                onBlur={(e: any) => {
                  handleBlur(e);
                  handleChangeFunc(
                    "horizontal_clearance",
                    values?.horizontal_clearance
                  );
                }}
                infoTooltipTitle={TooltipContent(
                  "top_face",
                  "horizontalClearance"
                )}
                invalid={
                  touched.horizontal_clearance && errors.horizontal_clearance
                }
                helperText={
                  touched.horizontal_clearance && errors.horizontal_clearance
                }
                regex={decimalRegExp}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopFace;
