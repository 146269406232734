import { Dispatch, SetStateAction } from "react";

export interface ICreateStrategyModal {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  data: any;
  onAdd: any;
}

export const modalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  backgroundColor: "white",
  transform: "translate(-50%, -50%)",
  maxWidth: "450px",
  width: "100%",
  borderRadius: "16px",
  outline: "none",
};

export const customStrategyReducer = (state: any, action: any): any => {
  switch (action.type) {
    case "SET-PASSES":
      return {
        ...state,
        passes: action.payload.map((pass: string) => {
          return { pass, operations: [] };
        }),
      };
    case "SET-OPERATION": {
      const { passIndex, operation } = action.payload;
      if (state.passes[passIndex].selectedData) {
        state.passes[passIndex].operations[
          state.passes[passIndex].selectedOperationIndex
        ] = operation;
        state.passes[passIndex].selectedData = null;
        state.passes[passIndex].selectedOperationIndex = null;
      } else {
        state.passes[passIndex].operations.push(operation);
      }
      state.passes[passIndex].openForm = false;
      return {
        ...state,
      };
    }
    case "ADD-NEW-OPERATION": {
      const { passIndex } = action.payload;
      state.passes[passIndex].openForm = true;
      return {
        ...state,
      };
    }
    case "SET-FORM-VIEW-STATUS": {
      const { passIndex, value } = action.payload;
      state.passes[passIndex].openForm = value;
      return {
        ...state,
      };
    }
    case "SET-SELECTED-DATA": {
      const { passIndex, operationIndex, reset } = action.payload;
      state.passes[passIndex].selectedData = reset
        ? null
        : state.passes[passIndex].operations[operationIndex];
      state.passes[passIndex].selectedOperationIndex = reset
        ? null
        : operationIndex;
      return {
        ...state,
      };
    }
    case "DELETE-OPERATION": {
      const { passIndex, operationIndex } = action.payload;
      state.passes[passIndex].operations.splice(operationIndex, 1);
      return {
        ...state,
      };
    }
    default:
      return {
        ...state,
      };
  }
};
