import clsx from "clsx";

interface ThProps {
  children?: any;
  className?: string;
}
const Th: React.FC<ThProps> = ({ children, className }) => {
  return (
    <th scope="col" className={clsx("border-hidden", className)}>
      <div className="w-full text-sm text-white font-medium text-left px-4 bg-surface-default cell h-10 flex items-center">
        {children}
      </div>
    </th>
  );
};

export default Th;
