import Modal from "react-modal";
import { useEffect } from "react";
import WarningSign from "assets/images/icons/uil_exclamation-triangle.svg";
import {
  customStylesConfirmPopUp,
  IConfirmPopUp,
} from "../../constant/invalidPopupUtils";
import Button from "components/Button";

const ConfirmPopUp: React.FC<IConfirmPopUp> = ({
  showConfirmPopUp,
  closeConfirmPopUp,
  confirmFunction,
  confirmMessage = "Are you sure you want to mark this strategy as Invalid?",
}) => {
  useEffect(() => {
    const warningTriangleImage = new Image();
    warningTriangleImage.src = WarningSign;
  }, []);

  return (
    <Modal
      isOpen={showConfirmPopUp}
      style={customStylesConfirmPopUp}
      onRequestClose={closeConfirmPopUp}
    >
      <div className="p-[16px] flex flex-col justify-center text-center gap-[32px]">
        <div>
          <img src={WarningSign} alt="Err" className="mx-auto" />
          <div className="text-[14px] font-normal">{confirmMessage}</div>
        </div>
        <div className="flex justify-between">
          <Button
            variant="modalGray"
            className="modal-button"
            onClick={closeConfirmPopUp}
          >
            Cancel
          </Button>
          <Button
            className="modal-button"
            onClick={() => {
              closeConfirmPopUp();
              confirmFunction();
            }}
          >
            Yes, Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmPopUp;
