import {
  useEffect,
  useState,
  useContext,
  Dispatch,
  SetStateAction,
  useCallback,
} from "react";
import { Checkbox } from "components/Checkbox";
import clsx from "clsx";
import ScatterChart from "components/ScatterChart";
import { GlobalContext } from "context/GlobalContext";
import { renderToString } from "react-dom/server";
import LoadingImg from "assets/images/loading-icon.gif";
import ToolPathButtonImg from "assets/images/icons/ph_path-fill.svg";
import InValidCross from "assets/images/icons/uiw_stop-o.svg";
import InValidCrossRed from "assets/images/icons/uiw_stop-o-red.svg";
import InValidToolPopUp from "./InValidToolPopUp";
import { useApiCall } from "hooks";
import { getParametersAPI } from "services";
import EditCuttingParametersModal from "components/EditCuttingParameters";
import { Menu, MenuItem } from "@mui/material";
import { BsThreeDotsVertical } from "react-icons/bs";
import EditIcon from "assets/images/icons/edit.svg";
import UndoIcon from "assets/images/icons/undo-params.svg";
import {
  SET_LOADING_BAR,
  SET_MACHINE_SETUP,
  OPERATIONS_NOT_SUPPORTING_STEPDOWN,
  OPERATIONS_NOT_SUPPORTING_STEPOVER,
} from "constant";
import AdditionalToolParamsModal from "components/AdditionalToolParamsModal";
import { createDeepCopy } from "utils";

interface IToolbox {
  className?: string;
  tool?: any;
  index: number;
  selectedTool?: any;
  setSelectedTool?: any;
  confirmInValidData?: Function;
  selectedStep: any;
  boxData: any;
  setBoxData: Dispatch<SetStateAction<any>>;
}

// 1. Tool specification
// 2. Tool material
// 3. Diameter (mm)
// 4. Flute Length (mm)
// 5. Total Length (mm)

const fetchCommonLabels = (measurement_unit: string) => {
  return [
    { label: "Tool ID", value: "Tool ID", unit: "" },
    { label: "Tool material", value: "Body material code", unit: "" },
    { label: "Total Length", value: "Body length", unit: measurement_unit },
    {
      label: "Cutting Diameter",
      value: "Cutting diameter",
      unit: measurement_unit,
    },
    { label: "Flute Length", value: "Flute length", unit: measurement_unit },
    {
      label: "Shaft Diameter",
      value: "Shaft diameter",
      unit: measurement_unit,
    },
    { label: "Flute Count", value: "Flute count", unit: "" },
  ];
};

const fetchToolTipContent = (toolType: string, measurement_unit: string) => {
  let labelArray = [...fetchCommonLabels(measurement_unit)];
  switch (toolType) {
    case "face mill":
    case "slot mill":
    case "t-slot mill":
    case "bull nose end mill":
    case "reamer":
      labelArray.push({
        label: "Corner Radius",
        value: "Corner radius",
        unit: measurement_unit,
      });
      break;
    case "boring bar":
      labelArray.push(
        {
          label: "Corner Radius",
          value: "Corner radius",
          unit: measurement_unit,
        },
        { label: "Description", value: "Description", unit: "" }
      );
      break;
    case "chamfer mill":
    case "taper mill":
    case "tapered ball":
    case "tapered bull nose":
      labelArray.push({ label: "Taper Angle", value: "Taper angle", unit: "" });
      break;
    case "center drill":
    case "spot drill":
    case "drill":
      labelArray.push({ label: "Point angle", value: "Point angle", unit: "" });
      break;
    case "tap":
    case "tap right hand":
    case "thread former":
    case "thread mill":
      labelArray.push({
        label: "Thread Pitch",
        value: "Thread pitch",
        unit: measurement_unit,
      });
      break;
  }
  return labelArray;
};

const TooltipContent = ({
  tool,
  measurement_unit,
}: {
  tool: any;
  measurement_unit: string;
}) => {
  const TOOL_TYPE = tool?.["Tool type"];
  const LABEL_ARRAY = fetchToolTipContent(TOOL_TYPE, measurement_unit);
  return (
    <div>
      {LABEL_ARRAY.map(({ label, value, unit }, idx) =>
        !unit ? (
          <p key={idx}>{`${label}: ${tool?.[value] ?? "N/A"}`}</p>
        ) : (
          <p key={idx}>{`${label} (${unit}): ${tool?.[value] ?? "N/A"}`}</p>
        )
      )}
    </div>
  );
};

const InvalidTooltipContent = ({ data }: any) => {
  return (
    <div
      className="max-w-[200px] h-auto"
      style={{ overflowWrap: "break-word" }}
    >
      {data}
    </div>
  );
};

const Toolbox: React.FC<IToolbox> = ({
  className,
  tool,
  selectedTool,
  setSelectedTool,
  index,
  confirmInValidData,
  selectedStep,
  boxData,
  setBoxData,
}) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { showTooltip, hideTooltip, projectInfo, machiningSetups } = state;
  const [seriesData, setSeriesData] = useState<any>([]);
  const [redPoint, setRedPoint] = useState<any>([]);
  const [yellowPoint, setYellowPoint] = useState<any>([]);
  const [showEditCuttingParamsModal, setShowEditCuttingParamsModal] =
    useState(false);
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [showInvalidPopUp, setShowInvalidPopUp] = useState(false);
  const measurement_unit: string = projectInfo?.unit;
  const [getParameters, loading] = useApiCall(getParametersAPI);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(menuAnchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  let inValidBorder =
    tool?.isValid === false ||
    tool?.valid_data?.["Spindle speed"]?.isInValid ||
    tool?.valid_data?.["Feed rate"]?.isInValid ||
    tool?.valid_data?.["Stepover [Ae]"]?.isInValid ||
    tool?.valid_data?.["Stepdown [Ap]"]?.isInValid;
  const handleClose = () => {
    setPopUpOpen(false);
  };

  const handleOpen = () => {
    setPopUpOpen(true);
  };
  // const [fetchData]: any = useApiCall(getGraphDataAPI);

  useEffect(() => {
    dispatch({
      type: SET_LOADING_BAR,
      payload: loading,
    });
  }, [dispatch, loading]);

  useEffect(() => {
    if (!tool) return;
    if (!tool.non_optimal_points) {
      const paramsRequestPayload = createDeepCopy(tool.additional_info);
      paramsRequestPayload.machining_strategy.non_optimal_points_only = true;
      getParameters([paramsRequestPayload]).then((res: any) => {
        if (!res) return;
        setSeriesData(
          res?.cutting_tools_parameters?.[0]?.cutting_tools_parameters?.[0]
            ?.non_optimal_points || []
        );
      });
    } else {
      setSeriesData(tool["non_optimal_points"] || []);
    }
    if (tool?.original_optimal_point) {
      setRedPoint([
        [
          Number(
            tool?.original_optimal_point["Tool life"]?.value?.toFixed(2)
          ) || 0,
          Number(
            tool?.original_optimal_point[
              "Material removal rate"
            ]?.value?.toFixed(2)
          ) || 0,
        ],
      ]);
      setYellowPoint([
        [
          Number(tool["Tool life"]?.value?.toFixed(2)) || 0,
          Number(tool["Material removal rate"]?.value?.toFixed(2)) || 0,
        ],
      ]);
    } else {
      setRedPoint([
        [
          Number(tool["Tool life"]?.value?.toFixed(2)) || 0,
          Number(tool["Material removal rate"]?.value?.toFixed(2)) || 0,
        ],
      ]);
    }
  }, [getParameters, tool]);

  const handleCheckTool = (flag: boolean) => {
    setSelectedTool(index, flag);
  };

  const menuOptions = [
    {
      image: (
        <img
          src={!inValidBorder ? InValidCross : InValidCrossRed}
          alt="InValid"
          width="12px"
        />
      ),
      label: inValidBorder ? "Mark as Valid" : "Mark as Invalid",
      action: () => {
        setMenuAnchorEl(null);
        setShowInvalidPopUp(true);
      },
    },
    {
      image: <img src={EditIcon} alt="edit-icon" />,
      label: "Edit",
      action: () => {
        setMenuAnchorEl(null);
        setShowEditCuttingParamsModal(true);
      },
    },
    ...(tool.paramsFlag === "user-edited"
      ? [
          {
            image: <img src={UndoIcon} alt="undo-icon" />,
            label: "Undo",
            action: () => {
              setMenuAnchorEl(null);
              revertToPreviousParams();
            },
          },
        ]
      : []),
  ];

  const revertToPreviousParams = useCallback(() => {
    const updated_setups = [...machiningSetups];
    const feature_idx = updated_setups?.[0]?.features?.findIndex(
      (feature: any) =>
        feature?.feature_id === tool?.additional_info?.feature_id
    );
    const feature_information = {
      ...updated_setups?.[0]?.features?.[feature_idx]?.featureInformation,
    };
    const selected_strategy_idx =
      feature_information?.strategies?.[0]?.machining_strategy?.findIndex(
        (item: any) => item?.isSelected
      );
    const paramsObject = feature_information.strategies[0].machining_strategy[
      selected_strategy_idx
    ].passes[selectedStep?.index - 1].operations[selectedStep?.subIndex][
      "parameters"
    ]?.find((param: any) => param?.["Tool ID"] === tool?.["Tool ID"]);

    if (!paramsObject?.originalParams) return;
    const paramsRequestPayload = {
      ...tool.additional_info,
      custom_cutting_parameters: [
        {
          cutting_speed: {
            value: paramsObject.originalParams["Spindle speed"].value,
            units: paramsObject.originalParams["Spindle speed"].unit,
          },
          cutting_feed: {
            value: paramsObject.originalParams["Feed rate"].value,
            units: paramsObject.originalParams["Feed rate"].unit,
          },
          ...(paramsObject.originalParams["Stepover [Ae]"] && {
            radial_depth_of_cut: {
              value: paramsObject.originalParams["Stepover [Ae]"].value,
              units: paramsObject.originalParams["Stepover [Ae]"].unit,
            },
          }),
          ...(paramsObject.originalParams["Stepdown [Ap]"] && {
            axial_depth_of_cut: {
              value: paramsObject.originalParams["Stepdown [Ap]"].value,
              units: paramsObject.originalParams["Stepdown [Ap]"].unit,
            },
          }),
        },
      ],
    };
    getParameters([paramsRequestPayload]).then((res: any) => {
      if (!res) return;
      const parameters =
        res?.cutting_tools_parameters?.[0]?.cutting_tools_parameters?.[0];
      delete parameters?.non_optimal_points;
      if (parameters) parameters.paramsFlag = "user-undo";
      Object.assign(paramsObject, parameters);
      Object.assign(boxData[index], parameters);
      boxData[index] = { ...boxData[index] };
      dispatch({
        type: SET_MACHINE_SETUP,
        payload: updated_setups,
      });
      setBoxData([...boxData]);
    });
  }, [
    machiningSetups,
    selectedStep,
    tool,
    getParameters,
    dispatch,
    setBoxData,
    boxData,
    index,
  ]);

  return (
    <div
      className={clsx(
        "shadow-input-2 min-w-[260px] max-w-[300px] flex flex-col flex-1 border border-black border-opacity-10",
        className,
        {
          "border-[1px] border-solid border-[#F04438] !border-opacity-100":
            inValidBorder,
        }
      )}
    >
      <div className="px-[10px] pt-[10px] mb-1">
        <div className="flex flex-row justify-between items-center mb-2">
          <div className="flex flex-row truncate">
            <Checkbox
              checked={selectedTool === tool?.["Tool ID"]}
              onChange={(val: boolean) => {
                handleCheckTool(val);
              }}
              type="yellow"
              disabled={tool?.isValid === false}
            />
            <span
              className="font-semibold text-sm text-black truncate ml-2"
              id={tool?.["Tool ID"]}
              onMouseOver={() => showTooltip(tool?.["Tool ID"])}
              onMouseLeave={() => hideTooltip()}
              data-tooltip-html={renderToString(
                <TooltipContent
                  tool={tool}
                  measurement_unit={measurement_unit}
                />
              )}
            >
              {tool?.["Tool ID"] || "N/A"}
            </span>
          </div>
          <div>
            <button
              onClick={handleClick}
              id={`${tool["Tool ID"]}-options`}
              className="!p-0"
            >
              <BsThreeDotsVertical className="!h-5 !w-[24px]" />
            </button>
            <Menu
              anchorEl={menuAnchorEl}
              open={menuOpen}
              onClose={() => {
                setMenuAnchorEl(null);
              }}
              id={`${tool["Tool ID"]}-options`}
              MenuListProps={{
                "aria-labelledby": `${tool["Tool ID"]}-options`,
                sx: {
                  py: 0,
                  fontWeight: "medium",
                },
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {menuOptions.map((option: any, index: number) => (
                <MenuItem
                  key={`${tool["Tool ID"]}-option-${index}`}
                  sx={{
                    py: "5px",
                    px: "8px",
                    width: "115px",
                    height: "28px",
                    fontSize: "11px",
                    fontWeight: 500,
                  }}
                  onClick={option.action}
                >
                  <div className="flex gap-2">
                    {option.image}
                    {option.label}
                  </div>
                </MenuItem>
              ))}
            </Menu>
          </div>
          <InValidToolPopUp
            showPopUp={showInvalidPopUp}
            closePopUp={() => setShowInvalidPopUp(false)}
            tool={{ ...tool }}
            confirmInValidData={confirmInValidData}
          />
          {/* <span
            className={clsx("text-xs font-semibold whitespace-nowrap", {
              "text-green-1db": tool?.label === "Lowest cost",
              "text-red-ff5": tool?.label !== "Lowest cost",
            })}
          >
            {tool?.label}
          </span> */}
        </div>
        <div className="h-[200px] w-full flex justify-center items-center">
          {seriesData?.length ? (
            <ScatterChart
              seriesData={seriesData}
              redPoint={redPoint}
              yellowPoint={yellowPoint}
              units={measurement_unit}
            />
          ) : (
            <img src={LoadingImg} className="w-22 h-20" alt="loader" />
          )}
        </div>
        {/* <div className="w-full mb-1">
          <StepSlider
            min={0}
            max={100}
            step={25}
            value={mrrValue}
            onChange={setMrrValue}
          />
          <div className="flex justify-between">
            <div className="flex flex-col">
              <span className="text-gray-807 font-medium text-[10px]">MRR</span>
              <span className="text-black text-xs font-medium">
                {mrrValue}%
              </span>
            </div>
            <div className="flex flex-col justify-end">
              <span className="text-gray-807 font-medium text-[10px]">
                Tool Life
              </span>
              <span className="text-black text-xs font-medium text-end">
                {100 - mrrValue}%
              </span>
            </div>
          </div>
        </div> */}
        {/* <div className="py-1">
          <SquareCheckbox
            checked={false}
            onChange={() => {}}
            label="Save configuration"
          ></SquareCheckbox>
        </div> */}
      </div>
      <div className={`${tool?.isValid === false ? "opacity-60" : ""}`}>
        <div className="w-full flex border-t border-black border-opacity-10">
          <div className="w-6/12 flex flex-col items-center justify-center py-0.5">
            <span className="text-gray-807 font-medium text-[10px]">
              Spindle Speed
              {tool?.valid_data?.["Spindle speed"]?.feedback && (
                <>
                  <span
                    className="text-white h-[11.67px] mx-[4px] w-[11.67px] text-[8px] inline-flex items-center justify-center bg-red-500 rounded-full"
                    id={`${tool["Tool ID"]}spindle-speed`}
                    onMouseOver={() => {
                      showTooltip(`${tool["Tool ID"]}spindle-speed`);
                    }}
                    onMouseLeave={() => hideTooltip()}
                    data-tooltip-html={renderToString(
                      <InvalidTooltipContent
                        data={
                          tool?.valid_data?.["Spindle speed"]?.feedback ?? ""
                        }
                      />
                    )}
                  >
                    i
                  </span>
                </>
              )}
            </span>
            <span
              className={`text-xs font-semibold text-center ${
                tool?.valid_data?.["Spindle speed"]?.isInValid
                  ? "text-red-500"
                  : "text-black"
              }`}
            >
              {tool["Spindle speed"]?.value?.toFixed(2) || "--"}{" "}
              {tool["Spindle speed"]?.unit}
            </span>
          </div>
          <div className="w-6/12 flex flex-col items-center justify-center py-0.5 border-l border-black border-opacity-10">
            <span className="text-gray-807 font-medium text-[10px]">
              Feed Rate
              {tool?.valid_data?.["Feed rate"]?.feedback && (
                <span
                  className="text-white h-[11.67px] mx-[4px] w-[11.67px] text-[8px] inline-flex items-center justify-center bg-red-500 rounded-full"
                  id={`${tool["Tool ID"]}feed-rate`}
                  onMouseOver={() => {
                    showTooltip(`${tool["Tool ID"]}feed-rate`);
                  }}
                  onMouseLeave={() => hideTooltip()}
                  data-tooltip-html={renderToString(
                    <InvalidTooltipContent
                      data={tool?.valid_data?.["Feed rate"]?.feedback ?? ""}
                    />
                  )}
                >
                  i
                </span>
              )}
            </span>
            <span
              className={`text-xs font-semibold text-center ${
                tool?.valid_data?.["Feed rate"]?.isInValid
                  ? "text-red-500"
                  : "text-black"
              }`}
            >
              {tool["Feed rate"]?.value?.toFixed(2) || "--"}{" "}
              {tool["Feed rate"]?.unit}
            </span>
          </div>
        </div>
        <div className="w-full flex border-t border-black border-opacity-10">
          <div className="w-6/12 flex flex-col items-center justify-center py-0.5">
            <span className="text-gray-807 font-medium text-[10px]">
              Stepover [Ae]
              {tool?.valid_data?.["Stepover [Ae]"]?.feedback && (
                <span
                  className="text-white h-[11.67px] mx-[4px] w-[11.67px] text-[8px] inline-flex items-center justify-center bg-red-500 rounded-full"
                  id={`${tool["Tool ID"]}radial-depth`}
                  onMouseOver={() => {
                    showTooltip(`${tool["Tool ID"]}radial-depth`);
                  }}
                  onMouseLeave={() => hideTooltip()}
                  data-tooltip-html={renderToString(
                    <InvalidTooltipContent
                      data={tool?.valid_data?.["Stepover [Ae]"]?.feedback ?? ""}
                    />
                  )}
                >
                  i
                </span>
              )}
            </span>
            <span
              className={`text-xs font-semibold text-center ${
                tool?.valid_data?.["Stepover [Ae]"]?.isInValid
                  ? "text-red-500"
                  : "text-black"
              }`}
            >
              {OPERATIONS_NOT_SUPPORTING_STEPOVER.has(
                tool?.additional_info?.machining_strategy?.operation
              )
                ? "N/A"
                : `${tool["Stepover [Ae]"]?.value?.toFixed(2) || "--"} ${
                    tool["Stepover [Ae]"]?.unit || ""
                  }`}
            </span>
          </div>
          <div className="w-6/12 flex flex-col items-center justify-center py-0.5 border-l border-black border-opacity-10">
            <span className="text-gray-807 font-medium text-[10px]">
              Stepdown [Ap]
              {tool?.valid_data?.["Stepdown [Ap]"]?.feedback && (
                <span
                  className="text-white h-[11.67px] mx-[4px] w-[11.67px] text-[8px] inline-flex items-center justify-center bg-red-500 rounded-full"
                  id={`${tool["Tool ID"]}axial-depth`}
                  onMouseOver={() => {
                    showTooltip(`${tool["Tool ID"]}axial-depth`);
                  }}
                  onMouseLeave={() => hideTooltip()}
                  data-tooltip-html={renderToString(
                    <InvalidTooltipContent
                      data={tool?.valid_data?.["Stepdown [Ap]"]?.feedback ?? ""}
                    />
                  )}
                >
                  i
                </span>
              )}
            </span>
            <span
              className={`text-xs font-semibold text-center ${
                tool?.valid_data?.["Stepdown [Ap]"]?.isInValid
                  ? "text-red-500"
                  : "text-black"
              }`}
            >
              {OPERATIONS_NOT_SUPPORTING_STEPDOWN.has(
                tool?.additional_info?.machining_strategy?.operation
              )
                ? "N/A"
                : `${tool["Stepdown [Ap]"]?.value?.toFixed(2) || "--"} ${
                    tool["Stepdown [Ap]"]?.unit || ""
                  }`}
            </span>
          </div>
        </div>
        <div className="w-full flex border-t border-black border-opacity-10">
          <div className="w-6/12 flex flex-col items-center justify-center bg-blue-eef py-0.5">
            <div className="w-full flex flex-col justify-center items-center py-0.5">
              <span className="text-gray-807 font-medium text-[10px]">MRR</span>
              <span className={`text-xs font-semibold text-center`}>
                {tool["Material removal rate"]?.value?.toFixed(0) || "--"}{" "}
                {tool["Material removal rate"]?.unit}
              </span>
            </div>
          </div>
          <div className="w-6/12 flex flex-col items-center justify-center bg-yellow-ffe py-0.5 border-l border-black border-opacity-10">
            <div className="w-full flex flex-col justify-center items-center py-0.5">
              <span className="text-gray-807 font-medium text-[10px]">
                Tool Life
              </span>
              <span className={`text-xs font-semibold text-center`}>
                {tool["Tool life"]?.value?.toFixed(2) || "--"}{" "}
                {tool["Tool life"]?.unit}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`w-full flex flex-col justify-center items-center mt-auto ${
          tool?.isValid === false ? "opacity-60" : ""
        }`}
      >
        <span className="text-gray-807 font-medium text-[10px] text-center">
          Torque
        </span>
        <span className={`text-xs font-semibold text-center`}>
          {tool["Torque"]?.value?.toFixed(3) || "--"} {tool["Torque"]?.unit}
        </span>
      </div>
      <div className="w-full flex flex-col justify-center items-center p-[8px] border-t border-black border-opacity-10">
        <button
          className="text-[12px] text-[#1570EF] font-semibold bg-blue-eef 
            w-full py-[6px] px-[12px] flex justify-center gap-[4px] border border-solid border-blue-200 rounded-[4px]"
          onClick={handleOpen}
        >
          <span>
            <img src={ToolPathButtonImg} alt="toolPathButton" />
          </span>
          <span className="">Tool Path Parameters</span>
        </button>
      </div>
      <AdditionalToolParamsModal
        popUpOpen={popUpOpen}
        handleClose={handleClose}
        tool={tool}
      />
      <EditCuttingParametersModal
        data={tool.additional_info}
        isOpen={showEditCuttingParamsModal}
        setIsOpen={setShowEditCuttingParamsModal}
        toolId={tool?.["Tool ID"]}
        selectedStep={selectedStep}
        boxData={boxData}
        setBoxData={setBoxData}
        index={index}
      />
    </div>
  );
};

export default Toolbox;
