import clsx from "clsx";
import { SET_SETUP_MAPPING } from "constant";
import { GlobalContext } from "context";
import { useContext, useState } from "react";

interface IFinalSummarySetupLabel {
  setupData: any;
  setupId: string;
}

const FinalSummarySetupLabel: React.FC<IFinalSummarySetupLabel> = ({
  setupData,
  setupId,
}) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { mappingForMachiningSetups } = state;
  const [isEdit, setIsEdit] = useState(false);
  const [revisionName, setRevisionName] = useState<string>(
    setupData.revisionName ?? "REV A"
  );

  const updateRevisionName = () => {
    const newObj = { ...mappingForMachiningSetups };
    newObj[setupId].revisionName = revisionName;
    dispatch({
      type: SET_SETUP_MAPPING,
      payload: newObj,
    });
  };

  const resetRevisionName = () => {
    setRevisionName(setupData.revisionName ?? "REV A");
  };

  return (
    <div className="flex flex-col">
      <div className="text-[12px] text-gray-600 font-semibold">
        Program name
      </div>
      <div className="flex items-center gap-[8px]">
        <div
          className={clsx("flex", {
            hidden: !isEdit,
          })}
        >
          <div className="text-[16px] text-gray-700 font-semibold">
            {setupData.name + "-" + setupData.programName + "-"}
          </div>
          <div className="inline-flex gap-[8px]">
            <input
              type="text"
              className="inline-flex border border-gray-200 rounded-[4px] w-auto px-[12px] py-[0px] h-[24px] text-[14px] font-semibold"
              value={revisionName}
              onChange={(event) => {
                setRevisionName(event.target.value);
              }}
            />
            <button
              type="button"
              className="bg-green-100 rounded-[4px] w-[24px] h-[24px] inline-flex align-center justify-center"
              onClick={() => {
                setIsEdit((val) => !val);
                updateRevisionName();
              }}
            >
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.16406 10.5007L8.33073 14.6673L16.6641 6.33398"
                  stroke="#039855"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            <button
              type="button"
              className="bg-gray-100 rounded-[4px] w-[24px] h-[24px] inline-flex align-center justify-center"
              onClick={() => {
                setIsEdit((val) => !val);
                resetRevisionName();
              }}
            >
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.63281 14.8692L10.002 10.5L14.3711 14.8692M14.3711 6.13086L10.0011 10.5L5.63281 6.13086"
                  stroke="#475467"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
        <div
          className={clsx("flex", {
            hidden: isEdit,
          })}
        >
          <div className="text-[16px] text-gray-700 font-semibold">
            {setupData.name + "-" + setupData.programName + "-" + revisionName}
          </div>
          <button
            type="button"
            className="w-[24px] h-[24px] inline-flex align-center justify-center"
            onClick={() => {
              setIsEdit((val) => !val);
            }}
          >
            <span className="inline-flex cursor-pointer">
              <svg
                className="w-[20px] h-[20px]"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.45682 11.9702C2.40068 11.9708 2.34498 11.9603 2.29292 11.9392C2.24087 11.9182 2.19348 11.8871 2.15349 11.8477C2.10764 11.8024 2.07236 11.7476 2.05018 11.6871C2.02799 11.6266 2.01945 11.5619 2.02516 11.4977L2.20599 9.2577C2.21374 9.15368 2.25941 9.05612 2.33432 8.98354L8.78599 2.53187C9.14547 2.19524 9.62374 2.01484 10.116 2.0302C10.605 2.034 11.0743 2.22379 11.4285 2.56104C11.7682 2.90987 11.9636 3.37418 11.9755 3.86095C11.9874 4.34772 11.8149 4.82102 11.4927 5.18604L5.03516 11.6669C4.96288 11.7389 4.86825 11.7841 4.76682 11.7952L2.49766 11.9994L2.45682 11.9702ZM3.04016 9.4852L2.91766 11.0835L4.51599 10.9377L10.8743 4.5677C10.9983 4.42098 11.0775 4.24176 11.1026 4.05132C11.1278 3.86089 11.0977 3.66725 11.016 3.49341C10.9343 3.31957 10.8044 3.17283 10.6418 3.07063C10.4791 2.96843 10.2906 2.91506 10.0985 2.91687C9.97225 2.90885 9.84569 2.92642 9.7264 2.96852C9.60712 3.01063 9.49757 3.07639 9.40432 3.16187L3.04016 9.4852Z"
                  fill="#475467"
                />
              </svg>
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default FinalSummarySetupLabel;
