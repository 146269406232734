import Modal from "components/Modal";

interface IDeleteProjectConfirmModal {
  isOpen: boolean;
  setIsOpen: any;
  deleteFunction: any;
  projectName: string;
}

const DeleteProjectConfirmModal: React.FC<IDeleteProjectConfirmModal> = ({
  isOpen,
  setIsOpen,
  deleteFunction,
  projectName,
}) => {
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} size="custom">
      <Modal.Body>
        <div className="flex flex-col text-center mt-6">
          <h2 className="font-semibold text-[16px]">Are you sure?</h2>
          <p className="text-[14px]">
            Are you sure you want to delete the <b>{projectName}</b>?
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex flex-row justify-center space-x-4 w-full mb-4">
          <button
            className="!rounded-[8px] px-[10px] !bg-red-f04 w-[140px] h-[40px] whitespace-nowrap text-white text-[14px]"
            onClick={() => {
              setIsOpen(false);
              deleteFunction();
            }}
          >
            Yes, Delete
          </button>
          <button
            className="!rounded-[8px] px-[10px] w-[140px] h-[40px] bg-surface-default text-gray-344 !text-white text-[14px]"
            onClick={() => setIsOpen(false)}
          >
            No
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteProjectConfirmModal;
