import Input from "components/Input";
import RadioComponent from "components/RadioComponent";
import {
  CONFIRM_OPTIONS,
  FINISH_TYPE_OPTIONS,
  HOLE_BOTTOM_OPTIONS,
  HOLE_BOTTOM_TYPE_OPTIONS,
  HOLE_SURFACE_TYPE_OPTIONS,
  SET_MACHINE_SETUP,
  HOLE_VALIDATION,
  POSITIVE_VALIDATION,
  BOTTOM_ANGLE_VALIDATION,
  QUANTITY_VALIDATION,
  decimalRegExp,
  negativeDecimalRegExp,
  HOLE_TYPE_OPTIONS,
  onlyNumberRegexExp,
} from "constant";
import { useContext, useEffect, useMemo } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { GlobalContext } from "context/GlobalContext";
import Select from "components/Select";
import FeatureInfoIcon from "assets/images/icons/feature-info.svg";
import TooltipContent from "../../FeatureToolTipContent";
import TooltipV2 from "components/ToolTipV2";

interface ISimpleHole {
  feature_info?: any;
  feature_id?: string;
}

const init_hole = {
  diameter: "",
  depth: "",
  quantity: "",
  hole_type: "",
  stock_to_remove: "",
  bottom_type: "",
  bottom: "",
  bottom_radius: "",
  bottom_angle: "",
  deburring: "",
  finish_type: "",
  upper_tolerance: "",
  lower_tolerance: "",
  vertical_clearance: "",
  horizontal_clearance: "",
  sequential: "",
  surface_type: "",
};

const SimpleHole: React.FC<ISimpleHole> = ({ feature_info, feature_id }) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { projectInfo, machiningSetups, setupNumber } = state;

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        diameter: Yup.number().required("This field is required"),
        // .min(
        //   HOLE_VALIDATION.diameter.min[projectInfo?.unit || "mm"],
        //   `Value must be at least ${
        //     HOLE_VALIDATION.diameter.min[projectInfo?.unit || "mm"]
        //   }`
        // ),
        depth: Yup.number().required("This field is required"),
        // .min(
        //   HOLE_VALIDATION.depth.min[projectInfo?.unit || "mm"],
        //   `Value must be at least ${
        //     HOLE_VALIDATION.depth.min[projectInfo?.unit || "mm"]
        //   }`
        // ),
        quantity: QUANTITY_VALIDATION,
        stock_to_remove: Yup.number().required("This field is required"),
        // .min(
        //   HOLE_VALIDATION.stock_to_remove.min[projectInfo?.unit || "mm"],
        //   `Value must be at least ${
        //     HOLE_VALIDATION.stock_to_remove.min[projectInfo?.unit || "mm"]
        //   }`
        // ),
        bottom_radius: Yup.number()
          .required("This field is required")
          .min(0, "Minimum value must be 0"),
        // .when("diameter", (diameter, schema) => {
        //   return diameter
        //     ? schema.max(
        //         roundOff(diameter / 2),
        //         `Value must be ${roundOff(diameter / 2)} or less`
        //       )
        //     : schema;
        // }),
        finish_type: Yup.string()
          .oneOf([...FINISH_TYPE_OPTIONS.map((option: any) => option.value)])
          .required("This field is required"),
        bottom_angle: BOTTOM_ANGLE_VALIDATION,
        upper_tolerance: Yup.number()
          .moreThan(0, "Value must be greater than 0")
          .required("This field is required"),
        lower_tolerance: Yup.number()
          .required("This field is required")
          .typeError("Please enter a valid number")
          .when("upper_tolerance", (upper_tolerance, schema) => {
            return upper_tolerance
              ? schema.lessThan(
                  upper_tolerance,
                  `Value must be less than ${upper_tolerance}`
                )
              : schema;
          }),
        vertical_clearance: POSITIVE_VALIDATION,
        horizontal_clearance: POSITIVE_VALIDATION,
      }),
    []
  );

  useEffect(() => {
    if (!feature_info) return;
    let formValues: any = {};
    Object.keys(init_hole).forEach((key: string) => {
      switch (true) {
        case key === "sequential":
        case key === "deburring":
          formValues = {
            ...formValues,
            [key]: feature_info?.[key] ? feature_info?.[key] === "yes" : "",
          };
          break;
        case key === "finish_type" &&
          ("upper_tolerance" in feature_info ||
            "lower_tolerance" in feature_info ||
            values?.finish_type === "custom"):
          formValues = {
            ...formValues,
            [key]: "custom",
          };
          break;
        default:
          formValues = {
            ...formValues,
            [key]: feature_info?.[key] ?? "",
          };
          break;
      }
    });
    setValues(formValues);
  }, [feature_info]);

  const {
    handleBlur,
    handleChange,
    setFieldValue,
    setFieldTouched,
    values,
    errors,
    touched,
    setValues,
  } = useFormik({
    initialValues: init_hole,
    validationSchema,
    onSubmit: () => {},
  });

  const handleChangeFunc = (key: string, value: any, flag: boolean = false) => {
    let updated_setups = [...machiningSetups];

    const setupIdx = updated_setups?.findIndex(
      (setup: any) => setup?.machiningSetupNumber === setupNumber
    );

    const feature_idx = updated_setups?.[setupIdx]?.features?.findIndex(
      (feature: any) => feature?.feature_id === feature_id
    );

    let updated_feature = {
      ...updated_setups?.[setupIdx]?.features?.[feature_idx]?.featureInformation
        ?.feature_data?.feature_info,
    };

    //TODO : Update logic for finish_type when finalValidation is enabled
    switch (key) {
      case "bottom_type":
        if (value === "through") {
          delete updated_feature["bottom"];
          setFieldValue("bottom", "");
          delete updated_feature["bottom_angle"];
          setFieldValue("bottom_angle", "");
          delete updated_feature["bottom_radius"];
          setFieldValue("bottom_radius", "");
        }
        updated_feature = {
          ...updated_feature,
          [key]: value,
        };
        break;
      case "bottom":
        if (value === "flat") {
          delete updated_feature["bottom_angle"];
          setFieldValue("bottom_angle", "");
        } else if (value === "v shape") {
          delete updated_feature["bottom_radius"];
          setFieldValue("bottom_radius", "");
        }
        updated_feature = {
          ...updated_feature,
          [key]: value,
        };
        break;
      case "finish_type":
        if (value !== "custom") {
          delete updated_feature.upper_tolerance;
          delete updated_feature.lower_tolerance;
          setFieldValue("upper_tolerance", "");
          setFieldValue("lower_tolerance", "");
          updated_feature = {
            ...updated_feature,
            [key]: value,
          };
        } else delete updated_feature.finish_type;
        break;
      case "deburring":
      case "sequential":
        updated_feature = {
          ...updated_feature,
          [key]: value ? "yes" : "no",
        };
        break;
      case "surface_type":
        updated_feature = {
          ...updated_feature,
          [key]: value,
        };
        break;
      case "hole_type":
        if (value === "solid") {
          delete updated_feature.stock_to_remove;
          setFieldValue("stock_to_remove", "");
        }
        updated_feature = {
          ...updated_feature,
          [key]: value,
        };
        break;
      default:
        if (value !== "") {
          updated_feature = {
            ...updated_feature,
            [key]: Number(value),
          };
        } else delete updated_feature[key];
    }
    if (flag) setFieldValue(key, value);
    updated_setups[setupIdx].features[
      feature_idx
    ].featureInformation.feature_data.feature_info = updated_feature;

    dispatch({
      type: SET_MACHINE_SETUP,
      payload: updated_setups,
    });
  };

  return (
    <div className="relative">
      <div className="mt-4">
        <p className="font-semibold text-base text-gray-202">
          Feature Dimensions
        </p>
        <div className="flex flex-row mt-4">
          <div className="w-6/12">
            <Input
              handleFocus={handleBlur}
              placeholder="Diameter"
              className="mr-2"
              type="text"
              name="diameter"
              value={values?.diameter}
              unit={projectInfo?.unit || "mm"}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc("diameter", values?.diameter);
              }}
              infoTooltipTitle={TooltipContent("simple_hole", "diameter")}
              invalid={touched.diameter && errors.diameter}
              helperText={touched.diameter && errors.diameter}
              regex={decimalRegExp}
            />
          </div>
          <div className="w-6/12">
            <Input
              handleFocus={handleBlur}
              placeholder="Depth"
              className="ml-2"
              type="text"
              name="depth"
              unit={projectInfo?.unit || "mm"}
              value={values?.depth}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc("depth", values?.depth);
              }}
              infoTooltipTitle={TooltipContent("simple_hole", "depth")}
              invalid={touched.depth && errors.depth}
              helperText={touched.depth && errors.depth}
              regex={decimalRegExp}
            />
          </div>
        </div>
      </div>

      <div className="mt-4">
        <p className="font-semibold text-base text-gray-202">Bottom Inputs</p>
        <div className="flex flex-row mt-4">
          <div className="flex justify-between items-center w-6/12 py-[14px]">
            <div className="flex align-baseline">
              <label className="font-semibold text-gray-475 truncate mr-2">
                Bottom Type
              </label>
              <TooltipV2
                openOnHover={false}
                title={TooltipContent("simple_hole", "bottomType")}
              >
                <div className="my-auto">
                  <img
                    className="inline-block"
                    src={FeatureInfoIcon}
                    alt=""
                    height={16}
                    width={16}
                  />
                </div>
              </TooltipV2>
            </div>
            <RadioComponent
              options={HOLE_BOTTOM_TYPE_OPTIONS}
              setValue={(val: any) =>
                handleChangeFunc("bottom_type", val, true)
              }
              value={values?.bottom_type}
            />
          </div>
          {values?.bottom_type === "blind" && (
            <div className="flex justify-between items-center w-6/12 py-[14px]">
              <div className="flex align-baseline ml-2">
                <label className="font-semibold text-gray-475 truncate mr-2">
                  Bottom
                </label>
                <TooltipV2
                  openOnHover={false}
                  title={TooltipContent("simple_hole", "bottom")}
                >
                  <div className="my-auto">
                    <img
                      className="inline-block"
                      src={FeatureInfoIcon}
                      alt=""
                      height={16}
                      width={16}
                    />
                  </div>
                </TooltipV2>
              </div>
              <RadioComponent
                options={HOLE_BOTTOM_OPTIONS}
                setValue={(val: any) => handleChangeFunc("bottom", val, true)}
                value={values?.bottom}
              />
            </div>
          )}
        </div>

        {values?.bottom_type === "blind" && (
          <div className="flex flex-row mt-4">
            <div className="w-6/12">
              {values?.bottom === "flat" && (
                <Input
                  handleFocus={handleBlur}
                  placeholder="Bottom Radius"
                  type="text"
                  className="mr-2"
                  name="bottom_radius"
                  unit={projectInfo?.unit || "mm"}
                  value={values?.bottom_radius}
                  onChange={handleChange}
                  onBlur={(e: any) => {
                    handleBlur(e);
                    handleChangeFunc("bottom_radius", values?.bottom_radius);
                  }}
                  infoTooltipTitle={TooltipContent(
                    "simple_hole",
                    "bottomRadius"
                  )}
                  invalid={touched.bottom_radius && errors.bottom_radius}
                  helperText={touched.bottom_radius && errors.bottom_radius}
                  regex={decimalRegExp}
                />
              )}
              {values?.bottom === "v shape" && (
                <Input
                  handleFocus={handleBlur}
                  placeholder="Bottom Angle"
                  type="text"
                  className="mr-2"
                  name="bottom_angle"
                  value={values?.bottom_angle}
                  onChange={handleChange}
                  onBlur={(e: any) => {
                    handleBlur(e);
                    handleChangeFunc("bottom_angle", values?.bottom_angle);
                  }}
                  infoTooltipTitle={TooltipContent(
                    "simple_hole",
                    "bottomAngle"
                  )}
                  invalid={touched.bottom_angle && errors.bottom_angle}
                  helperText={touched.bottom_angle && errors.bottom_angle}
                  regex={onlyNumberRegexExp}
                />
              )}
            </div>
          </div>
        )}
      </div>

      <div className="mt-4">
        <p className="font-semibold text-base text-gray-202">Other Inputs</p>
        <div className="flex flex-row mt-4">
          <div className="flex justify-between items-center w-6/12 py-[14px]">
            <div className="flex align-baseline">
              <label className="font-semibold text-gray-475 truncate mr-2">
                Sequential
              </label>
              <TooltipV2
                openOnHover={false}
                title={TooltipContent("simple_hole", "sequential")}
              >
                <div className="my-auto">
                  <img
                    className="inline-block"
                    src={FeatureInfoIcon}
                    alt=""
                    height={16}
                    width={16}
                  />
                </div>
              </TooltipV2>
            </div>
            <RadioComponent
              options={CONFIRM_OPTIONS}
              setValue={(val: any) => handleChangeFunc("sequential", val, true)}
              value={values?.sequential}
            />
          </div>
          <div className="flex justify-between items-center w-6/12 py-[14px]">
            <div className="flex align-baseline ml-2">
              <label className="font-semibold text-gray-475 truncate mr-2">
                Surface Type
              </label>
              <TooltipV2
                openOnHover={false}
                title={TooltipContent("simple_hole", "surfaceType")}
              >
                <div className="my-auto">
                  <img
                    className="inline-block"
                    src={FeatureInfoIcon}
                    alt=""
                    height={16}
                    width={16}
                  />
                </div>
              </TooltipV2>
            </div>
            <RadioComponent
              options={HOLE_SURFACE_TYPE_OPTIONS}
              setValue={(val: any) =>
                handleChangeFunc("surface_type", val, true)
              }
              value={values?.surface_type}
            />
          </div>
        </div>
        <div className="flex flex-row mt-4">
          <div className="flex justify-between items-center w-6/12 py-[14px]">
            <div className="flex align-baseline">
              <label className="font-semibold text-gray-475 truncate mr-2">
                Deburring
              </label>
              <TooltipV2
                openOnHover={false}
                title={TooltipContent("simple_hole", "deburring")}
              >
                <div className="my-auto">
                  <img
                    className="inline-block"
                    src={FeatureInfoIcon}
                    alt=""
                    height={16}
                    width={16}
                  />
                </div>
              </TooltipV2>
            </div>
            <RadioComponent
              options={CONFIRM_OPTIONS}
              setValue={(val: any) => handleChangeFunc("deburring", val, true)}
              value={values?.deburring}
            />
          </div>
          <div className="w-6/12">
            <Input
              handleFocus={handleBlur}
              placeholder="Quantity"
              className="ml-2"
              type="text"
              name="quantity"
              value={values?.quantity}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc("quantity", values?.quantity);
              }}
              infoTooltipTitle={TooltipContent("simple_hole", "quantity")}
              invalid={touched.quantity && errors.quantity}
              helperText={touched.quantity && errors.quantity}
              regex={onlyNumberRegexExp}
            />
          </div>
        </div>
        <div className="flex flex-row mt-4">
          <div className="flex justify-between items-center w-6/12 py-[14px]">
            <div className="flex align-baseline">
              <label className="font-semibold text-gray-475 truncate mr-2">
                Hole Type
              </label>
              <TooltipV2
                openOnHover={false}
                title={TooltipContent("simple_hole", "holeType")}
              >
                <div className="my-auto">
                  <img
                    className="inline-block"
                    src={FeatureInfoIcon}
                    alt=""
                    height={16}
                    width={16}
                  />
                </div>
              </TooltipV2>
            </div>
            <RadioComponent
              options={HOLE_TYPE_OPTIONS}
              setValue={(val: any) => handleChangeFunc("hole_type", val, true)}
              value={values?.hole_type}
            />
          </div>
          {values?.hole_type === "hollow" && (
            <div className="w-6/12">
              <Input
                handleFocus={handleBlur}
                placeholder="Stock to Remove"
                className="ml-2"
                type="text"
                name="stock_to_remove"
                unit={projectInfo?.unit || "mm"}
                value={values?.stock_to_remove}
                onChange={handleChange}
                onBlur={(e: any) => {
                  handleBlur(e);
                  handleChangeFunc("stock_to_remove", values?.stock_to_remove);
                }}
                infoTooltipTitle={TooltipContent(
                  "simple_hole",
                  "stockToRemove"
                )}
                infoId={`simpleHole${feature_id}-stockToRemove`}
                invalid={touched.stock_to_remove && errors.stock_to_remove}
                helperText={touched.stock_to_remove && errors.stock_to_remove}
                regex={decimalRegExp}
              />
            </div>
          )}
        </div>
      </div>
      <div className="mt-4">
        <p className="font-semibold text-base text-gray-202">
          Tolerance Inputs
        </p>
        {projectInfo?.unit === "in" ? (
          <div className="flex flex-row mt-4">
            <div className="w-6/12">
              <Input
                handleFocus={handleBlur}
                placeholder="Upper tolerance"
                type="text"
                className="mr-2"
                name="upper_tolerance"
                unit={projectInfo?.unit}
                value={values?.upper_tolerance}
                onChange={handleChange}
                onBlur={(e: any) => {
                  handleBlur(e);
                  handleChangeFunc("upper_tolerance", e?.target?.value);
                }}
                infoTooltipTitle={TooltipContent(
                  "simple_hole",
                  "upperTolerance"
                )}
                invalid={touched.upper_tolerance && errors.upper_tolerance}
                helperText={touched.upper_tolerance && errors.upper_tolerance}
                regex={decimalRegExp}
              />
            </div>
            <div className="w-6/12">
              <Input
                handleFocus={handleBlur}
                placeholder="Lower tolerance"
                type="text"
                className="ml-2"
                name="lower_tolerance"
                unit={projectInfo?.unit}
                value={values?.lower_tolerance}
                onChange={handleChange}
                onBlur={(e: any) => {
                  handleBlur(e);
                  handleChangeFunc("lower_tolerance", e?.target?.value);
                }}
                infoTooltipTitle={TooltipContent(
                  "simple_hole",
                  "lowerTolerance"
                )}
                invalid={touched.lower_tolerance && errors.lower_tolerance}
                helperText={touched.lower_tolerance && errors.lower_tolerance}
                regex={negativeDecimalRegExp}
              />
            </div>
          </div>
        ) : (
          <>
            <div className="flex flex-row mt-4">
              <div className="flex w-6/12">
                <Select
                  options={FINISH_TYPE_OPTIONS}
                  placeholder="Finish Type"
                  className="w-full mr-2"
                  value={values?.finish_type}
                  onChange={(val: any) =>
                    handleChangeFunc("finish_type", val, true)
                  }
                  handleClose={(value: string) => {
                    if (!value) setFieldTouched("finish_type", true);
                  }}
                  infoTooltipTitle={TooltipContent("simple_hole", "finishType")}
                  invalid={touched.finish_type && errors.finish_type}
                  helperText={touched.finish_type && errors.finish_type}
                  onBlur={handleBlur}
                />
              </div>
              {values?.finish_type === "custom" && (
                <div className="w-6/12">
                  <Input
                    handleFocus={handleBlur}
                    placeholder="Upper tolerance"
                    type="text"
                    className="ml-2"
                    name="upper_tolerance"
                    unit={projectInfo?.unit}
                    value={values?.upper_tolerance}
                    onChange={handleChange}
                    onBlur={(e: any) => {
                      handleBlur(e);
                      handleChangeFunc("upper_tolerance", e?.target?.value);
                    }}
                    infoTooltipTitle={TooltipContent(
                      "simple_hole",
                      "upperTolerance"
                    )}
                    invalid={touched.upper_tolerance && errors.upper_tolerance}
                    helperText={
                      touched.upper_tolerance && errors.upper_tolerance
                    }
                    regex={decimalRegExp}
                  />
                </div>
              )}
            </div>
            {values?.finish_type === "custom" && (
              <div className="w-6/12 mt-4">
                <Input
                  handleFocus={handleBlur}
                  placeholder="Lower tolerance"
                  type="text"
                  className="mr-2"
                  name="lower_tolerance"
                  unit={projectInfo?.unit}
                  value={values?.lower_tolerance}
                  onChange={handleChange}
                  onBlur={(e: any) => {
                    handleBlur(e);
                    handleChangeFunc("lower_tolerance", e?.target?.value);
                  }}
                  infoTooltipTitle={TooltipContent(
                    "simple_hole",
                    "lowerTolerance"
                  )}
                  invalid={touched.lower_tolerance && errors.lower_tolerance}
                  helperText={touched.lower_tolerance && errors.lower_tolerance}
                  regex={negativeDecimalRegExp}
                />
              </div>
            )}
          </>
        )}
      </div>
      <div className="mt-4">
        <p className="font-semibold text-base text-gray-202">
          Clearance Inputs
        </p>
        <div className="flex flex-row mt-4">
          <div className="flex w-6/12">
            <Input
              handleFocus={handleBlur}
              placeholder="Vertical Clearance"
              className="w-full mr-2"
              type="text"
              unit={projectInfo?.unit || "mm"}
              name="vertical_clearance"
              value={values?.vertical_clearance}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc(
                  "vertical_clearance",
                  values?.vertical_clearance
                );
              }}
              infoTooltipTitle={TooltipContent(
                "simple_hole",
                "verticalClearance"
              )}
              invalid={touched.vertical_clearance && errors.vertical_clearance}
              helperText={
                touched.vertical_clearance && errors.vertical_clearance
              }
              regex={decimalRegExp}
            />
          </div>
          <div className="flex w-6/12">
            <Input
              handleFocus={handleBlur}
              placeholder="Horizontal Clearance"
              className="w-full ml-2"
              type="text"
              unit={projectInfo?.unit || "mm"}
              name="horizontal_clearance"
              value={values?.horizontal_clearance}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc(
                  "horizontal_clearance",
                  values?.horizontal_clearance
                );
              }}
              infoTooltipTitle={TooltipContent(
                "simple_hole",
                "horizontalClearance"
              )}
              invalid={
                touched.horizontal_clearance && errors.horizontal_clearance
              }
              helperText={
                touched.horizontal_clearance && errors.horizontal_clearance
              }
              regex={decimalRegExp}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SimpleHole;
